import { SetState } from 'helpers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { snackbarActions } from 'store';
import { DocumentRow, useCreateDocumentMutation } from 'store/rtk/document.service';

export type FileType = 'termsAndConditions' | 'privacyPolicy' | 'marketingAndProfiling';

const useDocumentState = (
    allDocuments: DocumentRow[] | undefined,
    refetch: () => void,
    setIsModalOpen: SetState<boolean>,
    _isModalOpen: boolean
) => {
    const [createDocument] = useCreateDocumentMutation();
    const [file, setFile] = useState<File | null>(null);
    const [selectValue, setSelectValue] = useState<FileType | ''>('');
    const [isConsent, setIsConsent] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDocumentExists, setIsDocumentExists] = useState<boolean>(false);
    const [date, setDate] = useState<Date | null>(new Date());
    const dispatch = useDispatch();

    const existingDocumentsOfType = useMemo(() => {
        return allDocuments?.filter(document => document.tipology === selectValue);
    }, [allDocuments, selectValue]);

    const handleCheckExistingDocument = useCallback(() => {
        if (selectValue && file) {
            setIsDocumentExists(!!existingDocumentsOfType?.length);
        }
    }, [file, selectValue, existingDocumentsOfType]);

    const label = useMemo(() => {
        return selectValue === 'termsAndConditions'
            ? 'Termini e condizioni'
            : selectValue === 'privacyPolicy'
            ? 'Privacy Policy'
            : 'Marketing e Profilazione';
    }, [selectValue]);

    useEffect(() => {
        setIsDocumentExists(false);
        handleCheckExistingDocument();
    }, [file, handleCheckExistingDocument, selectValue]);

    const handleCreateDocument = useCallback(async () => {
        const today = new Date();

        if (isDocumentExists) {
            if (date && date <= today) {
                dispatch(
                    snackbarActions.setMessageAndType({
                        message: 'La data di scadenza deve essere successiva alla data odierna.',
                        type: 'error'
                    })
                );
                dispatch(snackbarActions.setIsOpen(true));
                return;
            }
        }

        try {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('file', file as File);
            const body = { tipology: selectValue, mandatory: isConsent.toString(), startingTime: date?.toISOString() };
            Object.entries(body).forEach(([key, value]) => value && formData.append(key, value));
            if (file?.type === 'application/pdf' && file?.name.endsWith('.pdf')) {
                await createDocument(formData).unwrap();
            } else {
                dispatch(snackbarActions.setMessageAndType({ message: 'Il file caricato non è un PDF.', type: 'error' }));
                dispatch(snackbarActions.setIsOpen(true));
                return;
            }
        } catch (error: any) {
            console.error('Failed to create document:', error);
            dispatch(
                snackbarActions.setMessageAndType({
                    message: 'Errore nella creazione del documento.',
                    type: 'error'
                })
            );
            dispatch(snackbarActions.setIsOpen(true));
            return;
        } finally {
            setIsLoading(false);
            refetch();
        }
        setFile(null);
        dispatch(snackbarActions.setMessageAndType({ message: 'File caricato.' }));
        dispatch(snackbarActions.setIsOpen(true));
        setIsModalOpen(false);
    }, [createDocument, date, dispatch, file, isConsent, refetch, selectValue, setIsModalOpen, isDocumentExists]);

    return {
        file,
        setFile,
        selectValue,
        setSelectValue,
        isConsent,
        setIsConsent,
        isLoading,
        isDocumentExists,
        date,
        setDate,
        handleCreateDocument,
        handleCheckExistingDocument,
        label
    };
};

export default useDocumentState;

