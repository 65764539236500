import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const ArrowUpSmall: SvgIconProps = ({ fill = colors.primary100 }) => {
    return (
        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="back_small">
                <path
                    id="Vector"
                    d="M0.6087 8.17014L7.6087 1.17014C7.8987 0.880136 8.3787 0.880136 8.6687 1.17014L15.6687 8.17014L14.6087 9.23014L8.1387 2.76014L1.6687 9.23014L0.6087 8.17014Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};

