import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Home: SvgIconProps = ({ fill = colors.primary90 }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1294_3399)">
                <path
                    d="M20.97 9.195C20.69 8.935 20.24 8.935 19.95 9.195L7 21.145L8.02 22.245L11.84 18.725V29.445C11.84 29.855 12.18 30.195 12.59 30.195H28.35C28.76 30.195 29.1 29.855 29.1 29.445V18.725L32.92 22.245L33.94 21.145L20.97 9.195Z"
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_1294_3399">
                    <rect width="40" height="40" rx="8" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
