import { FC, useMemo } from 'react';
import { Popup } from 'react-map-gl';
import { ArrowForward } from 'components/icons';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { TableTypes, areasActions, tabActions } from 'store';
import { PickedArea } from 'models';
import classes from './MapPopup.module.scss';
import { useGetAreaByIdQuery } from 'store/rtk/area.service';
import TrendIcon from 'components/trendIcon/TrendIcon';
import turf from 'turf';
import colors from 'styles/modules/colors.module.scss';

type MapPopupAttributes = {
    pickedArea: PickedArea;
    closeOnMove: boolean;
};

const MapPopup: FC<MapPopupAttributes> = ({ pickedArea, closeOnMove }) => {
    const { data, isFetching } = useGetAreaByIdQuery({ id: pickedArea!.id }, { skip: !pickedArea });
    const dispatch = useDispatch();

    const position = useMemo(() => {
        if (!data) return { longitude: 0, latitude: 0 };
        const point = turf.pointOnSurface(turf.feature(data.geometry as any));
        return { longitude: point.geometry.coordinates[0], latitude: point.geometry.coordinates[1] };
    }, [data]);

    const canClose = useMemo(() => {
        if (closeOnMove) return true;
        return undefined;
    }, [closeOnMove]);

    if (!pickedArea || isFetching || !data) return <></>;

    return (
        <Popup
            key={`${pickedArea.latitude}-${pickedArea.longitude}-${canClose}`}
            anchor="left"
            maxWidth="22rem"
            className={`${classes.popup}`}
            longitude={position.longitude}
            latitude={position.latitude}
            closeOnMove={canClose}
            closeOnClick={true}
            closeButton={false}
            onClose={e => {
                dispatch(areasActions.setPickedArea(undefined));
            }}
        >
            <div>
                <Typography>
                    {data.code} - {pickedArea.street}
                </Typography>
                <Typography>{data.cityName}</Typography>
                <Typography className={classes.totalAmount}>
                    {data.totalAmount?.toFixed(2) || 0}€{' '}
                    <TrendIcon pastMonthAmount={data.pastMonthAmount} currentMonthAmount={data.currentMonthAmount} />
                </Typography>
            </div>
            <button
                onClick={() => {
                    dispatch(areasActions.setSelectedArea(data));
                    dispatch(tabActions.setActiveTable(TableTypes.areas));
                    dispatch(areasActions.setPickedArea(undefined));
                }}
            >
                <ArrowForward fill={colors.primary0} />
            </button>
        </Popup>
    );
};

export default MapPopup;

