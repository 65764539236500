import { FC, useMemo } from 'react';
import classes from './SimulatorResultModal.module.scss';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { TariffSimulationData } from 'helpers';

type SimulatorResultModalProps = {
    data: TariffSimulationData;
};

const SimulatorResultModal: FC<SimulatorResultModalProps> = ({ data }) => {
    const { selectedMunicipality } = useSelector((x: RootState) => x.municipalities);

    const values = useMemo(() => {
        const totalAmount = `${data.totalAmount.toFixed(2)}€`;
        const noIva = `${(data.totalAmount / 1.22).toFixed(2)}€`;
        const iva = `${(data.totalAmount - data.totalAmount / 1.22).toFixed(2)}€`;
        const percentageFees = selectedMunicipality?.percentageFees
            ? data.totalAmount * (selectedMunicipality.percentageFees / 100)
            : 0;
        const fixedFees = selectedMunicipality?.fixedFees || 0;
        const fee = fixedFees + percentageFees;
        const total = `${(data.totalAmount + fee).toFixed(2)}€`;
        const commissionNoIva = `${(fee / 1.22).toFixed(2)}€`;
        const commissionIva = `${(fee - fee / 1.22).toFixed(2)}€`;

        return { totalAmount, noIva, iva, fee, total, commissionNoIva, commissionIva };
    }, [data, selectedMunicipality]);

    const appliedTariffs = useMemo(() => {
        return data.appliedSlopes.map((slope: any, index: number) => (
            <div key={index} className={classes.appliedTariffsWrapper}>
                <span className={classes.tariff}>
                    {slope.appliedSlope.type === 'proportional'
                        ? `${slope.appliedSlope.price.toFixed(2)}€/${slope.appliedSlope.time / 60}h`
                        : `${slope.appliedSlope.price.toFixed(2)}€`}
                </span>
                <span className={`${classes.tariff} ${classes.tariffTime}`}>
                    {slope.appliedSlope.type === 'proportional'
                        ? `Max ${
                              slope.appliedSlope.maxPrice
                                  ? `${slope.appliedSlope.maxPrice}€`
                                  : `${slope.appliedSlope.maxTime / 60}h`
                          }`
                        : slope.appliedSlope.endTime
                        ? `${slope.appliedSlope.startTime / 60}h-${slope.appliedSlope.endTime / 60}h`
                        : `${slope.appliedSlope.startHours}-${slope.appliedSlope.endHours}`}
                </span>
            </div>
        ));
    }, [data]);

    return (
        <div className={classes.container}>
            <div className={classes.subContainer}>
                <b>Tempo gratuito applicato:</b>
                <span className={classes.toTheRight}>{data.ticketInfo.freeTime}min</span>
                <b>Costo minimo:</b>
                <span className={classes.toTheRight}>{data.ticketInfo.minPrice.toFixed(2)}€</span>
            </div>
            <div className={classes.appliedTariffs}>
                <b>Tariffe applicate</b>
                {appliedTariffs}
            </div>
            <div className={classes.subContainer}>
                <b>Sosta</b>
                <span className={classes.toTheRight}>{values.totalAmount}</span>
                <span>Totale Sosta (senza IVA)</span>
                <span className={classes.toTheRight}>{values.noIva}</span>
                <span>IVA</span>
                <span className={classes.toTheRight}>{values.iva}</span>
            </div>
            <div className={classes.subContainer}>
                <b>Commissione Muvin</b>
                <span className={classes.toTheRight}>{values.fee.toFixed(2)}€</span>
                <span>Commissione (senza IVA)</span>
                <span className={classes.toTheRight}>{values.commissionNoIva}</span>
                <span>IVA</span>
                <span className={classes.toTheRight}>{values.commissionIva}</span>
            </div>
            <div className={`${classes.subContainer} ${classes.total}`}>
                <b>Costo Totale</b>
                <b className={classes.toTheRight}>{values.total}</b>
            </div>
        </div>
    );
};

export default SimulatorResultModal;

