import { FC } from 'react';
import PaymentStateFilter from './paymentStateFilter/PaymentStateFilter';
import BillingStateFilter from './billingStateFilter/BillingStateFilter';
import PaymentValueFilter from './paymentValueFilter/PaymentValueFilter';
import classes from './PaymentFilter.module.scss';
type PaymentFiltersProps = FC<{
    activeFilters: any;
    setActiveFilters: any;
}>;

const PaymentFilters: PaymentFiltersProps = ({ activeFilters, setActiveFilters }) => {
    return (
        <>
            <div className={classes.paymentFilterContainer}>
                <p>Pagamento</p>
                <div className={classes.paymentFilterFirstRow}>
                    <PaymentStateFilter activeFilters={activeFilters.paymentState} setActiveFilters={setActiveFilters} />
                    <BillingStateFilter activeFilters={activeFilters.billingState} setActiveFilters={setActiveFilters} />
                </div>
                <div className={classes.paymentFilterSecondRow}>
                    <PaymentValueFilter activeFilter={activeFilters.paymentValueRange} setActiveFilters={setActiveFilters} />
                </div>
            </div>
        </>
    );
};

export default PaymentFilters;
