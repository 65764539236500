import classes from './Snackbar.module.scss';
import { RootState, snackbarActions } from 'store';
import { Snackbar as MuiSnackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import { FC } from 'react';
import { CircleCheckSuccess } from 'components/icons/CircleCheckSuccess';
import { CircleCheckError } from 'components/icons/CircleCheckError';
import { useDispatch, useSelector } from 'react-redux';

const Snackbar: FC = () => {
    const dispatch = useDispatch();
    const { isOpen, message, type } = useSelector((root: RootState) => root.snackbar);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(snackbarActions.setIsOpen(false));
        dispatch(snackbarActions.setMessageAndType({ message: '', type: 'success' }));
    };
    if (!isOpen) return <></>;

    return (
        <MuiSnackbar
            open={isOpen && !!message && message.length > 0}
            autoHideDuration={3000}
            onClose={handleClose}
            className={classes.snackbarStyle}
        >
            <Alert
                onClose={handleClose}
                severity={type}
                variant="filled"
                icon={type === 'success' ? <CircleCheckSuccess /> : <CircleCheckError />}
            >
                <p className="small">{message}</p>
            </Alert>
        </MuiSnackbar>
    );
};

export default Snackbar;

