import { IconButton } from 'components/buttons';
import { Plus } from 'components/icons';
import { usePermissionHandler } from 'hook';
import { FC, useState } from 'react';
import { TabTypes } from 'store';
import { DocumentRow } from 'store/rtk/document.service';
import AddDocumentModal from './addDocumentModal/AddDocumentModal';

type AddDocumentProps = FC<{
    allDocuments: DocumentRow[] | undefined;
    refetch: () => void;
}>;

const AddDocument: AddDocumentProps = ({ allDocuments, refetch }) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const hasPermission = usePermissionHandler();

    return (
        <>
            <IconButton
                disabled={!hasPermission(TabTypes.settings, ['can-create'])}
                className="primary"
                icon={<Plus />}
                onClick={() => setIsModalOpen(true)}
            />
            <AddDocumentModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                allDocuments={allDocuments}
                refetch={refetch}
            />
        </>
    );
};

export default AddDocument;

