export const TariffChip = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2344_7790)">
                <path
                    d="M4 3V10.54C4 10.83 4.12 11.1 4.34 11.29L7.34 13.91C7.72 14.24 8.28 14.24 8.66 13.91C9.52 13.16 11 11.86 11.67 11.28C11.89 11.09 12.01 10.82 12.01 10.53V3C12 2.45 11.55 2 11 2C9.41 2 6.51 2 4.99 2C4.44 2 4 2.45 4 3ZM8.52 9.83V10.64H7.52V9.8C6.53 9.6 6.1 8.91 6 8.2H7.01C7.14 8.68 7.44 8.93 8.13 8.93C8.7 8.93 8.99 8.72 8.99 8.37C8.99 8.02 8.65 7.96 7.78 7.77C6.9 7.59 6.12 7.3 6.12 6.33C6.12 5.47 6.76 4.97 7.51 4.82V4H8.5V4.83C9.43 5 9.84 5.62 9.94 6.31H8.92C8.81 5.97 8.57 5.72 7.97 5.72C7.37 5.72 7.12 5.97 7.12 6.29C7.12 6.58 7.37 6.65 8.01 6.79C9.32 7.07 9.99 7.36 9.99 8.33C9.99 9.3 9.24 9.73 8.51 9.84L8.52 9.83Z"
                    fill="#8DBEF9"
                />
            </g>
            <defs>
                <clipPath id="clip0_2344_7790">
                    <rect width="8" height="12.17" fill="white" transform="translate(4 2)" />
                </clipPath>
            </defs>
        </svg>
    );
};

<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M18.04 15.1998C17.09 14.9898 16.72 14.8898 16.72 14.4598C16.72 13.9898 17.09 13.6198 17.97 13.6198C18.85 13.6198 19.22 13.9798 19.38 14.4898H20.88C20.73 13.4698 20.13 12.5498 18.76 12.3098V11.0898H17.29V12.2998C16.17 12.5198 15.23 13.2498 15.23 14.5298C15.23 15.9598 16.39 16.3798 17.68 16.6498C18.95 16.9298 19.46 16.9998 19.46 17.5398C19.46 18.0798 19.02 18.3698 18.19 18.3698C17.16 18.3698 16.73 18.0098 16.53 17.2898H15.04C15.19 18.3498 15.82 19.3698 17.29 19.6598V20.8998H18.77V19.6998C19.85 19.5398 20.95 18.8998 20.95 17.4698C20.95 16.0398 19.97 15.6098 18.03 15.1898L18.04 15.1998Z"
        fill="#C4DFFF"
    />
    <path
        d="M24.19 5H11.81C10.26 5 9 6.27 9 7.84V23.23C9 24.11 9.39 24.92 10.07 25.46L16.26 30.39C16.77 30.8 17.39 31 18 31C18.61 31 19.23 30.8 19.74 30.39L25.93 25.46C26.61 24.92 27 24.11 27 23.23V7.84C27 6.27 25.74 5 24.19 5ZM25.5 23.23C25.5 23.65 25.32 24.03 25 24.28L18.81 29.21C18.34 29.59 17.67 29.59 17.19 29.21L11 24.28C10.68 24.03 10.5 23.64 10.5 23.23V7.84C10.5 7.1 11.09 6.5 11.81 6.5H24.19C24.91 6.5 25.5 7.1 25.5 7.84V23.23Z"
        fill="#C4DFFF"
    />
</svg>;
