import { FC, PropsWithChildren } from 'react';
import { Typography } from '@mui/material';
import classes from './AccordionTypography.module.scss';

type AccordionTypographyProperties = FC<PropsWithChildren<{ className?: any }>>;

const AccordionTypography: AccordionTypographyProperties = ({ children, className }) => {
    return (
        <Typography>
            <div className={classes.accordionContainer}>
                <div className={`${classes.fieldsContainer} ${className}`}>{children}</div>
            </div>
        </Typography>
    );
};

export default AccordionTypography;
