import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Service } from 'models';

export interface ServiceState {
    services: Service[];
}

const name = 'services';
const initialState: ServiceState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const servicesActions = { ...slice.actions };
export const servicesReducer = slice.reducer;

function createInitialState(): ServiceState {
    return {
        services: []
    };
}

function createReducers() {
    return { setServices };

    function setServices(state: ServiceState, action: PayloadAction<Service[]>) {
        state.services = action.payload;
    }
}
