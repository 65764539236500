import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const AreaSettings: SvgIconProps = ({ fill = colors.primary90 }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.12462 10.629L7.8305 11.8539L11.3881 18.0259C11.5105 18.2426 11.7458 18.384 11.9999 18.384C12.254 18.384 12.4893 18.2521 12.6117 18.0259L16.1693 11.8539L16.8752 10.629L17.4964 9.54535C18.0517 8.58422 18.3434 7.48174 18.3434 6.36042C18.3529 2.85512 15.5011 0 11.9999 0C8.49874 0 5.64697 2.85512 5.64697 6.36042C5.64697 7.48174 5.93874 8.57479 6.49403 9.54535L7.11521 10.629H7.12462ZM11.9999 1.41343C14.7293 1.41343 16.9411 3.63722 16.9411 6.36042C16.9411 7.22733 16.7152 8.08481 16.2823 8.82921L15.6987 9.83746L14.9929 11.0624L11.9999 16.2544L9.00697 11.0624L8.30109 9.83746L7.71756 8.82921C7.28462 8.08481 7.05874 7.22733 7.05874 6.351C7.05874 3.6278 9.2705 1.404 11.9999 1.404V1.41343Z"
                fill={fill}
            />
            <path
                d="M11.5391 9.37571C11.6896 9.39456 11.8402 9.42283 12.0002 9.42283C12.1602 9.42283 12.3108 9.40398 12.4614 9.37571C13.0355 9.29091 13.5438 9.04591 13.9673 8.68784C14.6261 8.12247 15.0591 7.30269 15.0591 6.3604C15.0591 4.67371 13.6849 3.29797 12.0002 3.29797C10.3155 3.29797 8.94141 4.67371 8.94141 6.3604C8.94141 7.29326 9.37435 8.12247 10.0332 8.68784C10.4567 9.04591 10.9743 9.29091 11.5391 9.37571ZM12.0002 4.7114C12.9038 4.7114 13.6473 5.45581 13.6473 6.3604C13.6473 7.01058 13.2614 7.57595 12.7061 7.83979C12.4896 7.94344 12.2543 8.0094 12.0002 8.0094C11.7461 8.0094 11.5108 7.94344 11.2943 7.83979C10.7391 7.57595 10.3532 7.01058 10.3532 6.3604C10.3532 5.45581 11.0967 4.7114 12.0002 4.7114Z"
                fill={fill}
            />
            <path
                d="M23.6894 15.1707L18.0612 11.411L17.4024 12.5606L17.3553 12.636L22.0329 15.7549L12.0094 22.4452L1.98588 15.7549L6.66353 12.636L6.61647 12.5606L5.95765 11.411L0.310588 15.1707C0.112941 15.3026 0 15.5194 0 15.7549C0 15.9905 0.112941 16.2167 0.310588 16.3392L11.6047 23.8774C11.7271 23.9528 11.8588 23.9999 12 23.9999C12.1412 23.9999 12.2729 23.9622 12.3953 23.8774L23.6894 16.3392C23.8871 16.2072 24 15.9905 24 15.7549C24 15.5194 23.8871 15.2932 23.6894 15.1707Z"
                fill={fill}
            />
        </svg>
    );
};

