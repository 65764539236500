import { GridColDef } from '@mui/x-data-grid';
import { IconButton } from 'components/buttons';
import GridTable from 'components/gridTable/GridTable';
import { ArrowForward, Filters } from 'components/icons';
import {
    AreasFilters as AreasFiltersInterface,
    DEFAULT_AREAS_FILTER_VALUES,
    DashboardProps,
    TableExtractionFormat
} from 'helpers';
import { formatDate, openApiLink } from 'helpers/functions';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, areasActions, drawActions, filtersActions } from 'store';
import { useGetAllAreasQuery, useGetAreasDashboardInfoQuery } from 'store/rtk/area.service';
import classes from './AreasTable.module.scss';
import AreaDetail from './areaDetail/AreaDetail';
import AreasFilters from './areasFilters/AreasFilters';
import Dashboard from 'components/dashboard/Dashboard';
import { IsOnGoing } from 'components/icons/IsOnGoing';
import TableExportDropdown from 'components/tableExportDropdown/TableExportDropdown';
import { useLazyGetExtractionTokenQuery } from 'store/rtk/auth.service';
import TrendIcon from 'components/trendIcon/TrendIcon';
import deepEqual from 'deep-equal';

const getOnGoingStatusIcon = (dates: number[]) => (dates.some(date => date > Date.now()) ? <IsOnGoing /> : <></>);

const HEADERS: GridColDef[] = [
    {
        field: 'code',
        flex: 90,
        headerName: 'Codice Area'
    },
    { field: 'name', flex: 140, headerName: 'Nome' },
    { field: 'cityName', flex: 140, headerName: 'Città' },
    {
        field: 'totalAmount',
        flex: 90,
        headerName: 'Incassi',
        renderCell: props => {
            if (props.row.totalAmount === null || props.row.totalAmount === 0) return '0,00€';
            if (!props.row.totalAmount) return <></>;
            return (
                <div className={classes.totalAmount}>
                    {props.row?.totalAmount?.toFixed(2).replace('.', ',')}€{' '}
                    <TrendIcon pastMonthAmount={props.row.pastMonthAmount} currentMonthAmount={props.row.currentMonthAmount} />
                </div>
            );
        }
    },
    { field: 'createdAt', flex: 140, headerName: 'Inizio', valueGetter: props => formatDate(props.row.createdAt) },
    {
        field: 'parkingFinishTime',
        flex: 50,
        disableExport: true,
        sortable: false,
        headerName: '',
        renderCell: props => getOnGoingStatusIcon(props.row.parkingFinishTime)
    }
];

type AreasTableProps = FC<{
    toggleWidth: () => void;
    icon: JSX.Element;
}>;

const AreasTable: AreasTableProps = ({ toggleWidth, icon }) => {
    const [getToken] = useLazyGetExtractionTokenQuery();
    const [activeFilters, setActiveFilters] = useState<AreasFiltersInterface>(DEFAULT_AREAS_FILTER_VALUES);
    const [areFiltersOpen, setAreFiltersOpen] = useState<boolean>(false);
    const [orderBy, setOrderBy] = useState<{ column: string; type: string }>({ column: '', type: '' });
    const [searchedWord, setSearchedWord] = useState<string>('');
    const [paginationModel, setPaginationModel] = useState({ pageSize: 20, page: 0 });
    const { selectedArea } = useSelector((x: RootState) => x.areas);
    const { city, date } = useSelector((x: RootState) => x.filters);
    const dispatch = useDispatch();

    const startExtraction = useCallback(
        async (format: TableExtractionFormat) => {
            const columnsDictionary = HEADERS.filter(header => !header.disableExport).map(header => ({
                header: header.headerName,
                key: header.field
            }));
            const authorization = (await getToken().unwrap()).token;
            const queryParams = {
                city: !city ? '' : city,
                date: JSON.stringify(date),
                inProgress: activeFilters.inProgress,
                searchedWord,
                orderBy: JSON.stringify(orderBy),
                format: format.toLowerCase(),
                columns: JSON.stringify(columnsDictionary),
                authorization,
                sheet: 'Aree'
            };
            openApiLink('extraction/area', queryParams);
        },
        [getToken, activeFilters, searchedWord, city, date, orderBy]
    );

    const { isFetching, data: page } = useGetAllAreasQuery({
        offset: paginationModel.page * paginationModel.pageSize,
        pageSize: paginationModel.pageSize,
        city: !city ? '' : city,
        date: JSON.stringify(date),
        inProgress: activeFilters.inProgress,
        orderBy: JSON.stringify(orderBy),
        searchedWord
    });
    const { data: dashboardInfo } = useGetAreasDashboardInfoQuery({
        city: !city ? '' : city,
        date: JSON.stringify(date),
        inProgress: activeFilters.inProgress,
        searchedWord
    });

    const areFiltersActive = useMemo(
        () =>
            !(
                deepEqual(activeFilters, DEFAULT_AREAS_FILTER_VALUES) &&
                city.length === 0 &&
                date.endDate === 0 &&
                date.startDate === 0
            ),
        [activeFilters, city, date]
    );

    const headers = useMemo(
        () =>
            HEADERS.concat({
                field: 'export-dropdown',
                minWidth: 90,
                maxWidth: 90,
                disableExport: true,
                sortable: false,
                renderHeader: () => {
                    if (page?.length === 0) return <></>;
                    return <TableExportDropdown onFormatSelect={format => startExtraction(format)} />;
                },
                renderCell: props => {
                    return (
                        <div className={classes.arrow}>
                            <ArrowForward />
                        </div>
                    );
                }
            }),
        [startExtraction, page?.length]
    );

    const dashboardValues: DashboardProps[] = useMemo(
        () => [
            { label: 'Stalli', value: dashboardInfo?.totalSpaces ?? 0 },
            { label: 'Soste', value: dashboardInfo?.parkingNumber ?? 0 },
            { label: 'Incasso Totale', value: `${dashboardInfo?.totalAmount?.toFixed(2).replace('.', ',') ?? 0}€` }
        ],
        [dashboardInfo]
    );

    const onInputChangeHandler = useCallback((value: string) => setSearchedWord(value), []);

    const onRowSelection = useCallback(
        (value: any) => {
            dispatch(drawActions.zoomOnBbox(value.row.geometry));
            dispatch(areasActions.setSelectedArea(value.row));
        },
        [dispatch]
    );

    const resetFilters = useCallback(() => {
        dispatch(filtersActions.setDate({ startDate: 0, endDate: 0 }));
        dispatch(filtersActions.setActiveCity(''));
        setActiveFilters(DEFAULT_AREAS_FILTER_VALUES);
    }, [dispatch]);

    const onFiltersChangeHandler = useCallback(
        (key: keyof AreasFiltersInterface, value: any) => {
            if (key === 'city') {
                dispatch(filtersActions.setActiveCity(value));
                return;
            }
            if (key === 'date') {
                dispatch(filtersActions.setDate(value));
                return;
            }
            setActiveFilters(prev => {
                const filters = { ...prev, [key]: value };
                return filters;
            });
        },
        [dispatch]
    );

    if (selectedArea) return <AreaDetail />;

    if (areFiltersOpen)
        return (
            <AreasFilters
                activeFilters={activeFilters}
                setActiveFilters={onFiltersChangeHandler}
                setAreFiltersOpen={setAreFiltersOpen}
                resetFilters={resetFilters}
                dashboardValues={dashboardValues}
            />
        );

    return (
        <>
            <div className={classes.dashboard}>
                <Dashboard props={dashboardValues} />
            </div>
            <div className={classes.filterButton}>
                <IconButton className="tertiary" icon={<Filters />} onClick={() => setAreFiltersOpen(true)} />
                {areFiltersActive && (
                    <span className={classes.active}>
                        <IsOnGoing />
                    </span>
                )}
            </div>
            <IconButton className={`tertiary ${classes.fullScreen}`} icon={icon} onClick={toggleWidth} />
            <div className={classes.container}>
                <GridTable
                    enableSearchBar
                    enableToolbar
                    headers={headers}
                    rows={page ?? []}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    onRowSelection={onRowSelection}
                    isFetching={isFetching}
                    rowCount={dashboardInfo?.totalAreas}
                    setSearchedWord={onInputChangeHandler}
                    setOrderBy={setOrderBy}
                />
            </div>
        </>
    );
};

export default AreasTable;

