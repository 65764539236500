import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { RadioButtonFilterFields } from 'helpers';
import { FC, useMemo } from 'react';
import classes from './RadioButtonFilter.module.scss';
import { RadioChecked, RadioUnchecked } from 'components/icons';

type RadioButtonFilterProps = FC<{
    fields: RadioButtonFilterFields[];
    activeFilters: string;
    setActiveFilter: (key: string, value: string) => void;
    keyValue: string;
    label: string;
}>;

const RadioButtonFilter: RadioButtonFilterProps = ({ label, keyValue, activeFilters, fields, setActiveFilter }) => {
    const mappedFields = useMemo(
        () =>
            fields.map(field => (
                <FormControlLabel
                    key={field.value}
                    value={field.value}
                    control={<Radio icon={<RadioUnchecked />} checkedIcon={<RadioChecked />} />}
                    label={<span>{field.label}</span>}
                />
            )),
        [fields]
    );

    return (
        <div className={classes.container}>
            <p className="small lightBlue">
                <b>{label}</b>
            </p>
            <FormControl>
                <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                <RadioGroup
                    onChange={event => setActiveFilter(keyValue, event.target.value)}
                    value={activeFilters ? activeFilters : 'all'}
                >
                    {mappedFields}
                </RadioGroup>
            </FormControl>
        </div>
    );
};

export default RadioButtonFilter;

