import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary } from '@mui/material';
import classes from './TariffAccordion.module.scss';
import { IconTextButton } from 'components/buttons';
import { Plus } from 'components/icons';
import colors from 'styles/modules/colors.module.scss';

type TariffAccordionProperties = FC<
    PropsWithChildren<{
        title: JSX.Element | string;
        buttonTitle: string;
        customClasses?: string;
        isPricing?: boolean;
        defaultOpen?: boolean;
        onClickHandler?: any;
    }>
>;

const TariffAccordion: TariffAccordionProperties = ({
    title,
    buttonTitle,
    children,
    customClasses = '',
    isPricing = false,
    defaultOpen = false,
    onClickHandler = () => {}
}) => {
    const [expand, setExpand] = useState(false);
    const toggleAccordion = useCallback(() => {
        onClickHandler();
        setExpand(prev => !prev);
    }, [onClickHandler]);

    const accordionSummaryIcon = useMemo(() => {
        if (isPricing)
            return (
                <IconTextButton className="secondary" onClick={onClickHandler} icon={<Plus fill={colors.primary100} />}>
                    {buttonTitle}
                </IconTextButton>
            );
        if (!expand && !defaultOpen)
            return (
                <IconTextButton className="secondary" onClick={toggleAccordion} icon={<Plus fill={colors.primary100} />}>
                    {buttonTitle}
                </IconTextButton>
            );
        return <></>;
    }, [buttonTitle, defaultOpen, expand, isPricing, onClickHandler, toggleAccordion]);

    return (
        <div className={`${classes.container} ${customClasses}`}>
            <MuiAccordion expanded={defaultOpen || expand}>
                <AccordionSummary expandIcon={accordionSummaryIcon} aria-controls="panel1a-content" id="panel1a-header">
                    <div className={classes.title}>{title}</div>
                </AccordionSummary>
                <AccordionDetails>{children}</AccordionDetails>
            </MuiAccordion>
        </div>
    );
};

export default TariffAccordion;

