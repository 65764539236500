import colors from 'styles/modules/colors.module.scss';
import { SvgIconProps } from 'helpers';

export const Search: SvgIconProps = ({ fill = colors.primary90 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path
                d="M16.5623 15.6376C18.1684 13.7918 18.9952 11.3946 18.8683 8.9511C18.7415 6.50763 17.6709 4.20885 15.8823 2.53929C14.0937 0.869736 11.7267 -0.0401688 9.28029 0.00136161C6.83388 0.0428921 4.49914 1.03261 2.7682 2.76192C1.03727 4.49122 0.045352 6.82502 0.00151693 9.27138C-0.0423182 11.7177 0.865355 14.0856 2.53323 15.8758C4.2011 17.666 6.49887 18.7387 8.94222 18.8679C11.3856 18.997 13.7836 18.1725 15.6309 16.5681L21.0681 22L21.9986 21.0695L16.5623 15.6376ZM17.5567 9.53922C17.5544 11.6665 16.7084 13.706 15.2042 15.2103C13.7001 16.7146 11.6607 17.5609 9.53339 17.5634C7.94671 17.5627 6.39585 17.0916 5.0769 16.2096C3.75795 15.3276 2.73013 14.0744 2.12338 12.6083C1.51662 11.1422 1.35818 9.52912 1.66809 7.973C1.978 6.41688 2.74232 4.98758 3.86446 3.86581C4.9866 2.74404 6.41614 1.98017 7.97236 1.67077C9.52859 1.36137 11.1416 1.52034 12.6075 2.12758C14.0734 2.73482 15.3263 3.76305 16.2079 5.08229C17.0894 6.40153 17.5601 7.95254 17.5602 9.53922H17.5567Z"
                fill={fill}
            />
        </svg>
    );
};

