import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AreasTableItem, PickedArea } from 'models';

export interface AreasState {
    selectedArea: AreasTableItem | undefined;
    pickedArea: PickedArea | undefined;
}

const name = 'areas';
const initialState: AreasState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const areasActions = { ...slice.actions };
export const areasReducer = slice.reducer;

function createInitialState(): AreasState {
    return {
        selectedArea: undefined,
        pickedArea: undefined
    };
}

function createReducers() {
    return { setSelectedArea, setPickedArea };

    function setSelectedArea(state: AreasState, action: PayloadAction<AreasTableItem | undefined>) {
        state.selectedArea = action.payload;
    }

    function setPickedArea(state: AreasState, action: PayloadAction<PickedArea | undefined>) {
        state.pickedArea = action.payload;
    }
}

