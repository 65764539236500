import { FC, PropsWithChildren } from 'react';
import { HTMLButtonType } from 'helpers';
import Button from '../button/Button';

type TextButtonProps = FC<
    PropsWithChildren<{
        disabled?: boolean;
        onClick?: () => any;
        type?: HTMLButtonType;
        className: string;
    }>
>;

const TextButton: TextButtonProps = ({ children, onClick, className, type, disabled }) => {
    return (
        <Button type={type} className={`text ${className}`} onClick={onClick} disabled={disabled}>
            {children}
        </Button>
    );
};

export default TextButton;
