export const CloseBig = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path
                d="M26.064 10.061L25.003 9L17.534 16.47L10.064 9L9.003 10.061L16.47 17.531L9 25L10.061 26.061L17.531 18.591L25 26.061L26.061 25L18.594 17.531L26.064 10.061Z"
                fill="white"
            />
        </svg>
    );
};
