import Accordion from 'components/accordion/Accordion';
import AccordionTypography from 'components/accordionTypography/AccordionTypography';
import { IconButton } from 'components/buttons';
import TextButton from 'components/buttons/textButton/TextButton';
import { AttentionCircle, CheckmarkChecked, CircleRefound, RadioUnchecked } from 'components/icons';
import { RefoundButton } from 'components/icons/RefoundButton';
import Modal from 'components/modal/Modal';
import { ParkingDetail } from 'helpers';
import { PaymentInfo, UserRoles } from 'models';
import { OrderStatus } from 'models/order.model';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState, RootState, snackbarActions } from 'store';
import { useLazyPaymentRefundQuery } from 'store/rtk/parking.service';
import colors from 'styles/modules/colors.module.scss';
import classes from './Payment.module.scss';
import Snackbar from 'components/snackbar/Snackbar';

type PaymentProps = FC<{
    parkingInfo: ParkingDetail;
    refetchParkingDetails: () => void;
    refetchParkingList: () => void;
    paymentInfo: PaymentInfo;
}>;

const PaymentStatusRecord: Record<keyof typeof OrderStatus, string> = {
    created: 'Creato',
    paid: 'Pagato',
    canceled: 'Cancellato',
    refunded: 'Rimborsato'
};

const Payment: PaymentProps = ({ parkingInfo, refetchParkingDetails, refetchParkingList, paymentInfo }) => {
    const { user } = useSelector<RootState>(x => x.auth) as AuthState;
    const [openModal, setOpenModal] = useState(false);
    const [paymentRefund] = useLazyPaymentRefundQuery();
    const dispatch = useDispatch();
    const getPaymentStatusIcon = useCallback((paymentStatus: OrderStatus) => {
        if (paymentStatus === OrderStatus.paid) return <CheckmarkChecked />;
        if (paymentStatus === OrderStatus.refunded) return <CircleRefound />;
        if (paymentStatus === OrderStatus.canceled) return <AttentionCircle />;
        if (paymentStatus === OrderStatus.created) return <RadioUnchecked fill={colors.white} />;
        return <></>;
    }, []);

    const handleRefund = useCallback(async () => {
        if (!parkingInfo) return;
        try {
            await paymentRefund({ ticketId: parkingInfo.ticketId });
            refetchParkingDetails();
            refetchParkingList();
            setOpenModal(false);
            dispatch(
                snackbarActions.setMessageAndType({
                    message: `Pagamento rimborsato correttamente`,
                    type: 'success'
                })
            );
        } catch (error) {
            dispatch(
                snackbarActions.setMessageAndType({
                    message: ` Errore durante il rimborsamento del pagamento`,
                    type: 'error'
                })
            );
            console.log(error);
        } finally {
            dispatch(snackbarActions.setIsOpen(true));
        }
    }, [dispatch, parkingInfo, paymentRefund, refetchParkingDetails, refetchParkingList]);

    const refundButton = useMemo(() => {
        if (
            parkingInfo.paymentStatus === 'paid' &&
            parkingInfo.billingStatus === 'none' &&
            parkingInfo.orderType !== 'wallet' &&
            parkingInfo.orderAmount !== 0
        ) {
            return (
                <div>
                    <IconButton icon={<RefoundButton />} className="secondaryVariant" onClick={() => setOpenModal(true)} />
                </div>
            );
        }
    }, [parkingInfo.billingStatus, parkingInfo.orderAmount, parkingInfo.orderType, parkingInfo.paymentStatus, setOpenModal]);

    const orderId = useMemo(() => {
        if (!parkingInfo) return;
        if (parkingInfo.orderType === 'wallet') return 'Wallet';
        return parkingInfo.orderId.length === 0 ? '-' : parkingInfo.orderId;
    }, [parkingInfo]);

    if (!parkingInfo) return <></>;

    return (
        <div className={classes.accordionContainer}>
            <Accordion title={`${parkingInfo.paymentStatus === 'created' ? 'Pagamento Stimato' : 'Pagamento'}`}>
                {
                    <div className={classes.infoRefund}>
                        {parkingInfo.paymentStatus === OrderStatus.refunded && <p>Rimborsato</p>}
                    </div>
                }
                <AccordionTypography className={classes[parkingInfo.paymentStatus]}>
                    <div className={classes.field}>
                        <div>
                            <label className="small">
                                <b>Stato</b>
                            </label>
                            <p className={classes.capitalize}>
                                {getPaymentStatusIcon(parkingInfo.paymentStatus)}{' '}
                                {PaymentStatusRecord[parkingInfo.paymentStatus as keyof typeof PaymentStatusRecord]}
                            </p>
                        </div>
                        <div>
                            <label className="small">
                                <b>Termina con</b>
                            </label>
                            <p>{paymentInfo.paymentCircuitNumber}</p>
                        </div>
                    </div>
                    <div className={classes.field}>
                        <div>
                            <label className="small">
                                <b>
                                    {parkingInfo.paymentStatus === 'paid' && parkingInfo.orderAmount === 0
                                        ? 'Gratuita'
                                        : 'ID Transazione'}
                                </b>
                            </label>
                            <p className={classes.ellipsis}>{orderId}</p>
                        </div>
                        <div>
                            <label className="small">
                                <b>{`${parkingInfo.paymentStatus === 'created' ? 'Costo totale stimato' : 'Costo totale'}`}</b>
                            </label>
                            <p>{parkingInfo.orderAmount.toFixed(2).toString().replace('.', ',')}€</p>
                        </div>
                    </div>
                    <div className={classes.field}>
                        <div>
                            <label className="small">
                                <b>Gestore</b>
                            </label>
                            <p>{paymentInfo.paymentCircuit}</p>
                        </div>
                        {user?.role !== UserRoles.municipalityEmployee && (
                            <div>
                                <label className="small">
                                    <b>Commissioni Muvin</b>
                                </label>
                                <p>{parkingInfo.fees.toFixed(2).toString().replace('.', ',')}€</p>
                            </div>
                        )}
                    </div>
                    {refundButton}
                    <Modal
                        customClasses={classes.modal}
                        isOpen={openModal}
                        title="Rimborso sosta"
                        handleClose={() => setOpenModal(false)}
                    >
                        <span>
                            Rimborsare questa sosta del valore di <b>{parkingInfo.orderAmount}€</b> all'utente
                            <b>{` ${parkingInfo.firstName} ${parkingInfo.lastName}?`}</b>
                            <br /> L'azione e' irreversibile.
                        </span>
                        <div>
                            <TextButton className={classes.cancelButton} onClick={() => setOpenModal(false)}>
                                Annulla
                            </TextButton>
                            <TextButton className={classes.confirmButton} onClick={handleRefund}>
                                Rimborsa Sosta
                            </TextButton>
                        </div>
                    </Modal>
                </AccordionTypography>
            </Accordion>
            <Snackbar />
        </div>
    );
};

export default Payment;

