import { FC, PropsWithChildren, useState } from 'react';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ArrowDown } from 'components/icons';
import colors from 'styles/modules/colors.module.scss';
import classes from './SlopeAccordion.module.scss';

type SlopeAccordionProperties = FC<
    PropsWithChildren<{
        title?: JSX.Element | string;
    }>
>;

const SlopeAccordion: SlopeAccordionProperties = ({ title = <></>, children }) => {
    const [isAccordionOpen, setIsAccordionOpen] = useState(true);

    const handleAccordionChange = () => {
        setIsAccordionOpen(prev => !prev);
    };
    return (
        <div className={classes.container}>
            <MuiAccordion expanded={isAccordionOpen} onChange={handleAccordionChange}>
                <AccordionSummary
                    expandIcon={
                        <ArrowDown
                            fill={colors.primary90}
                            style={{ transform: isAccordionOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                        />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {title}
                </AccordionSummary>
                <AccordionDetails className={classes.details}>{children}</AccordionDetails>
            </MuiAccordion>
        </div>
    );
};

export default SlopeAccordion;

