import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Edit: SvgIconProps = ({ fill = colors.primary100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19.72" height="19.716" viewBox="0 0 19.72 19.716">
            <path
                id="modifica"
                d="M1773.9,368.215a.6.6,0,0,0-.175-.425l-5.975-5.974a.615.615,0,0,0-.849,0l-11.349,11.352a.605.605,0,0,0-.168.326l-1.2,7.169a.6.6,0,0,0,.593.7.619.619,0,0,0,.094-.007l7.173-1.2a.6.6,0,0,0,.325-.168l11.352-11.352A.6.6,0,0,0,1773.9,368.215Zm-19.055,12.943Zm.664-1.125,1.024-6.151,8.339-8.339.871-.871,1.584-1.584,5.126,5.125h0l-1.584,1.584-.872.872h0l-8.34,8.338-6.151,1.027Z"
                transform="translate(-1754.182 -361.646)"
                fill={fill}
            />
        </svg>
    );
};
