import { Checkbox, ListItemText } from '@mui/material';
import { CheckBoxChecked, CheckBoxUnchecked } from 'components/icons';
import GenericSelect from 'components/select/GenericSelect';
import { FC, useMemo } from 'react';
import classes from './billingStateFilter.module.scss';

type BillingStateFilterProps = FC<{
    activeFilters: string[];
    setActiveFilters: any;
}>;

const billingStates = [
    { id: 'none', label: 'Nessuno' },
    { id: 'pending', label: 'In attesa' },
    { id: 'issued', label: 'Rilasciato' }
];

const BillingStateFilter: BillingStateFilterProps = ({ activeFilters, setActiveFilters }) => {
    const selectOptions = useMemo(
        () =>
            billingStates.map(billingState => ({
                id: billingState.id,
                item: (
                    <>
                        <Checkbox
                            checked={activeFilters.indexOf(billingState.id as any) > -1}
                            icon={<CheckBoxUnchecked />}
                            checkedIcon={<CheckBoxChecked />}
                        />
                        <ListItemText primary={billingState.label} />
                    </>
                )
            })),
        [activeFilters]
    );

    return (
        <>
            <div className={classes.billingContainer}>
                <label className="small">
                    <b>Stato fattura</b>
                </label>
                <GenericSelect
                    placeholder="Seleziona stato fattura"
                    multiple
                    value={activeFilters}
                    onChange={(selectedFilters: any) => setActiveFilters('billingState', selectedFilters.target.value)}
                    renderValue={(selected: any) =>
                        billingStates
                            .filter(state => selected.includes(state.id))
                            .map(state => state.label)
                            .join(', ')
                    }
                    options={selectOptions}
                    sxFormControl={{ width: '21rem !important' }}
                />
            </div>
        </>
    );
};

export default BillingStateFilter;

