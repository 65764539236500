import CreateOrEditArea from 'components/createOrEditArea/CreateOrEditArea';
import { SpreadsheetsFriend } from 'components/icons';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MunicipalityTableTypes, RootState } from 'store';
import classes from './MunicipalitySettings.module.scss';
import MunicipalityDetails from './municipalityDetails/MunicipalityDetails';
import MunicipalityList from './municipalityList/MunicipalityList';
import SettingsAreaDetail from './settingsAreaDetail/SettingsAreaDetail';
import TariffRuleCreation from './tariffRuleCreation/TariffRuleCreation';
import TariffRuleList from './tariffRuleList/TariffRuleList';
import TariffSimulator from './tariffRuleCreation/TariffSimulator';

type MunicipalitySettingsProps = {
    map: JSX.Element;
};

const MunicipalitySettings: FC<MunicipalitySettingsProps> = ({ map }) => {
    const { selectedMunicipality, selectedTable, isCreateAreaOpen, selectedArea, isEditingArea } = useSelector(
        (x: RootState) => x.municipalities
    );
    const { selectedTariff, isCreatingRule, isEditingRule, isTariffSimulator } = useSelector((x: RootState) => x.tariffs);

    const leftComponent = useMemo(() => {
        if (isCreateAreaOpen) return <CreateOrEditArea />;
        if (isEditingArea) return <CreateOrEditArea />;
        if (selectedArea) return <SettingsAreaDetail />;
        if (selectedTariff) return <TariffRuleList />;
        if (selectedMunicipality) return <MunicipalityDetails />;
        return <MunicipalityList />;
    }, [isCreateAreaOpen, isEditingArea, selectedArea, selectedMunicipality, selectedTariff]);

    const rightComponent = useMemo(() => {
        if (selectedMunicipality && selectedTable === MunicipalityTableTypes.areas) return map;
        if (isCreatingRule || isEditingRule) return <TariffRuleCreation />;
        if (isTariffSimulator) return <TariffSimulator />;
        return (
            <>
                <SpreadsheetsFriend />
                Seleziona una città, quindi una tariffa e poi una regola.
            </>
        );
    }, [selectedMunicipality, selectedTable, map, isCreatingRule, isEditingRule, isTariffSimulator]);

    return (
        <div className={classes.container}>
            <div className={classes.left}>{leftComponent}</div>
            <div className={classes.map}>{rightComponent}</div>
        </div>
    );
};

export default MunicipalitySettings;

