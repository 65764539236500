import { IconButton, IconTextButton } from 'components/buttons';
import ChipsFilter from 'components/chipsFilter/ChipsFilter';
import CityFilter from 'components/cityFilter/CityFilter';
import Dashboard from 'components/dashboard/Dashboard';
import Divider from 'components/divider/Divider';
import { ArrowBack, ResetFilters } from 'components/icons';
import PeriodOfTimeFilter from 'components/periodOfTimeFilter/PeriodOfTimeFilter';
import RadioButtonFilter from 'components/radioButtonFIlter/RadioButtonFilter';
import deepEqual from 'deep-equal';
import { AreasFilters as AreasFiltersInterface, DEFAULT_AREAS_FILTER_VALUES, RadioButtonFilterFields, SetState } from 'helpers';
import { FC, useMemo } from 'react';
import colors from 'styles/modules/colors.module.scss';
import classes from './AreasFilters.module.scss';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const RADIO_BUTTON_FILTER_FIELDS: RadioButtonFilterFields[] = [
    { label: 'Tutti', value: 'all' },
    { label: 'Solo soste in corso', value: 'inProgress' },
    { label: 'Solo parcheggi senza soste in corso', value: 'notInProgress' }
];

type AreasFiltersProps = FC<{
    setAreFiltersOpen: SetState<boolean>;
    activeFilters: AreasFiltersInterface;
    setActiveFilters: any;
    resetFilters: any;
    dashboardValues: any;
}>;

const AreasFilters: AreasFiltersProps = ({
    activeFilters,
    setActiveFilters,
    setAreFiltersOpen,
    resetFilters,
    dashboardValues
}) => {
    const { city, date } = useSelector((x: RootState) => x.filters);

    const isDisabled = useMemo(
        () =>
            deepEqual(activeFilters, DEFAULT_AREAS_FILTER_VALUES) &&
            city.length === 0 &&
            date.endDate === 0 &&
            date.startDate === 0,
        [activeFilters, city, date]
    );

    return (
        <div className={classes.filtersContainer}>
            <div className={classes.dashboard}>
                <Dashboard props={dashboardValues} />
            </div>
            <div className={classes.header}>
                <div className={classes.title} onClick={() => setAreFiltersOpen(false)}>
                    <IconButton className="tertiary" icon={<ArrowBack />} onClick={() => setAreFiltersOpen(false)} />
                    <p>
                        <b>Filtri</b>
                    </p>
                </div>
                <IconTextButton
                    className="tertiary"
                    disabled={isDisabled}
                    icon={<ResetFilters fill={isDisabled ? colors.primary40 : colors.primary90} />}
                    onClick={resetFilters}
                >
                    Reset
                </IconTextButton>
            </div>
            <div className={classes.filters}>
                <p>Periodo sosta</p>
                <div className={classes.dateFilter}>
                    <PeriodOfTimeFilter activeFilter={activeFilters.date} setActiveFilter={setActiveFilters} />
                    <ChipsFilter activeFilter={activeFilters.date} setActiveFilter={setActiveFilters} />
                </div>
                <Divider />
                <div className={classes.cityFilter}>
                    <CityFilter activeFilter={activeFilters.city} setActiveFilters={setActiveFilters} />
                </div>
                <Divider />
                <div>
                    <p className="lightBlue">Soste attive</p>
                    <RadioButtonFilter
                        label="Soste in corso"
                        fields={RADIO_BUTTON_FILTER_FIELDS}
                        activeFilters={activeFilters.inProgress}
                        setActiveFilter={setActiveFilters}
                        keyValue="inProgress"
                    />
                </div>
            </div>
        </div>
    );
};

export default AreasFilters;

