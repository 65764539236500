import { GridColDef } from '@mui/x-data-grid';
import { IconButton } from 'components/buttons';
import Dashboard from 'components/dashboard/Dashboard';
import GridTable from 'components/gridTable/GridTable';
import { CheckmarkChecked, Filters, RadioUnchecked, ArrowForward } from 'components/icons';
import { AlertCircle } from 'components/icons/AlertCircle';
import {
    DEFAULT_USERS_FILTER_VALUES,
    DashboardProps,
    MS_CONVERTER,
    TableExtractionFormat,
    formatDate,
    openApiLink
} from 'helpers';
import { UserFilters } from 'models';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, usersActions } from 'store';
import { useGetStandardUsersQuery, useGetUsersDashboardInfoQuery } from 'store/rtk/user.service';
import classes from './UsersTable.module.scss';
import UserDetail from './userDetail/UserDetail';
import UsersFilters from './usersFilters/UsersFilters';
import TableExportDropdown from 'components/tableExportDropdown/TableExportDropdown';
import { useLazyGetExtractionTokenQuery } from 'store/rtk/auth.service';
import TrendIcon from 'components/trendIcon/TrendIcon';
import { IsOnGoing } from 'components/icons/IsOnGoing';
import deepEqual from 'deep-equal';

const getCanceledOrders = (canceledOrders: string) => {
    if (canceledOrders !== '0') return <AlertCircle />;
    return '';
};

const getBillingStatusIcon = (billingStatus: string[]) => {
    if (!billingStatus) return <CheckmarkChecked />;
    if (billingStatus[0] === null) return <CheckmarkChecked />;
    if (billingStatus.includes('pending')) return <RadioUnchecked fill="white" />;
    return <CheckmarkChecked />;
};

const HEADERS: GridColDef[] = [
    {
        field: 'firstName',
        flex: 90,
        headerName: 'Nome'
    },
    { field: 'lastName', flex: 90, headerName: 'Cognome' },
    { field: 'telephoneNumber', flex: 130, headerName: 'Numero' },
    {
        field: 'totalAmount',
        flex: 80,
        headerName: 'Spesa ad oggi',
        renderCell: props => {
            if (props.row.totalAmount === null || props.row.totalAmount === 0) return '0,00€';
            if (!props.row.totalAmount) return <></>;
            return (
                <div className={classes.totalAmount}>
                    {props.row?.totalAmount?.toFixed(2).replace('.', ',')}€{' '}
                    <TrendIcon pastMonthAmount={props.row.pastMonthAmount} currentMonthAmount={props.row.currentMonthAmount} />
                </div>
            );
        }
    },
    {
        field: 'billingsStatus',
        flex: 60,
        headerName: 'Fatture',
        renderCell: props => getBillingStatusIcon(props.row.billingsStatus),
        sortable: false
    },
    {
        field: 'createdAt',
        flex: 100,
        headerName: 'Utente dal',
        valueGetter: props => formatDate(props.row.createdAt, true)
    },
    {
        field: 'canceledOrders',
        flex: 50,
        headerName: '',
        sortable: false,
        disableExport: true,
        renderCell: props => getCanceledOrders(props.row.canceledOrders)
    }
];
type UsersTableProps = FC<{
    toggleWidth: () => void;
    icon: JSX.Element;
}>;

const UsersTable: UsersTableProps = ({ toggleWidth, icon }) => {
    const [getToken] = useLazyGetExtractionTokenQuery();
    const { selectedUser } = useSelector((x: RootState) => x.users);
    const [areFiltersOpen, setAreFiltersOpen] = useState<boolean>(false);
    const [activeFilters, setActiveFilters] = useState<UserFilters>(DEFAULT_USERS_FILTER_VALUES);
    const [searchedWord, setSearchedWord] = useState<string>('');
    const [orderBy, setOrderBy] = useState<{ column: string; type: string }>({ column: '', type: '' });
    const [paginationModel, setPaginationModel] = useState({ pageSize: 50, page: 0 });
    const dispatch = useDispatch();

    const startExtraction = useCallback(
        async (format: TableExtractionFormat) => {
            const columnsDictionary = HEADERS.filter(header => !header.disableExport).map(header => ({
                header: header.headerName,
                key: header.field
            }));

            const authorization = (await getToken().unwrap()).token;

            const queryParams = {
                searchedWord,
                inProgress: activeFilters.inProgress,
                vehicleType: JSON.stringify(activeFilters.vehicleTypes),
                billing: JSON.stringify(activeFilters.billing),
                blacklist: JSON.stringify(activeFilters.blacklist),
                format: format.toLowerCase(),
                orderBy: JSON.stringify(orderBy),
                columns: JSON.stringify(columnsDictionary),
                authorization,
                sheet: 'Utenti'
            };
            openApiLink('extraction/user', queryParams);
        },
        [activeFilters, getToken, searchedWord, orderBy]
    );

    const { data: dashboardInfo } = useGetUsersDashboardInfoQuery({
        searchedWord,
        vehicleType: JSON.stringify(activeFilters.vehicleTypes),
        billing: JSON.stringify(activeFilters.billing),
        blacklist: JSON.stringify(activeFilters.blacklist),
        inProgress: activeFilters.inProgress
    });
    const {
        data: page,
        isFetching,
        refetch: refetchUsersList
    } = useGetStandardUsersQuery(
        {
            offset: paginationModel.pageSize * paginationModel.page,
            pageSize: paginationModel.pageSize,
            searchedWord,
            inProgress: activeFilters.inProgress,
            vehicleType: JSON.stringify(activeFilters.vehicleTypes),
            billing: JSON.stringify(activeFilters.billing),
            blacklist: JSON.stringify(activeFilters.blacklist),
            orderBy: JSON.stringify(orderBy)
        },
        { refetchOnMountOrArgChange: true }
    );

    const areFiltersActive = useMemo(() => !deepEqual(activeFilters, DEFAULT_USERS_FILTER_VALUES), [activeFilters]);

    const headers = useMemo(
        () =>
            HEADERS.concat({
                field: 'export-dropdown',
                minWidth: 90,
                maxWidth: 90,
                disableExport: true,
                sortable: false,
                renderHeader: () => {
                    if (page?.length === 0) return <></>;
                    return <TableExportDropdown onFormatSelect={format => startExtraction(format)} />;
                },
                renderCell: props => {
                    return (
                        <div className={classes.arrow}>
                            <ArrowForward />
                        </div>
                    );
                }
            }),
        [startExtraction, page?.length]
    );

    const dashboardValues: DashboardProps[] = useMemo(() => {
        const activeUsers = dashboardInfo?.filter(info => info?.parking > 0)?.length;
        const newUsers = dashboardInfo?.filter(
            info => Date.now() - MS_CONVERTER.month < new Date(info?.created_at).getTime()
        ).length;
        return [
            {
                label: 'Utenti totali',
                value: dashboardInfo?.length!
            },
            {
                label: 'Utenti attivi',
                value: activeUsers!,
                info: "Utenti che hanno effettuato soste nell'ultimo mese"
            },
            {
                label: 'Nuovi Utenti',
                value: newUsers!,
                info: "Utenti che hanno creato un account nell'ultimo mese"
            }
        ];
    }, [dashboardInfo]);

    const onRowSelection = useCallback((value: any) => dispatch(usersActions.setSelectedUser(value.row.id)), [dispatch]);

    const onInputChangeHandler = useCallback((value: any) => setSearchedWord(value), []);

    const onFiltersChangeHandler = useCallback((key: keyof UserFilters, value: string[]) => {
        setActiveFilters(prev => {
            const filters = { ...prev, [key]: value };
            return filters;
        });
    }, []);

    const onUserDetailCloseHandler = useCallback(() => dispatch(usersActions.setSelectedUser(undefined)), [dispatch]);

    const resetFilters = useCallback(() => setActiveFilters(DEFAULT_USERS_FILTER_VALUES), []);

    if (selectedUser) return <UserDetail closeDetail={onUserDetailCloseHandler} refetch={refetchUsersList} />;

    if (areFiltersOpen)
        return (
            <UsersFilters
                dashboardValues={dashboardValues}
                activeFilters={activeFilters}
                setActiveFilters={onFiltersChangeHandler}
                setAreFiltersOpen={setAreFiltersOpen}
                resetFilters={resetFilters}
            />
        );

    return (
        <>
            <div className={classes.dashboard}>
                <Dashboard props={dashboardValues} />
            </div>
            <div className={classes.filterButton}>
                <IconButton className="tertiary" icon={<Filters />} onClick={() => setAreFiltersOpen(true)} />
                {areFiltersActive && (
                    <span className={classes.active}>
                        <IsOnGoing />
                    </span>
                )}
            </div>
            <IconButton className={`tertiary ${classes.fullScreen}`} icon={icon} onClick={toggleWidth} />
            <div className={classes.container}>
                <GridTable
                    enableSearchBar
                    enableToolbar
                    headers={headers}
                    rows={page ?? []}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    onRowSelection={onRowSelection}
                    isFetching={isFetching}
                    getRowClassName={(params: any) => (params.row.status === 'blacklisted' ? classes.canceled : '')}
                    rowCount={dashboardInfo?.length ?? 0}
                    setSearchedWord={onInputChangeHandler}
                    setOrderBy={setOrderBy}
                />
            </div>
        </>
    );
};

export default UsersTable;

