import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const BillingFilled: SvgIconProps = ({ fill = colors.secondary50 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_1974_7996)">
                <path
                    d="M23.3205 16.8933H18.573V0.685976C18.573 0.457317 18.4643 0.237805 18.2741 0.118902C18.0838 -0.00914635 17.8482 -0.027439 17.6308 0.054878L13.59 1.77439L9.54926 0.054878C9.37712 -0.0182927 9.18686 -0.0182927 9.02378 0.054878L4.98301 1.77439L0.942242 0.054878C0.733862 -0.0365854 0.489241 -0.00914634 0.298981 0.109756C0.10872 0.237805 0 0.448171 0 0.676829V20.5701C0 22.4634 1.53114 23.9909 3.39751 23.9909H20.6025C22.4779 23.9909 24 22.4543 24 20.561V17.5701C24 17.1951 23.692 16.8841 23.3205 16.8841V16.8933ZM8.91506 9.47561C7.74632 9.22866 6.69536 8.84451 6.69536 7.53659C6.69536 6.375 7.547 5.69817 8.56172 5.49695V4.39024H9.89355V5.5061C11.1438 5.73476 11.6874 6.57622 11.8143 7.5H10.4553C10.3103 7.03354 9.98415 6.70427 9.1778 6.70427C8.37146 6.70427 8.0453 7.04268 8.0453 7.47256C8.0453 7.85671 8.37146 7.95732 9.24122 8.14939C10.9989 8.52439 11.8868 8.91768 11.8868 10.2348C11.8868 11.5518 10.8811 12.128 9.91166 12.2744V13.372H8.57078V12.2378C7.23896 11.9726 6.66818 11.0396 6.53228 10.0701H7.88222C8.06342 10.7195 8.453 11.0579 9.38618 11.0579C10.1472 11.0579 10.5368 10.7744 10.5368 10.2988C10.5368 9.82317 10.0747 9.74085 8.92412 9.48476L8.91506 9.47561ZM14.487 20.1128H4.07701V18.7409H14.496V20.1128H14.487ZM15.846 16.6829H2.71801V15.311H15.855V16.6829H15.846ZM22.6319 20.561C22.6319 21.6951 21.7169 22.6189 20.5934 22.6189C19.47 22.6189 18.5549 21.6951 18.5549 20.561V18.2561H22.6319V20.561Z"
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_1974_7996">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
