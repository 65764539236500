import TariffInput from 'components/tariffInput/TariffInput';
import { FC, useCallback } from 'react';
import classes from './PricingTime.module.scss';
import { KeyboardEvent } from 'react';

type PricingTimeProps = FC<{
    values: any;
    label: string;
    onChange: (key: string, value: any) => void;
    options: { label: string; key: string; type?: string; isValid?: boolean }[];
    integerOnly?: boolean;
}>;

const PricingTime: PricingTimeProps = ({ onChange, values, label, options, integerOnly = false }) => {
    const onChangeHandler = useCallback((key: string) => (event: any) => onChange(key, event.target.value), [onChange]);

    return (
        <>
            {label.length ? <p className="small primary90">{label}</p> : <></>}
            <div className={classes.priceContainer}>
                {options.map(option => (
                    <div>
                        <p className={`small lightBlue ${classes.label}`}>
                            <b>{option.label}</b>
                        </p>
                        <TariffInput
                            onKeyDown={(evt: KeyboardEvent<HTMLInputElement>) =>
                                option.type === 'number' &&
                                ['e', 'E', '+', '-', integerOnly && '.'].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            value={values[option.key]}
                            onChange={onChangeHandler(option.key)}
                            type={option.type}
                            customClasses={option.isValid !== undefined && !option.isValid ? classes.error : ''}
                        />
                    </div>
                ))}
            </div>
        </>
    );
};

export default PricingTime;

