// components/DocumentForm.tsx
import { FC } from 'react';
import GenericSelect from 'components/select/GenericSelect';
import { FileType } from 'hook';
import { Options } from '../AddDocumentModal';

type DocumentFormProps = {
    selectValue: FileType | '';
    setSelectValue: (value: FileType) => void;
    options: Options;
};

const DocumentForm: FC<DocumentFormProps> = ({ selectValue, setSelectValue, options }) => {
    return (
        <div>
            <label className="small">
                <b>Tipologia</b>
            </label>
            <GenericSelect
                placeholder="Seleziona"
                onChange={(selected: any) => setSelectValue(selected.target.value as FileType)}
                options={options}
                value={selectValue}
            />
        </div>
    );
};

export default DocumentForm;

