import DragNdrop from 'components/dragNdrop/DragNdrop';
import { FC } from 'react';
import classes from './DocumentDragDrop.module.scss';
import colors from 'styles/modules/colors.module.scss';
import { Plus } from 'components/icons';

type DocumentDragDropProps = FC<{
    selectValue: string;
    setFile: (file: File | null) => void;
}>;

const DocumentDragDrop: DocumentDragDropProps = ({ selectValue, setFile }) => {
    return (
        <DragNdrop
            disabled={!selectValue}
            className={`${classes.drop} ${!selectValue ? classes.emptySelect : ''}`}
            onFileSelected={setFile}
            selectFileElement={selectValue && <span className={classes.openFile}>cerca tra i tuoi documenti</span>}
        >
            <div className={classes.plusIcon}>
                <Plus fill={selectValue ? colors.primary100 : colors.primary40} />
            </div>
            {selectValue ? (
                <span>Rilascia un file .pdf, o</span>
            ) : (
                <span>Seleziona prima la tipologia di documento da inserire</span>
            )}
        </DragNdrop>
    );
};

export default DocumentDragDrop;

