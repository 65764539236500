import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const ClearField: SvgIconProps = ({ fill = colors.primary60 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M8 0C3.58 0 0 3.58 0 8C0 12.42 3.58 16 8 16C12.42 16 15.99 12.42 16 8C16 3.58 12.42 0 8 0ZM8 14.5C4.42 14.5 1.5 11.58 1.5 8C1.5 4.42 4.42 1.5 8 1.5C11.58 1.5 14.5 4.42 14.5 8C14.5 11.58 11.58 14.5 8 14.5Z"
                fill={fill}
            />
            <path
                d="M10.4702 4.46997L8.00022 6.93997L5.53022 4.46997L4.47021 5.52997L6.94022 7.99997L4.47021 10.47L5.53022 11.53L8.00022 9.05997L10.4702 11.53L11.5302 10.47L9.06022 7.99997L11.5302 5.52997L10.4702 4.46997Z"
                fill={fill}
            />
        </svg>
    );
};

