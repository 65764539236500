import { Autocomplete, IconButton, InputAdornment, TextField } from '@mui/material';
import axios from 'axios';
import { AreaSettings, ClearField, Parking, Search } from 'components/icons';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { drawActions } from 'store';
import { useLazyGetAreaByIdQuery, useLazyGetAreasByNameOrCodeQuery } from 'store/rtk/area.service';
import colors from 'styles/modules/colors.module.scss';
import classes from './MapSearchBar.module.scss';

const MapSearchBar: FC = () => {
    const [getAreas] = useLazyGetAreasByNameOrCodeQuery();
    const [getAreaById] = useLazyGetAreaByIdQuery();
    const [currentData, setCurrentData] = useState<
        { key: number; value: string; label: string; street: string; treeEye: boolean }[]
    >([]);
    const dispatch = useDispatch();

    const onChangeHandler = (function () {
        let timeout: NodeJS.Timeout | null = null;
        return async (value: string) => {
            if (!value) {
                setCurrentData([]);
                return;
            }
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(async () => {
                const { data } = await getAreas({ searchedWord: value });
                const treeEyeData = await get3EyeGeoData(value);
                if (!data) return;
                setCurrentData([
                    ...data.map((area, index) => ({
                        key: 'data' + index,
                        treeEye: false,
                        value: area.id,
                        label: `${area.code} - ${area.name}`,
                        street: area.street
                    })),
                    ...treeEyeData
                        .filter((item: any) => item.properties.name?.length)
                        .map((item: any, index: number) => ({
                            key: 'treeEyeData' + index,
                            treeEye: true,
                            value: item.geometry,
                            label: item.properties.name,
                            street: item.properties.city && item.properties.city + ', ' + item.properties.countrycode
                        }))
                ]);
            }, 500);
        };
    })();

    const get3EyeGeoData = async (param: string) => {
        try {
            if (typeof param !== 'string' || param === '') return;
            const res = await axios.get(`https://www.3eye.it/geocoding/api?q=${param}`);
            return res.data.features;
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                console.error('Error:', error.message);
                throw new Error('Failed to fetch data from 3Eye Geo API');
            } else {
                console.error('Error:', error.message);
                throw error;
            }
        }
    };

    const onSelectHandler = async (id: any) => {
        if (!id) return;
        if (typeof id === 'string') {
            const res = await getAreaById({ id });
            if (res.data) dispatch(drawActions.zoomOnBbox(res.data.geometry));
        } else {
            dispatch(drawActions.zoomOnBbox(id));
        }
    };

    const onClearHandler = (event: any, params: any) => {
        event.preventDefault();
        params.inputProps.onChange &&
            params.inputProps.onChange({
                target: { value: '' }
            } as React.ChangeEvent<HTMLInputElement>);
        setCurrentData([]);
    };

    return (
        <Autocomplete
            classes={{ root: classes.container, paper: classes.paper }}
            disablePortal
            freeSolo
            options={currentData}
            onChange={(_event, newValue) => {
                if (typeof newValue !== 'string') onSelectHandler(newValue?.value as string);
            }}
            filterOptions={value => value}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderOption={(props, option) => (
                <li {...props} key={option.key} className={`${props.className} ${classes.listOption}`}>
                    <div>{option.treeEye ? <AreaSettings fill={colors.primary60} /> : <Parking fill={colors.primary60} />}</div>
                    <div className={classes.optionInfo}>
                        <p className="small">{option.label}</p>
                        <p className="small">{option.street}</p>
                    </div>
                </li>
            )}
            renderInput={params => (
                <TextField
                    onChange={(event: any) => onChangeHandler(event.target.value)}
                    {...params}
                    placeholder="Indirizzo o codice parcheggio"
                    classes={{ root: classes.textField }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: <Search />,
                        endAdornment: (
                            <InputAdornment position="end">
                                {params.inputProps.value && (
                                    <IconButton size="medium" onClick={event => onClearHandler(event, params)}>
                                        <ClearField />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        )
                    }}
                />
            )}
        />
    );
};

export default MapSearchBar;

