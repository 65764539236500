import { ArrowForward } from 'components/icons';
import classes from './Documents.module.scss';
import { Cookie, Description, PrivacyTip } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Documents = () => {
    const nav = useNavigate();
    return (
        <div className={classes.container}>
            <div
                className={classes.card}
                onClick={() => {
                    nav('termsAndConditions');
                }}
            >
                <div className={classes.icon}>
                    <Description />
                </div>
                <b>Termini e Condizioni</b>
                <ArrowForward />
            </div>
            <div
                className={classes.card}
                onClick={() => {
                    nav('privacyPolicy');
                }}
            >
                <div className={classes.icon}>
                    <PrivacyTip />
                </div>
                <b>Privacy Policy</b>
                <ArrowForward />
            </div>
            <div className={`small ${classes.disclaimer}`}>
                Il seguente documento è soggetto ad accettazione facoltativa. Potrai decidere in app se accettare o meno le
                disposizioni contenute in esso. La tua decisione non influirà sull'utilizzo del servizio.
            </div>
            <div
                className={classes.card}
                onClick={() => {
                    nav('marketingAndProfiling');
                }}
            >
                <div className={classes.icon}>
                    <Cookie />
                </div>
                <b>Marketing e Profilazione</b>
                <ArrowForward />
            </div>
        </div>
    );
};

export default Documents;

