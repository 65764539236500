import { FC } from 'react';
import { SettingsTypes } from '../settingsSidebar/SettingsSidebar';
import AdminsTable from './adminsTable/AdminsTable';
import classes from './SettingsTables.module.scss';
import { Report } from '@mui/icons-material';
import ManageContentTable from './manageContentTable/ManageContentTable';

type SettingsTablesProps = FC<{
    activeTab: SettingsTypes;
}>;

const SettingsTables: SettingsTablesProps = ({ activeTab }) => {
    return (
        <div className={classes.container}>
            <div className={classes.tables}>
                {activeTab === SettingsTypes.users ? (
                    <AdminsTable />
                ) : activeTab === SettingsTypes.manageContent ? (
                    <ManageContentTable />
                ) : (
                    <div className={classes.report}>
                        <Report />
                        <p>Work in progress</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SettingsTables;

