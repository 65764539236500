import { PaymentFilters } from 'helpers';
import { VehicleWithVehicleType } from './vehicle.model';

export interface UserUpdate {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    Municipalities: { name: string; id: string }[];
    role: UserRoles;
    prefix: string;
    telephoneNumber: string;
    customerId?: string;
}

export interface User extends UserUpdate {
    id: string;
    prefix: string;
    status: UsersStatus;
    defaultVehicle: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
}

export interface UserWithVehicles extends User {
    Vehicles: VehicleWithVehicleType[];
    canceledOrderId: string;
}

export interface UserToken extends User {
    token: string;
    tokenExpiration: number;
}

export interface UserFilters extends PaymentFilters {
    vehicleTypes: string[];
    inProgress: string;
}

export interface UserDashboardInfo {
    created_at: Date;
    parking: number;
}

export enum UserRoles {
    configurator = 'configurator',
    municipalityEmployee = 'municipalityEmployee',
    accountingOffice = 'accountingOffice',
    superAdmin = 'superAdmin'
}

export enum UsersStatus {
    disabled = 'disabled',
    enabled = 'enabled',
    blacklisted = 'blacklisted'
}

