import { Checkbox, FormControlLabel } from '@mui/material';
import Chip from 'components/chip/Chip';
import { CheckBoxChecked, CheckBoxUnchecked, CheckmarkChecked, RadioUnchecked } from 'components/icons';
import { FC, PropsWithChildren } from 'react';
import classes from './Card.module.scss';

type CardProps = PropsWithChildren<
    {
        chips?: { icon: JSX.Element; label: string; active?: boolean }[];
        onClick?: () => void;
        disabled?: boolean;
        checked?: boolean;
        onChange?: () => void;
        className?: string;
    } & ConditionalProps
>;
type ConditionalProps =
    | {
          checkIcon?: boolean | null;
          checkBox?: never;
      }
    | {
          checkIcon?: never;
          checkBox?: boolean;
      };

const Card: FC<CardProps> = ({
    children,
    chips,
    onClick,
    checkIcon = null,
    checkBox = false,
    disabled,
    checked,
    onChange,
    className
}) => {
    return (
        <div className={`${classes.container} ${className} ${checkBox ? classes.border : ''}`} onClick={onClick}>
            {checkIcon !== null && (
                <div className={classes.checkIcon}>{checkIcon ? <CheckmarkChecked /> : <RadioUnchecked />}</div>
            )}
            {checkBox ? (
                <FormControlLabel
                    sx={{
                        '.MuiFormControlLabel-label': {
                            color: 'white !important',
                            opacity: disabled ? 0.6 : 1
                        }
                    }}
                    control={
                        <Checkbox
                            disabled={disabled}
                            onChange={onChange}
                            checked={checked}
                            icon={<CheckBoxUnchecked />}
                            checkedIcon={<CheckBoxChecked />}
                            sx={{
                                '&.Mui-disabled': {
                                    opacity: 0.5
                                }
                            }}
                        />
                    }
                    label={<b>{children}</b>}
                />
            ) : (
                <b>{children}</b>
            )}
            <div className={classes.chips}>
                {chips?.map(({ icon, label, active }, i) => (
                    <Chip key={label + i} icon={icon} label={label} active={active} />
                ))}
            </div>
        </div>
    );
};

export default Card;
