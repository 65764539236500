import TariffInput from 'components/tariffInput/TariffInput';
import TariffAccordion from 'components/tariffAccordion/TariffAccordion';
import TariffAccordionTypography from 'components/tariffAccordionTypography/tariffAccordionTypography';
import classes from './FreeParkingTime.module.scss';
import { KeyboardEvent, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, createTariffRuleActions } from 'store';

const PICKERS = [
    {
        label: 'Giorni',
        type: 'days',
        min: 1,
        max: 999
    },
    {
        label: 'Ore',
        type: 'hours',
        min: 1,
        max: 23
    },
    {
        label: 'Minuti',
        type: 'mins',
        min: 1,
        max: 59
    }
];

const FreeParkingTime = () => {
    const { freeTime, minTime } = useSelector((x: RootState) => x.createTariffRule);
    const { selectedRule } = useSelector((x: RootState) => x.tariffs);
    const dispatch = useDispatch();

    const mappedFreeTimePickerInput = useMemo(
        () =>
            PICKERS.map((picker, index) => (
                <div key={index}>
                    <p className="small">
                        <b>{picker.label}</b>
                    </p>
                    <TariffInput
                        onKeyDown={(evt: KeyboardEvent<HTMLInputElement>) =>
                            ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()
                        }
                        onChange={(event: any) =>
                            dispatch(createTariffRuleActions.setFreeTime({ key: picker.type, value: event.target.value }))
                        }
                        value={(freeTime as any)[picker.type]}
                        type="number"
                        min={picker.min}
                        max={picker.max}
                        customClasses={classes.freeTimePicker}
                    />
                </div>
            )),
        [dispatch, freeTime]
    );

    const mappedEndingTimePickerInput = useMemo(
        () =>
            PICKERS.map((picker, index) => (
                <div key={index}>
                    <p className="small">
                        <b>{picker.label}</b>
                    </p>
                    <TariffInput
                        onKeyDown={(evt: KeyboardEvent<HTMLInputElement>) =>
                            ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()
                        }
                        onChange={(event: any) =>
                            dispatch(createTariffRuleActions.setMinTime({ key: picker.type, value: event.target.value }))
                        }
                        value={(minTime as any)[picker.type]}
                        type="number"
                        min={picker.min}
                        max={picker.max}
                        customClasses={classes.freeTimePicker}
                    />
                </div>
            )),
        [dispatch, minTime]
    );

    useEffect(() => {
        if (!selectedRule) return;

        let freeMins = selectedRule.freeTime ?? 0;
        const freeDays = Math.floor(freeMins / (24 * 60));
        freeMins = freeMins % (24 * 60);
        const freeHours = Math.floor(freeMins / 60);
        freeMins = freeMins % 60;

        dispatch(createTariffRuleActions.setFreeTime({ key: 'days', value: freeDays || undefined }));
        dispatch(createTariffRuleActions.setFreeTime({ key: 'hours', value: freeHours || undefined }));
        dispatch(createTariffRuleActions.setFreeTime({ key: 'mins', value: freeMins || undefined }));

        let minMins = selectedRule.minTime ?? 0;
        const minDays = Math.floor(minMins / (24 * 60));
        minMins = minMins % (24 * 60);
        const minHours = Math.floor(minMins / 60);
        minMins = minMins % 60;

        dispatch(createTariffRuleActions.setMinTime({ key: 'days', value: minDays || undefined }));
        dispatch(createTariffRuleActions.setMinTime({ key: 'hours', value: minHours || undefined }));
        dispatch(createTariffRuleActions.setMinTime({ key: 'mins', value: minMins || undefined }));

        dispatch(createTariffRuleActions.setMinTime({ key: 'amount', value: selectedRule.minPrice || undefined }));
    }, [dispatch, selectedRule]);

    return (
        <TariffAccordion
            title={
                <div className={classes.title}>
                    Tempo gratuito / Costo minimo &nbsp;
                    <span className="primary60">- opzionale</span>
                </div>
            }
            defaultOpen={!!selectedRule}
            buttonTitle="Aggiungi"
            customClasses={classes.fieldOrganizerAccordion}
        >
            <TariffAccordionTypography customClasses={classes.fieldOrganizerTypography}>
                <div className={classes.timeContainer}>
                    <label className="small">Tempo gratuito</label>
                    <div className={classes.freeTimePickerInput}>{mappedFreeTimePickerInput}</div>
                </div>
                <div className={classes.costContainer}>
                    <div>
                        <label className="small">Costo minimo</label>
                        <p className="small">
                            <b>Euro</b>
                        </p>
                        <TariffInput
                            customClasses={classes.euroInput}
                            onKeyDown={(evt: KeyboardEvent<HTMLInputElement>) =>
                                ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                            }
                            onChange={(event: any) =>
                                dispatch(createTariffRuleActions.setMinTime({ key: 'amount', value: event.target.value }))
                            }
                            value={minTime.amount}
                            type="number"
                            min={0}
                            placeholder="es.0.5"
                        />
                    </div>
                    <div>
                        <label className="small">Fino a</label>
                        <div className={classes.freeTimePickerInput}>{mappedEndingTimePickerInput}</div>
                    </div>
                </div>
            </TariffAccordionTypography>
        </TariffAccordion>
    );
};

export default FreeParkingTime;

