import GenericSelect from 'components/select/GenericSelect';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateTariffRuleState, RootState, createTariffRuleActions } from 'store';
import classes from './Time.module.scss';
import TimeSlot from './timeSlot/TimeSlot';
import WeeklyRepetitionModal from './weeklyRepetitionModal/WeeklyRepetitionModal';

const SELECT_OPTIONS = [
    { label: 'Giornaliera', value: 'always' },
    { label: 'Settimanale', value: 'weekly' }
];

const Time = () => {
    const { validityPeriod, isValid, isSubmitted } = useSelector<RootState>(x => x.createTariffRule) as CreateTariffRuleState;
    const dispatch = useDispatch();

    const selectOptions = useMemo(() => {
        return SELECT_OPTIONS.map(option => ({
            id: option.value,
            label: option.label,
            itemOnClick: () => option.value === 'weekly' && dispatch(createTariffRuleActions.setIsRepetitionModalOpen(true))
        }));
    }, [dispatch]);

    const onChangeHandler = (value: string) => {
        dispatch(
            createTariffRuleActions.setValidityPeriodValues({
                key: 'repetitionType',
                value
            })
        );
        dispatch(createTariffRuleActions.setIsRepetitionModalOpen(true));
    };

    useEffect(() => {
        dispatch(
            createTariffRuleActions.setIsValid({
                key: 'repetition-type',
                isValid: validityPeriod.repetitionType.length > 0
            })
        );
    }, [dispatch, validityPeriod.repetitionType]);

    return (
        <div className={classes.container}>
            <TimeSlot />
            <div className={classes.timeContainer}>
                <label className="small">
                    <b>Ripetizione</b>
                </label>
                <GenericSelect
                    placeholder="Seleziona"
                    value={validityPeriod.repetitionType}
                    onChange={(selectedRepetition: any) => onChangeHandler(selectedRepetition.target.value)}
                    className={isSubmitted && !isValid['repetition-type'] ? classes.error : ''}
                    options={selectOptions}
                    sxFormControl={{ width: '100% !important' }}
                />
                <WeeklyRepetitionModal />
            </div>
        </div>
    );
};

export default Time;
