import PeriodOfTimeFilter from 'components/periodOfTimeFilter/PeriodOfTimeFilter';
import classes from './PeriodFilters.module.scss';
import { FC } from 'react';
import { CustomerServiceFiltersItems } from 'helpers';
import ChipsFilter from 'components/chipsFilter/ChipsFilter';

type PeriodFilterProps = FC<{
    setActiveFilters: (key: keyof CustomerServiceFiltersItems, value: any) => void;
    activeFilters: CustomerServiceFiltersItems;
}>;

const PeriodFilters: PeriodFilterProps = ({ setActiveFilters, activeFilters }) => {
    return (
        <div className={classes.container}>
            <p>Periodo</p>
            <div className={classes.filters}>
                <PeriodOfTimeFilter activeFilter={activeFilters.date} setActiveFilter={setActiveFilters} />
                <ChipsFilter activeFilter={activeFilters.date} setActiveFilter={setActiveFilters} />
            </div>
        </div>
    );
};

export default PeriodFilters;
