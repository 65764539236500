import Accordion from 'components/accordion/Accordion';
import AccordionTypography from 'components/accordionTypography/AccordionTypography';
import { IconButton } from 'components/buttons';
import { ArrowBack, Delete, Edit } from 'components/icons';
import { Ellipse } from 'components/icons/Ellipse';
import { DEFAULT_LAYER_DATA, formatFeature } from 'helpers';
import { FC, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DrawPolygonState, MunicipalityState, RootState, TabTypes, areasActions, drawActions, municipalityActions } from 'store';
import colors from 'styles/modules/colors.module.scss';
import classes from './SettingAreaDetail.module.scss';
import Tariff from './tariff/Tariff';
import { usePermissionHandler } from 'hook';
import DeleteAreaModal from './deleteAreaModal/DeleteAreaModal';

const SettingsAreaDetail: FC = () => {
    const { selectedArea, selectedMunicipality } = useSelector<RootState>(x => x.municipalities) as MunicipalityState;
    const { mode } = useSelector<RootState>(x => x.draw) as DrawPolygonState;
    const dispatch = useDispatch();
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const hasPermission = usePermissionHandler();

    const onEditHandler = () => {
        dispatch(drawActions.setData({ ...DEFAULT_LAYER_DATA, features: [formatFeature(selectedArea?.geometry)] }));
        dispatch(drawActions.zoomOnBbox(selectedArea?.geometry));
        dispatch(municipalityActions.toggleEditArea());
        if (mode === 'modify') return;
        dispatch(drawActions.setMode('modify'));
    };

    const onBackHandler = () => {
        dispatch(municipalityActions.setSelectedArea(undefined));
        dispatch(areasActions.setSelectedArea(undefined));
    };

    return (
        <>
            <div className={classes.header}>
                <div className={classes.backButton}>
                    <IconButton className="tertiary" icon={<ArrowBack />} onClick={onBackHandler} />
                    <p>
                        <b>
                            <span>{selectedArea?.cityName || selectedMunicipality?.name} / </span> Area - {selectedArea?.name}
                        </b>
                    </p>
                </div>
                <div className={classes.buttons}>
                    <IconButton
                        disabled={!hasPermission(TabTypes.municipalitySettings, ['can-edit'])}
                        className="tertiary"
                        onClick={onEditHandler}
                        icon={
                            <Edit
                                fill={
                                    !hasPermission(TabTypes.municipalitySettings, ['can-delete'])
                                        ? colors.primary40
                                        : colors.primary100
                                }
                            />
                        }
                    />
                    <IconButton
                        disabled={!hasPermission(TabTypes.municipalitySettings, ['can-delete'])}
                        className="tertiary"
                        onClick={() => setIsDeleteOpen(true)}
                        icon={
                            <Delete
                                fill={
                                    !hasPermission(TabTypes.municipalitySettings, ['can-delete'])
                                        ? colors.primary40
                                        : colors.primary100
                                }
                            />
                        }
                    />
                </div>
            </div>
            <div className={classes.container}>
                <Accordion
                    title={
                        selectedArea?.isDisabled ? (
                            <>
                                <div className={classes.area}>
                                    <p>Informazioni</p>
                                    <div className={classes.disabled}>
                                        <Ellipse />
                                        <label className="small"> Area disabilitata</label>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <p>Informazioni</p>
                        )
                    }
                >
                    <AccordionTypography className={classes.fields}>
                        <div className={classes.column}>
                            <div>
                                <label className="small">Codice</label>
                                <p>{selectedArea?.code}</p>
                            </div>
                            <div>
                                <label className="small">Città</label>
                                <p>{selectedArea?.cityName || selectedMunicipality?.name}</p>
                            </div>
                            <div>
                                <label className="small">Indirizzo</label>
                                <p>{selectedArea?.street}</p>
                            </div>
                        </div>
                        <div className={classes.column}>
                            <div>
                                <label className="small">Nome</label>
                                <p>{selectedArea?.name}</p>
                            </div>
                            <div>
                                <label className="small">Capienza</label>
                                <p>{selectedArea?.parkingSpaces}</p>
                            </div>
                        </div>
                    </AccordionTypography>
                </Accordion>
                <DeleteAreaModal isOpen={isDeleteOpen} setIsOpen={setIsDeleteOpen} onBackHandler={onBackHandler} />
            </div>
            <Tariff className={classes.tariff} tariffName={selectedArea?.tariffName!} />
        </>
    );
};

export default memo(SettingsAreaDetail);

