import { SvgIconProps } from 'helpers';

export const RefoundButton: SvgIconProps = ({ fill }) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.5 23.1901V20.8601C15.21 20.7101 15.87 20.3701 16.4 19.8401C17.11 19.1301 17.5 18.1901 17.5 17.1901C17.5 16.1901 17.11 15.2501 16.4 14.5401C15.69 13.8301 14.75 13.4401 13.75 13.4401C13.15 13.4401 12.58 13.2101 12.16 12.7801C11.74 12.3501 11.5 11.7901 11.5 11.1901C11.5 10.5901 11.73 10.0201 12.16 9.60005C13.01 8.75005 14.49 8.75005 15.34 9.60005L16.2 10.4601L17.26 9.40005L16.4 8.54005C15.88 8.02005 15.21 7.69005 14.5 7.55005V5.18005H13V7.55005C12.29 7.69005 11.62 8.01005 11.09 8.54005C10.38 9.25005 9.98999 10.1901 9.98999 11.1901C9.98999 12.1901 10.38 13.1301 11.09 13.8401C11.8 14.5501 12.74 14.9401 13.74 14.9401C14.34 14.9401 14.91 15.1701 15.33 15.6001C16.21 16.4801 16.21 17.9001 15.33 18.7801C14.48 19.6301 13 19.6301 12.15 18.7801L11.22 17.8501L10.16 18.9101L11.09 19.8401C11.62 20.3701 12.28 20.7201 13 20.8601V23.1801H14.5V23.1901Z"
                fill="#8DBEF9"
            />
            <path
                d="M26 14.08C26.03 17.39 24.76 20.51 22.41 22.86C17.63 27.63 9.86 27.64 5.09 22.86C0.31 18.08 0.31 10.31 5.09 5.53C9.39 1.23 16.1 0.82 20.88 4.25H17.91V5.75H22.91C23.32 5.75 23.66 5.41 23.66 5V0H22.16V3.33C16.77 -0.85 8.97 -0.48 4.03 4.47C1.43 7.07 0 10.52 0 14.19C0 17.86 1.43 21.32 4.03 23.91C6.71 26.59 10.23 27.93 13.75 27.93C17.27 27.93 20.79 26.59 23.47 23.91C26.1 21.28 27.53 17.78 27.5 14.06H26V14.08Z"
                fill="#8DBEF9"
            />
        </svg>
    );
};
