import { FC, useMemo } from 'react';
import classes from './Dashboard.module.scss';
import { Info } from 'components/icons';
import { DashboardProps as DashboardPropsInterface } from 'helpers';

type DashboardProps = FC<{
    props: DashboardPropsInterface[];
}>;

const Dashboard: DashboardProps = ({ props }) => {
    const mappedFields = useMemo(
        () =>
            props
                .filter(prop => prop.label !== '' && prop.value !== '')
                .map(props => (
                    <div className={classes.data} key={props.label}>
                        <h1>{props.value}</h1>
                        <span title={props.info}>
                            {props.label} {props.info ? <Info /> : null}
                        </span>
                    </div>
                )),
        [props]
    );

    return <div className={classes.dashboardData}>{mappedFields}</div>;
};

export default Dashboard;

