import { AxiosContext } from './axiosContext';
import { FC, PropsWithChildren, createContext, useCallback, useContext } from 'react';
import { Area, AreaUpdate } from 'models';

interface AreaApi {
    updateArea: (update: AreaUpdate) => Promise<Area>;
    deleteAreas: (areasId: string[]) => Promise<string>;
}

const AreaContext = createContext<AreaApi>({} as AreaApi);

const AreaProvider: FC<PropsWithChildren> = ({ children }) => {
    const { putRequest, deleteRequest } = useContext(AxiosContext);

    const updateArea = useCallback(
        async (update: AreaUpdate) => {
            const { data } = await putRequest(`/areas`, { update });
            return data;
        },
        [putRequest]
    );

    const deleteAreas = useCallback(
        async (areasId: string[]) => {
            const { data } = await deleteRequest(`/areas`, { areasId });
            return data;
        },
        [deleteRequest]
    );

    return <AreaContext.Provider value={{ updateArea, deleteAreas }}>{children}</AreaContext.Provider>;
};

export { AreaContext, AreaProvider };
