import GenericSelect from 'components/select/GenericSelect';
import TariffInput from 'components/tariffInput/TariffInput';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, createTariffRuleActions } from 'store';
import classes from './Info.module.scss';

const SELECT_OPTIONS = [
    { label: 'Gratuita', value: 'free' },
    { label: 'Pagamento', value: 'slopes' },
    { label: 'Divieto', value: 'blocking' }
];

const Info = () => {
    const { name, type, isSubmitted, isValid } = useSelector((x: RootState) => x.createTariffRule);
    const { selectedRule } = useSelector((x: RootState) => x.tariffs);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!selectedRule) return;
        dispatch(createTariffRuleActions.setRuleName(selectedRule.name));
        dispatch(createTariffRuleActions.setRuleType(selectedRule.rateType));
    }, [dispatch, selectedRule]);

    useEffect(() => {
        dispatch(createTariffRuleActions.setIsValid({ key: 'rule', isValid: name.length > 2 }));
    }, [dispatch, name]);
    useEffect(() => {
        dispatch(createTariffRuleActions.setIsValid({ key: 'type', isValid: type.length > 0 }));
    }, [dispatch, type]);

    const selectOptions = useMemo(() => {
        return SELECT_OPTIONS.map(option => ({
            id: option.value,
            label: option.label
        }));
    }, []);

    return (
        <div className={classes.infoContainer}>
            <p className={classes.title}>Info</p>
            <div className={classes.inputs}>
                <div className={classes.wrapper}>
                    <p className={`${classes.subtitle} small`}>
                        <b>Nome</b>
                    </p>
                    <TariffInput
                        placeholder="es.Standard festivi"
                        type="text"
                        customClasses={isSubmitted && !isValid.rule ? classes.error : ''}
                        min={1}
                        max={20}
                        value={name}
                        onChange={(event: any) => dispatch(createTariffRuleActions.setRuleName(event.target.value))}
                    />
                </div>
                <div className={classes.wrapper}>
                    <p className={`${classes.subtitle} small`}>
                        <b>Tipo</b>
                    </p>

                    <GenericSelect
                        placeholder="Seleziona"
                        value={type}
                        className={isSubmitted && !isValid.type ? classes.error : ''}
                        onChange={(e: any) => dispatch(createTariffRuleActions.setRuleType(e.target.value))}
                        options={selectOptions}
                    />
                </div>
            </div>
        </div>
    );
};

export default Info;

