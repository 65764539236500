import Form from 'components/form/Form';
import Modal from 'components/modal/Modal';
import { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, billingsActions, snackbarActions } from 'store';
import { useLazyUpdateBillingDetailsQuery } from 'store/rtk/billing.service';
import classes from './BillingDetailsInfoEditModal.module.scss';
import Snackbar from 'components/snackbar/Snackbar';
import { usersApi } from 'store/rtk/user.service';
import { validCountryCodes } from 'helpers';

type BillingDetailsInfoEditModalProps = FC<{
    isEditModalOpen: boolean;
    closeModal: () => void;
    refetchBillingsData: () => void;
    refetchBillingDetailsData: () => void;
}>;

const BillingDetailsInfoEditModal: BillingDetailsInfoEditModalProps = ({
    isEditModalOpen,
    closeModal,
    refetchBillingsData,
    refetchBillingDetailsData
}) => {
    const { billingDetails, selectedBilling } = useSelector((x: RootState) => x.billings);
    const fields = useMemo(() => {
        return [
            { id: 'businessName', label: 'Ragione sociale', value: billingDetails?.businessName, required: true },
            { id: 'vatNumber', label: 'P.IVA/Codice fiscale', value: billingDetails?.vatNumber, required: true },
            {
                id: 'pec',
                label: 'PEC',
                value: billingDetails?.pec,
                required: false,
                validate: { pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ }
            },
            {
                id: 'sdi',
                label: 'SDI',
                value: billingDetails?.sdi,
                required: false
            },
            { id: 'address', label: 'Indirizzo', value: billingDetails?.address, required: true },
            { id: 'municipality', label: 'Città', value: billingDetails?.municipality, required: true },
            { id: 'province', label: 'Provincia', value: billingDetails?.province, required: true },
            { id: 'cap', label: 'CAP', value: billingDetails?.cap, required: true },
            {
                id: 'nation',
                label: 'Nazione',
                value: billingDetails?.nation,
                required: true,
                validate: {
                    pattern: /^[A-Za-z]{2}$/,
                    validate: (value: string) => validCountryCodes.includes(value.toUpperCase())
                }
            }
        ].flat();
    }, [billingDetails]);

    const [updateBillingDetails] = useLazyUpdateBillingDetailsQuery();
    const dispatch = useDispatch();

    const updateDetails = useCallback(
        async (update: Record<string, string>) => {
            if (update.nation) update.nation = update.nation.toUpperCase();
            const updateStore = {
                ...billingDetails!,
                ...update
            };
            await updateBillingDetails(updateStore)
                .then(res => {
                    if (!res.data) return;
                    dispatch(billingsActions.setBillingDetails(updateStore));
                    dispatch(usersApi.util.resetApiState());
                    closeModal();
                })
                .then(() => {
                    selectedBilling &&
                        dispatch(
                            billingsActions.setSelectedBilling({
                                ...selectedBilling,
                                businessName: updateStore.businessName
                            })
                        );
                    refetchBillingsData();
                    refetchBillingDetailsData();
                });
        },
        [
            billingDetails,
            closeModal,
            updateBillingDetails,
            dispatch,
            selectedBilling,
            refetchBillingsData,
            refetchBillingDetailsData
        ]
    );

    const onSubmitHandler = async (data: any) => {
        if (data.pec && data.sdi) {
            dispatch(
                snackbarActions.setMessageAndType({
                    message: `I campi PEC e SDI non possono essere entrambi compilati.`,
                    type: 'error'
                })
            );
            dispatch(snackbarActions.setIsOpen(true));
            return;
        }
        if (data.nation.toUpperCase() === 'IT' && !data.pec && !data.sdi) {
            dispatch(
                snackbarActions.setMessageAndType({
                    message: `Campo PEC/SDI obbligatorio se nazionalità è IT.`,
                    type: 'error'
                })
            );
            dispatch(snackbarActions.setIsOpen(true));
            return;
        }
        await updateDetails(data);
        dispatch(snackbarActions.setMessageAndType({ message: `Informazioni modificate con successo.` }));
        dispatch(snackbarActions.setIsOpen(true));
    };

    return (
        <>
            <Modal
                title="Modifica info azienda"
                isOpen={isEditModalOpen}
                handleClose={closeModal}
                customClasses={classes.container}
            >
                <Form formFields={fields} onSubmitHandler={onSubmitHandler} buttonLabel="Salva" undoAction={closeModal} />
            </Modal>
            <Snackbar />
        </>
    );
};

export default BillingDetailsInfoEditModal;

