import Accordion from 'components/accordion/Accordion';
import AccordionTypography from 'components/accordionTypography/AccordionTypography';
import { FC, useMemo } from 'react';
import classes from './PaymentMethods.module.scss';
import { PaymentContract } from 'helpers';

type PaymentMethodsProps = FC<{
    paymentContracts: PaymentContract[];
}>;

const PaymentMethods: PaymentMethodsProps = ({ paymentContracts }) => {
    const mappedPaymentContracts = useMemo(() => {
        return paymentContracts?.map(paymentContract => {
            return (
                <AccordionTypography>
                    <div className={classes.field}>
                        <div>
                            <label className="small">
                                <b>Provider</b>
                            </label>
                            <p>{paymentContract.paymentCircuit}</p>
                        </div>
                    </div>
                    <div className={classes.field}>
                        <div>
                            <label className="small">
                                <b>Ultime cifre</b>
                            </label>
                            <p>{paymentContract.paymentInstrumentInfo}</p>
                        </div>
                    </div>
                    <div className={classes.field}>
                        <div>
                            <label className="small">
                                <b>Contract Id</b>
                            </label>
                            <p>{paymentContract.contractId}</p>
                        </div>
                    </div>
                </AccordionTypography>
            );
        });
    }, [paymentContracts]);

    return (
        <div className={classes.accordionContainer}>
            <Accordion title="Metodi di pagamento">{mappedPaymentContracts}</Accordion>
        </div>
    );
};

export default PaymentMethods;

