import TextButton from 'components/buttons/textButton/TextButton';
import Card from 'components/card/Card';
import { Rule } from 'components/icons';
import Modal from 'components/modal/Modal';
import Searchbar from 'components/searchbar/Searchbar';
import Snackbar from 'components/snackbar/Snackbar';
import { TariffWithMunicipality } from 'helpers';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, municipalityActions, snackbarActions } from 'store';
import { useGetAllTariffsQuery, useLazyImportTariffQuery } from 'store/rtk/tariff.service';
import classes from './ImportTariffModal.module.scss';
import NoResult from 'components/noResult/NoResult';

type ImportTariffModalProps = FC<{
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    refetch: () => void;
}>;

const ImportTariffModal: ImportTariffModalProps = ({ isOpen, setIsOpen, refetch }) => {
    const { data: tariffs, refetch: refetchRules } = useGetAllTariffsQuery();
    const [searchedWord, setSearchedWord] = useState('');
    const dispatch = useDispatch();
    const [selectedTariff, setSelectedTariff] = useState<TariffWithMunicipality | null>(null);
    const handleClose = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);
    const [importTariff] = useLazyImportTariffQuery();
    const { selectedMunicipality } = useSelector((x: RootState) => x.municipalities);

    const filteredTariffs = useMemo(
        () =>
            tariffs?.filter(
                (tariff: any) =>
                    tariff.name.toLowerCase().includes(searchedWord.toLowerCase()) ||
                    tariff.Municipality.name.toLowerCase().includes(searchedWord.toLowerCase())
            ),
        [tariffs, searchedWord]
    );

    useEffect(() => {
        refetchRules();
    }, []);

    const tariffCard = useMemo(
        () =>
            filteredTariffs?.map((tariff: TariffWithMunicipality) => (
                <Card
                    className={`${classes.card} ${selectedTariff?.id === tariff.id ? classes.selected : ''}`}
                    key={tariff.id}
                    onClick={() => setSelectedTariff(tariff)}
                    chips={[{ icon: <Rule />, label: `${tariff?.TariffRules?.length}` }]}
                >
                    <span>{tariff.Municipality.name}</span> - <span>{tariff?.name}</span>
                </Card>
            )),
        [filteredTariffs, selectedTariff]
    );

    const importTariffHandler = async (action: boolean) => {
        if (!action) {
            setSelectedTariff(null);
            handleClose();
            return;
        }
        if (!selectedTariff || !selectedMunicipality) return;
        try {
            const { data } = await importTariff({ tariffId: selectedTariff.id, municipalityId: selectedMunicipality?.id });
            if (!data) {
                dispatch(snackbarActions.setMessageAndType({ message: `Tariffa non importata.`, type: 'error' }));
                dispatch(snackbarActions.setIsOpen(true));
                return;
            }
            dispatch(municipalityActions.addTariff(data));
            dispatch(snackbarActions.setMessageAndType({ message: `Tariffa importata con successo.` }));
            dispatch(snackbarActions.setIsOpen(true));
            refetch();
            refetchRules();
            handleClose();
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <Modal customClasses={classes.importTariffModal} title="Importa Tariffa" isOpen={isOpen} handleClose={handleClose}>
                <Searchbar value={searchedWord} onChange={setSearchedWord} />
                <div className={classes.cardContainer}>{tariffCard && tariffCard?.length > 0 ? tariffCard : <NoResult />}</div>
                <div className={classes.btnWrapper}>
                    <TextButton className={`secondary ${classes.btn}`} onClick={() => importTariffHandler(false)}>
                        Annulla
                    </TextButton>
                    <TextButton
                        disabled={!selectedTariff}
                        className={`${selectedTariff ? 'primary' : 'secondary'} ${classes.btn}`}
                        onClick={() => importTariffHandler(true)}
                    >
                        Importa
                    </TextButton>
                </div>
            </Modal>
            <Snackbar />
        </>
    );
};

export default ImportTariffModal;

