import Form from 'components/form/Form';
import classes from './ResetPassword.module.scss';
import { ArrowBack } from 'components/icons';
import { IconButton } from 'components/buttons';
import { useLazyResetPasswordRequestQuery } from 'store/rtk/user.service';
import { history } from 'helpers';
import Snackbar from 'components/snackbar/Snackbar';
import { useDispatch } from 'react-redux';
import { snackbarActions } from 'store/snackbar.slice';

const FIELDS = [{ label: 'Email', id: 'email', required: true, validate: { pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ } }];

const ResetPassword = () => {
    const dispatch = useDispatch();
    const [resetPasswordRequest] = useLazyResetPasswordRequestQuery();

    const onSubmitHandler = async (formFields: any) => {
        try {
            await resetPasswordRequest(formFields.email);
            dispatch(snackbarActions.setMessageAndType({ message: 'Mail inviata con successo.' }));
            dispatch(snackbarActions.setIsOpen(true));
        } catch {
            dispatch(snackbarActions.setMessageAndType({ message: 'Errore di invio.', type: 'error' }));
            dispatch(snackbarActions.setIsOpen(true));
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.form}>
                <div className={classes.header}>
                    <IconButton icon={<ArrowBack />} onClick={() => history.navigate('/')} className="tertiary" />
                    <p>
                        <b>Reset password</b>
                    </p>
                </div>
                <label>Invieremo un link per il reset della password alla mail collegata al tuo account</label>
                <Form formFields={FIELDS} onSubmitHandler={onSubmitHandler} buttonLabel="Invia link di verifica" />
            </div>
            <Snackbar />
        </div>
    );
};

export default ResetPassword;
