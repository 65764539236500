import { Checkbox,  ListItemText } from '@mui/material';
import { FC, useMemo } from 'react';
import classes from './PaymentStateFilter.module.scss';
import { CheckBoxChecked, CheckBoxUnchecked } from 'components/icons';
import GenericSelect from 'components/select/GenericSelect';

type PaymentStateFilterProps = FC<{
    activeFilters: string[];
    setActiveFilters: any;
}>;

const paymentTypes = [
    { id: 'created', label: 'Creato' },
    { id: 'paid', label: 'Pagato' },
    { id: 'canceled', label: 'Cancellato' },
    { id: 'refunded', label: 'Rimborsato' }
];

const PaymentStateFilter: PaymentStateFilterProps = ({ activeFilters, setActiveFilters }) => {
    const selectOptions = useMemo(
        () =>
            paymentTypes.map(paymentTypes => ({
                id: paymentTypes.id,
                item: (
                    <>
                        <Checkbox
                            checked={activeFilters.indexOf(paymentTypes.id as any) > -1}
                            icon={<CheckBoxUnchecked />}
                            checkedIcon={<CheckBoxChecked />}
                        />
                        <ListItemText primary={paymentTypes.label} />
                    </>
                )
            })),
        [activeFilters]
    );

    return (
        <>
            <div className={classes.paymentContainer}>
                <label className="small">
                    <b>Stato pagamento</b>
                </label>
                <GenericSelect
                    placeholder="Seleziona stato pagamento"
                    multiple
                    value={activeFilters}
                    onChange={(selectedFilters: any) => setActiveFilters('paymentState', selectedFilters.target.value)}
                    renderValue={(selected: any) =>
                        paymentTypes
                            .filter(state => selected.includes(state.id))
                            .map(state => state.label)
                            .join(', ')
                    }
                    options={selectOptions}
                    sxFormControl={{ width: '21rem !important' }}
                />
            </div>
        </>
    );
};

export default PaymentStateFilter;

