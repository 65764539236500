import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Delete: SvgIconProps = ({ fill = colors.primary100 }) => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Delete">
                <path
                    id="delete"
                    d="M27 9.69388H21.5099V7.67347C21.5099 7.49485 21.4389 7.32355 21.3123 7.19725C21.1857 7.07095 21.0139 7 20.8349 7H15.4349C15.2559 7 15.0843 7.07095 14.9577 7.19725C14.8311 7.32355 14.7599 7.49485 14.7599 7.67347V9.69388H9V11.0408H11.025V28.3265C11.025 28.5051 11.0961 28.6764 11.2226 28.8027C11.3492 28.929 11.521 29 11.7 29H24.3C24.479 29 24.6507 28.929 24.7772 28.8027C24.9038 28.6764 24.975 28.5051 24.975 28.3265V11.0408H27V9.69388ZM16.1099 8.34694H20.1599V9.69388H16.1099V8.34694ZM23.625 27.6531H12.375V11.0408H23.625V27.6531Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
