import { IconTextButton } from 'components/buttons';
import ChipsFilter from 'components/chipsFilter/ChipsFilter';
import Divider from 'components/divider/Divider';
import { Close, ResetFilters } from 'components/icons';
import PeriodOfTimeFilter from 'components/periodOfTimeFilter/PeriodOfTimeFilter';
import ValueRangeFilter from 'components/valueRangeFilter/ValueRangeFilter';
import { BillsFilters, DEFAULT_BILLS_FILTER_VALUES } from 'helpers';
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, billingsActions } from 'store';
import colors from 'styles/modules/colors.module.scss';
import classes from './BillingFiltersTab.module.scss';
import deepEqual from 'deep-equal';

type BillingFiltersTabProps = FC<{
    closeFilter: (value: boolean) => void;
}>;

const BillingFiltersTab: BillingFiltersTabProps = ({ closeFilter }) => {
    const dispatch = useDispatch();
    const { activeFilters } = useSelector((x: RootState) => x.billings);

    const resetFilters = useCallback(() => {
        dispatch(billingsActions.setActiveFilters(DEFAULT_BILLS_FILTER_VALUES));
        dispatch(billingsActions.setBillings([]));
        dispatch(billingsActions.setPages(0));
    }, [dispatch]);

    const onFiltersChangeHandler = useCallback(
        (key: keyof BillsFilters, value: any) => {
            dispatch(billingsActions.setBillings([]));
            dispatch(billingsActions.setPages(0));
            dispatch(billingsActions.setActiveFilters({ ...activeFilters, [key]: { ...(activeFilters[key] as any), ...value } }));
        },
        [activeFilters, dispatch]
    );

    return (
        <div className={classes.filtersTab}>
            <div className={classes.topBar}>
                <div className={classes.title} onClick={resetFilters}>
                    <div className={classes.closeBtn} onClick={() => closeFilter(false)}>
                        <Close />
                    </div>
                    <p>
                        <b>Filtri</b>
                    </p>
                </div>
                <IconTextButton
                    disabled={deepEqual(activeFilters, DEFAULT_BILLS_FILTER_VALUES)}
                    className="tertiary"
                    icon={
                        <ResetFilters
                            fill={deepEqual(activeFilters, DEFAULT_BILLS_FILTER_VALUES) ? colors.primary40 : colors.primary90}
                        />
                    }
                    onClick={resetFilters}
                >
                    Reset
                </IconTextButton>
            </div>
            <p className={classes.subtitle}>Data fatture richieste</p>
            <div className={classes.dateFilter}>
                <PeriodOfTimeFilter activeFilter={activeFilters.date} setActiveFilter={onFiltersChangeHandler} />
                <ChipsFilter activeFilter={activeFilters.date} setActiveFilter={onFiltersChangeHandler} />
            </div>
            <Divider />
            <p className={classes.subtitle}>Aziende</p>
            <div className={classes.inputTextGroup}>
                <ValueRangeFilter
                    title="Numero di dipendenti dell’azienda"
                    value={activeFilters.totalUsers}
                    objKey={'totalUsers'}
                    onFiltersChangeHandler={onFiltersChangeHandler}
                />
                <ValueRangeFilter
                    title="Numero di fatture totali dell’azienda"
                    value={activeFilters.totalBillings}
                    objKey="totalBillings"
                    onFiltersChangeHandler={onFiltersChangeHandler}
                />
            </div>
        </div>
    );
};

export default BillingFiltersTab;
