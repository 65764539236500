import { AreaProvider, UserProvider } from 'context';
import { Route, Routes, Navigate } from 'react-router-dom';
import Backoffice from 'views/backoffice/Backoffice';
import EmailConfirmed from 'views/emailConfirmed/EmailConfirmed';
import FirstAccess from 'views/firstAccess/FirstAccess';
import Login from 'views/login/Login';
import PrivateRoute from 'views/privateRoute/PrivateRoute';
import ResetPassword from 'views/resetPassword/ResetPassword';
import VerifyOtp from 'views/verifyOtp/VerifyOtp';
import Documents from 'views/documents/Documents';
import DocumentViewer from 'views/documents/documentViewer/DocumentViewer';

const Routing = () => {
    const token = localStorage.getItem('token');

    return (
        <Routes>
            <Route
                path="/confirm-email/:userId/:email/:otp"
                element={
                    <UserProvider>
                        <EmailConfirmed />
                    </UserProvider>
                }
            />
            <Route path="/documents" element={<Documents />} />
            <Route path="/documents/:id" element={<DocumentViewer />} />
            <Route path="/reset-password-request" element={<ResetPassword />} />
            <Route
                path="/first-access/:userId/:email/:otp"
                element={
                    <UserProvider>
                        <FirstAccess />
                    </UserProvider>
                }
            />
            <Route
                path="/reset-password/:userId/:email/:otp"
                element={
                    <UserProvider>
                        <FirstAccess isResetting={true} />
                    </UserProvider>
                }
            />
            <Route
                path="/verify-otp/:id/:telephoneNumber?"
                element={<UserProvider>{token ? <Navigate to="/backoffice" /> : <VerifyOtp />}</UserProvider>}
            />
            <Route path="/" element={<UserProvider>{token ? <Navigate to="/backoffice" /> : <Login />}</UserProvider>} />
            <Route
                path="/backoffice"
                element={
                    <AreaProvider>
                        <UserProvider>
                            <PrivateRoute>
                                <Backoffice />
                            </PrivateRoute>
                        </UserProvider>
                    </AreaProvider>
                }
            />
        </Routes>
    );
};

export default Routing;

