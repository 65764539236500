import { SwitchButton } from 'components/buttons';
import { Edit } from 'components/icons';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import colors from 'styles/modules/colors.module.scss';
import classes from './BillingDetailsInfo.module.scss';
import BillingDetailsInfoEditModal from './billingDetailsInfoEditModal/BillingDetailsInfoEditModal';

type BillingDetailsInfoProps = FC<{
    setCheckStatus: (value: boolean) => void;
    checkStatus: boolean;
    setShowOnlyNonIssuedBills: (value: boolean) => void;
    showOnlyNonIssuedBills: boolean;
    refetchBillingsData: () => void;
    refetchBillingDetailsData: () => void;
}>;

const BillingDetailsInfo: BillingDetailsInfoProps = ({
    setCheckStatus,
    checkStatus,
    setShowOnlyNonIssuedBills,
    showOnlyNonIssuedBills,
    refetchBillingsData,
    refetchBillingDetailsData
}) => {
    const { billingDetails } = useSelector((x: RootState) => x.billings);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    return (
        <div className={classes.container}>
            <div className={classes.topBar}>
                <p>{billingDetails?.businessName.toUpperCase()}</p>
                <SwitchButton
                    disabled={checkStatus}
                    onChange={() => {
                        setShowOnlyNonIssuedBills(!showOnlyNonIssuedBills);
                        if (!showOnlyNonIssuedBills && checkStatus) {
                            setCheckStatus(!checkStatus);
                        }
                    }}
                    checked={showOnlyNonIssuedBills}
                    label="Solo da evadere"
                />
            </div>
            <div className={classes.detailsGrid}>
                <div className={classes.gridItem}>
                    <p className={`small ${classes.firstParagraph}`}>
                        <b>Ragione sociale</b>
                    </p>
                    <p className={classes.data}>{billingDetails?.businessName}</p>
                </div>
                <div className={classes.gridItem}>
                    <p className={`small ${classes.firstParagraph}`}>
                        <b>P.IVA / Codice fiscale</b>
                    </p>
                    <p className={classes.data}>{billingDetails?.vatNumber}</p>
                </div>
                <div className={classes.gridItem}>
                    <p className={`small ${classes.firstParagraph}`}>
                        <b>{billingDetails?.pec !== '' ? 'PEC' : 'SDI'}</b>
                    </p>
                    <p className={classes.data}>{billingDetails?.pec !== '' ? billingDetails?.pec : billingDetails?.sdi}</p>
                </div>
                <div className={`${classes.gridItem} ${classes.gridItemFour}`}>
                    <button className={classes.edit} onClick={() => setIsEditModalOpen(!isEditModalOpen)}>
                        <Edit fill={colors.primary90} />
                    </button>
                </div>
                <div className={`${classes.gridItem} ${classes.gridItemFive}`}>
                    <p className={`small ${classes.firstParagraph}`}>
                        <b>Indirizzo</b>
                    </p>
                    <p className={classes.data}>{billingDetails?.address}</p>
                </div>
                <div className={`${classes.gridItem}`}>
                    <p className={`small ${classes.firstParagraph}`}>
                        <b>Città</b>
                    </p>
                    <p className={classes.data}>{billingDetails?.municipality}</p>
                </div>
                <div className={classes.gridItem}>
                    <p className={`small ${classes.firstParagraph}`}>
                        <b>Provincia</b>
                    </p>
                    <p className={classes.data}>{billingDetails?.province}</p>
                </div>
                <div className={`${classes.gridItem} ${classes.gridItemEight}`}>
                    <p className={`small ${classes.firstParagraph}`}>
                        <b>CAP</b>
                    </p>
                    <p className={classes.data}>{billingDetails?.cap}</p>
                </div>
                <div className={`${classes.gridItem}`}>
                    <p className={`small ${classes.firstParagraph}`}>
                        <b>Nazione</b>
                    </p>
                    <p className={`${classes.data} ${classes.toUpperCase}`}>{billingDetails?.nation}</p>
                </div>
            </div>
            <BillingDetailsInfoEditModal
                isEditModalOpen={isEditModalOpen}
                closeModal={() => setIsEditModalOpen(false)}
                refetchBillingsData={refetchBillingsData}
                refetchBillingDetailsData={refetchBillingDetailsData}
            />
        </div>
    );
};

export default BillingDetailsInfo;

