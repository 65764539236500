import { FC, useCallback, useEffect } from 'react';
import TariffAccordion from 'components/tariffAccordion/TariffAccordion';
import TariffAccordionTypography from 'components/tariffAccordionTypography/tariffAccordionTypography';
import classes from './Pricing.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, createTariffRuleActions } from 'store';
import PricingItem from './pricingItem/PricingItem';

const Pricing: FC = () => {
    const { slopes } = useSelector((x: RootState) => x.createTariffRule);
    const { selectedRule } = useSelector((x: RootState) => x.tariffs);
    const dispatch = useDispatch();

    const addSlopeHandler = useCallback(() => dispatch(createTariffRuleActions.addSlope()), [dispatch]);

    useEffect(() => {
        if (!selectedRule) return;
        const slopes = selectedRule.Slopes?.slice()
            .sort((a: any, b: any) => a.cardinality - b.cardinality)
            .map((slope: any, index: number) => {
                let createSlope: any = {
                    slopeType: slope.type,
                    amount: slope.price,
                    price: slope.maxPrice,
                    multiplier: slope.multiplier,
                    fromHour: slope.startHours,
                    toHour: slope?.endHours
                };
                let minutes = slope.time;
                if (minutes) {
                    const days = Math.floor(minutes / (24 * 60));
                    minutes = minutes % (24 * 60);
                    const hours = Math.floor(minutes / 60);
                    minutes = minutes % 60;
                    createSlope = {
                        ...createSlope,
                        days: days || undefined,
                        minutes: minutes || undefined,
                        hours: hours || undefined
                    };
                }
                let maxMinutes = slope.maxTime;
                if (maxMinutes) {
                    const maxDays = Math.floor(maxMinutes / (24 * 60));
                    maxMinutes = maxMinutes % (24 * 60);
                    const maxHours = Math.floor(maxMinutes / 60);
                    maxMinutes = maxMinutes % 60;
                    createSlope = {
                        ...createSlope,
                        maxDays: maxDays || undefined,
                        maxMinutes: maxMinutes || undefined,
                        maxHours: maxHours || undefined
                    };
                }
                let fromMinutes = slope.startTime;
                if (fromMinutes) {
                    const fromDays = Math.floor(fromMinutes / (24 * 60));
                    fromMinutes = fromMinutes % (24 * 60);
                    const fromHours = Math.floor(fromMinutes / 60);
                    fromMinutes = fromMinutes % 60;
                    createSlope = {
                        ...createSlope,
                        fromDays: fromDays || undefined,
                        fromMinutes: fromMinutes || undefined,
                        fromHours: fromHours || undefined
                    };
                } else {
                    createSlope = {
                        ...createSlope,
                        fromMinutes: 0
                    };
                }
                let toMinutes = slope.endTime;
                if (toMinutes) {
                    const toDays = Math.floor(toMinutes / (24 * 60));
                    toMinutes = toMinutes % (24 * 60);
                    const toHours = Math.floor(toMinutes / 60);
                    toMinutes = toMinutes % 60;
                    createSlope = {
                        ...createSlope,
                        toDays: toDays || undefined,
                        toMinutes: toMinutes || undefined,
                        toHours: toHours || undefined
                    };
                }
                return createSlope;
            });
        dispatch(createTariffRuleActions.setSlopes(slopes));
    }, [dispatch, selectedRule]);

    return (
        <TariffAccordion
            customClasses={classes.container}
            buttonTitle="Aggiungi slope"
            title="Pricing"
            onClickHandler={addSlopeHandler}
            isPricing={true}
            defaultOpen={slopes.length > 0}
        >
            <TariffAccordionTypography customClasses={classes.generalContainer}>
                {slopes.map((slope: any, index: number) => (
                    <PricingItem index={index} slope={slope} />
                ))}
            </TariffAccordionTypography>
        </TariffAccordion>
    );
};

export default Pricing;

