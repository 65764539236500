import { FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import classes from './Modal.module.scss';
import { IconButton } from 'components/buttons';
import { Close } from 'components/icons/Close';
import FocusLock from 'react-focus-lock';

type ModalProps = FC<
    PropsWithChildren<{
        customClasses?: string;
        handleClose: (...data: any) => any;
        isOpen: boolean;
        title: string;
        className?: string;
    }>
>;

const Modal: ModalProps = ({ children, isOpen, handleClose, title, customClasses, className }) => {
    if (!isOpen) return <></>;

    return createPortal(
        <FocusLock>
            <div className={classes.backdrop}>
                <div className={`${classes.modal} ${className}`}>
                    <div className={classes.header}>
                        <b>{title}</b>
                        <IconButton onClick={handleClose} className={classes.close} icon={<Close />} />
                    </div>
                    <div className={`${classes.scrollable} ${customClasses}`}>{children}</div>
                </div>
            </div>
        </FocusLock>,
        document.getElementById('modal-root') as Element
    );
};

export default Modal;

