export const ChatFilled = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26.1911 7H7.80893C6.26055 7 5 8.26941 5 9.82868V20.9435C5 22.5028 6.26055 23.7722 7.80893 23.7722H22.4888L27.7692 28.3201C27.9082 28.44 28.0769 28.5 28.2556 28.5C28.3648 28.5 28.464 28.48 28.5633 28.43C28.8313 28.3101 29 28.0402 29 27.7503V9.82868C29 8.26941 27.7395 7 26.1911 7ZM16.2556 10.8882H17.7444V16.8854H16.2556V10.8882ZM17 20.1239C16.4541 20.1239 16.0074 19.6741 16.0074 19.1244C16.0074 18.5746 16.4541 18.1248 17 18.1248C17.5459 18.1248 17.9926 18.5746 17.9926 19.1244C17.9926 19.6741 17.5459 20.1239 17 20.1239Z"
                fill="#FBFF36"
            />
        </svg>
    );
};
