import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from 'environments';
import { store } from 'store';

export type DocumentRow = {
    id: string;
    createdAt: string | null;
    expirationTime: string | null;
    deletedAt: string | null;
    updatedAt: string | null;
    name: string;
    mandatory: boolean;
    tipology: string;
    version: string;
    startingTime: string;
};

export const documentApi = createApi({
    reducerPath: 'documentApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${env.reactAppSocketUrl}${env.apiUrl}`,
        prepareHeaders: headers => {
            const authState = store.getState().auth.token as any;
            if (authState) {
                headers.set('authorization', authState);
            }
            return headers;
        }
    }),
    endpoints: builder => ({
        createDocument: builder.mutation<any, any>({
            query: body => ({
                url: '/documents',
                method: 'POST',
                body
            })
        }),
        getDocuments: builder.query<DocumentRow[], any>({
            query: () => ({
                url: '/documents',
                method: 'GET'
            })
        })
    })
});

export const { useCreateDocumentMutation, useGetDocumentsQuery } = documentApi;

