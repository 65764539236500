import { Snackbar } from '@mui/material';
import { FC, KeyboardEvent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, otpActions } from 'store';
import classes from './OtpInput.module.scss';

type OtpInputProps = {
    numberOfDigits: number;
    isError: boolean;
    setError: (value: boolean) => void;
};

const OtpInput: FC<OtpInputProps> = ({ numberOfDigits, isError, setError }) => {
    const otpBoxReference = useRef<HTMLInputElement[]>([]);
    const dispatch = useDispatch();
    const { otp } = useSelector((x: RootState) => x.otp);

    function handleChange(value: string, index: number): void {
        if (value.length > 1) return;
        const newArr = [...otp];
        newArr[index] = value;
        dispatch(otpActions.setOtp(newArr));
        if (value && index < numberOfDigits - 1) {
            otpBoxReference.current[index + 1] && otpBoxReference.current[index + 1].focus();
        }
        setError(false);
    }

    function handleKeyDown(e: KeyboardEvent<HTMLInputElement>, index: number): void {
        ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
        if (e.key === 'Backspace' && !e.currentTarget.value && index > 0) {
            otpBoxReference.current[index - 1] && otpBoxReference.current[index - 1].focus();
        }
        if (e.key === 'Enter' && e.currentTarget.value && index < numberOfDigits - 1) {
            otpBoxReference.current[index + 1] && otpBoxReference.current[index + 1].focus();
        }
    }

    useEffect(() => {
        if (otp.join('').length !== 0 || !otpBoxReference.current[0]) return;
        otpBoxReference.current[0].focus();
    }, [otp]);

    useEffect(() => {
        dispatch(otpActions.setOtp(new Array(numberOfDigits).fill('')));
    }, [dispatch, numberOfDigits]);

    return (
        <div className={classes.otpWrap}>
            <div className={classes.inputWrap}>
                {otp.map((digit, index) => (
                    <input
                        type="number"
                        className={`${classes.otpInput} ${isError ? classes.otpError : ''}`}
                        key={index}
                        value={digit}
                        autoComplete={'otp'}
                        maxLength={1}
                        max={9}
                        min={0}
                        onChange={e => handleChange(e.target.value, index)}
                        onKeyDown={e => handleKeyDown(e, index)}
                        ref={reference => (otpBoxReference.current[index] = reference as HTMLInputElement)}
                    />
                ))}
            </div>
            <Snackbar />
        </div>
    );
};

export default OtpInput;

