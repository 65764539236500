export const Erase = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_2737_15461"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="6"
                y="8"
                width="24"
                height="20"
            >
                <path d="M30 8H6V28H30V8Z" fill="white" />
            </mask>
            <g mask="url(#mask0_2737_15461)">
                <path
                    d="M28.1221 17.5912C28.6744 17.0301 28.9846 16.2698 28.9846 15.4772C28.9846 14.6846 28.6744 13.9243 28.1221 13.3632L23.6698 8.84801C23.1076 8.30491 22.3608 8.00195 21.5842 8.00195C20.8076 8.00195 20.0607 8.30491 19.4985 8.84801L7.25218 21.2576C6.70082 21.8192 6.3913 22.5793 6.3913 23.3716C6.3913 24.1639 6.70082 24.9239 7.25218 25.4856L8.55001 26.8034H6V28H28.0371V26.8034H19.0326L28.1221 17.5912ZM20.3304 9.69361C20.6674 9.36668 21.1158 9.18419 21.5822 9.18419C22.0486 9.18419 22.4971 9.36668 22.834 9.69361L27.2863 14.2064C27.4509 14.373 27.5815 14.5708 27.6706 14.7886C27.7597 15.0064 27.8056 15.2398 27.8056 15.4756C27.8056 15.7114 27.7597 15.9448 27.6706 16.1626C27.5815 16.3804 27.4509 16.5782 27.2863 16.7448L23.5006 20.5827L16.5463 13.5315L20.3304 9.69361ZM17.3641 26.8034H10.2193L8.08723 24.6424C7.75566 24.3053 7.56946 23.8487 7.56946 23.3728C7.56946 22.8968 7.75566 22.4402 8.08723 22.1032L15.7097 14.3771L22.6656 21.4291L17.3641 26.8034Z"
                    fill="#8DBEF9"
                />
                <path
                    d="M29.0239 24.1421C28.8306 24.1419 28.6417 24.1999 28.481 24.3086C28.3202 24.4173 28.1949 24.5719 28.1209 24.7529C28.0469 24.9338 28.0275 25.1329 28.0651 25.325C28.1028 25.5171 28.1958 25.6936 28.3325 25.8321C28.4691 25.9706 28.6432 26.0649 28.8327 26.1031C29.0223 26.1413 29.2187 26.1216 29.3972 26.0466C29.5757 25.9715 29.7283 25.8445 29.8355 25.6816C29.9428 25.5187 30 25.3271 29.9998 25.1313C29.9994 24.8691 29.8964 24.6177 29.7135 24.4323C29.5306 24.2469 29.2826 24.1425 29.0239 24.1421Z"
                    fill="#8DBEF9"
                />
                <path
                    d="M29.0239 20.4272C28.8306 20.4271 28.6417 20.485 28.481 20.5938C28.3202 20.7025 28.1949 20.8571 28.1209 21.038C28.0469 21.2189 28.0275 21.4181 28.0651 21.6102C28.1028 21.8023 28.1958 21.9788 28.3325 22.1173C28.4691 22.2558 28.6432 22.3501 28.8327 22.3882C29.0223 22.4264 29.2187 22.4067 29.3972 22.3317C29.5757 22.2567 29.7283 22.1297 29.8355 21.9667C29.9428 21.8038 30 21.6123 29.9998 21.4164C29.9994 21.1542 29.8964 20.9029 29.7135 20.7174C29.5306 20.532 29.2826 20.4277 29.0239 20.4272Z"
                    fill="#8DBEF9"
                />
                <path
                    d="M25.6113 23.2743C25.6113 23.47 25.6686 23.6612 25.7758 23.8239C25.883 23.9866 26.0355 24.1134 26.2138 24.1882C26.3921 24.2631 26.5883 24.2827 26.7777 24.2445C26.967 24.2064 27.1409 24.1122 27.2773 23.9738C27.4138 23.8355 27.5068 23.6592 27.5444 23.4673C27.5821 23.2754 27.5628 23.0766 27.4889 22.8958C27.415 22.715 27.2899 22.5606 27.1295 22.4519C26.969 22.3432 26.7803 22.2852 26.5873 22.2852C26.3286 22.2858 26.0807 22.3902 25.8979 22.5756C25.715 22.761 25.612 23.0122 25.6113 23.2743Z"
                    fill="#8DBEF9"
                />
            </g>
        </svg>
    );
};
