import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UserWithVehicles } from 'models';

export interface UsersState {
    users: UserWithVehicles[];
    selectedUser: string | undefined;
}

const name = 'users';
const initialState: UsersState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const usersActions = { ...slice.actions };
export const usersReducer = slice.reducer;

function createInitialState(): UsersState {
    return {
        users: [],
        selectedUser: undefined
    };
}

function createReducers() {
    return { setSelectedUser };

    function setSelectedUser(state: UsersState, action: PayloadAction<string | undefined>) {
        state.selectedUser = action.payload;
    }
}
