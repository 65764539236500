import { SvgIconProps } from 'helpers';

export const CompactSection: SvgIconProps = ({ fill }) => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.6857 18.75L26.7803 23.8447L25.7197 24.9053L19.3447 18.5303C19.0518 18.2374 19.0518 17.7626 19.3447 17.4697L25.7197 11.0947L26.7803 12.1553L21.6857 17.25L31.875 17.25V18.75L21.6857 18.75ZM14.3143 17.25L9.21967 12.1553L10.2803 11.0947L16.6553 17.4697C16.9482 17.7626 16.9482 18.2374 16.6553 18.5303L10.2803 24.9053L9.21967 23.8447L14.3143 18.75H4.125V17.25H14.3143Z"
                fill={fill}
            />
        </svg>
    );
};
