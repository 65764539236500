import { FC } from 'react';

export const Document: FC = () => {
    return (
        <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.38261 17.9662L15.6286 17.9662V16.4662L6.38261 16.4662L6.38261 17.9662Z" fill="white" />
            <path d="M6.38261 14.0743H15.6286V12.5743H6.38261V14.0743Z" fill="white" />
            <path d="M6.38261 21.8581H15.6286V20.3581H6.38261V21.8581Z" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.6001 0.25C1.18588 0.25 0.850098 0.585786 0.850098 1V25C0.850098 25.4142 1.18588 25.75 1.6001 25.75H20.4109C20.8251 25.75 21.1609 25.4142 21.1609 25V8.78378C21.1609 8.58709 21.0836 8.39827 20.9458 8.25801L13.2939 0.474222C13.1529 0.330788 12.9602 0.25 12.7591 0.25H1.6001ZM12.2285 9.31411C12.3692 9.45476 12.56 9.53378 12.7589 9.53378H19.6609V24.25H2.3501V1.75H12.009L12.0089 8.78377C12.0089 8.98268 12.0879 9.17345 12.2285 9.31411ZM18.6219 8.03378L13.509 2.83273L13.5089 8.03378H18.6219Z"
                fill="white"
            />
        </svg>
    );
};

