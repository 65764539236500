import TextButton from 'components/buttons/textButton/TextButton';
import Modal from 'components/modal/Modal';
import { FC } from 'react';
import classes from './SlopeTimeExceedAlertModal.module.scss';
import { SetState } from 'helpers';

type SlopeTimeExceedAlertModalProps = FC<{
    isOpen: boolean;
    setIsOpen: SetState<boolean>;
    onSubmitHandler: () => void;
}>;

const SlopeTimeExceedAlertModal: SlopeTimeExceedAlertModalProps = ({ isOpen, setIsOpen, onSubmitHandler }) => {
    return (
        <Modal title="Slope non valide" isOpen={isOpen} handleClose={() => setIsOpen(false)}>
            <div className={classes.container}>
                Il periodo di validità di alcune slope non è coperto dal periodo di validità generale della regola.
                <br />
                <br />
                Queste slope{' '}
                <span className={classes.red}>
                    <b>non verranno applicate</b>
                </span>{' '}
                alla regola.
                <div className={classes.buttons}>
                    <TextButton className="tertiary" onClick={() => setIsOpen(false)}>
                        Annulla
                    </TextButton>
                    <TextButton className="secondary" onClick={onSubmitHandler}>
                        Salva comunque
                    </TextButton>
                </div>
            </div>
        </Modal>
    );
};

export default SlopeTimeExceedAlertModal;

