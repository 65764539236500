import { Checkbox } from '@mui/material';
import TextButton from 'components/buttons/textButton/TextButton';
import { ArrowDown, CheckBoxChecked, CheckBoxUnchecked } from 'components/icons';
import Modal from 'components/modal/Modal';
import { FC, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { CreateTariffRuleState, RootState, createTariffRuleActions } from 'store';
import classes from './PresetModal.module.scss';

type PresetModalType = FC<{
    index: number;
    isOpen: boolean;
    onClose: (type: string) => void;
}>;

const PresetModal: PresetModalType = ({ index, isOpen, onClose }) => {
    const { selectedRule } = useSelector((x: RootState) => x.tariffs);
    const { validityPeriod } = useSelector((x: RootState) => x.createTariffRule);
    const [holiday, setHoliday] = useState<boolean>(false);
    const [startYear, setStartYear] = useState<Date>(new Date());
    const [beforeHoliday, setBeforeHoliday] = useState<boolean>(false);
    const { allYears } = useSelector<RootState>(x => x.createTariffRule) as CreateTariffRuleState;
    const dispatch = useDispatch();

    const onSaveHandler = () => {
        dispatch(createTariffRuleActions.setValidityPeriodSlot({ index, key: 'type', value: 'preset' }));
        dispatch(
            createTariffRuleActions.setValidityPeriodSlot({
                index,
                key: 'preset',
                value: { holiday, beforeHoliday, year: startYear.getFullYear() }
            })
        );
        onClose('preset');
    };

    useEffect(() => {
        if (!selectedRule || !selectedRule.ValidityPeriods[index]) return;
        setHoliday(selectedRule.ValidityPeriods[index].holiday ?? false);
        setBeforeHoliday(selectedRule.ValidityPeriods[index].beforeHoliday ?? false);
    }, [index, selectedRule]);

    useEffect(() => {
        if (!isOpen) return;
        setHoliday(validityPeriod.slots[index].preset.holiday ?? false);
        setBeforeHoliday(validityPeriod.slots[index].preset.beforeHoliday ?? false);
    }, [index, isOpen, validityPeriod.slots]);

    const onSelectHandler = (value: number) => {
        if (!value) return;
        setStartYear(new Date(value));
    };

    return (
        <Modal className={classes.modalContainer} handleClose={() => onClose('')} isOpen={isOpen} title="Preset">
            <div className={classes.subWrapper}>
                <div className={classes.checkboxWrapper}>
                    <Checkbox
                        icon={<CheckBoxUnchecked />}
                        checkedIcon={<CheckBoxChecked />}
                        onChange={(event: any) => setHoliday(event.target.checked)}
                        checked={holiday}
                    />
                    <label className={`lightBlue ${classes.title}`}>Giorni festivi</label>
                </div>
                <label className={`small lightBlue ${classes.text}`}>
                    1 gen, 6 gen, 25 apr, Lun dopo Pasqua, 1 mag, 2 giu, 15 ago, 1 nov, 8 dic, 25 dic, 26 dic
                </label>
            </div>
            <div className={classes.subWrapper}>
                <div className={classes.checkboxWrapper}>
                    <Checkbox
                        icon={<CheckBoxUnchecked />}
                        checkedIcon={<CheckBoxChecked />}
                        checked={beforeHoliday}
                        onChange={(event: any) => setBeforeHoliday(event.target.checked)}
                    />
                    <label className={`lightBlue ${classes.title}`}>Giorni prefestivi</label>
                </div>
                <label className={`small lightBlue ${classes.text}`}>
                    5 gen, 24 apr, 30 apr, 1 giu, 14 ago, 31 ott, 7 dic, 24 dic, 31 dic
                </label>
            </div>
            <div className={classes.yearWrapper}>
                <label className="lightBlue">dell&apos;anno</label>
                <div className={classes.datePickerContainer}>
                    <DatePicker
                        disabled={allYears}
                        showYearPicker
                        dateFormat="yyyy"
                        wrapperClassName="datePicker"
                        selected={startYear}
                        onChange={date => onSelectHandler(date?.getTime()!)}
                    />
                    <ArrowDown />
                </div>
            </div>
            <div className={classes.btn}>
                <TextButton className={`secondary ${classes.customButton}`} onClick={() => onClose('')}>
                    Annulla
                </TextButton>
                <TextButton
                    onClick={onSaveHandler}
                    className={`primary ${classes.customButton}`}
                    disabled={!holiday && !beforeHoliday}
                >
                    Salva
                </TextButton>
            </div>
        </Modal>
    );
};

export default PresetModal;

