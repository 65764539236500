import { createContext, FC, PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { env } from 'environments';
import { AuthState, RootState } from 'store';
import { useSelector } from 'react-redux';

interface AxiosApi {
    default: AxiosInstance;
    getRequest: (url: string, params?: any, options?: AxiosRequestConfig) => Promise<AxiosResponse>;
    postRequest: (url: string, body?: any, options?: AxiosRequestConfig) => Promise<AxiosResponse>;
    putRequest: (url: string, body?: any) => Promise<AxiosResponse>;
    deleteRequest: (url: string, body?: any) => Promise<AxiosResponse>;
}

export interface DefaultResponse {
    message: string;
}

const AxiosContext = createContext<AxiosApi>({
    default: axios.create()
} as AxiosApi);

const AxiosProvider: FC<PropsWithChildren> = ({ children }) => {
    const { token } = useSelector<RootState>(x => x.auth) as AuthState;

    const api = useMemo(() => {
        const baseURL = env.reactAppSocketUrl + env.apiUrl;
        return axios.create({
            baseURL,
            headers: { Authorization: token || '' }
        });
    }, [token]);

    useEffect(() => {
        const reqFullFilledInterceptor = (req: any) => {
            return req;
        };
        const resFullFilledInterceptor = (res: any) => {
            return res;
        };
        const rejInterceptor = (res: any) => {
            console.error(res);
            return res?.response?.data;
        };
        const reqInterceptors = api.interceptors.request.use(reqFullFilledInterceptor, rejInterceptor);
        const resInterceptors = api.interceptors.response.use(resFullFilledInterceptor, rejInterceptor);
        return () => {
            api.interceptors.request.eject(reqInterceptors);
            api.interceptors.response.eject(resInterceptors);
        };
    }, [api.interceptors.request, api.interceptors.response]);

    const getRequest = useCallback(
        async (url: string, params?: any, options?: AxiosRequestConfig) => api.get(url, { params, ...options }),
        [api]
    );
    const postRequest = useCallback(
        async (url: string, body?: any, options?: AxiosRequestConfig) => api.post(url, body, options),
        [api]
    );
    const putRequest = useCallback(async (url: string, body?: any) => api.put(url, body), [api]);
    const deleteRequest = useCallback(async (url: string, body?: any) => api.delete(url, { data: body }), [api]);

    return (
        <AxiosContext.Provider
            value={{
                default: api,
                getRequest,
                postRequest,
                putRequest,
                deleteRequest
            }}
        >
            {children}
        </AxiosContext.Provider>
    );
};

export { AxiosContext, AxiosProvider };
