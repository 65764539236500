import { SvgIconProps } from 'helpers';

export const Profile: SvgIconProps = ({ fill }) => {
    return (
        <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="profile">
                <path
                    id="Vector"
                    d="M7.00837 11.0411C10.1167 11.0411 12.65 8.56035 12.65 5.51649C12.65 2.47263 10.1167 0 7.00837 0C3.90003 0 1.3667 2.48079 1.3667 5.52465C1.3667 8.56851 3.90003 11.0493 7.00837 11.0493V11.0411ZM7.00837 1.21591C9.43337 1.21591 11.4 3.14179 11.4 5.51649C11.4 7.89119 9.43337 9.81707 7.00837 9.81707C4.58337 9.81707 2.6167 7.89119 2.6167 5.51649C2.6167 3.14179 4.58337 1.21591 7.00837 1.21591Z"
                    fill={fill}
                />
                <path
                    id="Vector_2"
                    d="M7.00833 13.3995C3.14167 13.3995 0 16.476 0 20.2625V24H1.24167V20.2625C1.24167 17.1534 3.825 14.6236 7 14.6236C10.175 14.6236 12.7583 17.1534 12.7583 20.2625V24H14V20.2625C14 16.476 10.8583 13.3995 6.99167 13.3995H7.00833Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
