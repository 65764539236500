import { DEFAULT_LAYER_DATA } from 'helpers';
import { EditableGeoJsonLayer } from 'nebula.gl';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, LayerMode, drawActions } from 'store';

export const useDrawLayer = () => {
    const [layerData, setLayerData] = useState(DEFAULT_LAYER_DATA);
    const [selectedFeatureIndexes, setSelectedFeatureIndexes] = useState<any[]>([]);
    const { mode, data } = useSelector((x: RootState) => x.draw);
    const { isCreateAreaOpen, isEditingArea } = useSelector((x: RootState) => x.municipalities);

    const dispatch = useDispatch();

    const onEdit = {
        view: () => {},
        drawPolygon: updateLayerDataHandler,
        modify: updateLayerDataHandler
    };

    useEffect(() => setLayerData(data), [data]);

    useEffect(() => {
        if (mode === 'modify') setSelectedFeatureIndexes([0]);
    }, [mode]);

    const drawLayer = useMemo(
        () =>
            new (EditableGeoJsonLayer as any)({
                id: `draw-${isCreateAreaOpen}-${isEditingArea}-${mode}`,
                data: layerData,
                mode: LayerMode[mode],
                selectedFeatureIndexes,
                onEdit: onEdit[mode],
                editHandlePointRadiusScale: 4,
                editHandlePointStrokeWidth: 0,
                getEditHandlePointOutlineColor: [248, 253, 0],
                getEditHandlePointColor: [248, 253, 0],
                getEditHandlePointRadius: 1,
                getFillColor: (feature: any) => (feature.geometry.type === 'Point' ? [248, 253, 0] : [248, 253, 0, 50]),
                getLineColor: [248, 253, 0],
                getLineWidth: (feature: any) => (feature.geometry.type === 'Point' ? 20 : 1),
                getTentativeFillColor: [248, 253, 0, 50],
                getTentativeLineColor: [248, 253, 0]
            }),
        [layerData, mode, isCreateAreaOpen, isEditingArea]
    );

    function updateLayerDataHandler({ updatedData, editType, editContext }: any) {
        if (editType === 'addFeature') {
            updateData(updatedData);
            return;
        }
        if (editType === 'addPosition') {
            updateData(updatedData);
            return;
        }
        if (editType === 'movePosition') {
            updateDataOnModify(updatedData, editContext);
            return;
        }
        if (editType === 'finishMovePosition') {
            updateData(updatedData);
            return;
        }
    }

    function updateData(updatedData: any) {
        const data = { ...updatedData, features: [updatedData.features[updatedData.features.length - 1]] };
        setLayerData(data);
        dispatch(drawActions.setData(data));
    }

    function updateDataOnModify(updatedData: any, editContext: any) {
        setLayerData((prev: any) => {
            const data = removeReadOnly(prev);
            if (
                data?.features[0]?.geometry?.coordinates[0]?.length > updatedData?.features[0]?.geometry?.coordinates[0]?.length
            ) {
                data.features[editContext.featureIndexes[0] as number].geometry.coordinates[0][
                    editContext.positionIndexes[1] as number
                ] = editContext.position;
                return data;
            }
            return updatedData;
        });
    }

    const removeReadOnly = (obj: any) => JSON.parse(JSON.stringify(obj));

    return drawLayer;
};

