export interface Billing {
    id: string;
    businessName: string;
    numberOfUsers: string;
    totalAmount: number;
    totalBillings: string;
    unbilled: string;
}

export interface UserBilling {
    plate: string;
    id: string;
    amount: number;
    area_name: string;
    payment_time: string;
    municipality_name: string;
    payment_status: string;
    area_code: string;
    billing_status: string;
}

export interface UsersBillings {
    business_name: string;
    userBillings: UserBilling[];
    first_name: string;
    last_name: string;
    id: string;
}

export interface BillingDetailsData {
    id: string;
    userId: string;
    businessName: string;
    vatNumber: string;
    pec: string | undefined;
    sdi: string | undefined;
    address: string;
    municipality: string;
    province: string;
    cap: string;
    createdAt: string;
    updatedAt: string;
    nation: string;
    usersBillings: UsersBillings[];
}

export enum BillingStatus {
    none = 'none',
    pending = 'pending',
    issued = 'issued'
}

export interface PaymentInfo {
    paymentCircuit: string;
    paymentCircuitNumber: string;
}
