import CreateEditTariffModalForm from 'components/createEditTariffModalForm/CreateEditTariffModalForm';
import Modal from 'components/modal/Modal';
import Snackbar from 'components/snackbar/Snackbar';
import { NewTariff } from 'helpers';
import { Tariff } from 'models';
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, snackbarActions, tariffActions } from 'store';
import { useGetTariffsByMunicipalityQuery, useLazyEditTariffNameQuery } from 'store/rtk/tariff.service';

type EditTariffModalProps = FC<{
    selectedTariff: Tariff;
}>;

const EditTariffModal: EditTariffModalProps = ({ selectedTariff }) => {
    const dispatch = useDispatch();
    const { isCreateEditModalOpen } = useSelector((x: RootState) => x.tariffs);
    const { municipality } = useSelector((x: RootState) => x.municipalities);
    const { data: tariffs, refetch: refetchTariffs } = useGetTariffsByMunicipalityQuery(
        { municipalityId: municipality?.id! },
        { skip: !municipality }
    );
    const [editTariffName] = useLazyEditTariffNameQuery();
    const onCreateEditHandler = useCallback(() => {
        dispatch(tariffActions.toggleCreateEditModalOpen());
    }, [dispatch]);

    const updateDetails = useCallback(
        async (data: NewTariff) => {
            if (tariffs?.findIndex(tariff => tariff.name === data.name && tariff.slopeReprise === data.slopeReprise) !== -1) {
                dispatch(snackbarActions.setMessageAndType({ message: `Tariffa già esistente`, type: 'error' }));
                dispatch(snackbarActions.setIsOpen(true));
                return;
            }
            if (data.name === undefined || data.slopeReprise === undefined) return;
            const res = await editTariffName({
                id: selectedTariff.id as string,
                name: data.name,
                slopeReprise: data.slopeReprise
            }).catch(err => console.log(err));
            if (!res) return;
            dispatch(tariffActions.setSelectedTariff({ ...selectedTariff, name: data.name, slopeReprise: data.slopeReprise }));
            dispatch(snackbarActions.setMessageAndType({ message: `Tariffa modificata con successo.` }));
            dispatch(snackbarActions.setIsOpen(true));
            refetchTariffs();
            onCreateEditHandler();
        },
        [tariffs, editTariffName, selectedTariff, dispatch, refetchTariffs, onCreateEditHandler]
    );

    return (
        <>
            <Modal title="Modifica tariffa" isOpen={isCreateEditModalOpen} handleClose={onCreateEditHandler}>
                <CreateEditTariffModalForm onSubmit={updateDetails} selectedTariff={selectedTariff} isEditMode={true} />
            </Modal>
            <Snackbar />
        </>
    );
};

export default EditTariffModal;

