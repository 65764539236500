import { SvgIconProps } from 'helpers';

export const CircleRefound: SvgIconProps = ({ fill }) => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.95318 12.562V11.5527C9.28318 11.4527 9.58318 11.2828 9.84318 11.033C10.6732 10.2035 10.6732 8.84436 9.84318 8.01488C9.44318 7.61513 8.90318 7.38528 8.33318 7.38528C8.09318 7.38528 7.86318 7.29533 7.69318 7.12544C7.34318 6.77566 7.34318 6.20601 7.69318 5.84624C8.03318 5.50645 8.63318 5.50645 8.97318 5.84624L9.40318 6.27597L10.2732 5.40651L9.84318 4.97678C9.59318 4.72694 9.28318 4.56704 8.95318 4.4671V3.43774H7.72318V4.45711C7.39318 4.54705 7.08318 4.71694 6.82318 4.96679C5.99318 5.79627 5.99318 7.15542 6.82318 7.9849C7.22318 8.38465 7.76318 8.61451 8.33318 8.61451C8.57318 8.61451 8.80318 8.70445 8.97318 8.87435C9.32318 9.22413 9.32318 9.79377 8.97318 10.1535C8.62318 10.5033 8.04318 10.5033 7.69318 10.1535L7.22318 9.68384L6.35318 10.5533L6.82318 11.023C7.08318 11.2828 7.39318 11.4527 7.72318 11.5427V12.5421H8.95318V12.562Z"
                fill="#FCFF7E"
            />
            <path
                d="M14.8333 8.005C14.8333 9.74391 14.1533 11.3729 12.9333 12.6021C11.7033 13.8314 10.0733 14.5009 8.33325 14.5009C6.59325 14.5009 4.96325 13.8214 3.73325 12.6021C2.50325 11.3729 1.83325 9.74391 1.83325 8.005C1.83325 6.26608 2.51325 4.6371 3.73325 3.40787C4.96325 2.17864 6.59325 1.50906 8.33325 1.50906C9.61325 1.50906 10.8233 1.87883 11.8733 2.5584H10.5633V4.05746H13.5933C14.0033 4.05746 14.3433 3.71768 14.3433 3.30793V0.269831H12.8433V1.39913C11.4833 0.469706 9.91325 0 8.33325 0C6.28325 0.00999375 4.24325 0.789507 2.67325 2.34853C-0.446748 5.47658 -0.446748 10.5334 2.67325 13.6615C4.23325 15.2205 6.28325 16 8.33325 16C10.3833 16 12.4233 15.2205 13.9933 13.6615C15.5533 12.1024 16.3333 10.0537 16.3333 8.005H14.8333Z"
                fill="#FCFF7E"
            />
        </svg>
    );
};
