import colors from 'styles/modules/colors.module.scss';

export const CheckmarkChecked = () => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.33326 16C6.75104 16 5.20435 15.5308 3.88877 14.6518C2.57319 13.7728 1.54781 12.5234 0.942287 11.0616C0.336768 9.59983 0.178294 7.99131 0.486924 6.43949C0.795553 4.88766 1.55743 3.46223 2.67619 2.34338C3.79494 1.22454 5.22033 0.462558 6.77213 0.153803C8.32394 -0.154951 9.93245 0.00337089 11.3943 0.608773C12.8561 1.21417 14.1056 2.23947 14.9847 3.55498C15.8638 4.87048 16.3331 6.41711 16.3333 7.99934C16.3333 9.04996 16.1265 10.0903 15.7245 11.061C15.3225 12.0317 14.7332 12.9137 13.9903 13.6566C13.2475 14.3996 12.3655 14.9889 11.3949 15.391C10.4242 15.793 9.38389 16 8.33326 16ZM4.69432 7.2107L3.75002 8.15501L6.63715 11.0389C6.69887 11.1011 6.77228 11.1504 6.85315 11.184C6.93401 11.2177 7.02073 11.2351 7.10832 11.2351C7.19592 11.2351 7.28266 11.2177 7.36352 11.184C7.44439 11.1504 7.51778 11.1011 7.5795 11.0389L12.9126 5.7058L11.9703 4.76342L7.10994 9.62892L4.69432 7.2107Z"
                fill={colors.primary60}
            />
        </svg>
    );
};
