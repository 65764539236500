import colors from 'styles/modules/colors.module.scss';

export const Close = ({ fill = colors.white }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path
                d="M17.064 1.061L16.003 0L8.534 7.47L1.064 0L0.00299835 1.061L7.47 8.531L0 16L1.061 17.061L8.531 9.591L16 17.061L17.061 16L9.594 8.531L17.064 1.061Z"
                fill={fill}
            />
        </svg>
    );
};

