import { SvgIconProps } from 'helpers';

export const Forward: SvgIconProps = () => {
    return (
        <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.608216 24C0.446907 24 0.292251 23.9368 0.178188 23.8243C0.0641256 23.7119 0 23.5593 0 23.4003C0 10.5187 3.69796 5.3365 13.144 5.1262V0.599668C13.144 0.486316 13.1766 0.375288 13.238 0.279451C13.2994 0.183615 13.387 0.106897 13.4908 0.0581891C13.5946 0.00948142 13.7103 -0.00922058 13.8244 0.00425034C13.9386 0.0177213 14.0466 0.0628137 14.1358 0.134301L25.7746 9.45044C25.8451 9.50658 25.9018 9.57755 25.9408 9.65814C25.9799 9.73873 26.0001 9.8269 26.0001 9.91621C26.0001 10.0055 25.9799 10.0937 25.9408 10.1743C25.9018 10.2549 25.8451 10.3258 25.7746 10.382L14.1358 19.6989C14.0464 19.7701 13.9384 19.8149 13.8243 19.8282C13.7102 19.8415 13.5946 19.8227 13.4909 19.7739C13.3872 19.7252 13.2996 19.6485 13.2382 19.5527C13.1768 19.457 13.1441 19.346 13.144 19.2327V14.727C6.76255 15.0101 1.21643 19.6885 1.21643 23.4003C1.21643 23.5593 1.1524 23.7119 1.03834 23.8243C0.92428 23.9368 0.769524 24 0.608216 24ZM14.3604 1.85904V5.71951C14.3604 5.87856 14.2964 6.03109 14.1823 6.14356C14.0682 6.25603 13.9135 6.31921 13.7522 6.31921C5.6037 6.31921 2.02009 9.99737 1.33889 19.6254C3.58361 16.3638 8.39419 13.514 13.7522 13.514C13.9135 13.514 14.0682 13.5772 14.1823 13.6897C14.2964 13.8021 14.3604 13.9547 14.3604 14.1137V17.9734L24.4268 9.91661L14.3604 1.85904Z"
                fill="#C4DFFF"
            />
        </svg>
    );
};
