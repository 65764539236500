export const Notifications = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1294_3405)">
                <path d="M19.9941 12.8874H18.4761V18.8849H19.9941V12.8874Z" fill="#8DBEF9" />
                <path
                    d="M19.2351 22.1243C19.794 22.1243 20.2471 21.6766 20.2471 21.1243C20.2471 20.572 19.794 20.1243 19.2351 20.1243C18.6762 20.1243 18.2231 20.572 18.2231 21.1243C18.2231 21.6766 18.6762 22.1243 19.2351 22.1243Z"
                    fill="#8DBEF9"
                />
                <path
                    d="M29.9518 6.5C27.7153 6.5 25.9038 8.28925 25.9038 10.4983C25.9038 12.7074 27.7153 14.4967 29.9518 14.4967C32.1883 14.4967 33.9997 12.7074 33.9997 10.4983C33.9997 8.28925 32.1883 6.5 29.9518 6.5Z"
                    fill="#F8FD00"
                />
                <path
                    d="M29.952 28.111L25.8231 24.6125C25.5599 24.3925 25.2159 24.2626 24.8718 24.2626H9.86394C9.12518 24.2626 8.51799 23.6728 8.51799 22.9332V11.8278C8.51799 11.0981 9.11506 10.4983 9.86394 10.4983H24.386C24.386 9.97855 24.467 9.47876 24.5985 8.99896H9.86394C8.28523 8.99896 7 10.2684 7 11.8278V22.9432C7 24.5025 8.28523 25.772 9.86394 25.772H24.8313L30.2151 30.3201C30.3568 30.44 30.5288 30.5 30.711 30.5C30.8223 30.5 30.9235 30.48 31.0247 30.43C31.2979 30.3101 31.47 30.0402 31.47 29.7503V15.7961C30.9842 15.9361 30.4782 16.006 29.952 16.006V28.121V28.111Z"
                    fill="#8DBEF9"
                />
            </g>
            <defs>
                <clipPath id="clip0_1294_3405">
                    <rect width="40" height="40" rx="8" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
