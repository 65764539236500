import { IconButton, IconTextButton } from 'components/buttons';
import classes from './CustomerServiceFilters.module.scss';
import { CloseBig, ResetFilters } from 'components/icons';
import { CustomerServiceFiltersItems, DEFAULT_CUSTOMER_SERVICES_FILTER_VALUES, RadioButtonFilterFields } from 'helpers';
import PeriodFilters from './periodFilters/PeriodFilters';
import Divider from 'components/divider/Divider';
import { FC, useMemo } from 'react';
import RadioButtonFilter from 'components/radioButtonFIlter/RadioButtonFilter';
import colors from 'styles/modules/colors.module.scss';
import deepEqual from 'deep-equal';

type CustomerServiceFiltersProps = FC<{
    activeFilters: CustomerServiceFiltersItems;
    setActiveFilters: any;
    resetFilters: any;
    setFiltersOpen: any;
}>;

const REQUEST_STATUS_BUTTON_FILTER_FIELDS: RadioButtonFilterFields[] = [
    { label: 'Tutti', value: 'all' },
    { label: 'Solo richieste da evadere', value: 'notCompleted' },
    { label: 'Solo richieste evase', value: 'completed' }
];

const READ_STATUS_BUTTON_FILTER_FIELDS: RadioButtonFilterFields[] = [
    { label: 'Tutti', value: 'all' },
    { label: 'Solo nuovi messaggi', value: 'notRead' },
    { label: 'Solo messaggi già visualizzati', value: 'alreadyRead' }
];

const BLACKLIST_BUTTON_FILTER_FIELDS: RadioButtonFilterFields[] = [
    { label: 'Tutti', value: 'all' },
    { label: 'Solo utenti in Blacklist', value: 'blacklisted' },
    { label: 'Solo utenti non in Blacklist', value: 'notBlacklisted' }
];

const CustomerServiceFilters: CustomerServiceFiltersProps = ({
    setFiltersOpen,
    activeFilters,
    resetFilters,
    setActiveFilters
}) => {
    const CUSTOMER_RADIO_BUTTON_FILTERS = useMemo(
        () => [
            {
                activeFilters: activeFilters.requestStatus,
                setActiveFilter: setActiveFilters,
                fields: REQUEST_STATUS_BUTTON_FILTER_FIELDS,
                keyValue: 'requestStatus',
                label: 'Risposte'
            },
            {
                activeFilters: activeFilters.readStatus,
                setActiveFilter: setActiveFilters,
                fields: READ_STATUS_BUTTON_FILTER_FIELDS,
                keyValue: 'readStatus',
                label: 'Nuovi Messaggi'
            },
            {
                activeFilters: activeFilters.blacklist,
                setActiveFilter: setActiveFilters,
                fields: BLACKLIST_BUTTON_FILTER_FIELDS,
                keyValue: 'blacklist',
                label: 'Blacklist'
            }
        ],
        [activeFilters, setActiveFilters]
    );

    return (
        <>
            <div className={classes.header}>
                <div className={classes.title}>
                    <IconButton className="tertiary" icon={<CloseBig />} onClick={() => setFiltersOpen(false)} />
                    <p>
                        <b>Filtri</b>
                    </p>
                </div>
                <IconTextButton
                    className="tertiary"
                    disabled={deepEqual(activeFilters, DEFAULT_CUSTOMER_SERVICES_FILTER_VALUES)}
                    icon={
                        <ResetFilters
                            fill={
                                deepEqual(activeFilters, DEFAULT_CUSTOMER_SERVICES_FILTER_VALUES)
                                    ? colors.primary40
                                    : colors.primary90
                            }
                        />
                    }
                    onClick={resetFilters}
                >
                    Reset
                </IconTextButton>
            </div>
            <PeriodFilters activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
            <Divider />
            <p className={`lightBlue ${classes.specific}`}>Specifiche</p>
            <div className={classes.radioFilters}>
                {CUSTOMER_RADIO_BUTTON_FILTERS.map(filter => (
                    <RadioButtonFilter
                        label={filter.label}
                        activeFilters={filter.activeFilters}
                        setActiveFilter={filter.setActiveFilter}
                        fields={filter.fields}
                        keyValue={filter.keyValue}
                    />
                ))}
            </div>
        </>
    );
};

export default CustomerServiceFilters;
