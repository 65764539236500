import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const ArrowBack: SvgIconProps = ({ fill = colors.primary100 }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1030_5196)">
                <path
                    d="M24.2175 9L25.2775 10.06L15.8075 19.53L25.2775 29L24.2175 30.06L14.2175 20.06C13.9275 19.77 13.9275 19.29 14.2175 19L24.2175 9Z"
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_1030_5196">
                    <rect width="40" height="40" rx="8" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
