import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from 'environments';
import { User, UserDashboardInfo, UserRoles, UserWithVehicles, UsersStatus } from 'models';
import { store } from 'store';

interface UserCreation {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    role: UserRoles;
    municipalities: string[];
}

interface DashboardDetailInfo {
    totalParking: number;
    totalAmount: number;
}

export const usersApi = createApi({
    reducerPath: 'users-api',
    baseQuery: fetchBaseQuery({
        fetchFn(input, init) {
            const authState = store.getState().auth.token as any;
            return fetch(input, { ...init, headers: { authorization: authState!, 'content-type': 'application/json' } });
        },
        baseUrl: `${env.reactAppSocketUrl}${env.apiUrl}`
    }),
    endpoints: builder => ({
        getUserDetailDashboardInfo: builder.query<DashboardDetailInfo, { id: string }>({
            query: params => ({
                url: '/users/detail-dashboard-info',
                method: 'GET',
                params
            }),
            keepUnusedDataFor: 10
        }),
        getUsersDashboardInfo: builder.query<
            UserDashboardInfo[],
            { searchedWord: string; vehicleType: string; blacklist: string; billing: string; inProgress: string }
        >({
            query: params => ({
                url: '/users/dashboard-info',
                method: 'GET',
                params
            }),
            keepUnusedDataFor: 10
        }),
        getStandardUsers: builder.query<
            UserWithVehicles[],
            {
                offset: number;
                pageSize: number;
                searchedWord: string;
                vehicleType: string;
                blacklist: string;
                billing: string;
                inProgress: string;
                orderBy: string;
            }
        >({
            query: params => ({
                url: '/users',
                method: 'GET',
                params: params
            }),
            keepUnusedDataFor: 100
        }),
        getStandardUserDetail: builder.query<UserWithVehicles, { id: string }>({
            query: params => ({
                url: '/users/detail',
                method: 'GET',
                params: params
            })
        }),
        getStandardUserById: builder.query<any, { id: string }>({
            query: params => ({
                url: '/users/by-id',
                method: 'GET',
                params: params
            }),
            keepUnusedDataFor: 100
        }),
        getAdminUsers: builder.query<User[], void>({
            query: offset => ({
                url: '/users/admin',
                method: 'GET',
                params: { offset }
            }),
            keepUnusedDataFor: 10
        }),
        createUser: builder.query<any, { user: UserCreation }>({
            query: body => ({
                url: '/users/backoffice',
                method: 'POST',
                body
            })
        }),
        login: builder.query<any, { email: string; password: string }>({
            query: body => ({
                url: '/auth/backoffice',
                method: 'POST',
                body
            })
        }),
        resendOtp: builder.query<{ message: string }, { id: string }>({
            query: body => ({
                url: '/auth/resend-otp',
                method: 'POST',
                body
            })
        }),
        updateUserStatus: builder.query<User, { id: string; status: UsersStatus; isBlacklistedFor: string }>({
            query: body => ({
                url: '/users/status',
                method: 'POST',
                body
            })
        }),
        deleteUser: builder.query<any, any>({
            query: body => ({
                url: '/users/backoffice',
                method: 'DELETE',
                body: { usersId: body }
            })
        }),
        resetPasswordRequest: builder.query<{ message: string }, string>({
            query: body => ({
                url: '/users/backoffice-reset-password-request',
                method: 'POST',
                body: { email: body }
            })
        }),
        resetPassword: builder.query<
            { message: string; telephoneNumber: string },
            { id: string; newPassword: string; otp: string }
        >({
            query: body => ({
                url: '/users/backoffice-reset-password',
                method: 'POST',
                body
            })
        }),
        updatePassword: builder.query<
            { message: string; telephoneNumber: string },
            { userId: string; password: string; otp: string }
        >({
            query: body => ({
                url: '/users/backoffice-update-password',
                method: 'PUT',
                body
            })
        })
    })
});

export const {
    useGetStandardUserByIdQuery,
    useGetUserDetailDashboardInfoQuery,
    useGetUsersDashboardInfoQuery,
    useGetStandardUsersQuery,
    useGetStandardUserDetailQuery,
    useGetAdminUsersQuery,
    useLazyResendOtpQuery,
    useLazyGetStandardUsersQuery,
    useLazyLoginQuery,
    useLazyCreateUserQuery,
    useLazyUpdateUserStatusQuery,
    useLazyDeleteUserQuery,
    useLazyResetPasswordRequestQuery,
    useLazyResetPasswordQuery,
    useLazyUpdatePasswordQuery
} = usersApi;

