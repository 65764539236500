import Searchbar from 'components/searchbar/Searchbar';
import { SetState } from 'helpers';
import { FC } from 'react';
import classes from './MunicipalitySearchbar.module.scss';

type MunicipalitySearchbarProps = FC<{ searchedWord: string; searchBarHandler: SetState<string> }>;
const MunicipalitySearchbar: MunicipalitySearchbarProps = ({ searchBarHandler, searchedWord }) => {
    return (
        <div className={classes.searchbar}>
            <p>Settings Aree</p>
            <Searchbar value={searchedWord} onChange={searchBarHandler} />
        </div>
    );
};

export default MunicipalitySearchbar;
