import { Delete, Document } from 'components/icons';
import { FC } from 'react';
import classes from './LoadedFileCard.module.scss';
import { IconButton } from 'components/buttons';
import { SetState } from 'helpers';

type LoadedFileCardProps = FC<{
    currentFile: File | null;
    setFile: SetState<File | null>;
}>;

const LoadedFileCard: LoadedFileCardProps = ({ currentFile, setFile }) => {
    const bytesToKB = (bytes: number) => Math.round(bytes / 1024) + ' kB';

    return (
        <div className={classes.loadedFile}>
            <div className={classes.fileWrap}>
                <span className={classes.fileIcon}>
                    <Document />
                </span>
                <b>{currentFile?.name}</b>
                <span className={classes.fileSize}>{bytesToKB(currentFile?.size || 0)}</span>
            </div>
            <IconButton icon={<Delete />} className={classes.deleteFile} onClick={() => setFile(null)} />
        </div>
    );
};

export default LoadedFileCard;

