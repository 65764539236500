import GenericSelect from 'components/select/GenericSelect';
import SlopeAccordion from 'components/slopeAccordion/SlopeAccordion';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, createTariffRuleActions } from 'store';
import PricingHeader from '../pricingHeader/PricingHeader';
import PricingTime from '../pricingTime/PricingTime';
import classes from './PricingItem.module.scss';
import FlatPricing from './flatPricing/FlatPricing';
import ProportionalPricing from './proportionalPricing/ProportionalPricing';
import StairwayPricing from './stairwayPricing/StairwayPricing';

const SELECT_OPTIONS = [
    { label: 'Proporzionale', value: 'proportional' },
    { label: 'Flat', value: 'flat' },
    { label: 'Stairway', value: 'stairway' }
];

type PricingItemProps = FC<{ slope: any; index: number }>;

const PricingItem: PricingItemProps = ({ slope, index }) => {
    const { slopes, isSubmitted } = useSelector((x: RootState) => x.createTariffRule);
    const [isRendered, setRender] = useState(false);

    const dispatch = useDispatch();

    const selectOptions = useMemo(() => {
        return SELECT_OPTIONS.map(option => ({
            id: option.value,
            label: option.label
        }));
    }, []);

    const onChangeHandler = useCallback(
        (index: number) => (key: string, value: any) => dispatch(createTariffRuleActions.updateSlope({ index, key, value })),
        [dispatch]
    );
    useEffect(() => {
        setRender(true);
    }, []);

    useEffect(() => {
        let isValid = false;
        if (slopes[index].slopeType === 'stairway') {
            isValid =
                ((slopes[index].fromDays >= 0 || slopes[index].fromHours >= 0 || slopes[index].fromMinutes >= 0) &&
                    (slopes[index].toDays > 0 || slopes[index].toHours > 0 || slopes[index].toMinutes > 0)) ||
                (slopes[index].fromHour.length > 0 &&
                    slopes[index].toHour.length > 0 &&
                    slopes[index].fromHour < slopes[index].toHour);
        }

        if (slopes[index].slopeType === 'proportional')
            isValid =
                (slopes[index].days > 0 || slopes[index].hours > 0 || slopes[index].minutes > 0) &&
                (slopes[index].price > 0 ||
                    slopes[index].maxDays > 0 ||
                    slopes[index].maxHours > 0 ||
                    slopes[index].maxMinutes > 0);

        if (slopes[index].slopeType === 'flat')
            isValid =
                slopes[index].days > 0 ||
                slopes[index].hours > 0 ||
                slopes[index].minutes > 0 ||
                (slopes[index].fromHour.length > 0 &&
                    slopes[index].toHour.length > 0 &&
                    slopes[index].fromHour < slopes[index].toHour);
        dispatch(
            createTariffRuleActions.setIsValid({
                key: `slope-${index}`,
                isValid: slopes[index].slopeType.length > 0 && slopes[index].amount > 0 && isValid
            })
        );
    }, [dispatch, index, slopes]);

    return (
        <SlopeAccordion title={<PricingHeader index={index} isFirst={index === 0} isLast={index === slopes.length - 1} />}>
            <div className={classes.leftSide}>
                <div>
                    <p className={`small lightBlue ${classes.label}`}>
                        <b>Tipo di slope</b>
                    </p>
                    <GenericSelect
                        value={slopes[index].slopeType}
                        onChange={(event: any) => onChangeHandler(index)('slopeType', event.target.value)}
                        placeholder="Seleziona"
                        className={isSubmitted && slopes[index].slopeType.length <= 0 ? classes.error : ''}
                        options={selectOptions}
                    />
                </div>
                <div id={`left-slope-${index}`}></div>
            </div>
            <div className={classes.rightSide}>
                <PricingTime
                    values={slope}
                    onChange={onChangeHandler(index)}
                    options={[
                        {
                            key: 'amount',
                            label: 'Costo (€)',
                            type: 'number',
                            isValid: !isSubmitted || slope.amount > 0
                        }
                    ]}
                    label=""
                />
                <div id={`right-slope-${index}`}></div>
            </div>
            {isRendered && slope.slopeType === 'stairway' && (
                <StairwayPricing slope={slopes[index]} onInputChange={onChangeHandler(index)} index={index} />
            )}
            {isRendered && slope.slopeType === 'proportional' && (
                <ProportionalPricing slope={slopes[index]} onInputChange={onChangeHandler(index)} index={index} />
            )}
            {isRendered && slope.slopeType === 'flat' && (
                <FlatPricing slope={slopes[index]} onInputChange={onChangeHandler(index)} index={index} />
            )}
        </SlopeAccordion>
    );
};
export default PricingItem;

