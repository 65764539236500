import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BillsFilters, DEFAULT_BILLS_FILTER_VALUES } from 'helpers';
import { Billing, BillingDetailsData } from 'models';

export interface BillingsState {
    billings: Billing[];
    filteredBillings: Billing[];
    selectedBilling: Billing | undefined;
    billingDetails: BillingDetailsData | undefined;
    filteredBillingDetails: BillingDetailsData | undefined;
    pages: number;
    activeFilters: BillsFilters;
}

const name = 'billings';
const initialState: BillingsState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const billingsActions = { ...slice.actions };
export const billingsReducer = slice.reducer;

function createInitialState(): BillingsState {
    return {
        billings: [],
        filteredBillings: [],
        selectedBilling: undefined,
        billingDetails: undefined,
        filteredBillingDetails: undefined,
        pages: 0,
        activeFilters: DEFAULT_BILLS_FILTER_VALUES
    };
}

function createReducers() {
    return {
        setBillings,
        issueBilling,
        setFilteredBillings,
        setSelectedBilling,
        setBillingDetails,
        setFilteredBillingDetails,
        setPages,
        setActiveFilters
    };

    function setBillings(state: BillingsState, action: PayloadAction<Billing[]>) {
        state.billings = action.payload.reduce(
            (acc, cur) => (acc.find(a => cur.businessName === a.businessName) ? acc : [...acc, cur]),
            [] as Billing[]
        );
        state.filteredBillings = state.billings.filter(billing => billing.unbilled !== '0');
    }
    function issueBilling(state: BillingsState, action: PayloadAction<Billing>) {
        const unfilteredBillingIdx = state.billings.findIndex(billing => billing.businessName === action.payload.businessName);
        if (unfilteredBillingIdx !== -1) state.billings[unfilteredBillingIdx] = action.payload;
        const filteredBillingIdx = state.filteredBillings.findIndex(
            billing => billing.businessName === action.payload.businessName
        );
        if (filteredBillingIdx !== -1)
            state.filteredBillings = state.filteredBillings.filter((v, idx) => idx !== filteredBillingIdx);
    }
    function setFilteredBillings(state: BillingsState, action: PayloadAction<Billing[]>) {
        state.filteredBillings = action.payload;
    }
    function setSelectedBilling(state: BillingsState, action: PayloadAction<Billing | undefined>) {
        state.selectedBilling = action.payload;
    }
    function setBillingDetails(state: BillingsState, action: PayloadAction<BillingDetailsData | undefined>) {
        state.billingDetails = action.payload;
    }
    function setFilteredBillingDetails(state: BillingsState, action: PayloadAction<BillingDetailsData | undefined>) {
        state.billingDetails = action.payload;
    }
    function setPages(state: BillingsState, action: PayloadAction<number>) {
        state.pages = action.payload;
    }
    function setActiveFilters(state: BillingsState, action: PayloadAction<BillsFilters>) {
        state.activeFilters = action.payload;
    }
}

