import { FC } from 'react';

export const Free: FC = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.46008 3.39C4.74008 3.19 5.07008 3.05 5.43008 2.98V1.99H6.62008V2.99C7.74008 3.19 8.23008 3.94 8.34008 4.77H7.12008C6.99008 4.36 6.70008 4.06 5.98008 4.06C5.67008 4.06 5.45008 4.12 5.29008 4.22L6.53008 5.46C7.77008 5.77 8.40008 6.16 8.40008 7.2C8.40008 7.24 8.40008 7.28 8.39008 7.32L10.7401 9.67C11.5301 8.65 12.0001 7.38 12.0101 6C12.0101 2.69 9.32008 0 6.01008 0C4.62008 0 3.35008 0.47 2.33008 1.26L4.46008 3.39Z"
                fill="#C4DFFF"
            />
            <path
                d="M7.61 8.65982C7.31 8.84982 6.97 8.95982 6.63 9.00982V9.98982H5.43V8.97982C4.24 8.73982 3.72 7.90982 3.61 7.04982H4.82C4.98 7.62982 5.33 7.92982 6.17 7.92982C6.42 7.92982 6.61 7.87982 6.78 7.81982L5.41 6.44982C4.52 6.21982 3.78 5.83982 3.77 4.80982L1.26 2.31982C0.47 3.33982 0 4.60982 0 5.98982C0 9.29982 2.69 11.9898 6 11.9898C7.39 11.9898 8.66 11.5098 9.67 10.7198L7.61 8.65982Z"
                fill="#C4DFFF"
            />
        </svg>
    );
};
