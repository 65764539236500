export const CheckBoxChecked = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="19" height="19" rx="4.5" stroke="#043F86" />
            <rect x="3" y="3" width="14" height="14" rx="2" fill="#1072E7" />
            <g clipPath="url(#clip0_26_327)">
                <path
                    d="M8.9316 12.6505C8.85514 12.6507 8.77941 12.6357 8.70879 12.6064C8.63817 12.5771 8.57408 12.534 8.52023 12.4798L6 9.96031L6.82274 9.13681L8.9316 11.2449L13.1773 7L14 7.82274L9.34297 12.4798C9.28912 12.534 9.22503 12.5771 9.15441 12.6064C9.08379 12.6357 9.00806 12.6507 8.9316 12.6505Z"
                    fill="#010D1D"
                />
            </g>
            <defs>
                <clipPath id="clip0_26_327">
                    <rect width="8" height="5.65053" fill="white" transform="translate(6 7)" />
                </clipPath>
            </defs>
        </svg>
    );
};
