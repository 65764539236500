export const CircleCheckError = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.99936 16C6.41717 15.9999 4.87054 15.5306 3.55504 14.6515C2.23955 13.7724 1.21426 12.523 0.608841 11.0612C0.00342055 9.59941 -0.154949 7.99091 0.153754 6.43912C0.462456 4.88733 1.22437 3.46195 2.34315 2.34317C3.46193 1.22439 4.88733 0.462456 6.43912 0.153754C7.99091 -0.154949 9.59939 0.00342055 11.0612 0.608841C12.523 1.21426 13.7724 2.23955 14.6515 3.55504C15.5306 4.87054 15.9999 6.41717 16 7.99936C16.0001 9.05005 15.7932 10.0905 15.3912 11.0612C14.9891 12.0319 14.3998 12.9139 13.6569 13.6569C12.9139 14.3998 12.0319 14.9891 11.0612 15.3912C10.0905 15.7932 9.05005 16.0001 7.99936 16ZM7.99936 9.21234L10.8187 12.0336L12.0336 10.8206L9.20783 7.99936L12.0336 5.1762L10.8193 3.96192L7.99936 6.78509L5.17618 3.96192L3.9619 5.17683L6.78702 8.00003L3.9619 10.8213L5.17618 12.0355L7.99936 9.21427V9.21234Z"
                fill="#FB9AA0"
            />
        </svg>
    );
};
