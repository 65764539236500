import { SvgIconProps } from 'helpers';

export const CircleFilled: SvgIconProps = ({ fill = '#1070E5' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <circle cx="8" cy="8" r="3" fill={fill} />
        </svg>
    );
};

