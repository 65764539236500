import { FC, useState } from 'react';
import AdminModal from './adminModal/AdminModal';
import { IconButton } from 'components/buttons';
import { Plus } from 'components/icons';
import { usePermissionHandler } from 'hook';
import { TabTypes } from 'store';

const CreateAdmin: FC = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const hasPermission = usePermissionHandler();

    return (
        <>
            <IconButton
                disabled={!hasPermission(TabTypes.settings, ['can-create'])}
                className="primary"
                icon={<Plus />}
                onClick={() => setIsModalOpen(true)}
            />
            <AdminModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        </>
    );
};

export default CreateAdmin;

