export const Chat = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26.1911 7H7.80893C6.26055 7 5 8.26941 5 9.82868V20.9435C5 22.5028 6.26055 23.7722 7.80893 23.7722H22.4888L27.7692 28.3201C27.9082 28.44 28.0769 28.5 28.2556 28.5C28.3648 28.5 28.464 28.48 28.5633 28.43C28.8313 28.3101 29 28.0402 29 27.7503V9.82868C29 8.26941 27.7395 7 26.1911 7ZM27.5112 26.1111L23.4615 22.6127C23.2035 22.3928 22.866 22.2629 22.5285 22.2629H7.80893C7.08437 22.2629 6.48883 21.6732 6.48883 20.9335V9.82868C6.48883 9.09902 7.07444 8.4993 7.80893 8.4993H26.201C26.9256 8.4993 27.5211 9.08903 27.5211 9.82868V26.1111H27.5112Z"
                fill="#8DBEF9"
            />
            <path d="M17.7443 10.8882H16.2555V16.8854H17.7443V10.8882Z" fill="#8DBEF9" />
            <path
                d="M17 20.1248C17.5482 20.1248 17.9926 19.6771 17.9926 19.1248C17.9926 18.5725 17.5482 18.1248 17 18.1248C16.4518 18.1248 16.0074 18.5725 16.0074 19.1248C16.0074 19.6771 16.4518 20.1248 17 20.1248Z"
                fill="#8DBEF9"
            />
        </svg>
    );
};
