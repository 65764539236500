import Modal from 'components/modal/Modal';
import { SetState } from 'helpers';
import { FC, useState } from 'react';
import classes from './DeleteMunicipalityModal.module.scss';
import { useGetAllMunicipalitiesQuery, useDeleteMunicipalityMutation } from 'store/rtk/municipality.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, municipalityActions, snackbarActions } from 'store';
import TextButton from 'components/buttons/textButton/TextButton';
import Snackbar from 'components/snackbar/Snackbar';
import { billingsApi } from 'store/rtk/billing.service';

type DeleteMunicipalityModalProps = FC<{ isOpen: boolean; setIsOpen: SetState<boolean> }>;

const DeleteMunicipalityModal: DeleteMunicipalityModalProps = ({ isOpen, setIsOpen }) => {
    const { selectedMunicipality } = useSelector((x: RootState) => x.municipalities);
    const [deleteMunicipality, { isLoading }] = useDeleteMunicipalityMutation();
    const { refetch: refetchMunicipalityList } = useGetAllMunicipalitiesQuery();
    const [isDenyModalOpen, setIsDenyModalOpen] = useState(false);
    const dispatch = useDispatch();

    const onSubmitHandler = async () => {
        try {
            if (!selectedMunicipality || isLoading) return;
            const res = await deleteMunicipality({ id: selectedMunicipality.id });

            if ('error' in res && res.error && 'status' in res.error && res.error.status === 409) {
                setIsDenyModalOpen(true);
                setIsOpen(false);
            } else if ('data' in res) {
                dispatch(billingsApi.util.resetApiState());
                dispatch(municipalityActions.deleteMunicipality(selectedMunicipality.id));
                dispatch(snackbarActions.setMessageAndType({ message: `Città eliminata con successo.` }));
                dispatch(snackbarActions.setIsOpen(true));
                setIsOpen(false);
                refetchMunicipalityList();
            }
        } catch (error) {
            console.error('Error deleting municipality:', error);
            dispatch(snackbarActions.setMessageAndType({ message: `Errore durante l'eliminazione della città.` }));
            dispatch(snackbarActions.setIsOpen(true));
        }
    };

    return (
        <>
            <Modal title="Elimina città" isOpen={isOpen} handleClose={() => setIsOpen(false)}>
                <div className={classes.container}>
                    <div>
                        Vuoi eliminare la città <b>{selectedMunicipality?.name}</b>? <br />
                        Verranno eliminate anche le aree e le tariffe correlate.
                    </div>
                    <div className={classes.buttons}>
                        <TextButton className="tertiary" onClick={() => setIsOpen(false)}>
                            Annulla
                        </TextButton>
                        <TextButton className="error" onClick={onSubmitHandler} disabled={isLoading}>
                            Elimina
                        </TextButton>
                    </div>
                </div>
            </Modal>
            <Modal title="Eliminazione non consentita" isOpen={isDenyModalOpen} handleClose={() => setIsDenyModalOpen(false)}>
                <div className={classes.denyContainer}>
                    <span>
                        La città <b>{selectedMunicipality?.name}</b> non può essere eliminata in quanto esistono soste associate
                        ad essa.
                    </span>
                    <span>E&apos; possibile però disabilitarne le aree.</span>
                    <TextButton className={`tertiary ${classes.btn}`} onClick={() => setIsDenyModalOpen(false)}>
                        Ho capito
                    </TextButton>
                </div>
            </Modal>
            <Snackbar />
        </>
    );
};

export default DeleteMunicipalityModal;

