import { IconButton, IconTextButton } from 'components/buttons';
import Dashboard from 'components/dashboard/Dashboard';
import Divider from 'components/divider/Divider';
import { ArrowBack, ResetFilters } from 'components/icons';
import RadioButtonFilter from 'components/radioButtonFIlter/RadioButtonFilter';
import VehicleFilter from 'components/vehicleFilter/VehicleFilter';
import deepEqual from 'deep-equal';
import { DEFAULT_USERS_FILTER_VALUES, RadioButtonFilterFields, SetState } from 'helpers';
import { UserFilters } from 'models';
import { FC } from 'react';
import colors from 'styles/modules/colors.module.scss';
import classes from './UsersFilter.module.scss';
import PaymentFilters from './paymentFilters/PaymentFilters';

const RADIO_BUTTON_FILTER_FIELDS: RadioButtonFilterFields[] = [
    { label: 'Tutti', value: 'all' },
    { label: 'Solo soste in corso', value: 'inProgress' },
    { label: 'Solo utenti senza soste in corso', value: 'notInProgress' }
];

type UsersFiltersProps = FC<{
    activeFilters: UserFilters;
    setActiveFilters: any;
    setAreFiltersOpen: SetState<boolean>;
    resetFilters: any;
    dashboardValues: any;
}>;

const UsersFilters: UsersFiltersProps = ({
    dashboardValues,
    activeFilters,
    setActiveFilters,
    setAreFiltersOpen,
    resetFilters
}) => {
    return (
        <div className={classes.filtersContainer}>
            <div className={classes.dashboard}>
                <Dashboard props={dashboardValues} />
            </div>
            <div className={classes.header}>
                <div className={classes.title}>
                    <IconButton className="tertiary" icon={<ArrowBack />} onClick={() => setAreFiltersOpen(false)} />
                    <p>
                        <b>Filtri</b>
                    </p>
                </div>
                <IconTextButton
                    className="tertiary"
                    disabled={deepEqual(activeFilters, DEFAULT_USERS_FILTER_VALUES)}
                    icon={
                        <ResetFilters
                            fill={deepEqual(activeFilters, DEFAULT_USERS_FILTER_VALUES) ? colors.primary40 : colors.primary90}
                        />
                    }
                    onClick={resetFilters}
                >
                    Reset
                </IconTextButton>
            </div>
            <div className={classes.filters}>
                <VehicleFilter
                    activeFilters={activeFilters.vehicleTypes}
                    setActiveFilters={setActiveFilters}
                    className={activeFilters.vehicleTypes.length === 0 ? '' : classes.inputStyle}
                />
                <Divider />
                <PaymentFilters activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
                <Divider />
                <div>
                    <p className="lightBlue">Soste attive</p>
                    <RadioButtonFilter
                        label="Soste in corso"
                        fields={RADIO_BUTTON_FILTER_FIELDS}
                        activeFilters={activeFilters.inProgress}
                        setActiveFilter={setActiveFilters}
                        keyValue="inProgress"
                    />
                </div>
            </div>
        </div>
    );
};

export default UsersFilters;

