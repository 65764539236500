import Card from 'components/card/Card';
import { BillingFilled, MoneySmall } from 'components/icons';
import { ProfileFilled } from 'components/icons/ProfileFilled';
import NoResult from 'components/noResult/NoResult';
import { DEFAULT_FAKE_BILLING } from 'helpers';
import { Billing } from 'models';
import { FC, useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, billingsActions } from 'store';
import colors from 'styles/modules/colors.module.scss';
import classes from './BillingList.module.scss';

type BillingListProps = FC<{
    billingList: Billing[];
    handleBillingClick: (billing: Billing) => void;
    billingsData: Billing[] | undefined;
}>;

const BillingList: BillingListProps = ({ billingList, handleBillingClick, billingsData }) => {
    const { billings, pages, selectedBilling } = useSelector((x: RootState) => x.billings);
    const dispatch = useDispatch();
    const { ref, inView } = useInView({
        threshold: 0
    });

    useEffect(() => {
        if (!inView) return;
        if (!billingsData || billingsData.length === 0) return;
        dispatch(billingsActions.setPages(pages + 1));
    }, [inView, billings]);

    useEffect(() => {
        if (!inView) return;
        if (!billingsData || billingsData.length === 0) return;
        dispatch(billingsActions.setBillings([...billingsData, ...billings]));
    }, [billingsData, inView]);

    const memoizedBillingList = useMemo(() => {
        return [...billingList, DEFAULT_FAKE_BILLING].map((billing, index) =>
            billing.id !== 'last-one' ? (
                <Card
                    checkIcon={billing.unbilled === '0'}
                    onClick={() => handleBillingClick(billing)}
                    className={selectedBilling?.businessName === billing.businessName ? classes.selected : ''}
                    key={billing.id + '_' + index}
                    chips={[
                        {
                            icon: <MoneySmall fill={billing.unbilled == '0' ? colors.primary90 : colors.white} />,
                            label: billing.totalAmount.toFixed(2).replace('.', ',') + ' €',
                            active: billing.unbilled !== '0'
                        },
                        {
                            icon: <ProfileFilled fill={billing.unbilled == '0' ? colors.primary90 : colors.white} />,
                            label: billing.numberOfUsers,
                            active: billing.unbilled !== '0'
                        },
                        {
                            icon: <BillingFilled fill={billing.unbilled == '0' ? colors.primary90 : colors.white} />,
                            label: billing.totalBillings,
                            active: billing.unbilled !== '0'
                        }
                    ]}
                >
                    {billing.businessName}
                </Card>
            ) : (
                <div key={billing.id + '_' + index} ref={ref}></div>
            )
        );
    }, [billingList, handleBillingClick, ref]);

    return <>{memoizedBillingList.length === 1 && billingsData?.length === 0 ? <NoResult /> : memoizedBillingList}</>;
};

export default BillingList;

