import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from 'environments';
import { Municipality, MunicipalityArea, MunicipalityCounter } from 'models';
import { store } from 'store';

export const municipalityApi = createApi({
    reducerPath: 'municipalities-api',
    baseQuery: fetchBaseQuery({
        fetchFn(input, init) {
            const authState = store.getState().auth.token as any;
            return fetch(input, { ...init, headers: { authorization: authState!, 'content-type': 'application/json' } });
        },
        baseUrl: `${env.reactAppSocketUrl}${env.apiUrl}`
    }),
    endpoints: builder => ({
        getAllMunicipalitiesName: builder.query<{ name: string }[], void>({
            query: () => ({
                url: '/municipalities/names',
                method: 'GET'
            }),
            keepUnusedDataFor: 100
        }),
        getAllMunicipalities: builder.query<MunicipalityCounter[], void>({
            query: () => ({
                url: '/municipalities',
                method: 'GET'
            }),
            keepUnusedDataFor: 100
        }),
        getMunicipalityById: builder.query<MunicipalityArea, string>({
            query: id => ({
                url: `/municipalities/${id}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 100
        }),
        createMunicipality: builder.query<Municipality, Municipality>({
            query: body => ({
                url: '/municipalities',
                method: 'POST',
                body
            })
        }),
        editMunicipality: builder.query<
            Municipality,
            { id: string; vatOff: string; fixedFees: number; percentageFees: number; ticketRequired: boolean }
        >({
            query: ({ id, vatOff, fixedFees, percentageFees, ticketRequired }) => ({
                url: `/municipalities/${id}`,
                method: 'PUT',
                body: { vatOff, fixedFees, percentageFees, ticketRequired }
            })
        }),
        deleteMunicipality: builder.mutation<void, { id: string }>({
            query: ({ id }) => ({
                url: `/municipalities/${id}`,
                method: 'DELETE'
            })
        })
    })
});

export const {
    useLazyGetAllMunicipalitiesNameQuery,
    useGetAllMunicipalitiesQuery,
    useLazyCreateMunicipalityQuery,
    useDeleteMunicipalityMutation,
    useLazyEditMunicipalityQuery,
    useLazyGetMunicipalityByIdQuery
} = municipalityApi;

