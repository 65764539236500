import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import GridTable from 'components/gridTable/GridTable';
import { useDispatch, useSelector } from 'react-redux';
import { AdminState, RootState, TabTypes, adminActions } from 'store';
import CreateAdmin from './createAdmin/CreateAdmin';
import classes from './AdminsTable.module.scss';
import { useGetAdminUsersQuery } from 'store/rtk/user.service';
import { FC, useEffect, useState } from 'react';
import { User } from 'models';
import { Delete, Edit } from 'components/icons';
import DeleteAdminModal from './deleteAdminModal/DeleteAdminModal';
import AdminModal from './createAdmin/adminModal/AdminModal';
import { useGetAllMunicipalitiesQuery } from 'store/rtk/municipality.service';
import { usePermissionHandler } from 'hook';

const ROLES_RECORD = {
    accountingOffice: 'Ufficio Contabilità',
    configurator: 'Configuratore',
    municipalityEmployee: 'Operatore Comunale',
    superAdmin: 'Super Admin'
};

const AdminsTable: FC = () => {
    const { user } = useSelector((x: RootState) => x.auth);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const { data } = useGetAdminUsersQuery();
    const { admin } = useSelector<RootState>(x => x.admin) as AdminState;
    const dispatch = useDispatch();
    const [paginationModel, setPaginationModel] = useState({ pageSize: 20, page: 0 });
    const [userToEdit, setUserToEdit] = useState<User>();
    const [userToDelete, setUserToDelete] = useState<User>();
    const { refetch: refetchMunicipalities } = useGetAllMunicipalitiesQuery();
    const hasPermission = usePermissionHandler();

    const HEADERS: GridColDef[] = [
        { headerName: 'Nome', field: 'firstName', minWidth: 100, flex: 1, sortable: false },
        { headerName: 'Cognome', field: 'lastName', minWidth: 110, flex: 1, sortable: false },
        { headerName: 'Email', field: 'email', minWidth: 180, flex: 1, sortable: false },
        { headerName: 'Numero di telefono', field: 'telephoneNumber', minWidth: 130, flex: 1, sortable: false },
        {
            headerName: 'Ruolo',
            field: 'role',
            minWidth: 150,
            renderCell: props => ROLES_RECORD[props.row.role as keyof typeof ROLES_RECORD],
            flex: 1,
            sortable: false
        },
        {
            field: '',
            minWidth: 120,
            renderCell: (params: GridRenderCellParams<any, any>) =>
                params.row?.id !== user?.id ? (
                    <div className={classes.icon}>
                        <>
                            {!hasPermission(TabTypes.settings, ['can-delete']) ? (
                                <></>
                            ) : (
                                <button onClick={() => setUserToDelete(params.row)} className={`secondary`}>
                                    <Delete />
                                </button>
                            )}
                            {!hasPermission(TabTypes.settings, ['can-edit']) ? (
                                <></>
                            ) : (
                                <button
                                    className={`secondary`}
                                    onClick={() => {
                                        setUserToEdit(params.row);
                                        setIsModalOpen(true);
                                    }}
                                >
                                    <Edit />
                                </button>
                            )}
                        </>
                    </div>
                ) : (
                    <></>
                ),

            sortable: false,
            flex: 1
        }
    ];

    useEffect(() => {
        if (data) dispatch(adminActions.setAdmin(data));
    }, [data, dispatch]);

    return (
        <div className={classes.container}>
            <div className={classes.create} onClick={refetchMunicipalities}>
                <CreateAdmin />
            </div>
            <DeleteAdminModal userToDelete={userToDelete} setUserToDelete={setUserToDelete} />
            <AdminModal
                userToEdit={userToEdit}
                setUserToEdit={setUserToEdit}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />
            <GridTable
                rowCount={admin.length}
                headers={HEADERS}
                rows={admin}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
            />
        </div>
    );
};

export default AdminsTable;

