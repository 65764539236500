import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AlertColor } from '@mui/material/Alert';

export interface SnackbarState {
    message: string;
    type: AlertColor;
    isOpen: boolean;
}

const name = 'snackbar';
const initialState: SnackbarState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const snackbarActions = { ...slice.actions };
export const snackbarReducer = slice.reducer;

function createInitialState(): SnackbarState {
    return {
        message: '',
        type: 'success',
        isOpen: false
    };
}

function createReducers() {
    return { setIsOpen, setMessageAndType };

    function setIsOpen(state: SnackbarState, action: PayloadAction<boolean>) {
        state.isOpen = action.payload;
    }
    function setMessageAndType(state: SnackbarState, action: PayloadAction<{ message: string; type?: AlertColor }>) {
        state.message = action.payload.message;
        state.type = action.payload.type ?? 'success';
    }
}
