import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface OtpState {
    otp: string[];
}

const name = 'otp';
const initialState: OtpState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const otpActions = { ...slice.actions };
export const otpReducer = slice.reducer;

function createInitialState(): OtpState {
    return {
        otp: []
    };
}

function createReducers() {
    return {
        setOtp
    };

    function setOtp(state: OtpState, action: PayloadAction<string[]>) {
        state.otp = action.payload;
    }
}

