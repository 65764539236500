import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { AuthState, RootState } from 'store';
import classes from './UserInfoCard.module.scss';
import { UserIcon } from 'components/icons';

const UserInfo: FC = () => {
    const { user } = useSelector<RootState>(x => x.auth) as AuthState;
    if (!user) return <></>;

    return (
        <div className={classes.card}>
            <UserIcon />
            <div className={classes.imageAndText}>
                <p className={classes.userName}>
                    {user.firstName} {user.lastName}
                </p>
                <p className={classes.role}>{user.email}</p>
            </div>
        </div>
    );
};

export default memo(UserInfo);
