import { IconTextButton } from 'components/buttons';
import { CircleBlacklist, Profile } from 'components/icons';
import { UsersStatus } from 'models';
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, TabTypes, TableTypes, tabActions, usersActions } from 'store';
import { useGetStandardUserByIdQuery } from 'store/rtk/user.service';
import classes from './UserInfo.module.scss';
import { usePermissionHandler } from 'hook';

const UserInfo: FC = () => {
    const { selectedCustomerService } = useSelector((x: RootState) => x.customerService);
    const dispatch = useDispatch();
    const { data } = useGetStandardUserByIdQuery({
        id: selectedCustomerService?.userId!
    });
    const selectUserHandler = useCallback(() => {
        if (data) {
            dispatch(usersActions.setSelectedUser(data[0].user.id));
            dispatch(tabActions.setActiveTab(TabTypes.home));
            dispatch(tabActions.setActiveTable(TableTypes.users));
        }
    }, [dispatch, data]);
    const hasPermission = usePermissionHandler();

    if (!selectedCustomerService) return <></>;

    return (
        <div className={classes.userInfo}>
            <div className={classes.info}>
                <p>
                    {selectedCustomerService.User.first_name} {selectedCustomerService.User.last_name}
                    {!selectedCustomerService.User.telephone_number && <span className={classes.deletedLabel}> (Eliminato)</span>}
                </p>
                {selectedCustomerService.User.telephone_number && (
                    <span>
                        {selectedCustomerService.User.telephone_number}
                        {selectedCustomerService.User.email ? ' - ' + selectedCustomerService.User.email : ''}
                    </span>
                )}
            </div>
            <div className={classes.profile}>
                {selectedCustomerService.User.status === UsersStatus.blacklisted && (
                    <div className={classes.status}>
                        <CircleBlacklist />
                        <b>Blacklist</b>
                    </div>
                )}
                <IconTextButton
                    disabled={!hasPermission(TabTypes.customerService, ['can-edit'])}
                    className="secondaryVariant"
                    icon={<Profile />}
                    onClick={selectUserHandler}
                >
                    Vedi profilo
                </IconTextButton>
            </div>
        </div>
    );
};

export default UserInfo;

