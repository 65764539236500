import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FormControl, MenuItem, Select, SxProps, Theme } from '@mui/material';
import { FC } from 'react';
import classes from './GenericSelect.module.scss';

type Option = {
    id: string;
    label?: string;
    item?: JSX.Element;
    itemOnClick?: () => void;
};
type Value = string | string[] | number;

type GenericSelectprops = FC<{
    options: Option[];
    value?: Value;
    onChange?: (e: any) => void;
    defaultValue?: string;
    register?: any;
    placeholder?: string;
    sx?: SxProps<Theme>;
    sxFormControl?: SxProps<Theme>;
    disabled?: boolean;
    renderValue?: any;
    multiple?: boolean;
    className?: string;
    error?: boolean;
}>;

const GenericSelect: GenericSelectprops = ({
    options,
    value,
    onChange,
    defaultValue,
    register,
    placeholder,
    sx,
    sxFormControl,
    disabled,
    renderValue,
    multiple,
    className,
    error
}) => {
    return (
        <div className={classes.container}>
            <FormControl sx={sxFormControl}>
                <Select
                    className={`${className}`}
                    IconComponent={KeyboardArrowDownIcon}
                    multiple={multiple}
                    renderValue={renderValue}
                    disabled={disabled}
                    defaultValue={defaultValue}
                    value={value}
                    onChange={onChange}
                    {...register}
                    placeholder={options.length === 0 ? 'Non ci sono opzioni selezionabili.' : placeholder}
                    sx={sx}
                    error={error}
                >
                    {options.map((option, index) => (
                        <MenuItem className={classes.options} key={index} value={option.id} onClick={option.itemOnClick}>
                            {option.label ? option.label : option.item ? option.item : 'Nessuna opzione'}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default GenericSelect;

