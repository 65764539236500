import { Checkbox, ListItemText } from '@mui/material';
import { CheckBoxChecked, CheckBoxUnchecked } from 'components/icons';
import GenericSelect from 'components/select/GenericSelect';
import { BILLING_FILTERS } from 'helpers';
import { FC, useMemo } from 'react';
import classes from './BillingFilters.module.scss';

type BillingFilterProps = FC<{
    activeFilters: string[];
    setActiveFilters: any;
}>;

const BillingFilters: BillingFilterProps = ({ activeFilters, setActiveFilters }) => {
    const selectOptions = useMemo(() => {
        return Object.keys(BILLING_FILTERS).map(key => ({
            id: key,
            item: (
                <>
                    <Checkbox
                        checked={activeFilters.indexOf(key) > -1}
                        icon={<CheckBoxUnchecked />}
                        checkedIcon={<CheckBoxChecked />}
                    />
                    <ListItemText primary={BILLING_FILTERS[key]} />
                </>
            )
        }));
    }, [activeFilters]);

    return (
        <div className={classes.container}>
            <label className="small">
                <b>Fatture</b>
            </label>
            <GenericSelect
                placeholder="Utenze con fatturazione associata"
                multiple
                value={activeFilters}
                onChange={(selectedFilters: any) => setActiveFilters('billing', selectedFilters.target.value)}
                renderValue={(selected: any) => selected.map((key: any) => BILLING_FILTERS[key]).join(', ')}
                options={selectOptions}
                sxFormControl={{ width: '21rem !important' }}
            />
        </div>
    );
};

export default BillingFilters;

