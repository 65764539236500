export const NotificationsFilled = () => {
    return (
        <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.0321 0.5C20.84 0.5 19.0645 2.28925 19.0645 4.49833C19.0645 6.70741 20.84 8.49667 23.0321 8.49667C25.2243 8.49667 26.9998 6.70741 26.9998 4.49833C26.9998 2.28925 25.2243 0.5 23.0321 0.5Z"
                fill="#FBFF36"
            />
            <path
                d="M17.5768 4.49834C17.5768 3.97855 17.6562 3.47876 17.7851 2.99896H2.80713C1.25974 2.99896 0 4.26843 0 5.82778V16.9432C0 18.5025 1.25974 19.772 2.80713 19.772H17.4776L22.7546 24.3201C22.8935 24.44 23.0621 24.5 23.2407 24.5C23.3498 24.5 23.449 24.48 23.5482 24.43C23.816 24.3101 23.9846 24.0402 23.9846 23.7503V9.91608C23.6771 9.97606 23.3597 10.006 23.0324 10.006C20.0268 10.006 17.5768 7.53707 17.5768 4.50833V4.49834ZM11.2484 6.88734H12.7362V12.8848H11.2484V6.88734ZM11.9923 16.1235C11.4467 16.1235 11.0004 15.6737 11.0004 15.1239C11.0004 14.5741 11.4467 14.1243 11.9923 14.1243C12.5379 14.1243 12.9842 14.5741 12.9842 15.1239C12.9842 15.6737 12.5379 16.1235 11.9923 16.1235Z"
                fill="#FBFF36"
            />
        </svg>
    );
};
