import BlacklistSwitchButton from 'components/buttons/blacklistSwitchButton/BlacklistSwitchButton';
import classes from './ModalEnabledArea.module.scss';
import TextButton from 'components/buttons/textButton/TextButton';
import Modal from 'components/modal/Modal';
import { SetState } from 'helpers';
import { Area } from 'models';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, municipalityActions } from 'store';
import { useLazyUpdateAreaStatusQuery } from 'store/rtk/area.service';

type ModalEnableAreaProps = FC<{
    isDisabledFor: string;
    setIsDisabledFor: SetState<string>;
}>;

const ModalEnableArea: ModalEnableAreaProps = ({ setIsDisabledFor, isDisabledFor }) => {
    const [text, setText] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const { selectedArea } = useSelector((state: RootState) => state.municipalities);
    const [updateAreaStatus] = useLazyUpdateAreaStatusQuery();
    const dispatch = useDispatch();

    const onClickHandler = useCallback(async () => {
        if (!selectedArea) {
            setIsDisabledFor(text);
            setIsOpen(false);
            return;
        }
        const newStatus = !selectedArea.isDisabled;
        const res = await updateAreaStatus({
            id: selectedArea.id,
            isDisabled: newStatus,
            isDisabledFor: text
        });
        if (!res.data) return;
        const updatedArea: Area = { ...selectedArea, isDisabled: res.data.isDisabled };
        dispatch(municipalityActions.setSelectedArea(updatedArea));
        setIsOpen(false);
    }, [selectedArea, updateAreaStatus, text, dispatch, setIsOpen, setIsDisabledFor]);

    const handleMotivationChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value);
    };

    const onCloseHandler = () => {
        setText('');
        setIsOpen(false);
    };

    const onChangeDisableHandler = () => {
        if (!selectedArea) {
            if (isDisabledFor.length > 0) {
                setIsDisabledFor('');
            } else {
                setIsOpen(true);
            }
            return;
        } else {
            if (selectedArea.isDisabled) onClickHandler();
            else setIsOpen(true);
        }
    };

    const isChecked = useMemo(() => {
        if (!selectedArea) {
            return isDisabledFor.length > 0;
        } else return selectedArea.isDisabled;
    }, [selectedArea, isDisabledFor]);

    return (
        <>
            <BlacklistSwitchButton checked={isChecked} onChange={onChangeDisableHandler} label="Area abilitata" />
            <Modal className={classes.modalArea} title="Disabilita area" isOpen={isOpen} handleClose={onCloseHandler}>
                Proseguendo, l’area rimarrà salvata ma verrà resa non disponibile nell’applicazione. Riporta sotto la motivazione
                brevemente, il messaggio verrà allegato all’area e sarà visibile agli utenti.
                <div className={classes.motivation}>
                    <label className="small lightBlue">Motivazione</label>
                    <textarea
                        onChange={handleMotivationChange}
                        maxLength={80}
                        placeholder="Es. Lavori di manutenzione"
                        value={text}
                    />
                </div>
                <div className={classes.buttons}>
                    <TextButton onClick={onCloseHandler} className="secondary">
                        Annulla
                    </TextButton>
                    <TextButton onClick={onClickHandler} className="primary" disabled={text.length === 0}>
                        Conferma
                    </TextButton>
                </div>
            </Modal>
        </>
    );
};
export default ModalEnableArea;

