import { FC } from 'react';
import { ArrowUpSmall, ArrowDownSmall } from '../icons';

type TrendIconProps = {
    pastMonthAmount: number;
    currentMonthAmount: number;
};

const TrendIcon: FC<TrendIconProps> = ({ pastMonthAmount, currentMonthAmount }) => {
    if (currentMonthAmount === null && pastMonthAmount === null) return <></>;
    if (pastMonthAmount === null && currentMonthAmount && currentMonthAmount > 0) return <ArrowUpSmall fill="#86F0AA" />;
    if (currentMonthAmount === null && pastMonthAmount && pastMonthAmount > 0) return <ArrowDownSmall fill="#FB9AA0" />;
    if (currentMonthAmount > pastMonthAmount) return <ArrowUpSmall fill="#86F0AA" />;
    if (currentMonthAmount < pastMonthAmount) return <ArrowDownSmall fill="#FB9AA0" />;
    return <></>;
};

export default TrendIcon;

