import { FC, useState } from 'react';
import TextButton from 'components/buttons/textButton/TextButton';
import classes from './DocumentActions.module.scss';
import Modal from 'components/modal/Modal';

type DocumentActionsProps = {
    setIsModalOpen: (open: boolean) => void;
    handleCreateDocument: () => void;
    isDisabled: boolean;
};

const DocumentActions: FC<DocumentActionsProps> = ({ setIsModalOpen, handleCreateDocument, isDisabled }) => {
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    return (
        <>
            <div className={classes.buttonsWrap}>
                <TextButton className="tertiary" onClick={() => setIsModalOpen(false)}>
                    Annulla
                </TextButton>
                <TextButton className="primary" disabled={isDisabled} onClick={() => setIsConfirmModalOpen(true)}>
                    Propaga
                </TextButton>
            </div>
            <Modal
                title="Conferma propagazione documento"
                handleClose={() => setIsConfirmModalOpen(false)}
                isOpen={isConfirmModalOpen}
            >
                <div className={classes.confirmModal}>
                    Propagando non c'è possibilità di cancellare il documento. Sicuri di voler procedere?
                    <div className={classes.buttonsModalWrap}>
                        <TextButton className="tertiary" onClick={() => setIsConfirmModalOpen(false)}>
                            Annulla
                        </TextButton>
                        <TextButton
                            className="primary"
                            onClick={() => {
                                handleCreateDocument();
                                setIsConfirmModalOpen(false);
                            }}
                        >
                            Conferma
                        </TextButton>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default DocumentActions;

