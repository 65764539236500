export const Stairway = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.75 19.5H4.75C4.34 19.5 4 19.16 4 18.75V11.75C4 11.34 4.34 11 4.75 11H11V4.75C11 4.34 11.34 4 11.75 4H18.75C19.16 4 19.5 4.34 19.5 4.75V18.75C19.5 19.16 19.16 19.5 18.75 19.5Z"
                fill="#8DBEF9"
            />
        </svg>
    );
};
