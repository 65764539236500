import { FC, useCallback } from 'react';
import classes from './TablesToggle.module.scss';
import { IconTextButton } from 'components/buttons';
import { Parking, Profile, Timer } from 'components/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, TableTypes, areasActions, tabActions, usersActions, parkingActions } from 'store';

const TablesToggle: FC = () => {
    const { activeTable } = useSelector((x: RootState) => x.tab);
    const dispatch = useDispatch();

    const onClickHandler = useCallback(
        (value: TableTypes) => {
            if (value === activeTable) return;
            dispatch(tabActions.setActiveTable(value));
            dispatch(usersActions.setSelectedUser(undefined));
            dispatch(areasActions.setSelectedArea(undefined));
            dispatch(parkingActions.setSelectedParking(undefined));
        },
        [activeTable, dispatch]
    );

    return (
        <div className={classes.container}>
            <div className={classes.buttons}>
                <IconTextButton
                    icon={<Parking />}
                    className={activeTable === TableTypes.areas ? classes.active : ''}
                    onClick={() => onClickHandler(TableTypes.areas)}
                >
                    Aree
                </IconTextButton>
                <IconTextButton
                    icon={<Timer />}
                    className={activeTable === TableTypes.stops ? classes.active : ''}
                    onClick={() => onClickHandler(TableTypes.stops)}
                >
                    Soste
                </IconTextButton>
                <IconTextButton
                    icon={<Profile />}
                    className={activeTable === TableTypes.users ? classes.active : ''}
                    onClick={() => onClickHandler(TableTypes.users)}
                >
                    Utenti
                </IconTextButton>
            </div>
        </div>
    );
};

export default TablesToggle;
