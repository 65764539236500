import Modal from 'components/modal/Modal';
import Snackbar from 'components/snackbar/Snackbar';
import { SetState } from 'helpers';
import { FC } from 'react';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, municipalityActions, snackbarActions } from 'store';
import { useGetAllMunicipalitiesQuery, useLazyEditMunicipalityQuery } from 'store/rtk/municipality.service';
import EditMunicipalityForm from './editMunicipalityForm/EditMunicipalityForm';

type EditMunicipalityModalProps = FC<{ isOpen: boolean; setIsOpen: SetState<boolean> }>;

const EditMunicipalityModal: EditMunicipalityModalProps = ({ isOpen, setIsOpen }) => {
    const { selectedMunicipality } = useSelector((x: RootState) => x.municipalities);
    const { refetch } = useGetAllMunicipalitiesQuery();
    const [editMunicipality, { isFetching }] = useLazyEditMunicipalityQuery();
    const dispatch = useDispatch();

    const onSubmit: SubmitHandler<FieldValues> = async city => {
        if (!city.percentageFeeCheckbox || isNaN(city.percentageFees)) city.percentageFees = 0;
        if (!city.fixedFeeCheckbox || isNaN(city.fixedFees)) city.fixedFees = 0;
        if (!selectedMunicipality || isFetching) return;
        const { fixedFees, percentageFees, vatOff, ticketRequired } = city;
        const parsedFixedFees = parseFloat(fixedFees);
        const parsedPercentageFees = parseFloat(percentageFees);
        console.log(ticketRequired);
        if (isNaN(parsedFixedFees) || isNaN(parsedPercentageFees)) {
            dispatch(snackbarActions.setMessageAndType({ type: 'error', message: `Input fees non valido.` }));
            dispatch(snackbarActions.setIsOpen(true));
            return;
        }

        const res = await editMunicipality({
            id: selectedMunicipality.id,
            vatOff,
            fixedFees: parsedFixedFees,
            percentageFees: parsedPercentageFees,
            ticketRequired: ticketRequired
        });

        if (!res.data) return;
        refetch();
        const updatedMunicipality = { ...selectedMunicipality, ...res.data };
        dispatch(municipalityActions.setSelectedMunicipality(updatedMunicipality));
        dispatch(snackbarActions.setMessageAndType({ message: `Città modificata con successo.` }));
        dispatch(snackbarActions.setIsOpen(true));
        setIsOpen(false);
    };

    return (
        <>
            <Modal title="Modifica città" isOpen={isOpen} handleClose={() => setIsOpen(false)}>
                <EditMunicipalityForm onSubmit={onSubmit} selectedMunicipality={selectedMunicipality} setIsOpen={setIsOpen} />
            </Modal>
            <Snackbar />
        </>
    );
};

export default EditMunicipalityModal;

