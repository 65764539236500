import { IconButton, IconTextButton } from 'components/buttons';
import Dashboard from 'components/dashboard/Dashboard';
import { ArrowBack, Profile } from 'components/icons';
import { DashboardProps } from 'helpers';
import { usePermissionHandler } from 'hook';
import { BillingStatus, UserRoles } from 'models';
import moment from 'moment';
import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AuthState,
    ParkingState,
    RootState,
    TabTypes,
    TableTypes,
    drawActions,
    parkingActions,
    tabActions,
    usersActions
} from 'store';
import { useGetParkingDetailDashboardInfoQuery, useGetParkingDetailQuery } from 'store/rtk/parking.service';
import classes from './ParkingDetail.module.scss';
import Billing from './billing/Billing';
import Parking from './parking/Parking';
import Payment from './payment/Payment';

type ParkingDetailProps = FC<{
    refetch: () => void;
}>;

const ParkingDetail: ParkingDetailProps = ({ refetch }) => {
    const { selectedParking } = useSelector<RootState>(x => x.parking) as ParkingState;
    const { user } = useSelector<RootState>(x => x.auth) as AuthState;
    const dispatch = useDispatch();
    const { data: dashboardInfo } = useGetParkingDetailDashboardInfoQuery({ id: selectedParking! });
    const { data, refetch: refetchParkingDetails } = useGetParkingDetailQuery({ id: selectedParking! });
    const hasPermission = usePermissionHandler();

    const dashboardValues: DashboardProps[] = useMemo(() => {
        let averageDuration: string;
        if (!dashboardInfo?.totalTime) averageDuration = '0h 0m';
        else {
            const start = moment(data?.ticket.startTime).seconds(0).milliseconds(0);
            const end = moment(data?.ticket.finishTime).seconds(0).milliseconds(0);
            const duration = moment.duration(end.diff(start));
            averageDuration = `${duration.hours()}h ${duration.minutes()}m`;
        }
        return [
            { label: 'Tempo di sosta', value: averageDuration },
            {
                label: 'Incasso Totale',
                value: dashboardInfo?.amount ? `${dashboardInfo?.amount.toFixed(2).replace('.', ',')}€` : '0,00€'
            },
            user?.role === UserRoles.municipalityEmployee
                ? {
                      label: '',
                      value: ''
                  }
                : {
                      label: 'Commissione Muvin',
                      value: dashboardInfo?.fees ? `${dashboardInfo?.fees.toFixed(2).replace('.', ',')}€` : '0,00€'
                  }
        ];
    }, [dashboardInfo, data, user]);

    const onClickHandler = () => {
        dispatch(usersActions.setSelectedUser(data?.ticket.userId));
        dispatch(tabActions.setActiveTable(TableTypes.users));
    };

    const parking = useMemo(
        () =>
            !data?.ticket.code && !data?.ticket.name
                ? '***'
                : `${data?.ticket.code} -${' '}
            ${data?.ticket.name}`,
        [data?.ticket]
    );

    useEffect(() => {
        if (data && data.ticket && data.ticket.geometry) dispatch(drawActions.zoomOnBbox(data.ticket.geometry));
    }, [data, dispatch]);

    if (!data) return <></>;

    return (
        <>
            <div className={classes.dashboard}>
                <Dashboard props={dashboardValues} />
            </div>
            <div className={classes.container}>
                <div className={classes.header}>
                    <div className={classes.backButton}>
                        <IconButton
                            className="tertiary"
                            icon={<ArrowBack />}
                            onClick={() => dispatch(parkingActions.setSelectedParking(undefined))}
                        />
                        <div>
                            <p>
                                <b>{parking}</b>
                            </p>
                            <span className="primary90">{data?.ticket.plate} - 1048DF12</span>
                        </div>
                    </div>
                    <IconTextButton
                        disabled={!hasPermission(TabTypes.home, ['can-edit'])}
                        className="secondary"
                        onClick={onClickHandler}
                        icon={<Profile />}
                    >
                        Vedi Profilo
                    </IconTextButton>
                </div>
                <Parking parkingInfo={data.ticket} />
                <Payment
                    parkingInfo={data.ticket}
                    refetchParkingDetails={refetchParkingDetails}
                    refetchParkingList={refetch}
                    paymentInfo={data.paymentInfo}
                />
                {data.ticket.billingStatus !== BillingStatus.none && <Billing data={data} />}
            </div>
        </>
    );
};

export default ParkingDetail;

