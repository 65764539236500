import Accordion from 'components/accordion/Accordion';
import AccordionTypography from 'components/accordionTypography/AccordionTypography';
import classes from './Tariff.module.scss';
import { FC } from 'react';

type TariffProps = FC<{
    tariffName: string;
    className?: string;
}>;

const Tariff: TariffProps = ({ tariffName, className }) => {
    if (!tariffName) return <></>;

    return (
        <div className={className}>
            <Accordion title="Tariffa">
                <AccordionTypography className={classes.field}>
                    <p>{tariffName}</p>
                </AccordionTypography>
            </Accordion>
        </div>
    );
};

export default Tariff;

