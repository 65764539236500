import { FC } from 'react';

export const Denied: FC = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 0C2.69 0 0 2.69 0 6C0 9.31 2.69 12 6 12C9.31 12 12 9.31 12 6C12 2.69 9.31 0 6 0ZM1.5 6C1.5 5.03 1.82 4.14 2.34 3.4L8.6 9.66C7.86 10.18 6.97 10.5 6 10.5C3.52 10.5 1.5 8.48 1.5 6ZM9.66 8.6L3.4 2.34C4.14 1.82 5.03 1.5 6 1.5C8.48 1.5 10.5 3.52 10.5 6C10.5 6.97 10.18 7.86 9.66 8.6Z"
                fill="#FB9AA0"
            />
        </svg>
    );
};

