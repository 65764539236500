import { RadioChecked } from 'components/icons';
import { FlyToInterpolator } from 'deck.gl/typed';

export const MAPBOX_TOKEN = 'pk.eyJ1IjoiYXRlbXBlcmluaSIsImEiOiJja3JwM3R2bXQxZDMwMnFudmNqazRxdXJxIn0.U6aWeL1odq_lPFw6NbiPWw';
export const DEFAULT_INITIAL_VIEW_STATE = {
    latitude: 42.592712559585706,
    longitude: 12.761355238724022,
    zoom: 5,
    maxPitch: 85,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
    minZoom: 5
};
export const DEFAULT_LAYER_DATA = {
    type: 'FeatureCollection',
    name: 'polygon',
    crs: {
        type: 'name',
        properties: { name: 'urn:ogc:def:crs:EPSG::6706' }
    },
    features: [] as any[]
};

export const DEFAULT_FAKE_CUSTOMER_SERVICE = {
    User: {
        first_name: '',
        last_name: '',
        status: ''
    },
    id: 'last-one',
    title: '',
    description: '',
    createdAt: new Date(),
    status: ''
};
export const DEFAULT_FAKE_MUNICIPALITY = {
    id: 'last-one',
    name: '',
    numberTariff: '',
    numberAreas: ''
};
export const DEFAULT_FAKE_BILLING = {
    id: 'last-one',
    businessName: '',
    numberOfUsers: '',
    totalAmount: 0,
    totalBillings: '',
    unbilled: ''
};
export const DEFAULT_USERS_FILTER_VALUES = {
    vehicleTypes: [],
    billing: [],
    blacklist: [],
    paymentMethods: [],
    inProgress: 'all'
};
export const DEFAULT_AREAS_FILTER_VALUES = { city: null, date: { startDate: 0, endDate: 0 }, inProgress: 'all' };
export const DEFAULT_MUNICIPALITY_AREAS_FILTER_VALUES = { inProgress: 'all', status: 'all' };
export const DEFAULT_CUSTOMER_SERVICES_FILTER_VALUES = {
    date: { startDate: 0, endDate: 0 },
    blacklist: 'all',
    readStatus: 'all',
    requestStatus: 'all'
};
export const DEFAULT_PARKING_FILTER_VALUES = {
    vehicleTypes: [],
    paymentState: [],
    billingState: [],
    paymentValueRange: [0, 100],
    city: null,
    date: { startDate: 0, endDate: 0 },
    activeParking: 'all',
    inProgress: 'all'
};
export const MS_CONVERTER = {
    day: 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
    month: 30 * 24 * 60 * 60 * 1000,
    year: 365 * 24 * 60 * 60 * 1000
};
export const DEFAULT_BILLS_FILTER_VALUES = {
    onlyUnbilled: false,
    date: { startDate: 0, endDate: 0 },
    totalUsers: { min: '', max: '' },
    totalBillings: { min: '', max: '' }
};
export const numberTest = /^-?\d{0,5}$/;
export const PAYMENT_STATUS_ICON = { paid: RadioChecked };
export const DEFAULT_SLOPE_VALUE = {
    slopeType: '',
    amount: '',
    days: '',
    hours: '',
    minutes: '',
    maxDays: '',
    maxHours: '',
    maxMinutes: '',
    price: '',
    fromDays: '',
    fromHours: '',
    fromMinutes: '',
    toDays: '',
    toHours: '',
    toMinutes: '',
    fromHour: '',
    toHour: '',
    endOfDay: false,
    multiplier: false
};
export const DAY_IN_MS = 86400000;
export const HOLIDAY_DAYS = ['1 gen', '6 gen', '25 apr', '1 mag', '2 giu', '15 ago', '1 nov', '8 dic', '25 dic', '26 dic'];
export const BEFORE_HOLIDAY_DAYS = ['5 gen', '24 apr', '30 apr', '1 giu', '14 ago', '31 ott', '7 dic', '24 dic', '31 dic'];
export const ROLES = {
    configurator: 'configurator',
    municipalityEmployee: 'municipalityEmployee',
    accountingOffice: 'accountingOffice',
    superAdmin: 'superAdmin'
};

export const SCOPES = {
    canCreate: 'can-create',
    canEdit: 'can-edit',
    canDelete: 'can-delete',
    canView: 'can-view',
    canExport: 'can-export'
};

export const PERMISSIONS = {
    [ROLES.configurator]: { municipalitySettings: [SCOPES.canView, SCOPES.canCreate, SCOPES.canEdit] },
    [ROLES.accountingOffice]: {
        home: [SCOPES.canView, SCOPES.canExport],
        billing: [SCOPES.canView],
        municipalitySettings: [SCOPES.canView],
        settings: [SCOPES.canView],
        customerService: [SCOPES.canView]
    },
    [ROLES.municipalityEmployee]: { home: [SCOPES.canView, SCOPES.canExport] },
    [ROLES.superAdmin]: {
        home: [SCOPES.canView, SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete],
        billing: [SCOPES.canView, SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete],
        municipalitySettings: [SCOPES.canView, SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete],
        settings: [SCOPES.canView, SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete],
        customerService: [SCOPES.canView, SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete]
    }
};

export const BILLING_FILTERS: Record<string, string> = { none: 'Non richiesta', pending: 'In sospeso', issued: 'Evase' };

export const BLACKLIST_FILTERS: Record<string, string> = {
    disabled: 'Disabilitato',
    enabled: 'Abilitato',
    blacklisted: 'Blacklisted'
};

export const PAYMENT_METHODS_FILTER: Record<string, string> = { visa: 'Visa', mastercard: 'Mastercard', maestro: 'Maestro' };

export const validCountryCodes = [
    'AF',
    'AX',
    'AL',
    'DZ',
    'AS',
    'AD',
    'AO',
    'AI',
    'AQ',
    'AG',
    'AR',
    'AM',
    'AW',
    'AU',
    'AT',
    'AZ',
    'BS',
    'BH',
    'BD',
    'BB',
    'BY',
    'BE',
    'BZ',
    'BJ',
    'BM',
    'BT',
    'BO',
    'BQ',
    'BA',
    'BW',
    'BV',
    'BR',
    'IO',
    'BN',
    'BG',
    'BF',
    'BI',
    'CV',
    'KH',
    'CM',
    'CA',
    'KY',
    'CF',
    'TD',
    'CL',
    'CN',
    'CX',
    'CC',
    'CO',
    'KM',
    'CG',
    'CD',
    'CK',
    'CR',
    'CI',
    'HR',
    'CU',
    'CW',
    'CY',
    'CZ',
    'DK',
    'DJ',
    'DM',
    'DO',
    'EC',
    'EG',
    'SV',
    'GQ',
    'ER',
    'EE',
    'SZ',
    'ET',
    'FK',
    'FO',
    'FJ',
    'FI',
    'FR',
    'GF',
    'PF',
    'TF',
    'GA',
    'GM',
    'GE',
    'DE',
    'GH',
    'GI',
    'GR',
    'GL',
    'GD',
    'GP',
    'GU',
    'GT',
    'GG',
    'GN',
    'GW',
    'GY',
    'HT',
    'HM',
    'VA',
    'HN',
    'HK',
    'HU',
    'IS',
    'IN',
    'ID',
    'IR',
    'IQ',
    'IE',
    'IM',
    'IL',
    'IT',
    'JM',
    'JP',
    'JE',
    'JO',
    'KZ',
    'KE',
    'KI',
    'KP',
    'KR',
    'KW',
    'KG',
    'LA',
    'LV',
    'LB',
    'LS',
    'LR',
    'LY',
    'LI',
    'LT',
    'LU',
    'MO',
    'MG',
    'MW',
    'MY',
    'MV',
    'ML',
    'MT',
    'MH',
    'MQ',
    'MR',
    'MU',
    'YT',
    'MX',
    'FM',
    'MD',
    'MC',
    'MN',
    'ME',
    'MS',
    'MA',
    'MZ',
    'MM',
    'NA',
    'NR',
    'NP',
    'NL',
    'NC',
    'NZ',
    'NI',
    'NE',
    'NG',
    'NU',
    'NF',
    'MK',
    'MP',
    'NO',
    'OM',
    'PK',
    'PW',
    'PS',
    'PA',
    'PG',
    'PY',
    'PE',
    'PH',
    'PN',
    'PL',
    'PT',
    'PR',
    'QA',
    'RE',
    'RO',
    'RU',
    'RW',
    'BL',
    'SH',
    'KN',
    'LC',
    'MF',
    'PM',
    'VC',
    'WS',
    'SM',
    'ST',
    'SA',
    'SN',
    'RS',
    'SC',
    'SL',
    'SG',
    'SX',
    'SK',
    'SI',
    'SB',
    'SO',
    'ZA',
    'GS',
    'SS',
    'ES',
    'LK',
    'SD',
    'SR',
    'SJ',
    'SE',
    'CH',
    'SY',
    'TW',
    'TJ',
    'TZ',
    'TH',
    'TL',
    'TG',
    'TK',
    'TO',
    'TT',
    'TN',
    'TR',
    'TM',
    'TC',
    'TV',
    'UG',
    'UA',
    'AE',
    'GB',
    'US',
    'UM',
    'UY',
    'UZ',
    'VU',
    'VE',
    'VN',
    'VG',
    'VI',
    'WF',
    'EH',
    'YE',
    'ZM',
    'ZW'
];
