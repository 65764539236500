import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import TextButton from 'components/buttons/textButton/TextButton';
import Modal from 'components/modal/Modal';
import { FC, useEffect, useMemo, useState } from 'react';
import classes from './WeeklyRepetitionModal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, createTariffRuleActions } from 'store';

const DAYS = [
    { label: 'L', value: 'monday' },
    { label: 'M', value: 'tuesday' },
    { label: 'M', value: 'wednesday' },
    { label: 'G', value: 'thursday' },
    { label: 'V', value: 'friday' },
    { label: 'S', value: 'saturday' },
    { label: 'D', value: 'sunday' }
];

const WeeklyRepetitionModal: FC = () => {
    const { weekly, validityPeriod, isRepetitionModalOpen } = useSelector((x: RootState) => x.createTariffRule);
    const { selectedRule } = useSelector((x: RootState) => x.tariffs);
    const [selectedDays, setSelectedDays] = useState<string[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setSelectedDays(weekly);
    }, [weekly]);

    const mappedDays = useMemo(
        () =>
            DAYS.map(day => (
                <ToggleButton className={classes.ToggleButton} value={day.value} key={day.value}>
                    {day.label}
                </ToggleButton>
            )),
        []
    );

    const onChangeHandler = (_event: React.MouseEvent<HTMLElement>, newFormats: string[]) => setSelectedDays(newFormats);

    const onCloseHandler = () => {
        dispatch(createTariffRuleActions.setIsRepetitionModalOpen(false));
        if (weekly.length > 0) return;
        dispatch(
            createTariffRuleActions.setValidityPeriodValues({
                key: 'repetitionType',
                value: 'always'
            })
        );
    };

    const onSaveHandler = () => {
        dispatch(createTariffRuleActions.setWeekly(selectedDays));
        dispatch(createTariffRuleActions.setIsRepetitionModalOpen(false));
        if (selectedDays.length > 0) return;
        dispatch(
            createTariffRuleActions.setValidityPeriodValues({
                key: 'repetitionType',
                value: 'always'
            })
        );
    };

    useEffect(() => {
        if (!selectedRule) return;
        dispatch(createTariffRuleActions.setWeekly(selectedRule.days));
    }, [dispatch, selectedRule]);

    return (
        <Modal
            handleClose={onCloseHandler}
            isOpen={validityPeriod.repetitionType === 'weekly' && isRepetitionModalOpen}
            title="Ripetizione ogni settimana"
            customClasses={classes.weekModal}
        >
            <label>Seleziona i giorni</label>
            <ToggleButtonGroup className={classes.toggleGroupButton} value={selectedDays} onChange={onChangeHandler}>
                {mappedDays}
            </ToggleButtonGroup>
            <div className={classes.buttonContainer}>
                <TextButton className={`secondary ${classes.customButton}`} onClick={onCloseHandler}>
                    Annulla
                </TextButton>
                <TextButton
                    disabled={selectedDays.length === 0}
                    onClick={onSaveHandler}
                    className={`primary ${classes.customButton}`}
                >
                    Salva
                </TextButton>
            </div>
        </Modal>
    );
};

export default WeeklyRepetitionModal;

