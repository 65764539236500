import { Checkbox } from '@mui/material';
import TextButton from 'components/buttons/textButton/TextButton';
import { CheckBoxChecked, CheckBoxUnchecked } from 'components/icons';
import GenericSelect from 'components/select/GenericSelect';
import { MunicipalityCounter } from 'models';
import { FC } from 'react';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import classes from './EditMunicipalityForm.module.scss';
import { SetState } from 'helpers';

type EditMunicipalityFormProps = FC<{
    onSubmit: SubmitHandler<FieldValues>;
    selectedMunicipality?: MunicipalityCounter;
    setIsOpen: SetState<boolean>;
}>;

const OPTIONS = [
    { id: 'complete', label: 'IVA Standard' },
    { id: 'noIva', label: 'Fuori campo IVA' },
    { id: 'none', label: 'Non fatturabile' }
];

const EditMunicipalityForm: EditMunicipalityFormProps = ({ onSubmit, selectedMunicipality, setIsOpen }) => {
    const {
        register,
        handleSubmit,
        control,
        reset,
        watch,
        formState: { errors, dirtyFields }
    } = useForm({
        mode: 'all',
        defaultValues: {
            name: selectedMunicipality?.name,
            orderCode: selectedMunicipality?.orderCode,
            vatOff: selectedMunicipality?.vatOff,
            percentageFeeCheckbox: selectedMunicipality?.percentageFees ? true : false,
            fixedFeeCheckbox: selectedMunicipality?.fixedFees ? true : false,
            percentageFees: selectedMunicipality?.percentageFees,
            fixedFees: selectedMunicipality?.fixedFees,
            ticketRequired: selectedMunicipality?.ticketRequired
        }
    });

    const values = watch();

    return (
        <form className={classes.formContainer} onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.block}>
                <label htmlFor="name">Nome città</label>
                <input
                    disabled
                    className={`${classes.textInput} ${errors.name && dirtyFields.name ? classes.error : ''}`}
                    type="text"
                    id="name"
                    placeholder="es. Roma"
                    {...register('name', { required: false })}
                />
            </div>
            <div className={classes.block}>
                <label htmlFor="orderCode">Codice commessa</label>
                <input
                    disabled
                    className={`${classes.textInput} ${errors.orderCode && dirtyFields.orderCode ? classes.error : ''}`}
                    type="text"
                    id="orderCode"
                    placeholder="es. 01234"
                    {...register('orderCode', { required: false })}
                />
            </div>
            <div className={classes.block}>
                <label>IVA</label>
                <GenericSelect
                    options={OPTIONS}
                    placeholder="Seleziona"
                    defaultValue=""
                    value={values.vatOff || ''}
                    register={{ ...register('vatOff', { required: true }) }}
                    error={errors.vatOff && dirtyFields.vatOff}
                />
            </div>
            <div className={classes.blockWithCheckbox}>
                <div className={classes.checkbox}>
                    <Controller
                        name="percentageFeeCheckbox"
                        control={control}
                        render={({ field }) => (
                            <Checkbox
                                defaultChecked={selectedMunicipality?.percentageFees ? true : false}
                                icon={<CheckBoxUnchecked />}
                                checkedIcon={<CheckBoxChecked />}
                                sx={{
                                    '&.Mui-disabled': {
                                        opacity: 0.5
                                    }
                                }}
                                {...field}
                            />
                        )}
                    />
                    <label className={classes.checkboxLabel} htmlFor="percentageFeeCheckbox">
                        Fee percentuale
                    </label>
                </div>
                <div className={classes.fieldOfCheckbox}>
                    <label htmlFor="percentageFees">Fee (%)</label>
                    <input
                        className={`${classes.textInput} ${
                            errors.percentageFees && dirtyFields.percentageFees ? classes.error : ''
                        }`}
                        type="number"
                        step={0.01}
                        id="percentageFees"
                        placeholder="es. 10"
                        {...register('percentageFees', {
                            required: false,
                            validate: value => !isNaN(value ? value : 0) || !values.percentageFeeCheckbox || value === 0
                        })}
                        disabled={!values.percentageFeeCheckbox}
                    />
                </div>
            </div>
            <div className={classes.blockWithCheckbox}>
                <div className={classes.checkbox}>
                    <Controller
                        name="fixedFeeCheckbox"
                        control={control}
                        render={({ field }) => (
                            <Checkbox
                                defaultChecked={selectedMunicipality?.fixedFees ? true : false}
                                icon={<CheckBoxUnchecked />}
                                checkedIcon={<CheckBoxChecked />}
                                sx={{
                                    '&.Mui-disabled': {
                                        opacity: 0.5
                                    }
                                }}
                                {...field}
                            />
                        )}
                    />
                    <label className={classes.checkboxLabel} htmlFor="fixedFeeCheckbox">
                        Fee fissa
                    </label>
                </div>
                <div className={classes.fieldOfCheckbox}>
                    <label htmlFor="fixedFees">Costo (Euro)</label>
                    <input
                        className={`${classes.textInput} ${errors.fixedFees && dirtyFields.fixedFees ? classes.error : ''}`}
                        type="number"
                        step={0.01}
                        id="fixedFees"
                        placeholder="es. 0.5"
                        {...register('fixedFees', {
                            required: false,
                            validate: value => !isNaN(value ? value : 0) || !values.fixedFeeCheckbox || value === 0
                        })}
                        disabled={!values.fixedFeeCheckbox}
                    />
                </div>
            </div>
            <div className={classes.block}>
                <div className={classes.checkbox}>
                    <Controller
                        name="ticketRequired"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                            <Checkbox
                                defaultChecked={selectedMunicipality?.ticketRequired ? true : false}
                                icon={<CheckBoxUnchecked />}
                                checkedIcon={<CheckBoxChecked />}
                                sx={{
                                    '&.Mui-disabled': {
                                        opacity: 0.5
                                    }
                                }}
                                {...field}
                            />
                        )}
                    />
                    <label className={classes.checkboxLabel} htmlFor="ticketRequired">
                        Necessario ticket MUVIN sul parabrezza
                    </label>
                </div>
            </div>
            <div className={classes.buttons}>
                <TextButton
                    className="tertiary"
                    type="button"
                    onClick={() => {
                        reset();
                        setIsOpen(false);
                    }}
                >
                    Annulla
                </TextButton>
                <TextButton className="primary" type="submit" disabled={!values.name || !values.vatOff}>
                    Conferma
                </TextButton>
            </div>
        </form>
    );
};

export default EditMunicipalityForm;

