import { SvgIconProps } from 'helpers';

export const Plus: SvgIconProps = ({ fill = '#010E1E' }) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="plus" clipPath="url(#clip0_1375_771)">
                <path id="plus_36x36" d="M10.25 22V11.75H0V10.25H10.25V0H11.75V10.25H22V11.75H11.75V22H10.25Z" fill={fill} />
            </g>
            <defs>
                <clipPath id="clip0_1375_771">
                    <rect width="22" height="22" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

