import { SvgIconProps } from 'helpers';

export const UserIcon: SvgIconProps = ({ fill }) => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.0084 17.0411C21.1167 17.0411 23.65 14.5604 23.65 11.5165C23.65 8.47263 21.1167 6 18.0084 6C14.9 6 12.3667 8.48079 12.3667 11.5247C12.3667 14.5685 14.9 17.0493 18.0084 17.0493V17.0411ZM18.0084 7.21591C20.4334 7.21591 22.4 9.14179 22.4 11.5165C22.4 13.8912 20.4334 15.8171 18.0084 15.8171C15.5834 15.8171 13.6167 13.8912 13.6167 11.5165C13.6167 9.14179 15.5834 7.21591 18.0084 7.21591Z"
                fill="#1072E7"
            />
            <path
                d="M18.0083 19.3994C14.1417 19.3994 11 22.4759 11 26.2624V29.9999H12.2417V26.2624C12.2417 23.1532 14.825 20.6235 18 20.6235C21.175 20.6235 23.7583 23.1532 23.7583 26.2624V29.9999H25V26.2624C25 22.4759 21.8583 19.3994 17.9917 19.3994H18.0083Z"
                fill="#1072E7"
            />
        </svg>
    );
};
