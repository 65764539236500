import { SvgIconProps } from 'helpers';

export const Vector: SvgIconProps = ({ fill }) => {
    return (
        <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.5302 12.4697C11.2373 12.1768 10.7625 12.1768 10.4696 12.4697L6.25334 16.6859L7.314 17.7465L10.2499 14.8107L10.2499 22.0811H11.7499L11.7499 14.8107L14.6858 17.7465L15.7464 16.6859L11.5302 12.4697Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.59448 0.25C1.18027 0.25 0.844482 0.585786 0.844482 1V25C0.844482 25.4142 1.18027 25.75 1.59448 25.75H20.4053C20.8195 25.75 21.1553 25.4142 21.1553 25V8.78378C21.1553 8.58709 21.078 8.39827 20.9401 8.25801L13.2883 0.474222C13.1473 0.330788 12.9546 0.25 12.7534 0.25H1.59448ZM12.0034 8.78378C12.0034 9.198 12.3392 9.53378 12.7534 9.53378H19.6553V24.25H2.34448V1.75H12.0034V8.78378ZM18.6163 8.03378L13.5034 2.83277V8.03378H18.6163Z"
                fill={fill}
            />
        </svg>
    );
};

