import { useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { Navigate, useParams } from 'react-router-dom';
import { env } from 'environments';
import classes from './DocumentViewer.module.scss';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs'
).toString();
const DocumentTipology = {
    termsAndConditions: 'termsAndConditions',
    privacyPolicy: 'privacyPolicy',
    marketingAndProfiling: 'marketingAndProfiling'
};

const DocumentViewer = () => {
    const [numPages, setNumPages] = useState<number>(0);
    const { id } = useParams();
    const containerRef = useRef<HTMLDivElement>();

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }
    if (!DocumentTipology[id as keyof typeof DocumentTipology]) return <Navigate to={'/documents'} />;

    return (
        <Document
            onLoadSuccess={onDocumentLoadSuccess}
            file={`${env.reactAppSocketUrl}${env.apiUrl}/documents/last-by-type/${id}`}
            className={classes.document}
        >
            {Array.from(new Array(numPages), (el, index) => {
                return (
                    <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        className={classes.page}
                        width={containerRef.current?.clientWidth}
                    />
                );
            })}
        </Document>
    );
};

export default DocumentViewer;

