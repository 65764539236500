import { PERMISSIONS } from 'helpers';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AuthState, RootState, TabTypes } from 'store';

export const usePermissionHandler = () => {
    const { user } = useSelector<RootState>(x => x.auth) as AuthState;

    const hasPermission = ({ permissions, scopes }: any) => {
        if (!permissions) return false;
        const scopesMap = {};
        scopes.forEach((scope: any) => ((scopesMap as any)[scope] = true));
        return permissions.some((permission: any) => (scopesMap as any)[permission]);
    };

    const getPermissions = useCallback((tab: TabTypes) => (!user?.role ? [] : (PERMISSIONS[user.role] as any)[tab]), [user]);

    const permissionGranted = useCallback(
        (tab: TabTypes, scopes: any) => {
            const permissions = getPermissions(tab);
            return hasPermission({ permissions, scopes });
        },
        [getPermissions]
    );

    return permissionGranted;
};
