import { IconButton, IconTextButton } from 'components/buttons';
import ChipsFilter from 'components/chipsFilter/ChipsFilter';
import CityFilter from 'components/cityFilter/CityFilter';
import Dashboard from 'components/dashboard/Dashboard';
import Divider from 'components/divider/Divider';
import { ArrowBack, ResetFilters } from 'components/icons';
import PeriodOfTimeFilter from 'components/periodOfTimeFilter/PeriodOfTimeFilter';
import RadioButtonFilter from 'components/radioButtonFIlter/RadioButtonFilter';
import VehicleFilter from 'components/vehicleFilter/VehicleFilter';
import deepEqual from 'deep-equal';
import {
    DEFAULT_PARKING_FILTER_VALUES,
    ParkingFilters as ParkingFiltersInterface,
    RadioButtonFilterFields,
    SetState
} from 'helpers';
import { FC, useMemo } from 'react';
import colors from 'styles/modules/colors.module.scss';
import classes from './ParkingFilter.module.scss';
import PaymentFilters from './paymentFilters/PaymentFilters';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

type ParkingFiltersProps = FC<{
    activeFilters: ParkingFiltersInterface;
    setActiveFilters: any;
    resetFilters: SetState<ParkingFiltersInterface>;
    setAreFiltersOpen: SetState<boolean>;
    dashboardValues: any;
}>;

const RADIO_BUTTON_FILTER_FIELDS: RadioButtonFilterFields[] = [
    { label: 'Tutti', value: 'all' },
    { label: 'Solo soste in corso', value: 'inProgress' },
    { label: 'Solo soste terminate', value: 'notInProgress' }
];

const ParkingFilters: ParkingFiltersProps = ({
    dashboardValues,
    activeFilters,
    setActiveFilters,
    resetFilters,
    setAreFiltersOpen
}) => {
    const { city, date } = useSelector((x: RootState) => x.filters);

    const isDisabled = useMemo(
        () =>
            deepEqual(activeFilters, DEFAULT_PARKING_FILTER_VALUES) &&
            city.length === 0 &&
            date.endDate === 0 &&
            date.startDate === 0,
        [activeFilters, city, date]
    );

    return (
        <div className={classes.filtersContainer}>
            <div className={classes.dashboard}>
                <Dashboard props={dashboardValues} />
            </div>
            <div className={classes.filterWrapper}>
                <div className={classes.header}>
                    <div className={classes.title}>
                        <IconButton className="tertiary" icon={<ArrowBack />} onClick={() => setAreFiltersOpen(false)} />
                        <p>
                            <b>Filtri</b>
                        </p>
                    </div>
                    <IconTextButton
                        className="tertiary"
                        disabled={isDisabled}
                        icon={<ResetFilters fill={isDisabled ? colors.primary40 : colors.primary90} />}
                        onClick={resetFilters}
                    >
                        Reset
                    </IconTextButton>
                </div>
            </div>
            <div className={classes.filters}>
                <p> Periodo </p>
                <div className={classes.dateFilter}>
                    <PeriodOfTimeFilter activeFilter={activeFilters.date} setActiveFilter={setActiveFilters} />
                    <ChipsFilter activeFilter={activeFilters.date} setActiveFilter={setActiveFilters} />
                </div>
                <Divider customClasses={classes.divider} />
                <CityFilter
                    className={classes.cityFilter}
                    activeFilter={activeFilters.city}
                    setActiveFilters={setActiveFilters}
                />
                <Divider customClasses={classes.divider} />
                <VehicleFilter
                    activeFilters={activeFilters.vehicleTypes}
                    setActiveFilters={setActiveFilters}
                    className={activeFilters.vehicleTypes.length === 0 ? '' : classes.inputStyle}
                />
                <Divider customClasses={classes.divider} />
                <div className={classes.paymentFilter}>
                    <PaymentFilters activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
                </div>
                <Divider customClasses={classes.lastDivider} />
                <p className={classes.subtitle}>Soste attive</p>
                <div className={classes.radioButtonFIlter}>
                    <RadioButtonFilter
                        label="Soste in corso"
                        fields={RADIO_BUTTON_FILTER_FIELDS}
                        activeFilters={activeFilters.inProgress}
                        setActiveFilter={setActiveFilters}
                        keyValue="inProgress"
                    />
                </div>
            </div>
        </div>
    );
};

export default ParkingFilters;

