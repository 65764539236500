import { Checkbox, ListItemText } from '@mui/material';
import { CheckBoxChecked, CheckBoxUnchecked } from 'components/icons';
import GenericSelect from 'components/select/GenericSelect';
import { PAYMENT_METHODS_FILTER } from 'helpers';
import { FC, useMemo } from 'react';
import classes from './PaymentMethodsFilter.module.scss';

type PaymentMethodsFilterProps = FC<{
    activeFilters: string[];
    setActiveFilters: any;
}>;

const PaymentMethodsFilter: PaymentMethodsFilterProps = ({ activeFilters, setActiveFilters }) => {
    const selectOptions = useMemo(() => {
        return Object.keys(PAYMENT_METHODS_FILTER).map(key => ({
            id: key,
            item: (
                <>
                    <Checkbox
                        checked={activeFilters.indexOf(key) > -1}
                        icon={<CheckBoxUnchecked />}
                        checkedIcon={<CheckBoxChecked />}
                    />
                    <ListItemText primary={PAYMENT_METHODS_FILTER[key]} />
                </>
            )
        }));
    }, [activeFilters]);

    return (
        <div className={classes.container}>
            <label className="small">
                <b>Metodi di pagamento</b>
            </label>
            <GenericSelect
                placeholder="Utenze con metodi associati"
                multiple
                value={activeFilters}
                onChange={(selectedFilters: any) => setActiveFilters('paymentMethods', selectedFilters.target.value)}
                renderValue={(selected: any) => selected.map((key: any) => PAYMENT_METHODS_FILTER[key]).join(', ')}
                options={selectOptions}
                sxFormControl={{ width: '21rem !important' }}
            />
        </div>
    );
};

export default PaymentMethodsFilter;

