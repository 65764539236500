import Routing from './Routing';
import { useLocation, useNavigate } from 'react-router-dom';
import { history } from 'helpers';
import { AxiosProvider } from 'context';
import { Provider } from 'react-redux';
import { store } from 'store';
import './styles/main.scss';

function App() {
    history.navigate = useNavigate();
    history.location = useLocation();

    return (
        <Provider store={store}>
            <AxiosProvider>
                <Routing />
            </AxiosProvider>
        </Provider>
    );
}

export default App;
