import { FC } from 'react';
import classes from './Divider.module.scss';

type DividerProps = FC<{
    customClasses?: string;
}>;
const Divider: DividerProps = ({ customClasses }) => {
    return (
        <div className={classes.dividerContainer}>
            <span className={`${customClasses ?? ''}`} />
        </div>
    );
};

export default Divider;

