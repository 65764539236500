export const Undo = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.2162 11.2411H10.331L14.8324 6.92573L13.8677 6L8.20037 11.4331C8.13685 11.4937 8.08646 11.5658 8.05208 11.6451C8.01769 11.7245 8 11.8096 8 11.8955C8 11.9814 8.01769 12.0665 8.05208 12.1459C8.08646 12.2252 8.13685 12.2973 8.20037 12.3579L13.8677 17.7902L14.8324 16.8653L10.331 12.5499H18.2162C20.449 12.5499 22.5903 13.4002 24.1691 14.9137C25.7479 16.4273 26.6348 18.4801 26.6348 20.6206C26.6348 22.761 25.7479 24.8139 24.1691 26.3274C22.5903 27.8409 20.449 28.6912 18.2162 28.6912H10.503V30H18.2162C20.811 30 23.2996 29.0118 25.1344 27.2528C26.9692 25.4938 28 23.1082 28 20.6206C28 18.133 26.9692 15.7473 25.1344 13.9883C23.2996 12.2293 20.811 11.2411 18.2162 11.2411Z"
                fill="#8DBEF9"
            />
        </svg>
    );
};
