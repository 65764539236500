import { useDispatch, useSelector } from 'react-redux';
import classes from './TimeSlot.module.scss';
import { RootState, createTariffRuleActions } from 'store';
import { useCallback, useEffect } from 'react';

const TimeSlot = () => {
    const { validityPeriod, isSubmitted, isValid } = useSelector((x: RootState) => x.createTariffRule);
    const dispatch = useDispatch();

    const onTimeChangeHandler = useCallback(
        (key: any, value: any) => {
            const period = { ...validityPeriod.period, [key]: value };
            dispatch(createTariffRuleActions.setValidityPeriodValues({ key: 'period', value: period }));
        },
        [dispatch, validityPeriod]
    );

    useEffect(() => {
        dispatch(
            createTariffRuleActions.setIsValid({
                key: 'time-slot',
                isValid:
                    !!validityPeriod.period.startTime &&
                    !!validityPeriod.period.startTime &&
                    validityPeriod.period.startTime < validityPeriod.period.endTime
            })
        );
    }, [dispatch, validityPeriod.period.endTime, validityPeriod.period.startTime]);

    return (
        <div className={classes.field}>
            <div>
                <label className="small">
                    <b>Dalle ore</b>
                </label>
                <input
                    onChange={(event: any) => onTimeChangeHandler('startTime', event?.target.value)}
                    value={validityPeriod.period.startTime?.length ? validityPeriod.period.startTime : ''}
                    type="time"
                    placeholder=" "
                    className={isSubmitted && !isValid['time-slot'] ? classes.error : ''}
                />
            </div>
            <div>
                <label className="small">
                    <b>Alle ore</b>
                </label>
                <input
                    onChange={(event: any) => onTimeChangeHandler('endTime', event?.target.value)}
                    value={validityPeriod.period.endTime?.length ? validityPeriod.period.endTime : ''}
                    type="time"
                    placeholder=" "
                    className={isSubmitted && !isValid['time-slot'] ? classes.error : ''}
                />
            </div>
        </div>
    );
};

export default TimeSlot;

