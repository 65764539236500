import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { WebMercatorViewport } from 'deck.gl/typed';
import { DEFAULT_INITIAL_VIEW_STATE, DEFAULT_LAYER_DATA } from 'helpers';
import { DrawPolygonMode, ModifyMode, ViewMode } from 'nebula.gl';
import turf from 'turf';

export interface DrawPolygonState {
    mode: keyof DrawPolygonModeType;
    data: any;
    initialViewState: {
        latitude: number;
        longitude: number;
        zoom: number;
        transitionDuration: number;
        transitionInterpolator: any;
        minZoom: number;
        pitch: number;
        bearing: number;
    };
}

export const LayerMode = {
    view: ViewMode,
    drawPolygon: DrawPolygonMode,
    modify: ModifyMode
};

export type DrawPolygonModeType = typeof LayerMode;

const name = 'drawPolygon';
const initialState: DrawPolygonState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const drawActions = { ...slice.actions };
export const drawReducer = slice.reducer;

function createInitialState(): DrawPolygonState {
    return {
        mode: 'view',
        data: DEFAULT_LAYER_DATA,
        initialViewState: { ...DEFAULT_INITIAL_VIEW_STATE }
    };
}

function createReducers() {
    return { setMode, setData, zoomOnBbox };

    function setMode(state: DrawPolygonState, action: PayloadAction<keyof DrawPolygonModeType>) {
        state.mode = action.payload;
    }

    function setData(state: DrawPolygonState, action: PayloadAction<any>) {
        state.data = action.payload;
    }

    function zoomOnBbox(state: DrawPolygonState, action: PayloadAction<any>) {
        let bbox: number[];
        try {
            bbox = turf.bbox(action.payload);
        } catch (err) {
            console.error(err);
            return;
        }
        const maxZoom = action.payload.maxZoom;
        if (!bbox || bbox.some(v => v == Infinity)) {
            bbox = [5.075684, 36.120128, 19.995117, 47.249407];
        }
        const viewport = new WebMercatorViewport();
        const newViewport = viewport.fitBounds(
            [
                [bbox[0], bbox[1]],
                [bbox[2], bbox[3]]
            ],
            { maxZoom: 18 }
        );
        state.initialViewState.latitude =
            newViewport.latitude === state.initialViewState.latitude ? newViewport.latitude + 0.000001 : newViewport.latitude;
        state.initialViewState.longitude = newViewport.longitude;
        state.initialViewState.zoom = maxZoom || newViewport.zoom + 8;
    }
}

