import PricingTime from '../../pricingTime/PricingTime';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, createTariffRuleActions } from 'store';
import RadioButton from 'components/radioButton/RadioButton';
import { Stairway } from 'components/icons';
import { isStringEmptyOrZero } from 'helpers';

const FROM_TIME_OPTIONS = [
    { label: 'Giorni', key: 'fromDays', type: 'number', validate: (slope: any) => slope.fromDays >= 0 && slope.fromDays < 999 },
    { label: 'Ore', key: 'fromHours', type: 'number', validate: (slope: any) => slope.fromHours >= 0 && slope.fromHours < 24 },
    {
        label: 'Minuti',
        key: 'fromMinutes',
        type: 'number',
        validate: (slope: any) => slope.fromMinutes >= 0 && slope.fromMinutes < 60
    }
];
const TO_TIME_OPTIONS = [
    { label: 'Giorni', key: 'toDays', type: 'number', validate: (slope: any) => slope.toDays > 0 && slope.toDays < 999 },
    { label: 'Ore', key: 'toHours', type: 'number', validate: (slope: any) => slope.toHours > 0 && slope.toHours < 24 },
    { label: 'Minuti', key: 'toMinutes', type: 'number', validate: (slope: any) => slope.toMinutes > 0 && slope.toMinutes < 60 }
];
const FIXED_TIME_OPTIONS = [
    {
        label: 'Dalle ore',
        key: 'fromHour',
        type: 'time',
        validate: (slope: any) => slope.fromHour.length > 0 && slope.fromHour < slope.toHour
    },
    { label: 'Alle ore', key: 'toHour', type: 'time', validate: (slope: any) => slope.toHour.length > 0 }
];
const STAIRWAY_TYPE_OPTIONS = [
    { value: 'dynamic', label: 'Tempo' },
    { value: 'fixed', label: 'Orario fisso' }
];

type StairwayPricingProps = FC<{
    slope: any;
    onInputChange: (key: string, value: any) => void;
    index: number;
}>;

const StairwayPricing: StairwayPricingProps = ({ onInputChange, slope, index }) => {
    const [stairwayType, setStairwayType] = useState('dynamic');
    const { isSubmitted } = useSelector((x: RootState) => x.createTariffRule);
    const dispatch = useDispatch();

    useEffect(() => {
        setStairwayType(slope.fromHour.length > 0 ? 'fixed' : 'dynamic');
    }, [slope.fromHour.length]);

    const fromTariff = useMemo(() => {
        if (stairwayType === 'dynamic')
            return (
                <div>
                    {!isStringEmptyOrZero(slope.fromDays) ? slope.fromDays + 'g ' : ''}
                    {!isStringEmptyOrZero(slope.fromHours) ? slope.fromHours + 'h ' : ''}
                    {!isStringEmptyOrZero(slope.fromMinutes) ? slope.fromMinutes + 'm ' : ''}-&nbsp;
                    {!isStringEmptyOrZero(slope.toDays) ? slope.toDays + 'g ' : ''}
                    {!isStringEmptyOrZero(slope.toHours) ? slope.toHours + 'h ' : ''}
                    {!isStringEmptyOrZero(slope.toMinutes) ? slope.toMinutes + 'm ' : ''}&nbsp;
                </div>
            );
        return (
            <div>
                {!isStringEmptyOrZero(slope.fromHour) ? slope.fromHour + ' ' : ''}-&nbsp;
                {!isStringEmptyOrZero(slope.toHour) ? slope.toHour : ''}&nbsp;
            </div>
        );
    }, [slope, stairwayType]);

    const fromTimeOptions = useMemo(() => {
        const isValid = FROM_TIME_OPTIONS.some(opt => opt.validate(slope));
        return FROM_TIME_OPTIONS.map((opt: any) => {
            opt.isValid = !isSubmitted || isValid;
            return opt;
        });
    }, [isSubmitted, slope]);

    const toTimeOptions = useMemo(() => {
        const isValid = TO_TIME_OPTIONS.some(opt => opt.validate(slope));
        return TO_TIME_OPTIONS.map((opt: any) => {
            opt.isValid = !isSubmitted || isValid;
            return opt;
        });
    }, [isSubmitted, slope]);

    const fixedTimeOption = useMemo(() => {
        const isValid = FIXED_TIME_OPTIONS.every(opt => opt.validate(slope));
        return FIXED_TIME_OPTIONS.map((opt: any) => {
            opt.isValid = !isSubmitted || isValid;
            return opt;
        });
    }, [isSubmitted, slope]);

    const onStairwayTypeChangeHandler = useCallback(
        (value: string) => {
            dispatch(
                createTariffRuleActions.clearSlopeField({
                    keys: [
                        ...FIXED_TIME_OPTIONS.map(opt => opt.key),
                        ...FROM_TIME_OPTIONS.map(opt => opt.key),
                        ...TO_TIME_OPTIONS.map(opt => opt.key)
                    ],
                    index
                })
            );
            setStairwayType(value);
        },
        [dispatch, index]
    );

    const leftRef = document.getElementById(`left-slope-${index}`);
    const rightRef = document.getElementById(`right-slope-${index}`);
    const headerRef = document.getElementById(`header-slope-${index}`);

    if (!rightRef || !leftRef || !headerRef) return <></>;

    return (
        <>
            {createPortal(
                <>
                    <Stairway />
                    &nbsp;
                    {fromTariff}
                    {slope.amount !== '' ? ' / ' + slope.amount + '€' : ''}
                </>,
                headerRef
            )}
            {createPortal(
                <>
                    <RadioButton onChange={onStairwayTypeChangeHandler} value={stairwayType} options={STAIRWAY_TYPE_OPTIONS} />
                    {stairwayType === 'dynamic' && (
                        <PricingTime values={slope} onChange={onInputChange} label="Da" options={fromTimeOptions} integerOnly />
                    )}
                    {stairwayType === 'fixed' && (
                        <PricingTime values={slope} onChange={onInputChange} label="" options={fixedTimeOption} />
                    )}
                </>,
                leftRef
            )}
            {createPortal(
                <>
                    {stairwayType === 'dynamic' && (
                        <PricingTime values={slope} onChange={onInputChange} label="A" options={toTimeOptions} integerOnly />
                    )}
                </>,
                rightRef
            )}
        </>
    );
};

export default StairwayPricing;

