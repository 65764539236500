import Accordion from 'components/accordion/Accordion';
import AccordionTypography from 'components/accordionTypography/AccordionTypography';
import { ParkingDetail, formatDate } from 'helpers';
import { FC, useMemo } from 'react';
import classes from './Parking.module.scss';

type ParkingProps = FC<{
    parkingInfo: ParkingDetail;
}>;

const LABELS = {
    car: 'Autoveicolo',
    motorbike: 'Motoveicolo',
    camper: 'Camper',
    bus: 'Bus',
    electricAuto: 'Auto elettrica',
    hybrid: 'Auto ibrida',
    gplAuto: 'Auto GPL'
};

const Parking: ParkingProps = ({ parkingInfo }) => {
    const parking = useMemo(
        () => (!parkingInfo.code && !parkingInfo.name ? '***' : `${parkingInfo.code} - ${parkingInfo.name}`),
        [parkingInfo]
    );
    const endingTime = useMemo(() => {
        if (!parkingInfo?.finishTime) return '';
        return parkingInfo.finishTime.valueOf() < Date.now() ? formatDate(parkingInfo.finishTime.valueOf()) : 'In corso';
    }, [parkingInfo?.finishTime]);

    if (!parkingInfo) return <></>;

    return (
        <div className={classes.accordionContainer}>
            <Accordion title="Area">
                <AccordionTypography>
                    <div className={classes.field}>
                        <div>
                            <label className="small">
                                <b>Parcheggio</b>
                            </label>
                            <p>{parking}</p>
                        </div>
                        <div>
                            <label className="small">
                                <b>Tipo veicolo</b>
                            </label>
                            <p>{LABELS[parkingInfo.type]}</p>
                        </div>
                    </div>
                    <div className={classes.field}>
                        <div>
                            <label className="small">
                                <b>Città</b>
                            </label>
                            <p>{parkingInfo.city ? parkingInfo.city : '***'}</p>
                        </div>
                        <div>
                            <label className="small">
                                <b>Inizio Sosta</b>
                            </label>
                            <p>{formatDate(parkingInfo.startTime.valueOf())}</p>
                        </div>
                    </div>
                    <div className={classes.field}>
                        <div>
                            <label className="small">
                                <b>Targa Veicolo</b>
                            </label>
                            <p>{parkingInfo.plate}</p>
                        </div>
                        <div>
                            <label className="small">
                                <b>Fine Sosta</b>
                            </label>
                            <p>{endingTime}</p>
                        </div>
                    </div>
                </AccordionTypography>
            </Accordion>
        </div>
    );
};

export default Parking;

