import TextButton from 'components/buttons/textButton/TextButton';
import Modal from 'components/modal/Modal';
import { SetState } from 'helpers';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTariffRuleActions, RootState, snackbarActions, tariffActions } from 'store';
import { useGetTariffsByMunicipalityQuery, useLazyDeleteTariffQuery } from 'store/rtk/tariff.service';
import classes from './DeleteTariffModal.module.scss';
import Snackbar from 'components/snackbar/Snackbar';

type DeleteTariffModalProps = FC<{ isOpen: boolean; setIsOpen: SetState<boolean> }>;

const DeleteTariffModal: DeleteTariffModalProps = ({ isOpen, setIsOpen }) => {
    const { selectedTariff } = useSelector((x: RootState) => x.tariffs);
    const { municipality } = useSelector((x: RootState) => x.municipalities);
    const { refetch: refetchTariffs } = useGetTariffsByMunicipalityQuery(
        { municipalityId: municipality?.id! },
        { skip: !municipality }
    );
    const [deleteTariff, { isFetching }] = useLazyDeleteTariffQuery();
    const dispatch = useDispatch();
    const onSubmitHandler = async () => {
        if (!selectedTariff?.id) return;
        try {
            const res = await deleteTariff({ id: selectedTariff.id });
            if (!res) return;
            dispatch(tariffActions.setSelectedTariff(undefined));
            dispatch(createTariffRuleActions.resetCreation());
            dispatch(tariffActions.setSelectedTariffRule(undefined));
            dispatch(tariffActions.setIsCreatingRule(false));
            dispatch(tariffActions.setIsEditingRule(false));
            dispatch(snackbarActions.setMessageAndType({ message: `Tariffa eliminata con successo.` }));
            dispatch(snackbarActions.setIsOpen(true));
            refetchTariffs();
        } catch (error) {
            console.log(error);
        }
        setIsOpen(false);
    };

    return (
        <>
            <Modal title="Elimina Tariffa" isOpen={isOpen} handleClose={() => setIsOpen(false)}>
                <div className={classes.container}>
                    Vuoi eliminare la tariffa {selectedTariff?.name}? <br />
                    <div className={classes.buttons}>
                        <TextButton className="tertiary" onClick={() => setIsOpen(false)}>
                            Annulla
                        </TextButton>
                        <TextButton className="error" onClick={onSubmitHandler} disabled={isFetching}>
                            Elimina
                        </TextButton>
                    </div>
                </div>
            </Modal>
            <Snackbar />
        </>
    );
};

export default DeleteTariffModal;

