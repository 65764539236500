import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TicketDashboard } from 'models/ticket.model';

export interface ParkingState {
    parking: TicketDashboard[];
    selectedParking: string | undefined;
}

const name = 'parking';
const initialState: ParkingState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const parkingActions = { ...slice.actions };
export const parkingReducer = slice.reducer;

function createInitialState(): ParkingState {
    return {
        parking: [],
        selectedParking: undefined
    };
}

function createReducers() {
    return { setParking, setSelectedParking, addPage };

    function setParking(state: ParkingState, action: PayloadAction<TicketDashboard[]>) {
        state.parking = action.payload;
    }

    function addPage(state: ParkingState, action: PayloadAction<TicketDashboard[]>) {
        state.parking = state.parking.concat(action.payload.filter(ticket => !state.parking.find(t => t.id === ticket.id)));
    }

    function setSelectedParking(state: ParkingState, action: PayloadAction<string | undefined>) {
        state.selectedParking = action.payload;
    }
}
