import { IconButton } from 'components/buttons';
import { Delete, Plus } from 'components/icons';
import GenericSelect from 'components/select/GenericSelect';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, createTariffRuleActions } from 'store';
import colors from 'styles/modules/colors.module.scss';
import classes from './ValidityPeriodItem.module.scss';
import DayModal from './dayModalProps/DayModal';
import PeriodModal from './periodModal/PeriodModal';
import PresetModal from './presetModal/PresetModal';
import SelectLabel from './selectLabel/SelectLabel';

const SELECT_OPTIONS = [
    { label: 'Periodo', value: 'period' },
    { label: 'Preset', value: 'preset' },
    { label: 'Giorno', value: 'day' }
];

type ValidityPeriodItemProps = FC<{
    index: number;
}>;

const ValidityPeriodItem: ValidityPeriodItemProps = ({ index }) => {
    const { alwaysValid, isValid, isSubmitted, validityPeriod } = useSelector((x: RootState) => x.createTariffRule);
    const { selectedRule } = useSelector((x: RootState) => x.tariffs);
    const [modalType, setModalType] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();

    const selectOptions = useMemo(() => {
        return SELECT_OPTIONS.map(option => ({
            id: option.value,
            label: option.label,
            itemOnClick: () => setIsModalOpen(true)
        }));
    }, []);

    const onChangeHandler = (e: any) => setModalType(e.target.value);

    const onCloseHandler = (type: string) => {
        setIsModalOpen(false);
        setModalType(type === '' ? validityPeriod.slots[index].type : type);
    };

    useEffect(() => {
        if (!selectedRule || !selectedRule.ValidityPeriods[index]) return;
        setModalType(selectedRule.ValidityPeriods[index].type === 'days' ? 'day' : selectedRule.ValidityPeriods[index].type);
    }, [index, selectedRule]);

    useEffect(() => {
        dispatch(
            createTariffRuleActions.setIsValid({
                key: `validitySlot-${index}`,
                isValid: alwaysValid || (!!validityPeriod.slots[index] && validityPeriod.slots[index].type.length > 0)
            })
        );
    }, [alwaysValid, dispatch, index, validityPeriod.slots]);

    return (
        <div className={classes.container}>
            {index > 0 && (
                <IconButton
                    disabled={alwaysValid}
                    className="secondary"
                    icon={<Delete fill={alwaysValid ? colors.primary40 : undefined} />}
                    onClick={() => dispatch(createTariffRuleActions.validityPeriodSlotsHandler({ actionType: 'delete', index }))}
                />
            )}
            {index === 0 && (
                <IconButton
                    disabled={alwaysValid}
                    className="secondary"
                    icon={<Plus fill={colors.primary100} />}
                    onClick={() => dispatch(createTariffRuleActions.validityPeriodSlotsHandler({ actionType: 'add', index }))}
                />
            )}
            <GenericSelect
                value={modalType}
                onChange={onChangeHandler}
                placeholder="Seleziona"
                disabled={alwaysValid}
                className={isSubmitted && !isValid[`validitySlot-${index}`] ? classes.error : ''}
                options={selectOptions}
                sxFormControl={{ width: '11rem !important' }}
            />
            <PeriodModal index={index} onClose={onCloseHandler} isOpen={isModalOpen && modalType === 'period'} />
            <DayModal index={index} onClose={onCloseHandler} isOpen={isModalOpen && modalType === 'day'} />
            <PresetModal index={index} onClose={onCloseHandler} isOpen={isModalOpen && modalType === 'preset'} />
            <SelectLabel index={index} />
        </div>
    );
};

export default ValidityPeriodItem;

