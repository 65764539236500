import { SvgIconProps } from 'helpers';

export const MoneySmall: SvgIconProps = ({ fill = 'white' }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 0C3.58 0 0 3.58 0 8C0 12.42 3.58 16 8 16C12.42 16 15.99 12.42 16 8C16 3.58 12.42 0 8 0ZM8.77 11.71V12.91H7.29V11.67C5.82 11.38 5.19 10.36 5.04 9.3H6.53C6.73 10.01 7.16 10.38 8.19 10.38C9.03 10.38 9.46 10.07 9.46 9.55C9.46 9.03 8.95 8.94 7.68 8.66C6.39 8.39 5.23 7.97 5.23 6.54C5.23 5.27 6.17 4.53 7.29 4.31V3.1H8.76V4.32C10.14 4.57 10.74 5.49 10.88 6.5H9.38C9.22 5.99 8.86 5.63 7.97 5.63C7.08 5.63 6.72 6 6.72 6.47C6.72 6.89 7.08 7 8.04 7.21C9.98 7.62 10.96 8.05 10.96 9.49C10.96 10.93 9.85 11.56 8.78 11.72L8.77 11.71Z"
                fill={fill}
            />
        </svg>
    );
};
