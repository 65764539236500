import { TextField } from '@mui/material';
import { BillsFilters, numberTest } from 'helpers';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import classes from './ValueRangeFilter.module.scss';

type ValueRangeFilterProps = FC<{
    value: Record<string, any>;
    title?: string;
    objKey: keyof BillsFilters;
    onFiltersChangeHandler?: (key: keyof BillsFilters, value: any) => void;
}>;

const ValueRangeFilter: ValueRangeFilterProps = ({ value, title, objKey, onFiltersChangeHandler }) => {
    const [error, setError] = useState(false);

    useEffect(() => {
        setError(value.min && value.max ? +value.max < +value.min : false);
    }, [value.min, value.max]);

    return (
        <div>
            <label className={`${classes.label} small`}>
                <span className="small">
                    <b>{title}</b>
                </span>
            </label>
            <div className={classes.inputs}>
                <TextField
                    className={classes.input}
                    error={error}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (!numberTest.test(e.target.value)) return;
                        onFiltersChangeHandler && onFiltersChangeHandler(objKey, { ...value, min: e.target.value });
                    }}
                    value={value.min}
                    variant="outlined"
                    size="small"
                    placeholder="es. 1"
                    label={
                        <span className="small">
                            <b>Minimo</b>
                        </span>
                    }
                />
                <TextField
                    className={classes.input}
                    error={error}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (!numberTest.test(e.target.value)) return;
                        onFiltersChangeHandler && onFiltersChangeHandler(objKey, { ...value, max: e.target.value });
                    }}
                    value={value.max}
                    size="small"
                    variant="outlined"
                    placeholder="es. 10"
                    label={
                        <span className="small">
                            <b>Massimo</b>
                        </span>
                    }
                />
            </div>
        </div>
    );
};

export default ValueRangeFilter;

