import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const ArrowDown: SvgIconProps = ({ style, fill = colors.primary60 }) => {
    return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" width="16" height="9" viewBox="0 0 16 9" fill="none">
            <path
                d="M15.6689 1.4512L8.66889 8.4512C8.37889 8.7412 7.89889 8.7412 7.60889 8.4512L0.608886 1.4512L1.66889 0.391201L8.13889 6.8612L14.6089 0.3912L15.6689 1.4512Z"
                fill={fill}
            />
        </svg>
    );
};

