import { Checkbox, FormControlLabel } from '@mui/material';
import { CheckBoxChecked, CheckBoxUnchecked } from 'components/icons';
import { FC } from 'react';
import classes from './ValidityPeriodButtons.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { CreateTariffRuleState, RootState, createTariffRuleActions } from 'store';

const ValidityPeriodButtons: FC = () => {
    const { allYears, alwaysValid } = useSelector<RootState>(x => x.createTariffRule) as CreateTariffRuleState;
    const dispatch = useDispatch();

    return (
        <div className={classes.wrapper}>
            <FormControlLabel
                onChange={() => dispatch(createTariffRuleActions.toggleAlwayValid())}
                checked={alwaysValid}
                control={<Checkbox icon={<CheckBoxUnchecked />} checkedIcon={<CheckBoxChecked />} />}
                label={<p className={classes.formLabel}>Sempre valida</p>}
            />
            <FormControlLabel
                sx={{
                    '.MuiFormControlLabel-label': {
                        opacity: alwaysValid ? 0.6 : 1
                    }
                }}
                disabled={alwaysValid}
                onChange={() => dispatch(createTariffRuleActions.toggleAllYears())}
                checked={allYears}
                control={<Checkbox icon={<CheckBoxUnchecked />} checkedIcon={<CheckBoxChecked />} />}
                label={<p className={classes.formLabel}>Tutti gli anni</p>}
            />
        </div>
    );
};

export default ValidityPeriodButtons;

