import { FC, KeyboardEvent } from 'react';
import classes from './TariffInput.module.scss';

type TariffInputProps = FC<{
    placeholder?: string;
    customClasses?: string;
    onChange?: any;
    value?: any;
    disabled?: boolean;
    type?: string;
    onKeyDown?: (evt: KeyboardEvent<HTMLInputElement>) => void;
    min?: number;
    max?: number;
}>;

const TariffInput: TariffInputProps = ({
    disabled = false,
    placeholder = '',
    customClasses = '',
    onChange,
    value,
    type = 'text',
    onKeyDown,
    min,
    max
}) => {
    return (
        <input
            min={min}
            max={max}
            onKeyDown={onKeyDown}
            disabled={disabled}
            id="name"
            type={type}
            onChange={onChange}
            placeholder={placeholder}
            className={`${classes.input} ${customClasses}`}
            value={value ?? ''}
        />
    );
};

export default TariffInput;

