import { history } from 'helpers';
import { useDebounce } from 'hook';
import { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AuthState, RootState, authActions } from 'store';

const PrivateRoute: FC<PropsWithChildren> = ({ children }) => {
    const { token, tokenExpiration } = useSelector<RootState>(x => x.auth) as AuthState;
    const dispatch = useDispatch();

    const delay = useMemo(() => {
        if (!tokenExpiration) return 10000;
        return tokenExpiration - Date.now();
    }, [tokenExpiration]);

    const logout = useCallback(() => dispatch(authActions.logout()), [dispatch]);
    useDebounce(logout, delay);

    if (!token) return <Navigate to="/" state={{ from: history.location }} />;

    return <>{children}</>;
};
export default PrivateRoute;

