// components/DocumentDetails.tsx
import { ArrowDown } from 'components/icons';
import { FC } from 'react';
import DatePicker from 'react-datepicker';
import classes from './DocumentDetails.module.scss';

type DocumentDetailsProps = {
    date: Date | null;
    setDate: (date: Date | null) => void;
    isDocumentExists: boolean;
    label: string;
};

const DocumentDetails: FC<DocumentDetailsProps> = ({ date, setDate, isDocumentExists, label }) => {
    return isDocumentExists ? (
        <>
            <span>
                È già presente un documento nella categoria “{label}”. Inserendo questo nuovo documento sovrascriverai il
                precedente. Gli utenti che hanno già accettato il precedente documento avranno un margine di tempo entro il quale
                accettare o rifiutare questo aggiornamento.
            </span>
            <div className={classes.pickerContainer}>
                <label className={'small'}>
                    <b>Data limite per l’accettazione</b>
                </label>
                <DatePicker
                    showIcon
                    dateFormat="dd/MM/yyyy"
                    wrapperClassName="datePicker"
                    placeholderText={date ? date.toString() : 'Seleziona una data'}
                    selected={date}
                    onChange={date => setDate(date)}
                    icon={<ArrowDown />}
                />
            </div>
        </>
    ) : null;
};

export default DocumentDetails;

