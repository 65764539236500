import Form from 'components/form/Form';
import Modal from 'components/modal/Modal';
import MunicipalitiesSelect from 'components/municipalitiesSelect/MunicipalitiesSelect';
import Snackbar from 'components/snackbar/Snackbar';
import { UserContext } from 'context';
import { SetState } from 'helpers';
import { User } from 'models';
import { ChangeEvent, FC, useContext, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { adminActions, snackbarActions } from 'store';
import { useGetAdminUsersQuery, useLazyCreateUserQuery } from 'store/rtk/user.service';
import classes from './AdminModal.module.scss';
import { parsePhoneNumber } from 'react-phone-number-input';

type AdminModalProps = FC<{
    isModalOpen: boolean;
    setIsModalOpen: SetState<boolean>;
    userToEdit?: User | undefined;
    setUserToEdit?: SetState<User | undefined>;
}>;

const AdminModal: AdminModalProps = ({ isModalOpen, setIsModalOpen, userToEdit, setUserToEdit }) => {
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedMunicipalities, setSelectedMunicipalities] = useState<string[] | { value: string; label: string }[]>([]);
    const [isRendered, setRender] = useState(false);
    const { updateUser } = useContext(UserContext);
    const [createUser] = useLazyCreateUserQuery();
    const { refetch } = useGetAdminUsersQuery();
    const dispatch = useDispatch();

    const FIELDS = useMemo(
        () => [
            { id: 'firstName', label: 'Nome', required: true, value: userToEdit?.firstName, pattern: /^[a-z ,.'-]+$/i },
            { id: 'lastName', label: 'Cognome', required: true, value: userToEdit?.lastName, pattern: /^[a-z ,.'-]+$/i },
            {
                id: 'email',
                label: 'Email',
                required: true,
                value: userToEdit?.email,
                validate: { pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/ }
            },
            {
                id: 'telephoneNumber',
                label: 'Numero di telefono',
                required: true,
                placeholder: '5551234567',
                prefix: userToEdit?.prefix,
                value: `${userToEdit?.prefix}${userToEdit?.telephoneNumber}`
            },
            {
                id: 'role',
                label: 'Ruolo',
                placeholder: 'Seleziona',
                required: true,
                value: userToEdit?.role,
                options: [
                    { id: 'configurator', label: 'Configuratore' },
                    { id: 'municipalityEmployee', label: 'Operatore Comunale' },
                    { id: 'accountingOffice', label: 'Ufficio Contabilità' },
                    { id: 'superAdmin', label: 'Super admin' }
                ]
            }
        ],
        [userToEdit]
    );

    async function onSubmitHandler(formFields: any) {
        const parsedNumber = parsePhoneNumber(formFields.telephoneNumber);
        formFields.telephoneNumber = parsedNumber?.nationalNumber;
        formFields.prefix = '+' + parsedNumber?.countryCallingCode;
        let skipClose = false;
        try {
            let municipalityIds;
            if (!(selectedMunicipalities[0] as any)?.value) municipalityIds = selectedMunicipalities;
            else municipalityIds = selectedMunicipalities.map((v: any) => v.value);
            if (!userToEdit) {
                const res = await createUser({ user: { ...formFields, municipalityIds } });
                if (!res.data) {
                    const errorType = (res.error as any).data.message;
                    let message: string = 'Errore generico.';

                    switch (errorType) {
                        case 'email-already-used': {
                            message = `L'email è già associata a un altro utente.`;
                            skipClose = true;
                            break;
                        }
                        case 'telephone-number-already-used': {
                            message = `Il numero di telefono è già associato a un altro utente.`;
                            skipClose = true;
                            break;
                        }
                    }
                    dispatch(
                        snackbarActions.setMessageAndType({
                            message: message,
                            type: 'error'
                        })
                    );

                    return;
                }
                dispatch(adminActions.addAdmin(res.data));
                dispatch(snackbarActions.setMessageAndType({ message: `Utente creato con successo.` }));
            } else {
                const res = await updateUser({ id: userToEdit.id, ...formFields, Municipalities: municipalityIds });
                if (!res.data) {
                    const errorType = res.message;
                    let message: string = 'Errore generico.';

                    switch (errorType) {
                        case 'email-already-used': {
                            message = `L'email è già associata a un altro utente.`;
                            skipClose = true;
                            break;
                        }
                        case 'telephone-number-already-used': {
                            message = `Il numero di telefono è già associato a un altro utente.`;
                            skipClose = true;
                            break;
                        }
                    }
                    dispatch(
                        snackbarActions.setMessageAndType({
                            message: message,
                            type: 'error'
                        })
                    );

                    return;
                }
                dispatch(adminActions.replaceUpdatedAdmin(res.data));
                dispatch(snackbarActions.setMessageAndType({ message: `Utente modificato con successo.` }));
                setUserToEdit && setUserToEdit(undefined);
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(snackbarActions.setIsOpen(true));
            if (skipClose) return;
            refetch();
            setIsModalOpen(false);
            setSelectedRole('');
        }
    }

    useEffect(() => {
        if (userToEdit?.Municipalities) {
            const municipalitiesIds = userToEdit.Municipalities.map(municipality => municipality.id);
            setSelectedMunicipalities(municipalitiesIds || []);
        }
    }, [userToEdit?.Municipalities]);

    useEffect(() => {
        setRender(!!userToEdit);
        if (userToEdit?.role !== undefined) setSelectedRole(userToEdit.role);
        return () => setRender(false);
    }, [userToEdit]);

    useEffect(() => {
        setRender(isModalOpen);
        return () => setRender(false);
    }, [isModalOpen]);

    return (
        <>
            <Modal
                title={!userToEdit ? 'Aggiungi nuovo utente' : 'Modifica utente'}
                handleClose={() => {
                    setIsModalOpen(false);
                    setUserToEdit && setUserToEdit(undefined);
                    setSelectedRole('');
                }}
                isOpen={isModalOpen}
            >
                <div className={classes.municipalities}>
                    <Form
                        formFields={FIELDS}
                        onSubmitHandler={onSubmitHandler}
                        onChange={(fieldId, event: ChangeEvent<any>) => {
                            if (fieldId === 'role') setSelectedRole(event.target.value);
                        }}
                        undoAction={() => {
                            setIsModalOpen(false);
                            setUserToEdit && setUserToEdit(undefined);
                            setSelectedRole('');
                        }}
                    />
                    {isRendered &&
                        selectedRole === 'municipalityEmployee' &&
                        createPortal(
                            <div className={classes.comuni}>
                                <MunicipalitiesSelect
                                    onMunicipalitiesSelected={setSelectedMunicipalities}
                                    municipalitiesId={selectedMunicipalities as string[]}
                                />
                            </div>,
                            document.getElementById('side-form-root')!
                        )}
                </div>
            </Modal>
            <Snackbar />
        </>
    );
};

export default AdminModal;

