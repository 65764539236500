import { FC } from 'react';

export const Payment: FC = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 0C2.69 0 0 2.69 0 6C0 9.31 2.69 12 6 12C9.31 12 12 9.31 12 6C12 2.69 9.31 0 6 0ZM6.63 9.02V10H5.43V8.99C4.24 8.75 3.72 7.92 3.61 7.06H4.82C4.98 7.64 5.33 7.94 6.17 7.94C6.85 7.94 7.2 7.69 7.2 7.26C7.2 6.83 6.79 6.76 5.75 6.53C4.7 6.31 3.76 5.96 3.76 4.8C3.76 3.76 4.52 3.16 5.43 2.99V2H6.62V3C7.74 3.2 8.23 3.95 8.34 4.78H7.12C6.99 4.37 6.7 4.07 5.98 4.07C5.26 4.07 4.96 4.37 4.96 4.75C4.96 5.1 5.25 5.18 6.03 5.35C7.6 5.69 8.4 6.03 8.4 7.21C8.4 8.39 7.5 8.9 6.63 9.02Z"
                fill="#1072E7"
            />
        </svg>
    );
};

