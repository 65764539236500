import { IconButton, IconTextButton } from 'components/buttons';
import { ArrowBack, ResetFilters } from 'components/icons';
import RadioButtonFilter from 'components/radioButtonFIlter/RadioButtonFilter';
import deepEqual from 'deep-equal';
import { DEFAULT_MUNICIPALITY_AREAS_FILTER_VALUES, IMunicipalityAreasFilters, RadioButtonFilterFields, SetState } from 'helpers';
import { FC, useMemo } from 'react';
import colors from 'styles/modules/colors.module.scss';
import classes from './MunicipalityAreasFilters.module.scss';

const PARKING_RADIO_BUTTON_FILTER_FIELDS: RadioButtonFilterFields[] = [
    { label: 'Tutti', value: 'all' },
    { label: 'Solo soste in corso', value: 'inProgress' },
    { label: 'Solo parcheggi senza soste in corso', value: 'notInProgress' }
];

const DISABLED_AREA_RADIO_BUTTON_FILTER_FIELDS: RadioButtonFilterFields[] = [
    { label: 'Tutte', value: 'all' },
    { label: 'Solo aree abilitate', value: 'enabled' },
    { label: 'Solo aree disabilitate', value: 'disabled' }
];

type MunicipalityAreasFiltersProps = FC<{
    setAreFiltersOpen: SetState<boolean>;
    activeFilters: IMunicipalityAreasFilters;
    setActiveFilters: (key: string, value: string) => void;
    resetFilters: () => void;
}>;

const MunicipalityAreasFilters: MunicipalityAreasFiltersProps = ({
    activeFilters,
    setActiveFilters,
    setAreFiltersOpen,
    resetFilters
}) => {
    const isDisabled = useMemo(() => deepEqual(activeFilters, DEFAULT_MUNICIPALITY_AREAS_FILTER_VALUES), [activeFilters]);

    return (
        <div className={classes.filtersContainer}>
            <div className={classes.header}>
                <div className={classes.title} onClick={() => setAreFiltersOpen(false)}>
                    <IconButton className="tertiary" icon={<ArrowBack />} onClick={() => setAreFiltersOpen(false)} />
                    <p>
                        <b>Filtri</b>
                    </p>
                </div>
                <IconTextButton
                    className="tertiary"
                    disabled={isDisabled}
                    icon={<ResetFilters fill={isDisabled ? colors.primary40 : colors.primary90} />}
                    onClick={resetFilters}
                >
                    Reset
                </IconTextButton>
            </div>
            <div className={classes.filters}>
                <div>
                    <p className="lightBlue">Aree</p>
                    <RadioButtonFilter
                        label="Soste in corso"
                        fields={PARKING_RADIO_BUTTON_FILTER_FIELDS}
                        activeFilters={activeFilters.inProgress}
                        setActiveFilter={setActiveFilters}
                        keyValue="inProgress"
                    />
                    <RadioButtonFilter
                        label="Aree disabilitate"
                        fields={DISABLED_AREA_RADIO_BUTTON_FILTER_FIELDS}
                        activeFilters={activeFilters.status}
                        setActiveFilter={setActiveFilters}
                        keyValue="status"
                    />
                </div>
            </div>
        </div>
    );
};

export default MunicipalityAreasFilters;

