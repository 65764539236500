import { FC, ReactNode, useRef, useState, useEffect } from 'react';

type DragNdropProps = {
    onFileSelected: ((file: File | null) => void) | null;
    width?: string;
    height?: string;
    children?: ReactNode;
    className?: string;
    selectFileElement?: ReactNode;
    disabled?: boolean;
};

const DragNdrop: FC<DragNdropProps> = ({
    onFileSelected,
    width,
    height,
    children,
    className,
    selectFileElement,
    disabled = false
}) => {
    const [file, setFile] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (disabled) return;
        const droppedFiles = event.dataTransfer.files;
        if (droppedFiles.length > 0 && onFileSelected) setFile(droppedFiles[0]);
    };

    const handleFilesSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!onFileSelected) return;
        const selectedFiles = event.target.files;
        if (selectedFiles && selectedFiles.length > 0) {
            setFile(selectedFiles[0]);
        }
    };

    useEffect(() => {
        onFileSelected && onFileSelected(file && file);
    }, [file, onFileSelected]);

    return (
        <div
            className={className}
            style={{ width: width, height: height }}
            onDrop={handleDrop}
            onDragOver={event => event.preventDefault()}
        >
            {children}
            <div onClick={() => fileInputRef.current?.click()}>{selectFileElement}</div>
            <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFilesSelected} accept=".pdf" />
        </div>
    );
};

export default DragNdrop;

