import { FC, PropsWithChildren } from 'react';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ArrowDown } from 'components/icons';
import colors from 'styles/modules/colors.module.scss';
import classes from './Accordion.module.scss';

type AccordionProperties = FC<
    PropsWithChildren<{
        title: JSX.Element | string;
    }>
>;

const Accordion: AccordionProperties = ({ title, children }) => {
    return (
        <div className={classes.container}>
            <MuiAccordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ArrowDown fill={colors.primary100} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>{children}</AccordionDetails>
            </MuiAccordion>
        </div>
    );
};

export default Accordion;

