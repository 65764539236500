import { FC } from 'react';
import classes from './UsersCounter.module.scss';

type UsersCounterProps = FC<{ value: number }>;

export const UsersCounter: UsersCounterProps = ({ value = 0 }) => {
    const userSvg = (
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.17716 5.89522C5.49335 6.49521 7.55811 4.46271 6.94097 2.19021C6.6743 1.18521 5.84383 0.382715 4.83049 0.112715C2.50668 -0.494785 0.449541 1.53771 1.05906 3.81771C1.32573 4.82271 2.15621 5.62522 3.16954 5.89522H3.17716Z"
                fill="#C4DFFF"
            />
            <path
                d="M3.59619 7.14756C1.51619 7.35006 0 9.18756 0 11.2426V11.4451C0 11.7526 0.259048 12.0076 0.571429 12.0076H7.42857C7.74095 12.0076 8 11.7526 8 11.4451V11.0701C8 8.76756 5.98095 6.92256 3.59619 7.15506V7.14756Z"
                fill="white"
            />
        </svg>
    );

    const counter = <span className={classes.counter}>{value}</span>;

    return (
        <div className={classes.iconContainer}>
            {userSvg}
            {counter}
        </div>
    );
};

