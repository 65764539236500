import { SvgIconProps } from 'helpers';

export const Parking: SvgIconProps = ({ fill, size = 24 }) => {
    return (
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="parking 1" clipPath="url(#clip0_1375_3240)">
                <g id="Icons">
                    <path
                        id="Vector"
                        d="M15.3164 6.39711C14.7404 6.08293 14.0684 5.92584 13.3178 5.92584H8.41309V18.3709H10.4553V13.5448H13.3178C14.0771 13.5448 14.7404 13.3877 15.3164 13.0735C15.8924 12.7593 16.3374 12.3142 16.6604 11.7469C16.9833 11.1709 17.1404 10.5077 17.1404 9.74839C17.1404 8.98911 16.9833 8.32584 16.6604 7.74984C16.3374 7.17384 15.8924 6.72875 15.3164 6.41457V6.39711ZM14.8276 10.7084C14.688 10.9877 14.496 11.2058 14.2429 11.3542C13.9985 11.5026 13.7018 11.5811 13.3702 11.5811H10.4553V7.88075H13.3702C13.7018 7.88075 13.9898 7.9593 14.2429 8.10766C14.496 8.26475 14.688 8.48293 14.8276 8.76221C14.9673 9.04148 15.0284 9.36439 15.0284 9.73966C15.0284 10.1149 14.9585 10.4291 14.8189 10.7084H14.8276Z"
                        fill={fill}
                    />
                    <path
                        id="Vector_2"
                        d="M12 0C5.38473 0 0 5.38473 0 12C0 18.6153 5.38473 24 12 24C18.6153 24 24 18.6153 24 12C24 5.38473 18.6153 0 12 0ZM12 22.6909C6.10909 22.6909 1.30909 17.8909 1.30909 12C1.30909 6.10909 6.10909 1.30909 12 1.30909C17.8909 1.30909 22.6909 6.10909 22.6909 12C22.6909 17.8909 17.8909 22.6909 12 22.6909Z"
                        fill={fill}
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1375_3240">
                    <rect width="24" height="24" fill={fill} />
                </clipPath>
            </defs>
        </svg>
    );
};

