import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from 'environments';
import { TariffWithMunicipality } from 'helpers';
import { Tariff } from 'models';
import { store } from 'store';

export const tariffApi = createApi({
    reducerPath: 'tariffs-api',
    baseQuery: fetchBaseQuery({
        fetchFn(input, init) {
            const authState = store.getState().auth.token as any;
            return fetch(input, { ...init, headers: { authorization: authState!, 'content-type': 'application/json' } });
        },
        baseUrl: `${env.reactAppSocketUrl}${env.apiUrl}`
    }),
    endpoints: builder => ({
        getTariffsByMunicipality: builder.query<Tariff[], { municipalityId: string }>({
            query: params => ({
                url: '/tariffs/by-municipality',
                method: 'get',
                params
            })
        }),
        createTariff: builder.query<any, { name: string; slopeReprise: boolean; municipalityId: string }>({
            query: body => ({
                url: '/tariffs',
                method: 'POST',
                body
            })
        }),
        importTariff: builder.query<any, { tariffId: string; municipalityId: string }>({
            query: body => ({
                url: '/tariffs/import',
                method: 'POST',
                body
            })
        }),
        getAllTariffs: builder.query<TariffWithMunicipality[], void>({
            query: () => ({
                url: '/tariffs',
                method: 'GET'
            })
        }),
        editTariffName: builder.query<any, { name: string; slopeReprise: boolean; id: string }>({
            query: body => ({
                url: '/tariffs',
                method: 'PUT',
                body
            })
        }),
        deleteTariff: builder.query<any, { id: string }>({
            query: body => ({
                url: '/tariffs',
                method: 'DELETE',
                body
            })
        })
    })
});

export const {
    useLazyGetTariffsByMunicipalityQuery,
    useGetTariffsByMunicipalityQuery,
    useLazyCreateTariffQuery,
    useLazyImportTariffQuery,
    useLazyEditTariffNameQuery,
    useGetAllTariffsQuery,
    useLazyDeleteTariffQuery
} = tariffApi;

