export const AttentionCircle = () => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.33261 16C6.75041 15.9999 5.20378 15.5306 3.88829 14.6515C2.5728 13.7724 1.54751 12.523 0.942092 11.0612C0.336673 9.5994 0.178304 7.9909 0.487005 6.43911C0.795707 4.88733 1.55762 3.46195 2.6764 2.34317C3.79518 1.22439 5.22058 0.462455 6.77236 0.153753C8.32415 -0.154948 9.93263 0.00342055 11.3944 0.60884C12.8562 1.21426 14.1056 2.23955 14.9847 3.55504C15.8638 4.87053 16.3331 6.41716 16.3332 7.99935C16.3333 9.05004 16.1264 10.0905 15.7244 11.0612C15.3224 12.0319 14.733 12.9139 13.9901 13.6568C13.2471 14.3998 12.3651 14.9891 11.3944 15.3912C10.4237 15.7932 9.38329 16.0001 8.33261 16ZM8.33261 10.8568C8.1065 10.8568 7.88546 10.9238 7.69746 11.0494C7.50946 11.175 7.36292 11.3536 7.27639 11.5625C7.18987 11.7714 7.16724 12.0013 7.21135 12.223C7.25546 12.4448 7.36434 12.6485 7.52422 12.8084C7.6841 12.9683 7.88781 13.0771 8.10958 13.1213C8.33134 13.1654 8.56119 13.1427 8.77009 13.0562C8.97899 12.9697 9.15755 12.8231 9.28317 12.6351C9.40879 12.4471 9.47582 12.2261 9.47582 12C9.47591 11.8498 9.44641 11.7011 9.389 11.5623C9.33158 11.4235 9.24738 11.2974 9.14121 11.1912C9.03504 11.085 8.90899 11.0007 8.77025 10.9432C8.6315 10.8857 8.48279 10.8568 8.33261 10.8568ZM7.47356 2.85613V9.71354H9.1884V2.85613H7.47356Z"
                fill="#FB9AA0"
            />
        </svg>
    );
};
