import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from 'models';

export interface AdminState {
    admin: User[];
}

const name = 'admins';
const initialState: AdminState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const adminActions = { ...slice.actions };
export const adminReducer = slice.reducer;

function createInitialState(): AdminState {
    return {
        admin: []
    };
}

function createReducers() {
    return { setAdmin, addAdmin, replaceUpdatedAdmin, removeDeletedAdmin };

    function setAdmin(state: AdminState, action: PayloadAction<User[]>) {
        state.admin = action.payload;
    }

    function addAdmin(state: AdminState, action: PayloadAction<User>) {
        state.admin.push(action.payload);
    }

    function replaceUpdatedAdmin(state: AdminState, action: PayloadAction<User>) {
        const index = state.admin.findIndex(user => user.id === action.payload.id);
        if (index < 0) return;
        state.admin[index] = action.payload;
    }

    function removeDeletedAdmin(state: AdminState, action: PayloadAction<string[]>) {
        state.admin = state.admin.filter(admin => !action.payload.includes(admin.id));
    }
}
