import { IconTextButton } from 'components/buttons';
import Card from 'components/card/Card';
import { Forward, Plus, Rule } from 'components/icons';
import { Tariff } from 'models';
import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, TabTypes, tariffActions } from 'store';
import classes from './TariffList.module.scss';
import CreateTariffModal from './createTariffModal/CreateTariffModal';
import ImportTariffModal from './importTariffModal/ImportTariffModal';
import { useGetTariffsByMunicipalityQuery } from 'store/rtk/tariff.service';
import { usePermissionHandler } from 'hook';
import NoResult from 'components/noResult/NoResult';

type TariffListProps = FC<{
    filter?: string;
}>;

const TariffList: TariffListProps = ({ filter }) => {
    const { municipality } = useSelector((x: RootState) => x.municipalities);
    const { data: tariffs, refetch: refetchTariffs } = useGetTariffsByMunicipalityQuery(
        { municipalityId: municipality?.id! },
        { skip: !municipality }
    );
    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const hasPermission = usePermissionHandler();
    const dispatch = useDispatch();

    const onCreateEditHandler = () => dispatch(tariffActions.toggleCreateEditModalOpen());

    const onImportHandler = () => setIsImportModalOpen(!isImportModalOpen);

    const filteredTariffs = useMemo(() => {
        if (!filter) return tariffs ?? [];
        return tariffs?.filter(tariff => tariff.name.toLowerCase().includes(filter.toLowerCase())) ?? [];
    }, [filter, tariffs]);

    const mappedTariffs = useMemo(() => {
        const onClickTariffHandler = (tariff: Tariff) => () => dispatch(tariffActions.setSelectedTariff(tariff));
        return filteredTariffs?.map(tariff => (
            <Card
                key={tariff.id}
                onClick={onClickTariffHandler(tariff)}
                chips={[{ icon: <Rule />, label: `${tariff?.TariffRules?.length ?? 0}` }]}
            >
                {tariff?.name}
            </Card>
        ));
    }, [dispatch, filteredTariffs]);

    return (
        <>
            {mappedTariffs.length > 0 ? mappedTariffs : <NoResult />}
            <IconTextButton
                disabled={!hasPermission(TabTypes.municipalitySettings, ['can-create'])}
                className={`secondary ${classes.importBtn}`}
                icon={<Forward />}
                onClick={onImportHandler}
            >
                Importa tariffa
            </IconTextButton>
            <IconTextButton
                disabled={!hasPermission(TabTypes.municipalitySettings, ['can-create'])}
                className={`primary ${classes.createBtn}`}
                icon={<Plus />}
                onClick={onCreateEditHandler}
            >
                Aggiungi tariffa
            </IconTextButton>
            <CreateTariffModal refetch={refetchTariffs} />
            <ImportTariffModal refetch={refetchTariffs} isOpen={isImportModalOpen} setIsOpen={setIsImportModalOpen} />
        </>
    );
};

export default TariffList;

