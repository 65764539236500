export const CircleCheckSuccess = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.00001 16C6.41779 16 4.87109 15.5308 3.55552 14.6518C2.23994 13.7728 1.21456 12.5234 0.609035 11.0616C0.00351564 9.59983 -0.154958 7.99131 0.153672 6.43949C0.462301 4.88766 1.22418 3.46223 2.34293 2.34338C3.46169 1.22454 4.88708 0.462558 6.43888 0.153803C7.99069 -0.154951 9.59919 0.00337089 11.061 0.608773C12.5228 1.21417 13.7723 2.23947 14.6515 3.55498C15.5306 4.87048 15.9999 6.41711 16 7.99934C16.0001 9.04996 15.7932 10.0903 15.3912 11.061C14.9892 12.0317 14.4 12.9137 13.6571 13.6566C12.9142 14.3996 12.0323 14.9889 11.0616 15.391C10.091 15.793 9.05064 16 8.00001 16ZM4.36106 7.2107L3.41677 8.15501L6.3039 11.0389C6.36562 11.1011 6.43903 11.1504 6.51989 11.184C6.60076 11.2177 6.68748 11.2351 6.77507 11.2351C6.86267 11.2351 6.9494 11.2177 7.03027 11.184C7.11114 11.1504 7.18453 11.1011 7.24624 11.0389L12.5794 5.7058L11.637 4.76342L6.77669 9.62892L4.36106 7.2107Z"
                fill="#86F0AA"
            />
        </svg>
    );
};
