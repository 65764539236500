import Searchbar from 'components/searchbar/Searchbar';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MunicipalityTableTypes, RootState, municipalityActions } from 'store';
import { useLazyGetMunicipalityByIdQuery } from 'store/rtk/municipality.service';
import classes from './MunicipalityDetails.module.scss';
import AreaList from './areasList/AreaList';
import MunicipalityActions from './municipalityActions/MunicipalityActions';
import MunicipalityToggle from './municipalityToggle/MunicipalityToggle';
import TariffList from './tariffList/TariffList';
import { Filters, IsOnGoing } from 'components/icons';
import { IconButton } from 'components/buttons';
import MunicipalityAreasFilters from './municipalityAreasFilters/MunicipalityAreasFilters';
import { DEFAULT_MUNICIPALITY_AREAS_FILTER_VALUES, IMunicipalityAreasFilters } from 'helpers';
import deepEqual from 'deep-equal';

const MunicipalityDetails: FC = () => {
    const { selectedMunicipality, selectedTable } = useSelector((x: RootState) => x.municipalities);
    const [searchedWord, setSearchedWord] = useState<string>('');
    const [searchedArea, setSearchedArea] = useState<string>('');
    const [areFiltersOpen, setAreFiltersOpen] = useState<boolean>(false);
    const [areaFilters, setAreaFilters] = useState<IMunicipalityAreasFilters>(DEFAULT_MUNICIPALITY_AREAS_FILTER_VALUES);
    const dispatch = useDispatch();
    const [getMunicipalityById] = useLazyGetMunicipalityByIdQuery();

    const updateFilters = useCallback(
        (key: string, value: string) => {
            setAreaFilters(prev => ({ ...prev, [key]: value }));
        },
        [setAreaFilters]
    );

    const areFiltersActive = useMemo(() => !deepEqual(areaFilters, DEFAULT_MUNICIPALITY_AREAS_FILTER_VALUES), [areaFilters]);

    useEffect(() => {
        (async () => {
            setSearchedArea('');
            if (!selectedMunicipality?.id) return dispatch(municipalityActions.setMunicipality(undefined));
            const { data } = await getMunicipalityById(selectedMunicipality.id);
            if (data) dispatch(municipalityActions.setMunicipality(data));
        })();
    }, [dispatch, setSearchedArea, getMunicipalityById, selectedMunicipality?.id]);

    return (
        <>
            <div className={`${classes.header} ${areFiltersOpen ? classes.headerWithFilter : ''}`}>
                <MunicipalityActions />
                <MunicipalityToggle />
                {!areFiltersOpen && (
                    <div className={classes.searchbar}>
                        {selectedTable === MunicipalityTableTypes.areas && (
                            <>
                                <Searchbar value={searchedArea} onChange={setSearchedArea} />
                                <div className={classes.filterButton}>
                                    <IconButton className="tertiary" icon={<Filters />} onClick={() => setAreFiltersOpen(true)} />
                                    {areFiltersActive && (
                                        <span className={classes.active}>
                                            <IsOnGoing />
                                        </span>
                                    )}
                                </div>
                            </>
                        )}
                        {selectedTable === MunicipalityTableTypes.tariff && (
                            <Searchbar value={searchedWord} onChange={setSearchedWord} />
                        )}
                    </div>
                )}
            </div>
            {selectedTable === MunicipalityTableTypes.areas && areFiltersOpen ? (
                <MunicipalityAreasFilters
                    setAreFiltersOpen={setAreFiltersOpen}
                    activeFilters={areaFilters}
                    setActiveFilters={updateFilters}
                    resetFilters={() => setAreaFilters(DEFAULT_MUNICIPALITY_AREAS_FILTER_VALUES)}
                />
            ) : (
                <div className={classes.cardList}>
                    {selectedTable === MunicipalityTableTypes.areas && (
                        <AreaList searchedArea={searchedArea} inProgress={areaFilters.inProgress} status={areaFilters.status} />
                    )}
                    {selectedTable === MunicipalityTableTypes.tariff && <TariffList filter={searchedWord} />}
                </div>
            )}
        </>
    );
};

export default MunicipalityDetails;

