import { FormControlLabel, Switch } from '@mui/material';
import { FC } from 'react';
import classes from './BlacklistSwitchButton.module.scss';

type BlacklistSwitchButtonProps = FC<{
    onChange?: any;
    checked?: boolean;
    label?: string;
    disabled?: boolean;
}>;

const BlacklistSwitchButton: BlacklistSwitchButtonProps = ({ onChange, checked, label, disabled }) => {
    return (
        <FormControlLabel
            value="start"
            control={
                <Switch
                    disabled={disabled}
                    onChange={event => onChange(event.target.checked)}
                    checked={checked}
                    classes={{ root: classes.blacklistSwitch }}
                />
            }
            label={<b>{label}</b>}
            labelPlacement="end"
        />
    );
};

export default BlacklistSwitchButton;
