import TextButton from 'components/buttons/textButton/TextButton';
import Modal from 'components/modal/Modal';
import { SetState } from 'helpers';
import { FC } from 'react';
import classes from './RuleDeleteModal.module.scss';

type RuleDeleteModalProps = FC<{
    isDeleteModalOpen: boolean;
    setIsDeleteModalOpen: SetState<boolean>;
    onDeleteHandler: () => void;
    selectedRuleName: string;
}>;

const RuleDeleteModal: RuleDeleteModalProps = ({
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    onDeleteHandler,
    selectedRuleName
}) => {
    const handleConfirmation = (confirmed: boolean) => {
        if (confirmed) {
            onDeleteHandler();
            setIsDeleteModalOpen(false);
        } else {
            setIsDeleteModalOpen(false);
        }
    };

    return (
        <Modal
            customClasses={classes.modal}
            isOpen={isDeleteModalOpen}
            title="Elimina regola"
            handleClose={() => setIsDeleteModalOpen(false)}
        >
            <span>
                Vuoi eliminare la regola <b>{selectedRuleName}</b> dalla piattaforma?
            </span>
            <div>
                <TextButton className={classes.cancelButton} onClick={() => handleConfirmation(false)}>
                    Annulla
                </TextButton>
                <TextButton className={classes.confirmButton} onClick={() => handleConfirmation(true)}>
                    Elimina
                </TextButton>
            </div>
        </Modal>
    );
};

export default RuleDeleteModal;

