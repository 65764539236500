import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from 'store/auth.slice';
import { drawReducer } from './draw.slice';
import { areaApi } from './rtk/area.service';
import { serviceApi } from './rtk/service.service';
import { servicesReducer } from './service.slice';
import { municipalityApi } from './rtk/municipality.service';
import { municipalityReducer } from './municipality.slice';
import { usersReducer } from './users.slice';
import { usersApi } from './rtk/user.service';
import { areasReducer } from './area.slice';
import { parkingReducer } from './parking.slice';
import { vehicleTypeApi } from './rtk/vehicleType.service';
import { adminReducer } from './admin.slice';
import { parkingApi } from './rtk/parking.service';
import { customerServiceReducer } from './customerService.slice';
import { customerServiceApi } from 'store/rtk/customerService.service';
import { tabReducer } from 'store/tab.slice';
import { billingsReducer } from './billing.slice';
import { billingsApi } from './rtk/billing.service';
import { tariffReducer } from './tariff.slice';
import { tariffApi } from './rtk/tariff.service';
import { createTariffRuleReducer } from './createTariffRule.slice';
import { tariffRuleApi } from './rtk/tariffRule.service';
import { otpReducer } from './otp.slice';
import { authApi } from './rtk/auth.service';
import { filtersReducer } from './filters.slice';
import { snackbarReducer } from './snackbar.slice';
import { documentApi } from './rtk/document.service';
import { paymentsApi } from './rtk/payments.service';
import { simulatorApi } from './rtk/simulator.service';

export * from './admin.slice';
export * from './auth.slice';
export * from './draw.slice';
export * from './service.slice';
export * from './municipality.slice';
export * from './otp.slice';
export * from './area.slice';
export * from './users.slice';
export * from './parking.slice';
export * from './customerService.slice';
export * from './tab.slice';
export * from './tariff.slice';
export * from './billing.slice';
export * from './createTariffRule.slice';
export * from './filters.slice';
export * from './snackbar.slice';

export const store = configureStore({
    reducer: {
        admin: adminReducer,
        auth: authReducer,
        draw: drawReducer,
        services: servicesReducer,
        municipalities: municipalityReducer,
        otp: otpReducer,
        users: usersReducer,
        areas: areasReducer,
        stops: parkingReducer,
        billings: billingsReducer,
        tariffs: tariffReducer,
        parking: parkingReducer,
        createTariffRule: createTariffRuleReducer,
        customerService: customerServiceReducer,
        tab: tabReducer,
        filters: filtersReducer,
        snackbar: snackbarReducer,
        [authApi.reducerPath]: authApi.reducer,
        [customerServiceApi.reducerPath]: customerServiceApi.reducer,
        [areaApi.reducerPath]: areaApi.reducer,
        [serviceApi.reducerPath]: serviceApi.reducer,
        [municipalityApi.reducerPath]: municipalityApi.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
        [tariffApi.reducerPath]: tariffApi.reducer,
        [tariffRuleApi.reducerPath]: tariffRuleApi.reducer,
        [vehicleTypeApi.reducerPath]: vehicleTypeApi.reducer,
        [billingsApi.reducerPath]: billingsApi.reducer,
        [parkingApi.reducerPath]: parkingApi.reducer,
        [documentApi.reducerPath]: documentApi.reducer,
        [paymentsApi.reducerPath]: paymentsApi.reducer,
        [simulatorApi.reducerPath]: simulatorApi.reducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({ serializableCheck: false })
            .concat(authApi.middleware)
            .concat(areaApi.middleware)
            .concat(serviceApi.middleware)
            .concat(municipalityApi.middleware)
            .concat(usersApi.middleware)
            .concat(vehicleTypeApi.middleware)
            .concat(parkingApi.middleware)
            .concat(tariffApi.middleware)
            .concat(tariffRuleApi.middleware)
            .concat(customerServiceApi.middleware)
            .concat(billingsApi.middleware)
            .concat(documentApi.middleware)
            .concat(paymentsApi.middleware)
            .concat(simulatorApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>;

