import Form from 'components/form/Form';
import classes from './FirstAccess.module.scss';
import { ChangeEvent, FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { history } from 'helpers';
import { useLazyResetPasswordQuery, useLazyResetPasswordRequestQuery, useLazyUpdatePasswordQuery } from 'store/rtk/user.service';
import { useDispatch } from 'react-redux';
import { snackbarActions } from 'store';

const FIELDS = [
    { label: 'Password', id: 'password', required: true },
    { label: 'Ripeti Password', id: 'confirmPassword', required: true }
];
const MIN_PASSWORD_LENGTH = 6;
const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+\[\]{};:'",.<>\/?\\|`~])[A-Za-z\d!@#$%^&*()\-_=+\[\]{};:'",.<>\/?\\|`~]{6,}$/;

type FirstAccessProps = { isResetting?: boolean };

const FirstAccess: FC<FirstAccessProps> = ({ isResetting }) => {
    const [updatePassword] = useLazyUpdatePasswordQuery();
    const [resetPassword] = useLazyResetPasswordQuery();
    const { userId: id, otp, email } = useParams();
    const { location } = history;
    const [errorMessage, setErrorMessage] = useState('');
    const [isLinkExpired, setIsLinkExpired] = useState(false);
    const [resetPasswordRequest] = useLazyResetPasswordRequestQuery();
    const dispatch = useDispatch();

    const onFormChangeHandler = (field: string, value: ChangeEvent<any>) => {
        if (field === 'password' && value.target.value.length < MIN_PASSWORD_LENGTH) {
            setErrorMessage('La password deve contenere almeno 6 caratteri.');
        } else if (field === 'password' && errorMessage === 'La password deve contenere almeno 6 caratteri.') {
            setErrorMessage('');
        }
    };

    async function onSubmitHandler(formFields: any) {
        const { password, confirmPassword } = formFields;

        if (password !== confirmPassword) {
            setErrorMessage('Le password non corrispondono.');
            return;
        }
        if (password.length < MIN_PASSWORD_LENGTH) {
            setErrorMessage('La password deve contenere almeno 6 caratteri.');
            return;
        }
        if (!passwordRegex.test(password)) {
            setErrorMessage(
                'La password deve contenere almeno una lettera minuscola, una lettera maiuscola, un numero e un carattere speciale.'
            );
            return;
        }
        if (formFields.password !== formFields.confirmPassword) return;
        let res;
        if (location.pathname.includes('reset-password'))
            res = await resetPassword({ id: id!, newPassword: formFields.password, otp: otp! });
        else res = await updatePassword({ userId: id!, password: formFields.password, otp: otp! });
        if (!res?.data) {
            const errorType = (res as any).error.status;
            if (errorType === 400) {
                setErrorMessage('Si prega di inserire una password diversa da quella usata in precedenza.');
                return;
            } else {
                if (errorType === 500) {
                    setErrorMessage('Errore di sistema. Si prega di riprovare.');
                } else {
                    setErrorMessage('Il link di reset della password è scaduto o errato. Clicca per riceverne uno nuovo.');
                    setIsLinkExpired(true);
                }
                return;
            }
        }
        history.navigate(`/verify-otp/${id}/${res.data.telephoneNumber}`);
    }

    const resendLinkOnError = async () => {
        if (!email) return;
        try {
            await resetPasswordRequest(email);
            setIsLinkExpired(false);
            dispatch(snackbarActions.setMessageAndType({ message: 'Segui le istruzioni nella nuova mail inviata.' }));
            history.navigate('/');
        } catch {
            dispatch(snackbarActions.setMessageAndType({ message: 'Errore di invio.', type: 'error' }));
        } finally {
            dispatch(snackbarActions.setIsOpen(true));
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.form}>
                <p>
                    <b>Benvenuto! </b>
                </p>
                <div>
                    <p className="small">Inserisci qui la nuova password per il tuo account rispettando i seguenti requisiti:</p>
                    <ul className="small">
                        <li>Deve contenere almeno 6 caratteri.</li>
                        <li>
                            La password deve contenere almeno una lettera minuscola, una lettera maiuscola, un numero e un
                            carattere speciale.
                        </li>
                        {!!isResetting && <li>Deve essere diversa da quella usata in precedenza.</li>}
                    </ul>
                </div>
                <Form
                    formFields={FIELDS}
                    onSubmitHandler={isLinkExpired ? resendLinkOnError : onSubmitHandler}
                    buttonLabel={isLinkExpired ? 'Invia link di verifica' : 'Conferma e accedi'}
                    errorMessage={errorMessage}
                    onChange={onFormChangeHandler}
                />
            </div>
        </div>
    );
};

export default FirstAccess;

