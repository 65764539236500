import TextButton from 'components/buttons/textButton/TextButton';
import Snackbar from 'components/snackbar/Snackbar';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState, authActions, otpActions, snackbarActions } from 'store';
import { useLazyResendOtpQuery } from 'store/rtk/user.service';
import classes from './VerifyOtp.module.scss';
import OtpInput from './otpInput/OtpInput';

const VerifyOtp = () => {
    const [otpError, setOtpError] = useState<boolean>(false);
    const { id, telephoneNumber } = useParams();
    const [resendOtp] = useLazyResendOtpQuery();
    const dispatch = useDispatch();
    const { otp } = useSelector((x: RootState) => x.otp);
    const [isDisabled, setIsDisabled] = useState(false);

    const onSubmitHandler = async () => {
        try {
            const res = await dispatch(authActions.verifyOtp({ id: id!, otpToVerify: otp.join('') }) as any);
            if (res.payload) setOtpError(true);
            if (res.payload.message === 'invalid-otp-error' && res.payload.details !== undefined) {
                if (res.payload.details !== undefined)
                    dispatch(
                        snackbarActions.setMessageAndType({
                            message: `Il codice OTP inserito non è corretto. Hai ${4 - res.payload.details} ${
                                4 - res.payload.details !== 1 ? 'tentativi rimasti' : 'tentativo rimasto'
                            }.`,
                            type: 'error'
                        })
                    );
                else {
                    setIsDisabled(true);
                    dispatch(
                        snackbarActions.setMessageAndType({
                            message: `Il codice OTP è scaduto. Seleziona "Invia di nuovo" per ricevere un nuovo codice.`,
                            type: 'error'
                        })
                    );
                }
            }
            if (res.payload.message === 'otp-expired') {
                setIsDisabled(true);
                dispatch(
                    snackbarActions.setMessageAndType({
                        message: `Il codice OTP è scaduto. Seleziona "Invia di nuovo" per ricevere un nuovo codice.`,
                        type: 'error'
                    })
                );
            }
            if (!res.payload) {
                dispatch(
                    snackbarActions.setMessageAndType({
                        message: `Codice OTP verificato con successo. Benvenuto in Muvin!`
                    })
                );
            }
            dispatch(snackbarActions.setIsOpen(true));
            dispatch(otpActions.setOtp(new Array(4).fill('')));
        } catch (error) {
            console.log(error);
        }
    };

    const onResendOtpHandler = async () => {
        try {
            const res = await resendOtp({ id: id! });
            if (res.error) {
                dispatch(snackbarActions.setMessageAndType({ message: `Errore nell'invio del codice OTP.`, type: 'error' }));
            } else {
                setIsDisabled(false);
                dispatch(snackbarActions.setMessageAndType({ message: `Codice OTP inviato con successo.` }));
                dispatch(otpActions.setOtp(new Array(4).fill('')));
            }
            dispatch(snackbarActions.setIsOpen(true));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (isDisabled || otp.join('').length !== 4) return;
        const onEnterHandler = (e: KeyboardEvent) => {
            if (e.key === 'Enter') onSubmitHandler();
        };
        window.addEventListener('keydown', onEnterHandler);
        return () => {
            window.removeEventListener('keydown', onEnterHandler);
        };
    }, [otp, isDisabled]);

    return (
        <div className={classes.container}>
            <div className={classes.form}>
                <p className="small">
                    {`È stato inviato un codice verifica di 4 cifre al numero di telefono (*******${
                        telephoneNumber ?? ''
                    }) associato al tuo account. Inserisci il
                    codice per completare il processo di verifica.`}
                </p>
                <div className={classes.inputs}>
                    <OtpInput numberOfDigits={4} isError={otpError} setError={setOtpError} />
                </div>
                <div className={classes.btns}>
                    <TextButton className="secondary" onClick={onResendOtpHandler}>
                        Invia di nuovo
                    </TextButton>
                    <TextButton disabled={isDisabled || otp.join('').length !== 4} onClick={onSubmitHandler} className="primary">
                        Verifica codice
                    </TextButton>
                </div>
            </div>
            <Snackbar />
        </div>
    );
};

export default VerifyOtp;

