import { SvgIconProps } from 'helpers';

export const ExpandSection: SvgIconProps = ({ fill }) => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.06066 18.75L12.1553 23.8447L11.0947 24.9053L4.71967 18.5303C4.42678 18.2374 4.42678 17.7626 4.71967 17.4697L11.0947 11.0947L12.1553 12.1553L7.06066 17.25L28.9393 17.25L23.8447 12.1553L24.9053 11.0947L31.2803 17.4697C31.421 17.6103 31.5 17.8011 31.5 18C31.5 18.1989 31.421 18.3897 31.2803 18.5303L24.9053 24.9053L23.8447 23.8447L28.9393 18.75L7.06066 18.75Z"
                fill={fill}
            />
        </svg>
    );
};
