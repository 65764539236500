import { Checkbox, ListItemText } from '@mui/material';
import { CheckBoxChecked, CheckBoxUnchecked } from 'components/icons';
import GenericSelect from 'components/select/GenericSelect';
import { VehicleType } from 'models';
import { FC, useEffect, useMemo, useState } from 'react';
import { useLazyGetVehicleTypesQuery } from 'store/rtk/vehicleType.service';
import classes from './VehicleFilter.module.scss';

type VehicleFilterProps = FC<{
    activeFilters: string[];
    setActiveFilters: any;
    className?: string;
}>;

const LABELS: Record<string, string> = {
    car: 'Autoveicolo',
    motorbike: 'Motoveicolo',
    camper: 'Camper',
    bus: 'Bus',
    electricAuto: 'Auto elettrica',
    hybrid: 'Auto ibrida',
    gplAuto: 'Auto GPL'
};

const VehicleFilter: VehicleFilterProps = ({ activeFilters, setActiveFilters, className }) => {
    const [vehicleTypes, setVehicleTypes] = useState<VehicleType[]>([]);
    const [getVehicleTypes] = useLazyGetVehicleTypesQuery();

    useEffect(() => {
        (async () => {
            const res = await getVehicleTypes();
            if (!res.data) return;
            setVehicleTypes(res.data);
        })();
    }, [getVehicleTypes]);

    const selectOptions = useMemo(() => {
        return vehicleTypes.map(vehicleType => ({
            id: vehicleType.type,
            item: (
                <>
                    <Checkbox
                        checked={activeFilters.indexOf(vehicleType.type) > -1}
                        icon={<CheckBoxUnchecked />}
                        checkedIcon={<CheckBoxChecked />}
                    />
                    <ListItemText primary={LABELS[vehicleType.type]} />
                </>
            )
        }));
    }, [activeFilters, vehicleTypes]);

    return (
        <div className={`${classes.vehiclesContainer} ${className}`}>
            <p>Veicoli</p>
            <div className="small">
                <label>
                    <b>Utenti con i seguenti veicoli:</b>
                </label>
            </div>
            <GenericSelect
                placeholder="Tutti i veicoli"
                multiple
                value={activeFilters}
                onChange={selectedFilters => setActiveFilters('vehicleTypes', selectedFilters.target.value)}
                renderValue={(selected: any) => selected.map((key: any) => LABELS[key]).join(', ')}
                options={selectOptions}
                sxFormControl={{ width: '21rem !important' }}
            />
        </div>
    );
};

export default VehicleFilter;

