import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import PricingTime from '../../pricingTime/PricingTime';
import { CheckBoxChecked, CheckBoxUnchecked, Flat } from 'components/icons';
import { Checkbox, FormControlLabel } from '@mui/material';
import classes from './FlatPricing.module.scss';
import RadioButton from 'components/radioButton/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, createTariffRuleActions } from 'store';
import { isStringEmptyOrZero } from 'helpers';

const FLAT_DYNAMIC_OPTIONS = [
    { label: 'Giorni', key: 'days', type: 'number', validate: (slope: any) => slope.days > 0 && slope.days < 999 },
    { label: 'Ore', key: 'hours', type: 'number', validate: (slope: any) => slope.hours > 0 && slope.hours < 24 },
    { label: 'Minuti', key: 'minutes', type: 'number', validate: (slope: any) => slope.minutes > 0 && slope.minutes < 60 }
];

const FLAT_FIXED_OPTIONS = [
    {
        label: 'Dalle ore',
        key: 'fromHour',
        type: 'time',
        validate: (slope: any) => slope.fromHour.length > 0 && slope.fromHour < slope.toHour
    },
    { label: 'Alle ore', key: 'toHour', type: 'time', validate: (slope: any) => slope.toHour.length > 0 }
];

const FLAT_TYPE_OPTIONS = [
    { value: 'dynamic', label: 'Tempo' },
    { value: 'fixed', label: 'Orario fisso' }
];

type FlatPricingProps = FC<{
    slope: any;
    onInputChange: (key: string, value: any) => void;
    index: number;
}>;

const FlatPricing: FlatPricingProps = ({ index, onInputChange, slope }) => {
    const [stairwayType, setFlatType] = useState('dynamic');
    const { isSubmitted } = useSelector((x: RootState) => x.createTariffRule);
    const dispatch = useDispatch();

    const tariffs = useMemo(
        () => (
            <div>
                {!isStringEmptyOrZero(slope.days) ? slope.days + 'g ' : ''}
                {!isStringEmptyOrZero(slope.hours) ? slope.hours + 'h ' : ''}
                {!isStringEmptyOrZero(slope.minutes) ? slope.minutes + 'm' : ''}&nbsp;
            </div>
        ),
        [slope]
    );

    const fixedTariff = useMemo(() => {
        return (
            <div>
                {!isStringEmptyOrZero(slope.fromHour) ? slope.fromHour + ' ' : ''}-&nbsp;
                {!isStringEmptyOrZero(slope.toHour) ? slope.toHour : ''}&nbsp;
            </div>
        );
    }, [slope]);

    const flatDynamicOptions = useMemo(() => {
        const isValid = FLAT_DYNAMIC_OPTIONS.some(opt => opt.validate(slope));
        return FLAT_DYNAMIC_OPTIONS.map((opt: any) => {
            opt.isValid = !isSubmitted || isValid;
            return opt;
        });
    }, [isSubmitted, slope]);

    const flatFixedOption = useMemo(() => {
        const isValid = FLAT_FIXED_OPTIONS.every(opt => opt.validate(slope));
        return FLAT_FIXED_OPTIONS.map((opt: any) => {
            opt.isValid = !isSubmitted || isValid;
            return opt;
        });
    }, [isSubmitted, slope]);

    const leftRef = document.getElementById(`left-slope-${index}`);
    const rightRef = document.getElementById(`right-slope-${index}`);
    const headerRef = document.getElementById(`header-slope-${index}`);

    const onRadioChangeHandler = useCallback(
        (value: string) => {
            dispatch(
                createTariffRuleActions.clearSlopeField({
                    keys: [...FLAT_DYNAMIC_OPTIONS.map(opt => opt.key), ...FLAT_FIXED_OPTIONS.map(opt => opt.key)],
                    index
                })
            );
            setFlatType(value);
            onInputChange('multiplier', false);
        },
        [dispatch, index, onInputChange]
    );

    useEffect(() => {
        setFlatType(slope.fromHour.length > 0 ? 'fixed' : 'dynamic');
    }, [slope.fromHour.length]);

    if (!rightRef || !leftRef || !headerRef) return <></>;

    return (
        <>
            {createPortal(
                <>
                    <Flat />
                    &nbsp;
                    {tariffs}
                    {fixedTariff}
                    {!isStringEmptyOrZero(slope.amount) ? ' / ' + slope.amount + '€' : ''}
                    {slope.multiplier ? ', Moltiplica' : ''}
                </>,
                headerRef
            )}
            {createPortal(
                <>
                    <RadioButton onChange={onRadioChangeHandler} value={stairwayType} options={FLAT_TYPE_OPTIONS} />
                    {stairwayType === 'dynamic' && (
                        <PricingTime values={slope} onChange={onInputChange} label="" options={flatDynamicOptions} integerOnly />
                    )}
                    {stairwayType === 'fixed' && (
                        <PricingTime label="" onChange={onInputChange} values={slope} options={flatFixedOption} />
                    )}
                </>,
                leftRef
            )}
            {createPortal(
                <>
                    {stairwayType === 'dynamic' && (
                        <FormControlLabel
                            onChange={e => {
                                onInputChange('multiplier', (e.target as any).checked);
                            }}
                            checked={slope.multiplier}
                            control={<Checkbox icon={<CheckBoxUnchecked />} checkedIcon={<CheckBoxChecked />} />}
                            label={
                                <label className={`primary90 ${classes.multiplyCheckboxLabel}`}>
                                    Moltiplica <span className="primary60">(es. 5€ / 1g→10€ / 2g...)</span>
                                </label>
                            }
                        />
                    )}
                </>,
                rightRef
            )}
        </>
    );
};
export default FlatPricing;

