import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CustomerServiceItem } from 'helpers';
import { CustomerServiceStatusUpdate } from 'store/rtk/customerService.service';
export interface CustomerServiceState {
    customerServiceItems: CustomerServiceItem[];
    selectedCustomerService: CustomerServiceItem | undefined;
}

const name = 'customerService';
const initialState: CustomerServiceState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const customerServiceActions = { ...slice.actions };
export const customerServiceReducer = slice.reducer;

function createInitialState(): CustomerServiceState {
    return {
        customerServiceItems: [],
        selectedCustomerService: undefined
    };
}

function createReducers() {
    return {
        setCustomerServiceItems,
        setSelectedCustomerService,
        updateCustomerServiceStatus,
        updateSelectedCustomerServiceStatus,
        updateItemsUserStatus
    };

    function setCustomerServiceItems(state: CustomerServiceState, action: PayloadAction<CustomerServiceItem[]>) {
        state.customerServiceItems = action.payload.reduce(
            (acc, cur) => (acc.find(a => cur.id === a.id) ? acc : [...acc, cur]),
            [] as CustomerServiceItem[]
        );
    }

    function setSelectedCustomerService(state: CustomerServiceState, action: PayloadAction<{ id: string }>) {
        const selected = state.customerServiceItems.find(item => item.id === action.payload.id);
        if (!selected) return;
        state.selectedCustomerService = selected;
    }

    function updateItemsUserStatus(state: CustomerServiceState, action: PayloadAction<{ id: string; status: string }>) {
        const { id, status } = action.payload;
        if (state.selectedCustomerService?.userId === id) state.selectedCustomerService.User.status = status;
        state.customerServiceItems.forEach(item => {
            if (item.userId === id) item.User.status = status;
        });
    }

    function updateCustomerServiceStatus(state: CustomerServiceState, action: PayloadAction<CustomerServiceStatusUpdate>) {
        const index = state.customerServiceItems.findIndex(item => item.id === action.payload.id);
        if (index >= 0) state.customerServiceItems[index].status = action.payload.status;
    }

    function updateSelectedCustomerServiceStatus(
        state: CustomerServiceState,
        action: PayloadAction<{ status: string; id: string }>
    ) {
        const selected = state.customerServiceItems.find(item => item.id === action.payload.id);
        if (selected)
            state.selectedCustomerService = {
                ...selected,
                status: action.payload.status
            };
    }
}
