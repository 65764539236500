import { IconTextButton } from 'components/buttons';
import classes from './SettingsSidebar.module.scss';
import { Parking, Profile, Vector } from 'components/icons';
import { FC } from 'react';
import { SetState } from 'helpers';

export enum SettingsTypes {
    users = 'users',
    settings = 'settings',
    manageContent = 'manageContent'
}

type SettingsSidebarProps = FC<{
    activeTab: SettingsTypes;
    setActiveTab: SetState<SettingsTypes>;
}>;

const SettingsSidebar: SettingsSidebarProps = ({ activeTab, setActiveTab }) => {
    return (
        <div className={classes.container}>
            <IconTextButton
                className={`${activeTab === SettingsTypes.users ? classes.active : ''} ${classes.menuButton}`}
                icon={<Profile />}
                onClick={() => setActiveTab(SettingsTypes.users)}
            >
                Utenti e Ruoli
            </IconTextButton>
            <IconTextButton
                className={`${activeTab === SettingsTypes.manageContent ? classes.active : ''} ${classes.menuButton}`}
                icon={<Vector />}
                onClick={() => setActiveTab(SettingsTypes.manageContent)}
            >
                Gestione contenuti
            </IconTextButton>
            <IconTextButton
                className={`${activeTab === SettingsTypes.settings ? classes.active : ''} ${classes.menuButton}`}
                icon={<Parking />}
                onClick={() => setActiveTab(SettingsTypes.settings)}
            >
                Impostazioni
            </IconTextButton>
        </div>
    );
};

export default SettingsSidebar;

