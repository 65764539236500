import { FC } from 'react';

export const SlopeReprise: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 3.25C3.58579 3.25 3.25 3.58579 3.25 4V17.25C3.25 17.6642 3.58579 18 4 18H17.25C17.6642 18 18 17.6642 18 17.25V4C18 3.58579 17.6642 3.25 17.25 3.25H4ZM10.0833 8.25C9.65377 8.25 9.30554 8.59823 9.30554 9.02779C9.30554 9.45735 9.65377 9.80558 10.0833 9.80558H11C12.258 9.80558 13.2778 10.8254 13.2778 12.0833C13.2778 13.3413 12.258 14.3611 11 14.3611H8.24999V12.8611H11C11.4295 12.8611 11.7778 12.5129 11.7778 12.0833C11.7778 11.6538 11.4295 11.3056 11 11.3056H10.0833C8.82534 11.3056 7.80554 10.2858 7.80554 9.02779C7.80554 7.7698 8.82534 6.75 10.0833 6.75H12.8333V8.25H10.0833Z"
                fill="#E6F3FF"
            />
            <path d="M21 20.25C21 20.6642 20.6642 21 20.25 21H7V19.5H19.5V7H21V20.25Z" fill="#E6F3FF" />
        </svg>
    );
};

