import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const ArrowForward: SvgIconProps = ({ fill = colors.primary100 }) => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.06 28.06L12 27L21.47 17.53L12 8.06L13.06 7L23.06 17C23.35 17.29 23.35 17.77 23.06 18.06L13.06 28.06Z"
                fill={fill}
            />
        </svg>
    );
};

