import { IconTextButton } from 'components/buttons';
import Card from 'components/card/Card';
import { Plus, Stallo } from 'components/icons';
import { Area } from 'models';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, TabTypes, drawActions, municipalityActions } from 'store';
import classes from './AreaList.module.scss';
import { usePermissionHandler } from 'hook';
import { FC } from 'react';
import { useGetAllAreaByMunicipalityQuery } from 'store/rtk/area.service';
import NoResult from 'components/noResult/NoResult';

type AreaListProps = {
    searchedArea: string;
    inProgress: string;
    status: string;
};

const AreaList: FC<AreaListProps> = ({ searchedArea, inProgress, status }) => {
    const { municipality } = useSelector((x: RootState) => x.municipalities);
    const { data, isFetching } = useGetAllAreaByMunicipalityQuery(
        { municipalityId: municipality?.id!, searchedArea, inProgress, status },
        { skip: !municipality }
    );
    const dispatch = useDispatch();
    const hasPermission = usePermissionHandler();

    const onCardHandler = (area: Area) => {
        return () => {
            const tariff = municipality?.Tariffs.find(tariff => tariff.id === (area as any).tariffId);
            dispatch(municipalityActions.setSelectedArea({ ...area, tariffName: tariff?.name || '' }));
            dispatch(drawActions.zoomOnBbox(area.geometry));
        };
    };
    const createAreaHandler = () => dispatch(municipalityActions.toggleCreateArea());

    return (
        <>
            {!isFetching && data && data.length > 0 ? (
                data.map(area => (
                    <Card
                        key={area.id}
                        chips={[{ icon: <Stallo />, label: `${area?.parkingSpaces ?? 0}` }]}
                        onClick={onCardHandler(area)}
                    >
                        {area.code} - {area.name}
                    </Card>
                ))
            ) : (
                <NoResult />
            )}
            <IconTextButton
                disabled={!hasPermission(TabTypes.municipalitySettings, ['can-create'])}
                className={`primary ${classes.createBtn}`}
                icon={<Plus />}
                onClick={createAreaHandler}
            >
                Aggiungi area
            </IconTextButton>
        </>
    );
};
export default AreaList;

