import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from 'environments';
import { CustomerServiceItem } from 'helpers';
import { store } from 'store';
export interface CustomerServiceStatusUpdate {
    id: string;
    status: string;
}

export const customerServiceApi = createApi({
    reducerPath: 'customerService-api',
    baseQuery: fetchBaseQuery({
        fetchFn(input, init) {
            const authState = store.getState().auth.token as any;
            return fetch(input, { ...init, headers: { authorization: authState!, 'content-type': 'application/json' } });
        },
        baseUrl: `${env.reactAppSocketUrl}${env.apiUrl}`
    }),
    endpoints: builder => ({
        getAllCustomerService: builder.query<CustomerServiceItem[], { offset: number }>({
            query: params => ({
                url: '/customers-service',
                method: 'GET',
                params: params
            }),
            keepUnusedDataFor: 10
        }),
        countNewCustomerServiceRequest: builder.query<{ numberOfRequest: number }, any>({
            query: () => ({
                url: '/customers-service/new-requests',
                method: 'GET'
            }),
            keepUnusedDataFor: 10
        }),
        updateCustomerServiceStatus: builder.query<any, CustomerServiceStatusUpdate>({
            query: body => ({
                url: '/customers-service',
                method: 'PUT',
                body
            }),
            keepUnusedDataFor: 10
        })
    })
});

export const {
    useLazyUpdateCustomerServiceStatusQuery,
    useUpdateCustomerServiceStatusQuery,
    useGetAllCustomerServiceQuery,
    useLazyGetAllCustomerServiceQuery,
    useCountNewCustomerServiceRequestQuery,
    useLazyCountNewCustomerServiceRequestQuery
} = customerServiceApi;
