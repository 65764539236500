import TextButton from 'components/buttons/textButton/TextButton';
import { AlertCircle } from 'components/icons/AlertCircle';
import GenericSelect from 'components/select/GenericSelect';
import { FormFields } from 'helpers';
import parsePhoneNumber from 'libphonenumber-js';
import { ChangeEvent, FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInputWithCountrySelect, { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import it from 'react-phone-number-input/locale/it.json';
import 'react-phone-number-input/style.css';
import classes from './Form.module.scss';

type FormProps = FC<
    PropsWithChildren<{
        onSubmitHandler: any;
        formFields: FormFields[];
        onChange?: (name: string, value: ChangeEvent<any>) => any;
        buttonLabel?: string;
        undoAction?: () => any;
        errorMessage?: string;
    }>
>;

const Form: FormProps = ({
    onSubmitHandler,
    children,
    formFields,
    onChange = () => {},
    buttonLabel = 'Invia',
    undoAction,
    errorMessage
}) => {
    const {
        register,
        handleSubmit,
        control,
        reset,
        watch,
        trigger,
        formState: { isValid, errors, dirtyFields }
    } = useForm({ mode: 'all' });

    const values = watch();

    const fieldHandler = useCallback(
        (field: FormFields) => {
            const onChangeHandler = (field: string) => (value: any) => {
                onChange(field, value);
                trigger();
            };

            if (field.options) {
                return (
                    <div className={classes.select}>
                        <GenericSelect
                            register={register(field.id, { required: field.required, onChange: onChangeHandler(field.id) })}
                            options={field.options}
                            value={values[field.id] || field.value}
                            placeholder={field.placeholder}
                        />
                    </div>
                );
            }
            if (field.id === 'telephoneNumber') {
                return (
                    <Controller
                        name="countryCode"
                        defaultValue={field.prefix ? parsePhoneNumber(`${field.prefix}00000`)?.country ?? 'IT' : 'IT'}
                        control={control}
                        rules={{
                            required: field.required
                        }}
                        render={({ field: { onChange: onCountryCodeChange, value: countryCode } }) => (
                            <Controller
                                name="telephoneNumber"
                                defaultValue={field.value}
                                control={control}
                                rules={{
                                    required: field.required,
                                    validate: value => isValidPhoneNumber(value)
                                }}
                                render={({ field: { onChange, value } }) => {
                                    const prefix = `+${getCountryCallingCode(countryCode) ?? 39}`;
                                    const valueToParse = (value as string)?.startsWith('+') ? value : `${prefix}${value}`;
                                    const parsedValue = parsePhoneNumber(valueToParse);
                                    const inputValue = parsedValue?.countryCallingCode ? `${parsedValue.number}` : prefix;
                                    return (
                                        <PhoneInputWithCountrySelect
                                            labels={it}
                                            value={inputValue}
                                            phoneDigits={inputValue}
                                            onChange={onChange}
                                            onCountryChange={onCountryCodeChange}
                                            defaultCountry={'IT'}
                                            className={`${errors[field.id] && dirtyFields[field.id] ? classes.phoneInput : ''}`}
                                        />
                                    );
                                }}
                            />
                        )}
                    />
                );
            }
            if (field.type === 'number')
                return (
                    <input
                        className={`${classes.formInput} ${errors[field.id] && dirtyFields[field.id] ? classes.error : ''}`}
                        defaultValue={field.value ? field.value : null}
                        type="number"
                        {...register(field.id, {
                            required: field.required,
                            onChange: onChangeHandler(field.id),
                            ...field.validate
                        })}
                        placeholder={field.placeholder}
                    />
                );
            return (
                <input
                    className={`${classes.formInput} ${errors[field.id] && dirtyFields[field.id] ? classes.error : ''}`}
                    defaultValue={field.value ? field.value : null}
                    type={['password', 'confirmPassword'].includes(field.id) ? 'password' : 'text'}
                    {...register(field.id, {
                        required: field.required,
                        onChange: onChangeHandler(field.id),
                        ...field.validate
                    })}
                    placeholder={field.placeholder}
                />
            );
        },
        [register, errors, values, control, onChange, trigger, dirtyFields]
    );

    const undoHandler = useCallback(() => {
        if (!undoAction) return;
        undoAction();
        reset();
    }, [reset, undoAction]);

    const fields = useMemo(
        () =>
            formFields.map((field: FormFields) => {
                return (
                    <div className={`${classes.field} ${classes.formInput}`} key={field.id}>
                        <label htmlFor={field.id}>
                            <p className="small">
                                <b>{field.label}</b>
                            </p>
                        </label>
                        {fieldHandler(field)}
                    </div>
                );
            }),
        [fieldHandler, formFields]
    );

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className={classes.containerFields}>
                <div className={classes.fields}>
                    {fields}
                    {errorMessage && (
                        <div className={classes.alertPassword}>
                            <div>
                                <AlertCircle />{' '}
                            </div>
                            <p className={`small ${classes.errorMessage}`}> {errorMessage}</p>
                        </div>
                    )}
                    {children}
                </div>
                <div id="side-form-root"></div>
            </div>
            <div className={classes.buttons}>
                {undoAction && (
                    <TextButton className="tertiary" type="button" onClick={undoHandler}>
                        Annulla
                    </TextButton>
                )}
                <TextButton className="primary" type="submit" disabled={!isValid}>
                    {buttonLabel}
                </TextButton>
            </div>
        </form>
    );
};

export default Form;

