import { ArrowDown } from 'components/icons';
import { AreaDateFilter, DAY_IN_MS } from 'helpers';
import { FC, useCallback, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux';
import { RootState, TabState, TabTypes, TableTypes } from 'store';
import { FiltersState } from 'store/filters.slice';
import classes from './PeriodOfTimeFilter.module.scss';

interface CustomizeText {
    label: string;
    placeholder: string;
}

const DEFAULT_TEXT_VALUE_FIRST_PICKER: CustomizeText = {
    label: 'Da',
    placeholder: 'Data'
};
const DEFAULT_TEXT_VALUE_SECOND_PICKER: CustomizeText = {
    label: 'A',
    placeholder: 'Data'
};

type PeriodOfTimeFilterProps = FC<{
    activeFilter: AreaDateFilter;
    setActiveFilter: any;
    customClasses?: string;
    customTextFirstPicker?: CustomizeText;
    customTextSecondPicker?: CustomizeText;
}>;

const PeriodOfTimeFilter: PeriodOfTimeFilterProps = ({
    activeFilter,
    setActiveFilter,
    customClasses = '',
    customTextFirstPicker = DEFAULT_TEXT_VALUE_FIRST_PICKER,
    customTextSecondPicker = DEFAULT_TEXT_VALUE_SECOND_PICKER
}) => {
    const { date } = useSelector<RootState>(x => x.filters) as FiltersState;
    const { activeTab, activeTable } = useSelector<RootState>(x => x.tab) as TabState;

    const currentDate = useMemo(() => {
        if ([TabTypes.municipalitySettings, TabTypes.billing, TabTypes.customerService].includes(activeTab)) return activeFilter;
        if ([TableTypes.areas, TableTypes.stops].includes(activeTable)) return date;
        return activeFilter;
    }, [activeFilter, activeTab, activeTable, date]);

    const startDate = useMemo(() => (currentDate.startDate ? new Date(currentDate.startDate) : null), [currentDate]);

    const endDate = useMemo(() => (currentDate.endDate ? new Date(currentDate.endDate - DAY_IN_MS) : null), [currentDate]);

    const error = useMemo(() => (startDate && endDate ? startDate.getTime() > endDate.getTime() : false), [startDate, endDate]);

    const onSelectHandler = useCallback(
        (key: string, value: number) => {
            if (!value) {
                setActiveFilter('date', { [key]: 0 });
                return;
            }
            setActiveFilter('date', { [key]: value + (key === 'endDate' ? DAY_IN_MS : 0) });
        },
        [setActiveFilter]
    );

    return (
        <div className={`${classes.dateContainer} ${customClasses}`}>
            <div className={classes.pickerContainer}>
                <label className={`small ${error ? classes.labelError : ''}`}>
                    <b>{customTextFirstPicker.label}</b>
                </label>
                <div className={classes.datePickerContainer}>
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        className={`${error ? classes.dateError : ''}`}
                        placeholderText={customTextFirstPicker.placeholder}
                        wrapperClassName="datePicker"
                        selected={startDate}
                        onChange={date => onSelectHandler('startDate', date?.getTime()!)}
                    />
                    <ArrowDown />
                </div>
            </div>
            <div className={classes.pickerContainer}>
                <label className={`small ${error ? classes.labelError : ''}`}>
                    <b>{customTextSecondPicker.label}</b>
                </label>
                <div className={classes.datePickerContainer}>
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        className={`${error ? classes.dateError : ''}`}
                        placeholderText={customTextSecondPicker.placeholder}
                        wrapperClassName="datePicker"
                        selected={endDate}
                        onChange={date => onSelectHandler('endDate', date?.getTime()!)}
                    />
                    <ArrowDown />
                </div>
            </div>
        </div>
    );
};

export default PeriodOfTimeFilter;

