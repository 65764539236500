import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const ArrowUp: SvgIconProps = ({ fill = colors.primary100 }) => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.6089 20.6087L17.6089 13.6087C17.8989 13.3187 18.3789 13.3187 18.6689 13.6087L25.6689 20.6087L24.6089 21.6687L18.1389 15.1987L11.6689 21.6687L10.6089 20.6087Z"
                fill={fill}
            />
        </svg>
    );
};

