import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import TariffAccordion from 'components/tariffAccordion/TariffAccordion';
import TariffAccordionTypography from 'components/tariffAccordionTypography/tariffAccordionTypography';
import { FC, useEffect, useMemo } from 'react';
import classes from './Vehicle.module.scss';
import { CheckBoxChecked, CheckBoxUnchecked } from 'components/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, createTariffRuleActions } from 'store';
import { useGetVehicleTypesQuery } from 'store/rtk/vehicleType.service';

const LABELS = {
    car: 'Autoveicoli',
    motorbike: 'Motoveicoli',
    camper: 'Camper',
    bus: 'Bus',
    electricAuto: 'Auto elettriche',
    hybrid: 'Auto ibride',
    gplAuto: 'Auto GPL'
};

const Vehicle: FC = () => {
    const dispatch = useDispatch();
    const { vehicleIds, isValid, isSubmitted } = useSelector((x: RootState) => x.createTariffRule);
    const { selectedRule } = useSelector((x: RootState) => x.tariffs);
    const { data: vehicles } = useGetVehicleTypesQuery();

    const mappedVehicles = useMemo(() => {
        if (!vehicles) return <></>;
        return vehicles.map(vehicle => (
            <FormControlLabel
                key={vehicle.id}
                checked={vehicleIds.includes(vehicle.id)}
                control={
                    <Checkbox
                        value={vehicle.id}
                        className={isSubmitted && !isValid.vehicles ? classes.error : ''}
                        icon={<CheckBoxUnchecked />}
                        checkedIcon={<CheckBoxChecked />}
                    />
                }
                label={LABELS[vehicle.type] || vehicle.type}
            />
        ));
    }, [isSubmitted, isValid.vehicles, vehicleIds, vehicles]);
    useEffect(() => {
        if (!selectedRule) return;
        dispatch(createTariffRuleActions.setRuleVehicles(selectedRule.VehicleTypes.map((vehicle: any) => vehicle.id)));
    }, [dispatch, selectedRule]);

    useEffect(() => {
        dispatch(createTariffRuleActions.setIsValid({ key: 'vehicles', isValid: vehicleIds.length > 0 }));
    }, [dispatch, vehicleIds]);

    return (
        <TariffAccordion
            title="Veicoli"
            buttonTitle="Aggiungi"
            customClasses={classes.fieldOrganizerAccordion}
            defaultOpen={!!selectedRule}
        >
            <p className={`small ${classes.subtitle}`}>Applica la regola a:</p>
            <TariffAccordionTypography customClasses={classes.fieldOrganizerTypography}>
                <FormGroup onChange={(event: any) => dispatch(createTariffRuleActions.toggleRuleVehicles(event.target.value))}>
                    {mappedVehicles}
                </FormGroup>
            </TariffAccordionTypography>
        </TariffAccordion>
    );
};

export default Vehicle;
