import { Search } from 'components/icons';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import classes from './Searchbar.module.scss';

type SearchbarProps = FC<{
    value: any;
    onChange: (value: any) => any;
}>;

const Searchbar: SearchbarProps = ({ value, onChange }) => {
    const [inputValue, setInputValue] = useState(value);
    const onChangeInputHandler = useCallback(({ target }: ChangeEvent<HTMLInputElement>): void => {
        setInputValue(target.value);
    }, []);

    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            if (inputValue === value) return;
            onChange(inputValue);
        }, 500);
        return () => clearTimeout(delayInputTimeoutId);
    }, [inputValue]);

    return (
        <div className={classes.container}>
            <Search />
            <input defaultValue={inputValue} onChange={onChangeInputHandler} type="search" placeholder="Ricerca..." />
        </div>
    );
};

export default Searchbar;

