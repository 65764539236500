import AccordionTypography from 'components/accordionTypography/AccordionTypography';
import classes from './Billing.module.scss';
import Accordion from 'components/accordion/Accordion';
import { FC } from 'react';
import { CompanyInformation } from 'models/companyInformation.model';

type BillingProps = FC<{
    billingInfo: CompanyInformation;
}>;

const Billing: BillingProps = ({ billingInfo }) => {
    if (!billingInfo) return <></>;
    return (
        <div className={classes.accordionContainer}>
            <Accordion title="Fatturazione">
                <AccordionTypography>
                    <div className={classes.field}>
                        <div>
                            <label className="small">
                                <b>Ragione Sociale</b>
                            </label>
                            <p>{billingInfo.business_name}</p>
                        </div>
                        <div>
                            <label className="small">
                                <b>Indirizzo</b>
                            </label>
                            <p>{billingInfo.address}</p>
                        </div>
                        <div>
                            <label className="small">
                                <b>CAP</b>
                            </label>
                            <p>{billingInfo.cap}</p>
                        </div>
                    </div>
                    <div className={classes.field}>
                        <div>
                            <label className="small">
                                <b>P.IVA / Codice Fiscale</b>
                            </label>
                            <p className={classes.ellipsis}>{billingInfo.vat_number}</p>
                        </div>
                        <div>
                            <label className="small">
                                <b>Città</b>
                            </label>
                            <p>{billingInfo.municipality}</p>
                        </div>
                        <div>
                            <label className="small">
                                <b>Nazione</b>
                            </label>
                            <p>{billingInfo.nation}</p>
                        </div>
                    </div>
                    <div className={classes.field}>
                        <div>
                            <label className="small">
                                <b>PEC / SDI</b>
                            </label>
                            <p>{billingInfo.sdi ? billingInfo.sdi : billingInfo.pec}</p>
                        </div>
                        <div>
                            <label className="small">
                                <b>Provincia</b>
                            </label>
                            <p>{billingInfo.province}</p>
                        </div>
                    </div>
                </AccordionTypography>
            </Accordion>
        </div>
    );
};

export default Billing;

