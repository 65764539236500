import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Billing: SvgIconProps = ({ fill = colors.primary90 }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="receipt">
                <g id="Icons">
                    <path
                        id="Vector"
                        d="M23.1004 16.8997H18.3978V0.686237C18.3978 0.457491 18.2901 0.237896 18.1016 0.118948C17.9131 -0.00914983 17.6798 -0.0274495 17.4644 0.054899L13.4618 1.77507L9.45914 0.054899C9.28863 -0.0182997 9.10016 -0.0182997 8.93862 0.054899L4.93599 1.77507L0.93335 0.054899C0.726936 -0.0365993 0.484624 -0.00914983 0.296159 0.109798C0.107694 0.237896 0 0.448342 0 0.677087V20.578C0 22.472 1.51669 24 3.36544 24H20.4081C22.2658 24 23.7735 22.4628 23.7735 20.5688V17.5768C23.7735 17.2017 23.4684 16.8906 23.1004 16.8906V16.8997ZM3.36544 22.6367C2.2526 22.6367 1.34618 21.7125 1.34618 20.578V1.72017L4.67572 3.14754C4.84624 3.22074 5.0347 3.22074 5.19625 3.14754L9.19888 1.42737L13.2015 3.14754C13.372 3.22074 13.5605 3.22074 13.722 3.14754L17.0516 1.72017V20.578C17.0516 21.3465 17.2939 22.0602 17.7247 22.6367H3.36544ZM22.4273 20.578C22.4273 21.7125 21.5209 22.6367 20.4081 22.6367C19.2952 22.6367 18.3888 21.7125 18.3888 20.578V18.2722H22.4273V20.578Z"
                        fill={fill}
                    />
                    <path id="Vector_2" d="M15.7053 15.326H2.69226V16.6985H15.7053V15.326Z" fill={fill} />
                    <path id="Vector_3" d="M14.3593 18.7571H4.03857V20.1296H14.3593V18.7571Z" fill={fill} />
                    <path
                        id="Vector_4"
                        d="M9.28852 11.0621C8.36415 11.0621 7.97824 10.7327 7.79875 10.074H6.46155C6.59617 11.0438 7.16156 11.9771 8.48081 12.2425V13.377H9.80904V12.2791C10.7783 12.1327 11.7655 11.5471 11.7655 10.2386C11.7655 8.93022 10.886 8.53678 9.14493 8.15249C8.29235 7.96034 7.96029 7.86884 7.96029 7.4754C7.96029 7.04536 8.29235 6.70681 9.08211 6.70681C9.87186 6.70681 10.2039 7.03621 10.3475 7.50285H11.6937C11.5591 6.56957 11.0206 5.72778 9.79109 5.50819V4.39191H8.47184V5.49904C7.46669 5.70033 6.62309 6.36827 6.62309 7.53945C6.62309 8.84787 7.66413 9.23217 8.82185 9.47921C9.96161 9.73541 10.4193 9.79946 10.4193 10.2935C10.4193 10.7876 10.0244 11.053 9.27955 11.053L9.28852 11.0621Z"
                        fill={fill}
                    />
                </g>
            </g>
        </svg>
    );
};

