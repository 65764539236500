export const AreaSettingsFilled = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2404_18769)">
                <path
                    d="M19.3881 26.0259C19.5105 26.2426 19.7458 26.384 19.9999 26.384C20.254 26.384 20.4893 26.2521 20.6117 26.0259L25.5058 17.5453C26.0611 16.5842 26.3529 15.4817 26.3529 14.3604C26.3529 10.8551 23.5011 8 19.9999 8C16.4987 8 13.647 10.8551 13.647 14.3604C13.647 15.4817 13.9387 16.5748 14.494 17.5453L19.3881 26.0259ZM19.9999 11.298C21.6846 11.298 23.0587 12.6737 23.0587 14.3604C23.0587 16.0471 21.6846 17.4229 19.9999 17.4229C18.3152 17.4229 16.9411 16.0471 16.9411 14.3604C16.9411 12.6737 18.3152 11.298 19.9999 11.298Z"
                    fill="#F8FD00"
                />
                <path
                    d="M31.6894 23.1709L26.0612 19.4111L25.4024 20.5607L21.8447 26.7327C21.4682 27.3923 20.7624 27.7975 20.0094 27.7975C19.2565 27.7975 18.5506 27.3923 18.1741 26.7421L14.6165 20.5701L13.9576 19.4206L8.31059 23.1709C8.11294 23.3028 8 23.5195 8 23.7551C8 23.9906 8.11294 24.2168 8.31059 24.3393L19.6047 31.8776C19.7271 31.9529 19.8588 32.0001 20 32.0001C20.1412 32.0001 20.2729 31.9624 20.3953 31.8776L31.6894 24.3393C31.8871 24.2074 32 23.9906 32 23.7551C32 23.5195 31.8871 23.2933 31.6894 23.1709Z"
                    fill="#F8FD00"
                />
            </g>
            <defs>
                <clipPath id="clip0_2404_18769">
                    <rect width="40" height="40" rx="8" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
