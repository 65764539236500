import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Download: SvgIconProps = ({ fill = colors.primary100 }) => {
    return (
        <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="download">
                <g id="Vector">
                    <path
                        d="M24.0321 16.4269L18.7499 21.709V9.22803H17.2499V21.709L11.9677 16.4269L10.9071 17.4875L17.4696 24.05C17.6102 24.1907 17.801 24.2697 17.9999 24.2697C18.1988 24.2697 18.3896 24.1907 18.5302 24.05L25.0927 17.4875L24.0321 16.4269Z"
                        fill={fill}
                    />
                    <path d="M29.6666 27.7697V26.2697H6.33325V27.7697H29.6666Z" fill={fill} />
                </g>
            </g>
        </svg>
    );
};

