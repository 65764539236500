import { FC } from 'react';

export const Spinner: FC = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 24.5C19.1274 24.5 24.5 19.1274 24.5 12.5C24.5 5.87258 19.1274 0.5 12.5 0.5C5.87258 0.5 0.5 5.87258 0.5 12.5C0.5 19.1274 5.87258 24.5 12.5 24.5ZM12.5 22.5C18.0228 22.5 22.5 18.0228 22.5 12.5C22.5 6.97715 18.0228 2.5 12.5 2.5C6.97715 2.5 2.5 6.97715 2.5 12.5C2.5 18.0228 6.97715 22.5 12.5 22.5Z"
                fill="url(#paint0_angular_9886_44774)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.2823 10.1006C23.8301 10.0299 24.3314 10.4167 24.402 10.9644C24.4677 11.4736 24.5007 11.9865 24.5007 12.4999C24.5007 13.0522 24.053 13.4999 23.5007 13.4999C22.9484 13.4999 22.5007 13.0522 22.5007 12.4999C22.5007 12.0721 22.4732 11.6447 22.4185 11.2204C22.3478 10.6726 22.7346 10.1713 23.2823 10.1006Z"
                fill="#509FFD"
            />
            <defs>
                <radialGradient
                    id="paint0_angular_9886_44774"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(12.5 12.5) scale(12)"
                >
                    <stop stopColor="#1072E7" stopOpacity="0" />
                    <stop offset="1" stopColor="#509FFD" />
                </radialGradient>
            </defs>
        </svg>
    );
};

