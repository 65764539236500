export const Info = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.9994 10C16.4171 10.0001 14.8705 10.4694 13.555 11.3485C12.2395 12.2277 11.2142 13.4772 10.6088 14.939C10.0034 16.4008 9.84505 18.0093 10.1538 19.5611C10.4626 21.1129 11.2245 22.5383 12.3434 23.6571C13.4622 24.7759 14.8877 25.5377 16.4395 25.8463C17.9913 26.1549 19.5999 25.9965 21.0616 25.391C22.5234 24.7855 23.7728 23.7601 24.6518 22.4445C25.5308 21.1289 26 19.5822 26 18C26 16.9494 25.7931 15.909 25.391 14.9384C24.9889 13.9678 24.3996 13.0858 23.6566 12.3429C22.9137 11.6001 22.0317 11.0108 21.061 10.6088C20.0903 10.2068 19.05 9.99992 17.9994 10ZM17.9994 12.2606C18.2465 12.2606 18.4881 12.3339 18.6936 12.4712C18.8991 12.6084 19.0593 12.8035 19.1539 13.0318C19.2485 13.2601 19.2733 13.5114 19.2252 13.7538C19.1771 13.9962 19.0582 14.2189 18.8836 14.3937C18.7089 14.5685 18.4863 14.6877 18.244 14.736C18.0016 14.7844 17.7504 14.7598 17.522 14.6654C17.2936 14.571 17.0983 14.4111 16.9608 14.2057C16.8233 14.0003 16.7498 13.7588 16.7496 13.5117C16.7496 13.1802 16.8812 12.8622 17.1156 12.6278C17.3499 12.3933 17.6678 12.2608 17.9994 12.2606ZM20.7922 23.0993H15.2052V21.3942H17.1461V17.5414H15.2052V15.8363H17.9994C18.2252 15.8366 18.4417 15.9265 18.6014 16.0862C18.7611 16.2459 18.8509 16.4623 18.8513 16.6882V21.3942H20.7922V23.0993Z"
                fill="#C4DFFF"
            />
        </svg>
    );
};
