import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from 'environments';
import { store } from 'store';

export const authApi = createApi({
    reducerPath: 'auth-api',
    baseQuery: fetchBaseQuery({
        fetchFn(input, init) {
            const authState = store.getState().auth.token as any;
            return fetch(input, { ...init, headers: { authorization: authState!, 'content-type': 'application/json' } });
        },
        baseUrl: `${env.reactAppSocketUrl}${env.apiUrl}`
    }),
    endpoints: builder => ({
        getExtractionToken: builder.query<{ token: string }, void>({
            query: () => ({
                url: '/auth/extraction-token',
                method: 'GET'
            }),
            keepUnusedDataFor: 30
        })
    })
});

export const { useGetExtractionTokenQuery, useLazyGetExtractionTokenQuery } = authApi;

