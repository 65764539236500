import { User, UserUpdate } from 'models';
import { FC, PropsWithChildren, createContext, useCallback, useContext } from 'react';
import { AxiosContext } from './axiosContext';

interface UserApi {
    confirmEmail: (userId: string, email: string, otpToCompare: string) => Promise<string>;
    updatePassword: (userId: string, password: string, otpToCompare: string) => Promise<{ data: string }>;
    updateUser: (update: User) => Promise<any>;
    deleteUsers: (usersId: string[]) => Promise<string>;
}

const UserContext = createContext<UserApi>({} as UserApi);

const UserProvider: FC<PropsWithChildren> = ({ children }) => {
    const { postRequest, putRequest, deleteRequest } = useContext(AxiosContext);

    const confirmEmail = useCallback(
        async (userId: string, email: string, otpToCompare: string) => {
            const { data } = await postRequest(`/users/confirm-email`, {
                userId,
                email,
                otpToCompare
            });
            return data;
        },
        [postRequest]
    );

    const updatePassword = useCallback(
        async (userId: string, password: string, otp: string) => {
            const res = await putRequest(`/users/backoffice-update-password`, {
                userId,
                password,
                otp
            });
            return res;
        },
        [putRequest]
    );

    const updateUser = useCallback(
        async (update: UserUpdate) => {
            const res = await putRequest(`/users/backoffice`, {
                id: update.id,
                email: update.email,
                firstName: update.firstName,
                lastName: update.lastName,
                role: update.role,
                prefix: update.prefix,
                telephoneNumber: update.telephoneNumber,
                municipalityIds: update.Municipalities
            }).catch(error => {
                console.log(error);
            });
            return res;
        },
        [putRequest]
    );

    const deleteUsers = useCallback(
        async (usersId: string[]) => {
            const res = await deleteRequest(`/users`, { usersId });
            return res.data;
        },
        [deleteRequest]
    );

    return (
        <UserContext.Provider value={{ confirmEmail, updatePassword, updateUser, deleteUsers }}>{children}</UserContext.Provider>
    );
};

export { UserContext, UserProvider };

