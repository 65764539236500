export const Logout = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1417_5075)">
                <path
                    d="M12.858 19.3299V20.7417L29.7359 20.7417L26.2388 24.848L27.3496 25.7506L31.8336 20.4847C31.9412 20.3583 32 20.1989 32 20.0344C32 19.8698 31.9412 19.7105 31.8336 19.584L27.3516 14.3219L26.2406 15.2245L29.7378 19.3299L12.858 19.3299Z"
                    fill="#8DBEF9"
                />
                <path
                    d="M22.9082 32L22.9082 28.2353L21.4655 28.2353L21.4655 30.5882L9.44282 30.5882L9.44282 9.41177L21.4655 9.41176L21.4655 11.7647L22.9082 11.7647L22.9082 8L8.0001 8L8.0001 32L22.9082 32Z"
                    fill="#8DBEF9"
                />
            </g>
            <defs>
                <clipPath id="clip0_1417_5075">
                    <rect width="40" height="40" rx="8" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
