import { Feature } from '@loaders.gl/schema';
import { TextLayer, GeoJsonLayer, Layer } from 'deck.gl/typed';
import AreasLayer from 'helpers/layers/areasLayer';
import { FC, PropsWithChildren, createContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, TabTypes, areasActions, drawActions } from 'store';
import { centroid } from 'turf';

interface MapApi {
    areasLayer: Layer;
}

const MapContext = createContext<MapApi>({} as MapApi);

const MapProvider: FC<PropsWithChildren> = ({ children }) => {
    const { activeTab } = useSelector((x: RootState) => x.tab);
    const { mode } = useSelector((x: RootState) => x.draw);
    const { selectedArea, pickedArea } = useSelector((x: RootState) => x.areas);
    const { isCreateAreaOpen, selectedArea: selectedEditArea, isEditingArea } = useSelector((x: RootState) => x.municipalities);
    const { token } = useSelector((x: RootState) => x.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if (activeTab === TabTypes.municipalitySettings) dispatch(areasActions.setPickedArea(undefined));
    }, [activeTab, dispatch]);

    const areasLayer = useMemo(() => {
        return new AreasLayer({
            id: `areas-layer-${Date.now()}`,
            data: [`/api/v1/areas/tiles/{z}/{x}/{y}.mvt?newerThen=${Date.now()}`],
            minZoom: 5,
            maxZoom: 19,

            binary: false,
            pickable: !isCreateAreaOpen && !isEditingArea,

            loadOptions: {
                fetch: {
                    headers: {
                        Authorization: token
                    }
                }
            },

            updateTriggers: {
                getFillColor: [selectedArea?.id, pickedArea?.id, selectedEditArea, isEditingArea]
            },

            getFillColor: [80, 159, 0, 50],
            getLineColor: [80, 159, 253, 255],
            getLineWidth: (f: any) => {
                if (f.properties.layerName === 'clusters') return 40;
                else return 1;
            },
            getPointRadius: 1000,
            lineWidthMinPixels: 1,
            lineJointRounded: true,

            onClick(pickingInfo, _event) {
                if (pickingInfo.object?.type === 'Feature') {
                    const feature: Feature = pickingInfo.object;
                    if (feature.geometry.type === 'Point') {
                        dispatch(
                            drawActions.zoomOnBbox({
                                ...feature.geometry,
                                coordinates: feature.properties?.lat
                                    ? [feature.properties?.lng, feature.properties?.lat]
                                    : pickingInfo.coordinate,
                                maxZoom: feature.properties?.count === 1 ? 16 : feature.properties?.expansionZoom + 1
                            })
                        );
                    } else if (feature.geometry.type === 'Polygon') {
                        dispatch(
                            drawActions.zoomOnBbox({
                                type: 'Point',
                                coordinates: pickingInfo.coordinate,
                                maxZoom: Math.max(pickingInfo.viewport!.zoom, 17)
                            })
                        );
                        if (activeTab === TabTypes.municipalitySettings) return;
                        dispatch(
                            areasActions.setPickedArea({
                                ...feature.properties,
                                longitude: pickingInfo.coordinate![0],
                                latitude: pickingInfo.coordinate![1]
                            } as any)
                        );
                    }
                }
            },

            renderSubLayers(props) {
                const data: Feature[] = props.data as any;
                if (!data) return [];
                const points = data.filter(f => f.geometry.type === 'Point');
                if (points.length) {
                    const clusterLayer = new TextLayer({
                        id: `area-clusters-${props.tile.id}`,
                        data: points,
                        getText: (d: any) => d.properties.count.toString(),
                        getPosition: (d: any) => {
                            const coords = d.geometry.coordinates;
                            return [coords[0], coords[1]];
                        },
                        visible: true,
                        backgroundPadding: [8, 4],
                        getBackgroundColor: [0, 0, 0],
                        getAlignmentBaseline: 'center',
                        getColor: [255, 255, 255, 255],
                        background: true,
                        sizeUnits: 'pixels',
                        getSize: 20,
                        pickable: true
                    });
                    return clusterLayer;
                }
                const filteredIds: string[] = [];
                if (isEditingArea && selectedEditArea) filteredIds.push(selectedEditArea.id);

                const polygons = data.filter(f => f.geometry.type === 'Polygon');

                if (!polygons.length) return [];

                const polygonLayer = new GeoJsonLayer({
                    id: `area-${props.tile.id}`,
                    data: polygons.filter(f => !filteredIds.includes(f.properties?.id)),

                    getFillColor: area => {
                        if (area?.properties?.is_disabled) return [242, 45, 57, 20];
                        if (selectedArea?.id === area?.properties?.id) return [248, 253, 0, 30];
                        return [80, 159, 253, 50];
                    },
                    getLineColor: area => {
                        if (pickedArea?.id === area?.properties?.id) return [248, 253, 0, 255];
                        if (selectedArea?.id === area?.properties?.id) return [248, 253, 0, 255];
                        if (area?.properties?.is_disabled) return [251, 154, 160, 255];
                        return [80, 159, 253, 255];
                    },
                    lineJointRounded: true,
                    pickable: true
                });

                const filteredLabelsIds: string[] = [];
                if (isEditingArea && selectedEditArea?.id) filteredLabelsIds.push(selectedEditArea?.id);
                if (pickedArea?.id) filteredLabelsIds.push(pickedArea?.id);

                const areasLabelLayer = new TextLayer({
                    id: `area-label-${props.tile.id}`,
                    data: polygons.filter(f => !filteredLabelsIds.includes(f.properties?.id)),
                    getText: (d: any) => d.properties.code,
                    getPosition: (d: any) => {
                        const coords = centroid(d.geometry).geometry.coordinates;
                        return [coords[0], coords[1]];
                    },
                    backgroundPadding: [8, 4],
                    getBackgroundColor: [1, 28, 61],
                    getAlignmentBaseline: 'center',
                    getColor: [255, 255, 255],
                    background: true,
                    sizeUnits: 'pixels',
                    getSize: 20
                });

                return [polygonLayer, areasLabelLayer];
            }
        });
    }, [dispatch, selectedArea, pickedArea, selectedEditArea, isEditingArea, activeTab, mode, token]);

    return <MapContext.Provider value={{ areasLayer }}>{children}</MapContext.Provider>;
};

export { MapContext, MapProvider };

