import { useState } from 'react';
import SettingsSidebar, { SettingsTypes } from './settingsSidebar/SettingsSidebar';
import SettingsTables from './settingsTables/SettingsTables';

const Settings = () => {
    const [activeTab, setActiveTab] = useState<SettingsTypes>(SettingsTypes.users);

    return (
        <>
            <SettingsSidebar activeTab={activeTab} setActiveTab={setActiveTab} />
            <SettingsTables activeTab={activeTab} />
        </>
    );
};

export default Settings;
