import { FC, useRef, useState } from 'react';
import classes from './TableExportDropdown.module.scss';
import { ArrowDown } from 'components/icons';
import { Popper } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import { TableExtractionFormat } from 'helpers';

type TableExportDropdownProps = {
    onFormatSelect: (type: TableExtractionFormat) => void;
};

const TableExportDropdown: FC<TableExportDropdownProps> = ({ onFormatSelect }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const buttonRef = useRef<any>();
    return (
        <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
            <span
                ref={buttonRef}
                className={`${classes.container} ${isDropdownOpen ? classes.open : ''}`}
                onClick={() => setIsDropdownOpen(prev => !prev)}
            >
                <ArrowDown /> Esporta
                <Popper
                    open={isDropdownOpen}
                    anchorEl={buttonRef.current}
                    className={classes.dropdown}
                    placement={'bottom-start'}
                >
                    <label onClick={() => onFormatSelect('XLSX')}>XLSX</label>
                    <label onClick={() => onFormatSelect('CSV')}>CSV</label>
                </Popper>
            </span>
        </ClickAwayListener>
    );
};

export default TableExportDropdown;

