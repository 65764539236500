import { _TileLoadProps, _getURLFromTemplate } from '@deck.gl/geo-layers/typed';
import { Feature } from '@loaders.gl/schema';
import { MVTLayer } from 'deck.gl/typed';

type ParsedMvtTile = Feature[];

export type _MVTLayerProps = {
    uniqueIdProperty?: string;
    highlightedFeatureId?: string | number | null;
    binary?: boolean;
};

export default class AreasLayer<ExtraProps extends {} = {}> extends MVTLayer<Required<_MVTLayerProps> & ExtraProps> {
    getTileData(loadProps: _TileLoadProps): Promise<ParsedMvtTile> {
        const { data, binary } = this.state;
        const { index, signal } = loadProps;

        const url = _getURLFromTemplate(data, loadProps);
        if (!url) {
            return Promise.reject('Invalid URL');
        }
        let loadOptions = this.getLoadOptions();
        const { fetch } = this.props;
        loadOptions = {
            ...loadOptions,
            mimeType: 'application/x-protobuf',
            mvt: {
                ...loadOptions?.mvt,
                coordinates: 'wgs84',
                tileIndex: index
            },
            gis: binary ? { format: 'binary' } : {}
        };
        return fetch(url, { propName: 'data', layer: this, loadOptions, signal });
    }
}

