import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface FiltersState {
    city: string;
    date: { startDate: number; endDate: number };
}

const name = 'tab';
const initialState: FiltersState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const filtersActions = { ...slice.actions };
export const filtersReducer = slice.reducer;

function createInitialState(): FiltersState {
    return {
        city: '',
        date: { startDate: 0, endDate: 0 }
    };
}

function createReducers() {
    return { setActiveCity, setDate };

    function setActiveCity(state: FiltersState, action: PayloadAction<string>) {
        state.city = action.payload;
    }

    function setDate(state: FiltersState, action: PayloadAction<{ startDate: number; endDate: number }>) {
        state.date = { ...state.date, ...action.payload };
    }
}

