import { Checkbox, ListItemText } from '@mui/material';
import { CheckBoxChecked, CheckBoxUnchecked } from 'components/icons';
import GenericSelect from 'components/select/GenericSelect';
import { BLACKLIST_FILTERS } from 'helpers';
import { FC, useMemo } from 'react';
import classes from './BlacklistFilters.module.scss';

type BlacklistFiltersProps = FC<{
    activeFilters: string[];
    setActiveFilters: any;
}>;

const BlacklistFilters: BlacklistFiltersProps = ({ activeFilters, setActiveFilters }) => {
    const selectOptions = useMemo(() => {
        return Object.keys(BLACKLIST_FILTERS).map(key => ({
            id: key,
            item: (
                <>
                    <Checkbox
                        checked={activeFilters.indexOf(key) > -1}
                        icon={<CheckBoxUnchecked />}
                        checkedIcon={<CheckBoxChecked />}
                    />
                    <ListItemText primary={BLACKLIST_FILTERS[key]} />
                </>
            )
        }));
    }, [activeFilters]);

    return (
        <div className={classes.container}>
            <label className="small">
                <b>Blacklist</b>
            </label>
            <GenericSelect
                placeholder="Utenze in blacklist"
                multiple
                value={activeFilters}
                onChange={(selectedFilters: any) => setActiveFilters('blacklist', selectedFilters.target.value)}
                renderValue={(selected: any) => selected.map((key: any) => BLACKLIST_FILTERS[key]).join(', ')}
                className={activeFilters.length === 0 ? '' : classes.inputStyle}
                options={selectOptions}
                sxFormControl={{ width: '21rem !important' }}
            />
        </div>
    );
};

export default BlacklistFilters;

