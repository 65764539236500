export const Proportional = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.7485 19.4985H4.74852C4.44852 19.4985 4.16852 19.3185 4.05852 19.0385C3.93852 18.7585 4.00852 18.4385 4.21852 18.2185L18.2185 4.21852C18.4285 4.00852 18.7585 3.93852 19.0385 4.05852C19.3185 4.17852 19.4985 4.44852 19.4985 4.74852V18.7485C19.4985 19.1585 19.1585 19.4985 18.7485 19.4985Z"
                fill="#8DBEF9"
            />
        </svg>
    );
};
