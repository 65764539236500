import { AreaDateFilter, DAY_IN_MS, MS_CONVERTER, getTodayStart } from 'helpers';
import { FC, useCallback, useMemo } from 'react';
import classes from './ChipsFilter.module.scss';
import { useSelector } from 'react-redux';
import { FiltersState, RootState, TabState, TabTypes, TableTypes } from 'store';
import moment from 'moment';

const periods = [
    { label: 'Giorno', value: 'day' },
    { label: 'Settimana', value: 'week' },
    { label: 'Mese', value: 'month' },
    { label: 'Anno', value: 'year' }
];

type ChipFilterProps = FC<{
    activeFilter: AreaDateFilter;
    setActiveFilter: any;
}>;

const ChipsFilter: ChipFilterProps = ({ setActiveFilter, activeFilter }) => {
    const { date: currentDate } = useSelector<RootState>(x => x.filters) as FiltersState;
    const { activeTable, activeTab } = useSelector<RootState>(x => x.tab) as TabState;

    const activeChip = useMemo(() => {
        if ([TabTypes.municipalitySettings, TabTypes.billing, TabTypes.customerService].includes(activeTab)) return activeFilter;
        if ([TableTypes.areas, TableTypes.stops].includes(activeTable)) return currentDate;
        return activeFilter;
    }, [activeFilter, activeTab, activeTable, currentDate]);

    const onClickHandler = useCallback(
        (value: string) => () => {
            const now = getTodayStart();
            const startDate = now - (MS_CONVERTER as any)[value] + DAY_IN_MS;
            setActiveFilter('date', { startDate, endDate: now + DAY_IN_MS });
        },
        [setActiveFilter]
    );

    const isChipSelected = useCallback(
        (value: 'day' | 'week' | 'month' | 'year') => {
            if (activeChip.endDate === 0 || moment(activeChip.endDate).diff(getTodayStart()) !== DAY_IN_MS) return false;
            const startDate = moment(activeChip.startDate);
            const endDate = moment(activeChip.endDate);
            switch (value) {
                case 'day':
                    return endDate.diff(startDate) === DAY_IN_MS;
                case 'week':
                    return endDate.diff(startDate) === DAY_IN_MS * 7;
                case 'month':
                    return endDate.diff(startDate) === DAY_IN_MS * 30;
                default:
                    return endDate.diff(startDate) === DAY_IN_MS * 365;
            }
        },
        [activeChip]
    );

    const mappedChips = useMemo(
        () =>
            periods.map(period => (
                <div
                    key={period.label}
                    data-selected={isChipSelected(period.value as any)}
                    className={classes.chip}
                    onClick={onClickHandler(period.value)}
                >
                    {period.label}
                </div>
            )),
        [isChipSelected, onClickHandler]
    );

    return (
        <div className={classes.chipsFilterContainer}>
            <label className="small">
                <b>Sosta effettuata nell'ultimo:</b>
            </label>
            <div className={classes.mappedChips}>{mappedChips}</div>
        </div>
    );
};

export default ChipsFilter;

