import { FC } from 'react';
import TextButton from 'components/buttons/textButton/TextButton';
import { Checkbox } from '@mui/material';
import { CheckBoxChecked, CheckBoxUnchecked } from 'components/icons';
import classes from './CreateEditTariffModalForm.module.scss';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { NewTariff } from 'helpers';
import { Tariff } from 'models';
import { useDispatch } from 'react-redux';
import { tariffActions } from 'store';

type CreateEditTariffModalFormProps = FC<{
    onSubmit: SubmitHandler<NewTariff>;
    selectedTariff?: Tariff;
    isEditMode?: boolean;
}>;

const CreateEditTariffModalForm: CreateEditTariffModalFormProps = ({ onSubmit, selectedTariff, isEditMode = false }) => {
    const dispatch = useDispatch();
    const onCreateEditHandler = () => dispatch(tariffActions.toggleCreateEditModalOpen());
    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors, dirtyFields, isValid }
    } = useForm({
        mode: 'all',
        defaultValues: {
            name: isEditMode ? selectedTariff?.name : '',
            slopeReprise: isEditMode ? selectedTariff?.slopeReprise : true
        }
    });

    return (
        <form className={classes.formContainer} onSubmit={handleSubmit(onSubmit)}>
            {isEditMode ? 'Stai modificando una tariffa.' : 'Stai creando una nuova tariffa, assegnale un nome.'}
            <div>
                <label htmlFor="name" className="small lightBlue">
                    {isEditMode ? 'Nome tariffa' : 'Nuovo nome tariffa'}
                </label>
                <input
                    className={`${classes.textInput} ${errors.name && dirtyFields.name ? classes.error : ''}`}
                    type="text"
                    id="name"
                    placeholder="es. Standard"
                    {...register('name', { required: true })}
                />
            </div>
            <div className={classes.checkbox}>
                <div>
                    <Controller
                        name="slopeReprise"
                        control={control}
                        render={({ field }) => (
                            <Checkbox
                                defaultChecked={isEditMode ? selectedTariff?.slopeReprise : true}
                                icon={<CheckBoxUnchecked />}
                                checkedIcon={<CheckBoxChecked />}
                                sx={{
                                    '&.Mui-disabled': {
                                        opacity: 0.5
                                    }
                                }}
                                {...register('slopeReprise')}
                                {...field}
                            />
                        )}
                    />
                    <label className={classes.checkboxLabel} htmlFor="slopeReprise">
                        Slope Reprise
                    </label>
                </div>
                <label className={classes.info}>
                    Replicata l'ultima slope utilizzata dall'utente all'interno di una regola, nei periodi non coperti da altre
                    slope
                </label>
            </div>
            <div className={classes.buttons}>
                <TextButton
                    className="tertiary"
                    type="button"
                    onClick={() => {
                        reset();
                        onCreateEditHandler();
                    }}
                >
                    Annulla
                </TextButton>
                <TextButton className="primary" type="submit" disabled={!isValid}>
                    Conferma
                </TextButton>
            </div>
        </form>
    );
};

export default CreateEditTariffModalForm;

