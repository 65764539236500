import { IconTextButton } from 'components/buttons';
import { Tariff, Parking } from 'components/icons';
import classes from './MunicipalityToggle.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, MunicipalityTableTypes, municipalityActions } from 'store';

const MunicipalityToggle = () => {
    const { selectedTable } = useSelector((x: RootState) => x.municipalities);
    const dispatch = useDispatch();

    const onClickHandler = (table: MunicipalityTableTypes) => () => dispatch(municipalityActions.setSelectedTable(table));
    return (
        <div className={classes.toggle}>
            <IconTextButton
                icon={<Tariff />}
                className={selectedTable === MunicipalityTableTypes.tariff ? classes.active : ''}
                onClick={onClickHandler(MunicipalityTableTypes.tariff)}
            >
                Tariffe
            </IconTextButton>
            <IconTextButton
                icon={<Parking />}
                className={selectedTable === MunicipalityTableTypes.areas ? classes.active : ''}
                onClick={onClickHandler(MunicipalityTableTypes.areas)}
            >
                Aree
            </IconTextButton>
        </div>
    );
};
export default MunicipalityToggle;
