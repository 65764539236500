export const CircleEmpty = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.99989 4.44446C6.03545 4.44446 4.44434 6.03557 4.44434 8.00001C4.44434 9.96446 6.03545 11.5556 7.99989 11.5556C9.96434 11.5556 11.551 9.96446 11.5554 8.00001C11.5554 6.03557 9.96434 4.44446 7.99989 4.44446ZM7.99989 10.8889C6.40878 10.8889 5.111 9.59112 5.111 8.00001C5.111 6.4089 6.40878 5.11112 7.99989 5.11112C9.591 5.11112 10.8888 6.4089 10.8888 8.00001C10.8888 9.59112 9.591 10.8889 7.99989 10.8889Z"
                fill="white"
            />
        </svg>
    );
};
