import BillingDetailsTable from 'components/billingDetailsTable/BillingDetailsTable';
import Card from 'components/card/Card';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, billingsActions } from 'store';
import BillingDetailsInfo from './billingDetailsInfo/BillingDetailsInfo';
import { BillingFilled, MoneySmall } from 'components/icons';
import { ProfileFilled } from 'components/icons/ProfileFilled';
import Modal from 'components/modal/Modal';
import classes from './BillingDetails.module.scss';
import TextButton from 'components/buttons/textButton/TextButton';
import { TableExtractionFormat, calculateChipsValues, openApiLink } from 'helpers';
import colors from 'styles/modules/colors.module.scss';
import { useGetBillingDetailsQuery, useLazyIssueBillQuery } from 'store/rtk/billing.service';
import TableExportDropdown from 'components/tableExportDropdown/TableExportDropdown';
import { BILLINGS_DETAILS_HEADERS } from 'components/billingDetailsTable/accordionTable/AccordionTable';
import { useLazyGetExtractionTokenQuery } from 'store/rtk/auth.service';

type BillingDetailsProps = FC<{
    refetchBillingsData: () => void;
}>;

const BillingDetails: BillingDetailsProps = ({ refetchBillingsData }) => {
    const [getToken] = useLazyGetExtractionTokenQuery();
    const { selectedBilling, billingDetails } = useSelector((x: RootState) => x.billings);
    const { data: billingDetailsData, refetch: refetchBillingDetailsData } = useGetBillingDetailsQuery({
        name: selectedBilling ? selectedBilling.businessName : ''
    });
    const dispatch = useDispatch();
    const [checkStatus, setCheckStatus] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showOnlyNonIssuedBills, setShowOnlyNonIssuedBills] = useState(true);
    const [issueBill] = useLazyIssueBillQuery();

    const issueBills = useCallback(
        async (billsToBeIssued: string[]) => {
            if (billsToBeIssued.length === 0) return;
            await issueBill({ billingsId: billsToBeIssued }).then(res => {
                if (!res.data) return;
                setCheckStatus(true);
                setShowOnlyNonIssuedBills(!showOnlyNonIssuedBills);
                refetchBillingsData();
                refetchBillingDetailsData();
            });
        },
        [issueBill, refetchBillingsData, refetchBillingDetailsData, showOnlyNonIssuedBills]
    );

    const handleConfirmation = useCallback(
        (confirmed: boolean) => {
            setIsModalOpen(false);
            if (confirmed) {
                const billsToBeIssued = billingDetails?.usersBillings.flatMap(data =>
                    data.userBillings.filter(bill => bill.billing_status !== 'issued' && bill.id).map(bill => bill.id)
                );
                if (billsToBeIssued && billsToBeIssued.length > 0) issueBills(billsToBeIssued);
                if (selectedBilling) {
                    const updatedBilling = { ...selectedBilling, unbilled: '0' };
                    dispatch(billingsActions.issueBilling(updatedBilling));
                    dispatch(billingsActions.setSelectedBilling(updatedBilling));
                }
            } else setCheckStatus(false);
        },
        [billingDetails, issueBills, selectedBilling, dispatch]
    );

    const chips = useMemo(
        () =>
            selectedBilling &&
            billingDetails && [
                {
                    icon: <MoneySmall />,
                    label: `${calculateChipsValues(billingDetails)?.totalAmount.replace('.', ',')} €`,
                    active: !checkStatus
                },
                {
                    icon: <ProfileFilled />,
                    label: `${calculateChipsValues(billingDetails)?.numberOfUsers}`,
                    active: !checkStatus
                },
                {
                    icon: <BillingFilled fill={colors.white} />,
                    label: `${selectedBilling.unbilled}`,
                    active: !checkStatus
                }
            ],
        [checkStatus, selectedBilling, billingDetails]
    );

    useEffect(() => {
        if (!billingDetailsData || !selectedBilling) return;
        dispatch(billingsActions.setBillingDetails(billingDetailsData));
    }, [billingDetailsData, selectedBilling, dispatch]);

    useEffect(() => {
        if (!selectedBilling) return;
        if (selectedBilling.unbilled !== '0') {
            setShowOnlyNonIssuedBills(true);
            setCheckStatus(false);
        } else {
            setShowOnlyNonIssuedBills(false);
            setCheckStatus(true);
        }
    }, [selectedBilling]);

    const startExtraction = useCallback(
        async (format: TableExtractionFormat) => {
            const columnsDictionary = BILLINGS_DETAILS_HEADERS.filter(header => !header.disableExport).map(header => ({
                header: header.headerName,
                key: header.field
            }));
            const finalColumnDictionary = [
                ...columnsDictionary,
                { header: 'Ragione sociale', key: 'businessName' },
                { header: 'P.IVA/Codice fiscale', key: 'vatNumber' },
                { header: 'PEC/SDI', key: 'pecOrSdi' },
                { header: 'Indirizzo', key: 'address' },
                { header: 'Comune', key: 'municipality' },
                { header: 'Provincia', key: 'province' },
                { header: 'Cap', key: 'cap' },
                { header: 'Nazione', key: 'nation' }
            ];
            const authorization = (await getToken().unwrap()).token;
            const queryParams = {
                name: selectedBilling!.businessName,
                showOnlyNonIssuedBills: showOnlyNonIssuedBills ? 'true' : 'false',
                format: format.toLowerCase(),
                columns: JSON.stringify(finalColumnDictionary),
                authorization,
                sheet: 'Fatture'
            };
            openApiLink('extraction/billings/by-name', queryParams);
        },
        [getToken, selectedBilling, showOnlyNonIssuedBills]
    );

    return (
        <div className={classes.container}>
            <BillingDetailsInfo
                setCheckStatus={setCheckStatus}
                checkStatus={checkStatus}
                setShowOnlyNonIssuedBills={setShowOnlyNonIssuedBills}
                showOnlyNonIssuedBills={showOnlyNonIssuedBills!}
                refetchBillingsData={refetchBillingsData}
                refetchBillingDetailsData={refetchBillingDetailsData}
            />
            <div className={classes.subHeader}>
                <Card
                    checkBox={true}
                    chips={chips}
                    disabled={selectedBilling?.unbilled === '0' || !showOnlyNonIssuedBills}
                    onChange={() => setIsModalOpen(true)}
                    checked={checkStatus}
                >
                    Segna tutte le fatture come evase
                </Card>
                <TableExportDropdown onFormatSelect={format => startExtraction(format)} />
            </div>
            <BillingDetailsTable showOnlyNonIssuedBills={showOnlyNonIssuedBills} />
            <Modal
                customClasses={classes.modal}
                isOpen={isModalOpen}
                title="Segna tutte le fatture come evase"
                handleClose={() => setIsModalOpen(false)}
            >
                <span>
                    Vuoi segnare nel sistema di aver evaso tutte le fatture di {billingDetails?.businessName}? L’azione è
                    irreversibile.
                </span>
                <div>
                    <TextButton className={classes.cancelButton} onClick={() => handleConfirmation(false)}>
                        Annulla
                    </TextButton>
                    <TextButton className={classes.confirmButton} onClick={() => handleConfirmation(true)}>
                        Conferma
                    </TextButton>
                </div>
            </Modal>
        </div>
    );
};

export default BillingDetails;

