import { SvgIconProps } from 'helpers';

export const ResetFilters: SvgIconProps = ({ fill }) => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26.3597 11.3334L23.1615 14.5316L19.9633 11.3334L19.0083 12.2883L22.2065 15.4865L19.0083 18.6847L19.9633 19.6397L23.1615 16.4415L26.3597 19.6397L27.3146 18.6847L24.1164 15.4865L27.3146 12.2883L26.3597 11.3334Z"
                fill={fill}
            />
            <path
                d="M29.3685 8.38739C29.3135 8.2715 29.2267 8.17359 29.1183 8.10502C29.0098 8.03645 28.8842 8.00004 28.7559 8H7.67479C7.5465 8.00004 7.42084 8.03645 7.31241 8.10502C7.20398 8.17359 7.11721 8.2715 7.06218 8.38739C7.00912 8.50411 6.98945 8.63326 7.00536 8.76048C7.02126 8.88771 7.07211 9.00804 7.15227 9.10811L15.1072 18.6486V27.3243C15.1085 27.4399 15.1389 27.5534 15.1956 27.6541C15.2523 27.7549 15.3334 27.8398 15.4315 27.9009C15.5381 27.9638 15.6592 27.9979 15.7829 28C15.8861 27.9986 15.9878 27.9739 16.0802 27.9279L20.9451 25.4955C21.0588 25.441 21.1547 25.3553 21.2216 25.2485C21.2886 25.1416 21.3239 25.018 21.3234 24.8919V20.4144L19.9721 21.7658V24.4775L16.4586 26.2342V18.4054C16.4573 18.2482 16.4033 18.096 16.3054 17.973L9.11623 9.35135H29.0712L29.2784 9.10811C29.3623 9.00999 29.4156 8.88939 29.4316 8.76127C29.4476 8.63315 29.4257 8.50315 29.3685 8.38739Z"
                fill={fill}
            />
        </svg>
    );
};
