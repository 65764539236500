import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export enum TabTypes {
    home = 'home',
    notifications = 'notifications',
    settings = 'settings',
    billing = 'billing',
    customerService = 'customerService',
    municipalitySettings = 'municipalitySettings'
}

export enum TableTypes {
    areas = 'areas',
    users = 'users',
    stops = 'stops'
}

export interface TabState {
    activeTab: TabTypes;
    activeTable: TableTypes;
}

const name = 'tab';
const initialState: TabState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const tabActions = { ...slice.actions };
export const tabReducer = slice.reducer;

function createInitialState(): TabState {
    return {
        activeTab: TabTypes.home,
        activeTable: TableTypes.areas
    };
}

function createReducers() {
    return { setActiveTab, setActiveTable };

    function setActiveTab(state: TabState, action: PayloadAction<TabTypes>) {
        state.activeTab = action.payload;
    }

    function setActiveTable(state: TabState, action: PayloadAction<TableTypes>) {
        state.activeTable = action.payload;
    }
}

