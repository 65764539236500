import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Dot: SvgIconProps = ({ fill = colors.confirm90 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
            <circle cx="8.40039" cy="8" r="3" fill={fill} />
        </svg>
    );
};

