import { IconButton } from 'components/buttons';
import { Filters, IsOnGoing } from 'components/icons';
import NoResult from 'components/noResult/NoResult';
import Searchbar from 'components/searchbar/Searchbar';
import deepEqual from 'deep-equal';
import {
    CustomerServiceFiltersItems,
    CustomerServiceItem,
    CustomerServiceStatus,
    DEFAULT_CUSTOMER_SERVICES_FILTER_VALUES,
    SetState
} from 'helpers';
import { FC, forwardRef, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { customerServiceActions } from 'store';
import { useLazyUpdateCustomerServiceStatusQuery } from 'store/rtk/customerService.service';
import classes from './CustomerServiceItems.module.scss';
import Item from './customerServiceItem/CustomerServiceItem';

type CustomerServiceItemsProps = FC<{
    setFilteredCustomerServices: SetState<CustomerServiceItem[]>;
    activeFilters: CustomerServiceFiltersItems;
    setFiltersOpen: any;
    setSearchedWord: (value: string) => void;
    searchedWord: string;
    items: any[];
    ref: any;
}>;

const CustomerServiceItems: CustomerServiceItemsProps = forwardRef(
    ({ searchedWord, setSearchedWord, setFiltersOpen, items, setFilteredCustomerServices, activeFilters }, ref) => {
        const dispatch = useDispatch();
        const [updateCustomerServiceStatus] = useLazyUpdateCustomerServiceStatusQuery();
        const onCustomerServiceStatusUpdate = useCallback(
            async (id: string, status: string, item: any) => {
                const { data } = await updateCustomerServiceStatus({ id, status });
                if (!data) return;
                dispatch(customerServiceActions.setSelectedCustomerService({ id }));
                if (item.status === CustomerServiceStatus.completed || item.status === CustomerServiceStatus.read) return;
                setFilteredCustomerServices(prev => {
                    const index = prev.findIndex(item => item.id === data.id);
                    if (index >= 0) {
                        const updatedItem = { ...prev[index], status: data.status };
                        const updatedArray = [...prev];
                        updatedArray[index] = updatedItem;
                        return updatedArray;
                    }
                    return prev;
                });
                dispatch(customerServiceActions.updateCustomerServiceStatus(data));
                dispatch(customerServiceActions.updateSelectedCustomerServiceStatus({ status, id }));
            },
            [dispatch, setFilteredCustomerServices, updateCustomerServiceStatus]
        );

        const mappedItems = useMemo(() => {
            return items.map(item =>
                item.id !== 'last-one' ? (
                    <Item
                        onClick={() => {
                            onCustomerServiceStatusUpdate(item.id, CustomerServiceStatus.read, item);
                            setFiltersOpen(false);
                        }}
                        key={item.id}
                        item={item}
                    />
                ) : (
                    <div key={item.id} ref={ref as any} id={item.id}></div>
                )
            );
        }, [items, onCustomerServiceStatusUpdate, ref, setFiltersOpen]);

        const areFiltersActive = useMemo(
            () => !deepEqual(activeFilters, DEFAULT_CUSTOMER_SERVICES_FILTER_VALUES),
            [activeFilters]
        );

        const handleOnChange = (value: string) => setSearchedWord(value);

        return (
            <div className={classes.container}>
                <div className={classes.header}>
                    <p>Assistenza</p>
                    <div className={classes.filters}>
                        <Searchbar value={searchedWord} onChange={value => handleOnChange(value)} />
                        <div className={classes.filterButton}>
                            <IconButton className="tertiary" icon={<Filters />} onClick={() => setFiltersOpen(true)} />
                            {areFiltersActive && (
                                <span className={classes.active}>
                                    <IsOnGoing />
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                {mappedItems.length > 1 ? <div className={classes.items}>{mappedItems}</div> : <NoResult />}
            </div>
        );
    }
);

export default CustomerServiceItems;

