import React, { FC, useMemo, useState } from 'react';
import AreasTable from './areasTable/AreasTable';
import UsersTable from './usersTable/UsersTable';
import TablesToggle from './tablesToggle/TablesToggle';
import classes from './Tables.module.scss';
import ParkingTable from './parkingTable/ParkingTable';
import { useSelector } from 'react-redux';
import { RootState, TableTypes } from 'store';
import { ExpandSection } from 'components/icons/ExpandSection';
import { CompactSection } from 'components/icons/CompactSection';

const Tables: FC = () => {
    const { activeTable } = useSelector((x: RootState) => x.tab);
    const [isFullScreenToggle, setIsFullScreenToggle] = useState(false);

    const toggleContainerWidth = () => {
        setIsFullScreenToggle(prev => !prev);
    };

    const icon = useMemo(() => (isFullScreenToggle ? <CompactSection /> : <ExpandSection />), [isFullScreenToggle]);

    return (
        <div className={`${classes.container} ${isFullScreenToggle ? classes.containerWidth : ''}`}>
            <div className={classes.toggle}>
                <TablesToggle />
            </div>
            <div className={classes.tables}>
                {activeTable === TableTypes.areas && <AreasTable toggleWidth={toggleContainerWidth} icon={icon} />}
                {activeTable === TableTypes.users && <UsersTable toggleWidth={toggleContainerWidth} icon={icon} />}
                {activeTable === TableTypes.stops && <ParkingTable toggleWidth={toggleContainerWidth} icon={icon} />}
            </div>
        </div>
    );
};

export default Tables;
