import { SvgIconProps } from 'helpers';

export const BillingFilledSmall: SvgIconProps = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2987_693)">
                <path
                    d="M13.0313 10.1749H10.9213V2.97163C10.9213 2.87 10.873 2.77244 10.7884 2.71959C10.7039 2.66268 10.5992 2.65455 10.5025 2.69114L8.70664 3.45537L6.91074 2.69114C6.83424 2.65862 6.74968 2.65862 6.6772 2.69114L4.8813 3.45537L3.0854 2.69114C2.99279 2.65049 2.88407 2.66268 2.79951 2.71553C2.71495 2.77244 2.66663 2.86594 2.66663 2.96756V11.809C2.66663 12.6505 3.34713 13.3293 4.17663 13.3293H11.8233C12.6568 13.3293 13.3333 12.6464 13.3333 11.805V10.4757C13.3333 10.309 13.1964 10.1708 13.0313 10.1708V10.1749ZM6.62888 6.87813C6.10943 6.76837 5.64234 6.59764 5.64234 6.01634C5.64234 5.50008 6.02085 5.19927 6.47184 5.10984V4.61797H7.06376V5.1139C7.61944 5.21553 7.86104 5.58951 7.91741 6.00008H7.31341C7.24898 5.79276 7.10402 5.64642 6.74565 5.64642C6.38727 5.64642 6.24231 5.79683 6.24231 5.98789C6.24231 6.15862 6.38727 6.20333 6.77384 6.2887C7.55501 6.45537 7.94963 6.63016 7.94963 7.21553C7.94963 7.80089 7.50266 8.05699 7.07181 8.12203V8.60984H6.47586V8.10577C5.88394 7.98789 5.63026 7.57325 5.56986 7.14236H6.16983C6.25037 7.43098 6.42351 7.58138 6.83826 7.58138C7.1765 7.58138 7.34965 7.45537 7.34965 7.24398C7.34965 7.0326 7.14429 6.99602 6.6329 6.88219L6.62888 6.87813ZM9.10528 11.6058H4.47863V10.996H9.10931V11.6058H9.10528ZM9.70928 10.0814H3.87463V9.47163H9.71331V10.0814H9.70928ZM12.7253 11.805C12.7253 12.309 12.3186 12.7196 11.8193 12.7196C11.32 12.7196 10.9133 12.309 10.9133 11.805V10.7806H12.7253V11.805Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_2987_693">
                    <rect width="10.6667" height="10.6667" fill="white" transform="translate(2.66663 2.66675)" />
                </clipPath>
            </defs>
        </svg>
    );
};

