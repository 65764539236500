export const Invoice = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.1004 22.8997H24.3978V6.68624C24.3978 6.45749 24.2901 6.2379 24.1016 6.11895C23.9131 5.99085 23.6798 5.97255 23.4644 6.0549L19.4618 7.77507L15.4591 6.0549C15.2886 5.9817 15.1002 5.9817 14.9386 6.0549L10.936 7.77507L6.93335 6.0549C6.72694 5.9634 6.48462 5.99085 6.29616 6.1098C6.10769 6.2379 6 6.44834 6 6.67709V26.578C6 28.472 7.51669 30 9.36544 30H26.4081C28.2658 30 29.7735 28.4628 29.7735 26.5688V23.5768C29.7735 23.2017 29.4684 22.8906 29.1004 22.8906V22.8997ZM9.36544 28.6367C8.2526 28.6367 7.34618 27.7125 7.34618 26.578V7.72017L10.6757 9.14754C10.8462 9.22074 11.0347 9.22074 11.1962 9.14754L15.1989 7.42737L19.2015 9.14754C19.372 9.22074 19.5605 9.22074 19.722 9.14754L23.0516 7.72017V26.578C23.0516 27.3465 23.2939 28.0602 23.7247 28.6367H9.36544ZM28.4273 26.578C28.4273 27.7125 27.5209 28.6367 26.4081 28.6367C25.2952 28.6367 24.3888 27.7125 24.3888 26.578V24.2722H28.4273V26.578Z"
                fill="#C4DFFF"
            />
            <path d="M21.7054 21.3259H8.69238V22.6984H21.7054V21.3259Z" fill="#C4DFFF" />
            <path d="M20.3593 24.7572H10.0386V26.1297H20.3593V24.7572Z" fill="#C4DFFF" />
            <path
                d="M15.2884 17.0621C14.364 17.0621 13.9781 16.7327 13.7986 16.0739H12.4614C12.596 17.0438 13.1614 17.9771 14.4807 18.2424V19.377H15.8089V18.279C16.7782 18.1326 17.7654 17.547 17.7654 16.2386C17.7654 14.9302 16.8859 14.5367 15.1448 14.1524C14.2922 13.9603 13.9602 13.8688 13.9602 13.4753C13.9602 13.0453 14.2922 12.7068 15.082 12.7068C15.8717 12.7068 16.2038 13.0361 16.3474 13.5028H17.6936C17.559 12.5695 17.0205 11.7277 15.791 11.5081V10.3918H14.4717V11.499C13.4666 11.7003 12.623 12.3682 12.623 13.5394C12.623 14.8478 13.664 15.2321 14.8217 15.4791C15.9615 15.7353 16.4192 15.7994 16.4192 16.2935C16.4192 16.7876 16.0243 17.0529 15.2794 17.0529L15.2884 17.0621Z"
                fill="#C4DFFF"
            />
        </svg>
    );
};
