export const Flat = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.75 17.75H4.75C4.34 17.75 4 17.41 4 17V7.75C4 7.34 4.34 7 4.75 7H18.75C19.16 7 19.5 7.34 19.5 7.75V17C19.5 17.41 19.16 17.75 18.75 17.75Z"
                fill="#8DBEF9"
            />
        </svg>
    );
};
