import { useDispatch } from 'react-redux';
import { adminActions, snackbarActions } from 'store';
import Modal from 'components/modal/Modal';
import { SetState } from 'helpers';
import { FC, useContext } from 'react';
import { UserContext } from 'context';
import { useGetAdminUsersQuery } from 'store/rtk/user.service';
import classes from './DeleteAdminModal.module.scss';
import TextButton from 'components/buttons/textButton/TextButton';
import { User } from 'models';
import Snackbar from 'components/snackbar/Snackbar';

type DeleteAdminModalProps = FC<{
    userToDelete: User | undefined;
    setUserToDelete: SetState<User | undefined>;
}>;

const DeleteAdminModal: DeleteAdminModalProps = ({ userToDelete, setUserToDelete }) => {
    const { deleteUsers } = useContext(UserContext);
    const { refetch } = useGetAdminUsersQuery();
    const dispatch = useDispatch();

    const onClickHandler = async () => {
        if (!userToDelete) return;
        try {
            const res = await deleteUsers([userToDelete.id]);
            if (!res) return;
            dispatch(adminActions.removeDeletedAdmin([userToDelete.id]));
            dispatch(snackbarActions.setMessageAndType({ message: `Utente rimosso con successo.` }));
            dispatch(snackbarActions.setIsOpen(true));
            refetch();
            setUserToDelete(undefined);
        } catch (error) {
            console.error('Error deleting user:', error);
            dispatch(snackbarActions.setMessageAndType({ type: 'error', message: `Errore durante la rimozione dell'utente.` }));
            dispatch(snackbarActions.setIsOpen(true));
        }
    };

    return (
        <>
            <Modal title="Elimina utente" handleClose={() => setUserToDelete(undefined)} isOpen={!!userToDelete}>
                Vuoi eliminare l'utente dalla piattaforma ? <br />
                Non avrà più accesso ed i suoi dati saranno rimossi.
                <div className={classes.buttons}>
                    <TextButton className={`tertiary`} onClick={() => setUserToDelete(undefined)}>
                        Annulla
                    </TextButton>
                    <TextButton className={`error`} onClick={onClickHandler}>
                        Elimina
                    </TextButton>
                </div>
            </Modal>
            <Snackbar />
        </>
    );
};

export default DeleteAdminModal;

