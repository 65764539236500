import { SvgIconProps } from 'helpers';

export const Filters: SvgIconProps = ({ fill }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1836_5623)">
                <path
                    d="M31.3721 10.3892C31.3174 10.2729 31.2307 10.1746 31.1222 10.1056C31.0138 10.0367 30.8879 10.0001 30.7594 10H9.67651C9.54795 9.99968 9.42199 10.0361 9.31342 10.1049C9.20486 10.1738 9.11823 10.2722 9.06371 10.3886C9.00919 10.5051 8.98904 10.6346 9.00567 10.7621C9.02229 10.8896 9.07499 11.0097 9.15755 11.1082L17.1096 20.6496V29.3242C17.1094 29.4393 17.1387 29.5525 17.1946 29.6531C17.2506 29.7537 17.3314 29.8384 17.4292 29.899C17.5271 29.9596 17.6389 29.9941 17.7539 29.9993C17.8689 30.0045 17.9833 29.9802 18.0862 29.9287L22.9515 27.4961C23.0652 27.4412 23.161 27.3554 23.2279 27.2484C23.2949 27.1414 23.3303 27.0177 23.3299 26.8915V20.6478L31.2829 11.1055C31.3642 11.0069 31.4158 10.8872 31.4316 10.7603C31.4474 10.6335 31.4268 10.5048 31.3721 10.3892ZM18.3079 19.9738L11.1235 11.3515H29.316L22.1307 19.9747C22.0307 20.0963 21.9763 20.2489 21.9767 20.4063V26.4762L18.4629 28.2331V20.4063C18.4625 20.2486 18.4077 20.0959 18.3079 19.9738Z"
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_1836_5623">
                    <rect width="40" height="40" rx="8" fill={fill} />
                </clipPath>
            </defs>
        </svg>
    );
};
