import { FC } from 'react';
import classes from './AreaTariffs.module.scss';

type AreaTariffsProps = FC<{
    tariffName: string;
}>;

const AreaTariffs: AreaTariffsProps = ({ tariffName }) => {
    if (!tariffName) return <></>;
    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <p className="lightBlue">Tariffa</p>
            </div>
            <div className={classes.tariffContainer}>{tariffName}</div>
        </div>
    );
};

export default AreaTariffs;
