import { FC, PropsWithChildren } from 'react';
import { Typography } from '@mui/material';
import classes from './tariffAccordionTypography.module.scss';

type TariffAccordionTypographyProperties = FC<
    PropsWithChildren<{
        customClasses?: string;
    }>
>;

const TariffAccordionTypography: TariffAccordionTypographyProperties = ({ children, customClasses = '' }) => {
    return <Typography className={`${classes.fieldsContainer} ${customClasses}`}>{children}</Typography>;
};

export default TariffAccordionTypography;
