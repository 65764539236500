import TextButton from 'components/buttons/textButton/TextButton';
import Modal from 'components/modal/Modal';
import Snackbar from 'components/snackbar/Snackbar';
import { SetState } from 'helpers';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MunicipalityState, RootState, areasActions, snackbarActions } from 'store';
import { useDeleteAreaMutation } from 'store/rtk/area.service';
import classes from './DeleteAreaModal.module.scss';

type DeleteAreaModalProps = FC<{
    isOpen: boolean;
    setIsOpen: SetState<boolean>;
    onBackHandler: () => void;
}>;

const DeleteAreaModal: DeleteAreaModalProps = ({ isOpen, setIsOpen, onBackHandler }) => {
    const { selectedArea } = useSelector<RootState>(x => x.municipalities) as MunicipalityState;
    const [deleteArea] = useDeleteAreaMutation();
    const [isDenyModalOpen, setIsDenyModalOpen] = useState(false);
    const dispatch = useDispatch();

    const onSubmitHandler = async () => {
        try {
            if (!selectedArea) return;
            const res = await deleteArea({ areaId: selectedArea.id });
            if ('error' in res && res.error && 'status' in res.error && res.error.status === 409) {
                setIsDenyModalOpen(true);
                setIsOpen(false);
            } else if ('data' in res) {
                dispatch(areasActions.setSelectedArea(undefined));
                dispatch(snackbarActions.setMessageAndType({ message: `Area eliminata con successo.` }));
                dispatch(snackbarActions.setIsOpen(true));
                setIsOpen(false);
                onBackHandler();
                setIsOpen(false);
            }
        } catch (error) {
            console.error('An error occurred:', error);
            dispatch(snackbarActions.setMessageAndType({ type: 'error', message: `Errore durante l'eliminazione dell'area.` }));
            dispatch(snackbarActions.setIsOpen(true));
            setIsOpen(false);
        }
    };

    if (!selectedArea) return null;

    return (
        <>
            <Modal title="Elimina area" isOpen={isOpen} handleClose={() => setIsOpen(false)}>
                <div className={classes.container}>
                    Vuoi eliminare l&#x2019;area <b>{selectedArea.code + ' - ' + selectedArea.name}</b> ?
                    <div className={classes.buttons}>
                        <TextButton className="tertiary" onClick={() => setIsOpen(false)}>
                            Annulla
                        </TextButton>
                        <TextButton className="error" onClick={onSubmitHandler} disabled={false}>
                            Elimina
                        </TextButton>
                    </div>
                </div>
            </Modal>
            <Modal title="Eliminazione non consentita" isOpen={isDenyModalOpen} handleClose={() => setIsDenyModalOpen(false)}>
                <div className={classes.denyContainer}>
                    <span>
                        L&apos;area <b>{`${selectedArea.code} - ${selectedArea.name}`}</b> non può essere eliminata in quanto
                        esistono soste associate ad essa.
                    </span>
                    <span>E&apos; possibile però disabilitare l&apos;area.</span>
                    <TextButton className={`tertiary ${classes.btn}`} onClick={() => setIsDenyModalOpen(false)}>
                        Ho capito
                    </TextButton>
                </div>
            </Modal>
            <Snackbar />
        </>
    );
};

export default DeleteAreaModal;

