export const Rule = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 10C14 8.6 13.04 7.44 11.75 7.11V2H10.25V7.11C8.96 7.44 8 8.61 8 10C8 11.39 8.96 12.56 10.25 12.89V14H11.75V12.89C13.04 12.56 14 11.39 14 10ZM11 11.5C10.17 11.5 9.5 10.83 9.5 10C9.5 9.17 10.17 8.5 11 8.5C11.83 8.5 12.5 9.17 12.5 10C12.5 10.83 11.83 11.5 11 11.5Z"
                fill="#8DBEF9"
            />
            <path
                d="M8 6C8 4.6 7.04 3.44 5.75 3.11V2H4.25V3.11C2.96 3.44 2 4.6 2 6C2 7.4 2.96 8.56 4.25 8.89V14H5.75V8.89C7.04 8.56 8 7.39 8 6ZM5 7.5C4.17 7.5 3.5 6.83 3.5 6C3.5 5.17 4.17 4.5 5 4.5C5.83 4.5 6.5 5.17 6.5 6C6.5 6.83 5.83 7.5 5 7.5Z"
                fill="#8DBEF9"
            />
        </svg>
    );
};
