import { SvgIconProps } from 'helpers';

export const ProfileFilled: SvgIconProps = ({ fill = 'white' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24" fill="none">
            <path
                d="M7.00007 11.0449C10.1047 11.0449 12.635 8.56326 12.635 5.51837C12.635 2.47347 10.1047 0 7.00007 0C3.89543 0 1.36511 2.48163 1.36511 5.52653C1.36511 8.57143 3.89543 11.0531 7.00007 11.0531V11.0449Z"
                fill={fill}
            />
            <path
                d="M6.51724 13.4123C2.80499 13.6572 0 16.8572 0 20.5061V23.3878C0 23.7225 0.282996 24 0.624257 24H13.3757C13.717 24 14 23.7225 14 23.3878V20.2612C14 16.3184 10.5957 13.1429 6.52556 13.4123H6.51724Z"
                fill={fill}
            />
        </svg>
    );
};
