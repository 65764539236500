import { Delete, ArrowBack } from 'components/icons';
import { FC, useCallback } from 'react';
import { IconButton } from 'components/buttons';
import { createTariffRuleActions } from 'store';
import { useDispatch } from 'react-redux';
import classes from './PricingHeader.module.scss';
import colors from 'styles/modules/colors.module.scss';

type PricingHeaderProps = FC<{
    isFirst: boolean;
    isLast: boolean;
    index: number;
}>;

const PricingHeader: PricingHeaderProps = ({ isFirst, isLast, index }) => {
    const dispatch = useDispatch();
    const handlePropagation = useCallback(
        (fn: Function) => (e: any) => {
            e.stopPropagation();
            fn();
        },
        []
    );

    const onSortHandler = useCallback(
        (offset: number) => () => dispatch(createTariffRuleActions.sortSlope({ index, offset })),
        [dispatch, index]
    );

    const removeSlopeHandler = useCallback(() => dispatch(createTariffRuleActions.removeSlope(index)), [dispatch, index]);

    return (
        <div className={classes.container}>
            <div className={classes.title} id={`header-slope-${index}`}></div>
            <div className={classes.actions}>
                <IconButton
                    className={`secondary ${classes.down}`}
                    icon={<ArrowBack fill={isLast ? colors.primary40 : undefined} />}
                    onClick={handlePropagation(onSortHandler(1))}
                    disabled={isLast}
                />
                <IconButton
                    className={`secondary ${classes.up}`}
                    icon={<ArrowBack fill={isFirst ? colors.primary40 : undefined} />}
                    onClick={handlePropagation(onSortHandler(-1))}
                    disabled={isFirst}
                />
                <IconButton
                    className={`secondary ${classes.delete}`}
                    icon={<Delete />}
                    onClick={handlePropagation(removeSlopeHandler)}
                />
            </div>
        </div>
    );
};

export default PricingHeader;
