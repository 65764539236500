import { useParams } from 'react-router-dom';
import classes from './EmailConfirmed.module.scss';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from 'context';

import Lottie from 'react-lottie';
import * as confirmedAnimation from '../../animations/Confirmed.json';
import * as failedAnimation from '../../animations/Failed.json';

const EmailConfirmed: FC = () => {
    const { userId, email, otp } = useParams();
    const { confirmEmail } = useContext(UserContext);
    const [isError, setIsError] = useState(false);

    const confirmEmailHandler = useCallback(async () => {
        setIsError(false);
        if (!userId || !email || !otp) return;
        try {
            await confirmEmail(userId, email, otp);
        } catch (error) {
            setIsError(true);
            console.error('Error confirming email:', error);
        }
    }, [confirmEmail, email, otp, userId]);

    useEffect(() => {
        confirmEmailHandler();
    }, [confirmEmailHandler, otp, userId]);

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: isError ? failedAnimation : confirmedAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className={classes.confirmationContainer}>
            <div className={classes.confirmationCard}>
                <div className={classes.grayBg}>
                    <div className={classes.gradientTop}></div>
                </div>
                <div className={classes.header}>
                    <img src={'/assets/images/logo_muvin_color.png'} alt="Muvin Logo" className={classes.logoMuvin} />
                    <h1 className={classes.title}>muvin</h1>
                    <img src={'/assets/images/logo_sis.png'} alt="QSIS Logo" className={classes.logoSis} />
                </div>
                <div className={`${classes.confirmationMessage} ${isError ? classes.confirmationMessageError : ''}`}>
                    {isError ? (
                        <h2>Ci dispiace ma non siamo riusciti a verificare la tua email!</h2>
                    ) : (
                        <h2>Email confermata con successo</h2>
                    )}
                    <div className={classes.animatedBox}>
                        <Lottie options={defaultOptions} height={128} width={128} />
                    </div>
                    <div className={classes.message}>
                        {isError ? <p>Ritenta il processo di verifica.</p> : <p>Grazie per aver confermato la tua email.</p>}
                        {isError ? null : (
                            <p>
                                <b>Benvenuto a bordo!</b>
                            </p>
                        )}
                    </div>
                </div>
                {isError && (
                    <div className={`${classes.support} ${isError ? classes.supportError : ''}`}>
                        Non riesci a risolvere il problema? Invia una segnalazione a{' '}
                        <a href="mailto:support@muvin.com">support@muvin.com</a>
                    </div>
                )}
                <div className={classes.footer}>
                    <img src={'/assets/images/logo_sis_light.png'} alt="QSIS Logo Light" className={classes.sisLogoLight} />
                    <div className={classes.social}>
                        <img src={'/assets/images/instagram_logo.png'} alt="Instagram" className={classes.socialIcon} />
                        <img src={'/assets/images/facebook_logo.png'} alt="Facebook" className={classes.socialIcon} />
                        <img src={'/assets/images/x_logo.png'} alt="X" className={classes.socialIcon} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailConfirmed;

