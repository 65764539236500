import { SvgIconProps } from 'helpers';

export const Timer: SvgIconProps = ({ fill }) => {
    return (
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="timer">
                <path
                    id="Path 187"
                    d="M10.4999 2.5396V1.49917H14V0H5.49993V1.49917H8.99994V2.5396C6.48651 2.73341 4.14596 3.89265 2.46928 5.77406C0.79259 7.65547 -0.0897973 10.1128 0.00723207 12.6304C0.104261 15.1481 1.17314 17.5303 2.98966 19.2772C4.80619 21.0242 7.22905 22 9.74994 22C12.2708 22 14.6938 21.0242 16.5103 19.2772C18.3269 17.5303 19.3957 15.1481 19.4928 12.6304C19.5898 10.1128 18.7074 7.65547 17.0307 5.77406C15.354 3.89265 13.0134 2.73341 10.4999 2.5396ZM9.74994 20.4887C8.11824 20.4887 6.52326 20.0051 5.16655 19.0991C3.80984 18.1931 2.75239 16.9053 2.12797 15.3987C1.50354 13.892 1.34015 12.2341 1.65848 10.6346C1.97681 9.03518 2.76252 7.56598 3.9163 6.41284C5.07009 5.25969 6.54009 4.47439 8.14044 4.15623C9.74078 3.83808 11.3997 4.00138 12.9072 4.62546C14.4147 5.24954 15.7031 6.30637 16.6096 7.66233C17.5161 9.01829 18 10.6125 18 12.2433C17.9976 14.4294 17.1277 16.5252 15.581 18.0711C14.0343 19.6169 11.9373 20.4863 9.74994 20.4887Z"
                    fill={fill}
                />
                <path
                    id="Path 188"
                    d="M10.5 4.99725H9V12.5541L13.031 16.5829L14.0921 15.5225L10.5 11.9324V4.99725Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
