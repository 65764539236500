import { FC } from 'react';
import classes from './Chip.module.scss';

type ChipProps = FC<{ icon: JSX.Element; label: string; active?: boolean }>;

const Chip: ChipProps = ({ active = false, icon, label }) => {
    return (
        <span className={`${classes.chip} ${active ? classes.active : ''}`}>
            {icon}
            <span>{label}</span>
        </span>
    );
};
export default Chip;

