import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Area, Municipality, MunicipalityArea, MunicipalityCounter, Tariff } from 'models';

export interface MunicipalityState {
    municipalities: MunicipalityCounter[];
    selectedMunicipality: MunicipalityCounter | undefined;
    municipality: MunicipalityArea | undefined;
    selectedTable: MunicipalityTableTypes;
    selectedArea: Area | undefined;
    isEditingArea: boolean;
    isCreateAreaOpen: boolean;
}

export enum MunicipalityTableTypes {
    areas = 'areas',
    tariff = 'tariff'
}

const name = 'municipalities';
const initialState: MunicipalityState = createInitialState();
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const municipalityActions = { ...slice.actions };
export const municipalityReducer = slice.reducer;

function createInitialState(): MunicipalityState {
    return {
        municipalities: [],
        selectedMunicipality: undefined,
        selectedTable: MunicipalityTableTypes.tariff,
        municipality: undefined,
        selectedArea: undefined,
        isCreateAreaOpen: false,
        isEditingArea: false
    };
}

function createReducers() {
    return {
        setMunicipalities,
        addMunicipalities,
        setSelectedMunicipality,
        setSelectedTable,
        updateMunicipality,
        deleteMunicipality,
        setSelectedArea,
        addArea,
        replaceUpdatedArea,
        addTariff,
        toggleCreateArea,
        toggleEditArea,
        setMunicipality,
        resetCreateEditArea,
        resetSlice
    };

    function setMunicipalities(state: MunicipalityState, action: PayloadAction<MunicipalityCounter[]>) {
        state.municipalities = action.payload;
    }

    function addMunicipalities(state: MunicipalityState, action: PayloadAction<MunicipalityCounter>) {
        const municipalities = [...state.municipalities];
        municipalities.unshift(action.payload);
        state.municipalities = municipalities;
    }

    function updateMunicipality(state: MunicipalityState, action: PayloadAction<Municipality>) {
        const municipalities = [...state.municipalities];
        const index = municipalities.findIndex(m => m.id === action.payload.id);
        municipalities[index].name = action.payload.name;
        state.selectedMunicipality = municipalities[index];
        state.municipalities = municipalities;
    }

    function deleteMunicipality(state: MunicipalityState, action: PayloadAction<string>) {
        state.municipalities = state.municipalities.filter(({ id }) => id !== action.payload);
        state.selectedMunicipality = undefined;
    }

    function setSelectedMunicipality(state: MunicipalityState, action: PayloadAction<MunicipalityCounter | undefined>) {
        state.selectedMunicipality = action.payload;
    }

    function toggleCreateArea(state: MunicipalityState) {
        state.isCreateAreaOpen = !state.isCreateAreaOpen;
    }

    function toggleEditArea(state: MunicipalityState) {
        state.isEditingArea = !state.isEditingArea;
    }

    function setSelectedArea(state: MunicipalityState, action: PayloadAction<Area | undefined>) {
        state.selectedArea = action.payload;
    }

    function setMunicipality(state: MunicipalityState, action: PayloadAction<MunicipalityArea | undefined>) {
        state.municipality = action.payload;
    }

    function addArea(state: MunicipalityState, action: PayloadAction<Area>) {
        if (!state.municipality || !state.municipality.Areas) return;
        state.municipality.Areas.unshift(action.payload);
    }

    function replaceUpdatedArea(state: MunicipalityState, action: PayloadAction<Area>) {
        if (state.municipality) {
            const index = state.municipality?.Areas.findIndex(area => area.id === action.payload.id);
            if (index && index < 0) return;
            state.municipality!.Areas[index!] = action.payload;
        }
        state.selectedArea = action.payload;
    }

    function addTariff(state: MunicipalityState, action: PayloadAction<Tariff>) {
        if (!state.municipality || !state.municipality.Areas) return;
        state.municipality.Tariffs.unshift(action.payload);
    }

    function setSelectedTable(state: MunicipalityState, action: PayloadAction<MunicipalityTableTypes>) {
        state.selectedTable = action.payload;
    }

    function resetCreateEditArea(state: MunicipalityState) {
        state.isCreateAreaOpen = false;
        state.isEditingArea = false;
    }

    function resetSlice() {
        return createInitialState();
    }
}

