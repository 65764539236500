import Form from 'components/form/Form';
import { FC } from 'react';
import classes from './Login.module.scss';
import { history } from 'helpers';
import { useLazyLoginQuery } from 'store/rtk/user.service';
import { useDispatch } from 'react-redux';
import { snackbarActions } from 'store/snackbar.slice';
import Snackbar from 'components/snackbar/Snackbar';

const FIELDS = [
    { label: 'Email', id: 'email', required: true, validate: { pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ } },
    { label: 'Password', id: 'password', required: true }
];

const Login: FC = () => {
    const [login] = useLazyLoginQuery();
    const dispatch = useDispatch();
    async function onSubmitHandler(formFields: any) {
        const { data } = await login({ email: formFields.email, password: formFields.password });
        if (!data) {
            dispatch(snackbarActions.setMessageAndType({ message: `L' e-mail o password inseriti sono errati.`, type: 'error' }));
            dispatch(snackbarActions.setIsOpen(true));
            return;
        }
        history.navigate(`/verify-otp/${data.id}/${data.telephoneNumber}`);
    }

    return (
        <div className={classes.container}>
            <div className={classes.form}>
                <p>
                    <b>Benvenuto!</b>
                </p>
                <Form formFields={FIELDS} onSubmitHandler={onSubmitHandler}>
                    <div className={classes.passwordRecovery}>
                        <p className={'small'}>Non ricordi la password?</p>
                        <a href={'/reset-password-request'} className={`small ${classes.underline}`}>
                            Resetta la password
                        </a>
                    </div>
                </Form>
                <Snackbar />
            </div>
        </div>
    );
};

export default Login;

