import { IconButton, IconTextButton } from 'components/buttons';
import { ArrowBack, Timer } from 'components/icons';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, TabTypes, TableTypes, UsersState, parkingActions, tabActions } from 'store';
import classes from './UserDetail.module.scss';
import Account from './account/Account';
import PaymentMethods from './paymentMethods/PaymentMethods';
import Vehicles from './vehicles/Vehicles';
import Billing from './billing/Billing';
import { useGetStandardUserDetailQuery, useGetUserDetailDashboardInfoQuery } from 'store/rtk/user.service';
import { DashboardProps } from 'helpers';
import Dashboard from 'components/dashboard/Dashboard';
import { FormGroup } from '@mui/material';
import BlacklistSwitchButton from 'components/buttons/blacklistSwitchButton/BlacklistSwitchButton';
import { UserWithVehicles, UsersStatus } from 'models';
import BlacklistModal from './blacklistModal/BlacklistModal';
import { usePermissionHandler } from 'hook';
import { useGetPaymentContractsQuery } from 'store/rtk/payments.service';

type UserDetailProps = FC<{
    closeDetail: any;
    refetch: () => void;
}>;

const UserDetail: UserDetailProps = ({ closeDetail, refetch }) => {
    const [user, setUser] = useState<UserWithVehicles>({} as UserWithVehicles);
    const { selectedUser } = useSelector<RootState>(x => x.users) as UsersState;
    const { data: dashboardInfo } = useGetUserDetailDashboardInfoQuery({ id: selectedUser! });
    const { data, refetch: refetchUserDetails } = useGetStandardUserDetailQuery({ id: selectedUser! });
    const { data: paymentContracts } = useGetPaymentContractsQuery({ id: user?.customerId! }, { skip: !user?.customerId });
    const [isBlacklistModalOpen, setIsBlacklistModalOpen] = useState<boolean>(false);
    const hasPermission = usePermissionHandler();
    const dispatch = useDispatch();

    useEffect(() => setUser(data!), [data]);

    const dashboardValues: DashboardProps[] = useMemo(
        () => [
            { label: 'Soste Totali', value: dashboardInfo?.totalParking! },
            {
                label: 'Media Mensile',
                value: `${(dashboardInfo?.totalAmount! / 12)?.toFixed(2).replace('.', ',')!}€`,
                info: "Utenti che hanno effettuato soste nell'ultimo mese"
            },
            {
                label: 'Spesa Totale',
                value: `${dashboardInfo?.totalAmount! > 0 ? dashboardInfo?.totalAmount?.toFixed(2).replace('.', ',')! : 0}€`
            }
        ],
        [dashboardInfo]
    );

    const onClickHandler = useCallback(async () => {
        dispatch(parkingActions.setSelectedParking(data?.canceledOrderId));
        dispatch(tabActions.setActiveTable(TableTypes.stops));
    }, [data, dispatch]);

    return (
        <>
            <div>
                <div className={classes.dashboard}>
                    <Dashboard props={dashboardValues} />
                </div>
                <div className={classes.container}>
                    <div className={classes.header}>
                        <div className={classes.backButton}>
                            <IconButton className="tertiary" icon={<ArrowBack />} onClick={closeDetail} />
                            <p>
                                <b>
                                    {data?.firstName} {data?.lastName}
                                </b>
                                {!data?.telephoneNumber && <span className={classes.deletedLabel}> (Eliminato)</span>}
                            </p>
                        </div>
                        <div className={classes.headerLeftSide}>
                            <FormGroup>
                                <BlacklistSwitchButton
                                    disabled={!hasPermission(TabTypes.home, ['can-edit'])}
                                    checked={user?.status === UsersStatus.blacklisted}
                                    onChange={() => setIsBlacklistModalOpen(true)}
                                    label="Blacklist"
                                />
                            </FormGroup>
                            {data?.canceledOrderId! && (
                                <IconTextButton className="secondary" onClick={onClickHandler} icon={<Timer />}>
                                    Ultima sosta
                                </IconTextButton>
                            )}
                        </div>
                    </div>
                    <Account user={data as UserWithVehicles} />
                    {paymentContracts && <PaymentMethods paymentContracts={paymentContracts} />}
                    <Vehicles vehicles={(data as any)?.vehicles!} />
                    <Billing billingInfo={(data as any)?.companyInfo!} />
                </div>
            </div>
            <BlacklistModal
                refetch={refetchUserDetails}
                refetchUsersList={refetch}
                user={user!}
                setUser={setUser}
                isModalOpen={isBlacklistModalOpen}
                setIsModalOpen={setIsBlacklistModalOpen}
            />
        </>
    );
};

export default UserDetail;

