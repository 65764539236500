import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from 'environments';
import { BillsFilters } from 'helpers';
import { Billing, BillingDetailsData } from 'models';
import { store } from 'store';

export const billingsApi = createApi({
    reducerPath: 'billings-api',
    baseQuery: fetchBaseQuery({
        fetchFn(input, init) {
            const authState = store.getState().auth.token as any;
            return fetch(input, { ...init, headers: { authorization: authState!, 'content-type': 'application/json' } });
        },
        baseUrl: `${env.reactAppSocketUrl}${env.apiUrl}`
    }),
    endpoints: builder => ({
        getBillings: builder.query<
            Billing[],
            {
                offset: number;
                searchedWord: string;
            } & BillsFilters
        >({
            query: params => ({
                url: '/company-information/backoffice',
                method: 'GET',
                params
            }),
            keepUnusedDataFor: 100
        }),
        getBillingDetails: builder.query<BillingDetailsData, { name: string }>({
            query: params => ({
                url: '/company-information/by-name',
                method: 'GET',
                params
            }),
            keepUnusedDataFor: 100
        }),
        issueBill: builder.query<BillingDetailsData, { billingsId: string[] }>({
            query: body => ({
                url: '/tickets',
                method: 'POST',
                body
            }),
            keepUnusedDataFor: 100
        }),
        updateBillingDetails: builder.query<BillingDetailsData, BillingDetailsData>({
            query: body => ({
                url: '/company-information',
                method: 'PUT',
                body
            }),
            keepUnusedDataFor: 100
        })
    })
});

export const { useGetBillingsQuery, useGetBillingDetailsQuery, useLazyIssueBillQuery, useLazyUpdateBillingDetailsQuery } =
    billingsApi;

