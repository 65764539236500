export const AlertCircle = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.9994 26C16.4172 25.9999 14.8705 25.5306 13.555 24.6515C12.2395 23.7724 11.2143 22.523 10.6088 21.0612C10.0034 19.5994 9.84505 17.9909 10.1538 16.4391C10.4625 14.8873 11.2244 13.4619 12.3432 12.3432C13.4619 11.2244 14.8873 10.4625 16.4391 10.1538C17.9909 9.84505 19.5994 10.0034 21.0612 10.6088C22.5229 11.2143 23.7724 12.2395 24.6515 13.555C25.5306 14.8705 25.9999 16.4172 26 17.9994C26.0001 19.05 25.7932 20.0905 25.3911 21.0612C24.9891 22.0319 24.3998 22.9139 23.6568 23.6568C22.9139 24.3998 22.0319 24.9891 21.0612 25.3912C20.0904 25.7932 19.05 26.0001 17.9994 26ZM17.9994 20.8568C17.7732 20.8568 17.5522 20.9238 17.3642 21.0494C17.1762 21.175 17.0297 21.3536 16.9431 21.5625C16.8566 21.7714 16.834 22.0013 16.8781 22.223C16.9222 22.4448 17.0311 22.6485 17.191 22.8084C17.3509 22.9683 17.5546 23.0771 17.7763 23.1213C17.9981 23.1654 18.2279 23.1427 18.4368 23.0562C18.6457 22.9697 18.8243 22.8231 18.9499 22.6351C19.0755 22.4471 19.1426 22.2261 19.1426 22C19.1427 21.8498 19.1132 21.7011 19.0557 21.5623C18.9983 21.4235 18.9141 21.2974 18.808 21.1912C18.7018 21.085 18.5757 21.0007 18.437 20.9432C18.2983 20.8857 18.1495 20.8568 17.9994 20.8568ZM17.1403 12.8561V19.7135H18.8551V12.8561H17.1403Z"
                fill="#FB9AA0"
            />
        </svg>
    );
};
