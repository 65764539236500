export const Area = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.12021 6.05994C8.99021 5.97994 8.85021 5.93994 8.68021 5.93994H7.22021V7.78994H8.68021C8.85021 7.78994 8.99022 7.74994 9.11022 7.67994C9.23022 7.59994 9.33022 7.49994 9.40022 7.35994C9.47022 7.21994 9.50022 7.05994 9.50022 6.87994C9.50022 6.69994 9.47022 6.52994 9.40022 6.38994C9.33022 6.24994 9.23022 6.13994 9.11022 6.05994H9.12021Z"
                fill="#8DBEF9"
            />
            <path
                d="M8 2C4.69 2 2 4.69 2 8C2 11.31 4.69 14 8 14C11.31 14 14 11.31 14 8C14 4.69 11.31 2 8 2ZM10.33 7.87C10.17 8.16 9.95 8.38 9.66 8.54C9.37 8.7 9.04 8.78 8.66 8.78H7.23V11.19H6.21V4.96H8.66C9.04 4.96 9.37 5.04 9.66 5.2C9.95 5.36 10.17 5.58 10.33 5.87C10.49 6.16 10.57 6.49 10.57 6.87C10.57 7.25 10.49 7.58 10.33 7.87Z"
                fill="#8DBEF9"
            />
        </svg>
    );
};
