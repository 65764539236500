import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import axios from 'axios';
import { IconButton } from 'components/buttons';
import Chip from 'components/chip/Chip';
import GridTable from 'components/gridTable/GridTable';
import { Download } from 'components/icons';
import { Dot } from 'components/icons/Dot';
import Snackbar from 'components/snackbar/Snackbar';
import { env } from 'environments';
import { formatDate, getDocumentStatus } from 'helpers';
import { FC, useCallback, useMemo, useState } from 'react';
import { store } from 'store';
import { DocumentRow, useGetDocumentsQuery } from 'store/rtk/document.service';
import colors from 'styles/modules/colors.module.scss';
import classes from './ManageContentTable.module.scss';
import AddDocument from './addDocument/AddDocument';

enum Tipology {
    termsAndConditions = 'Termini e Condizioni',
    privacyPolicy = 'Privacy Policy',
    marketingAndProfiling = 'Marketing e Profilazione'
}

const ManageContentTable: FC = () => {
    const [paginationModel, setPaginationModel] = useState({ pageSize: 20, page: 0 });
    const { data, refetch } = useGetDocumentsQuery({});

    const HEADERS: GridColDef[] = [
        { headerName: 'Tipologia', field: 'tipology', minWidth: 170, sortable: false, flex: 1 },
        { headerName: 'Nome', field: 'name', minWidth: 170, sortable: false, flex: 1 },
        { headerName: 'Caricato il', field: 'updatedAt', minWidth: 150, sortable: false, flex: 1 },
        { headerName: 'Valido dal', field: 'startingTime', minWidth: 150, sortable: false, flex: 1 },
        { headerName: 'Scadenza', field: 'expiration', minWidth: 100, sortable: false, flex: 1 },
        {
            headerName: 'Status',
            field: 'status',
            minWidth: 100,
            sortable: false,
            renderCell: (params: GridRenderCellParams<any, any>) => <div>{params.row.status}</div>,
            flex: 1
        },
        {
            headerName: '',
            field: 'download',
            minWidth: 100,
            renderCell: (params: GridRenderCellParams<any, any>) => (
                <div className={classes.icon}>
                    <IconButton
                        icon={<Download />}
                        onClick={() => handleDocumentDownload(params.row.id, params.row.name)}
                        className={'secondary'}
                    />
                </div>
            ),
            sortable: false,
            flex: 1
        }
    ];

    const getStatus = useCallback((expTime: string | null, startingTime: string) => {
        const getStatusLabel = (status: string) => {
            switch (status) {
                case 'ongoing':
                    return <Chip label="In corso" icon={<Dot />} />;
                case 'expiring':
                    return <Chip label="In scadenza" icon={<Dot fill={colors.secondary80} />} />;
                case 'expired':
                    return <Chip label="Scaduto" icon={<Dot fill={colors.grey50} />} />;
                case 'programmed':
                    return <Chip label="Programmato" icon={<Dot />} />;
                default:
                    return 'Programmato';
            }
        };

        return getStatusLabel(getDocumentStatus(expTime, startingTime));
    }, []);

    const sortedData = useMemo(() => {
        return (data ? [...data] : []).sort((a, b) => {
            if (a.expirationTime === null && b.expirationTime === null) {
                return 0;
            }
            if (a.expirationTime === null) {
                return -1;
            }
            if (b.expirationTime === null) {
                return 1;
            }
            return Number(new Date(b.expirationTime)) - Number(new Date(a.expirationTime));
        });
    }, [data]);

    const rows = useMemo(
        () =>
            sortedData.map((document: DocumentRow) => ({
                id: document.id,
                tipology: Tipology[document.tipology as keyof typeof Tipology],
                name: document.name,
                updatedAt: document.updatedAt ? formatDate(new Date(document.updatedAt).getTime(), true) : 'Nessuna',
                startingTime: document.startingTime ? formatDate(new Date(document.startingTime).getTime(), true) : 'Nessuna',
                expiration: document.expirationTime ? formatDate(new Date(document.expirationTime).getTime(), true) : 'Nessuna',
                status: getStatus(document.expirationTime, document.startingTime)
            })),
        [getStatus, sortedData]
    );

    const handleDocumentDownload = async (id: string, fileName: string) => {
        const authState = store.getState().auth.token;
        await axios({
            url: `${env.reactAppSocketUrl}${env.apiUrl}/documents/file-by-id/${id}`,
            method: 'GET',
            responseType: 'blob',
            headers: {
                Authorization: `${authState}`
            }
        })
            .then(response => {
                const href = URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch(error => {
                console.log(error);
            });
    };

    return (
        <div className={classes.container}>
            <div className={classes.create}>
                <AddDocument allDocuments={data} refetch={refetch} />
            </div>
            <GridTable
                headers={HEADERS}
                rows={rows}
                rowCount={data?.length ?? 0}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
            />
            <Snackbar />
        </div>
    );
};

export default ManageContentTable;

