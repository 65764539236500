import { SvgIconProps } from 'helpers';

export const RadioUnchecked: SvgIconProps = ({ fill = '#C4DFFF' }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 0C3.58 0 0 3.58 0 8C0 12.42 3.58 16 8 16C12.42 16 15.99 12.42 16 8C16 3.58 12.42 0 8 0ZM8 14.5C4.42 14.5 1.5 11.58 1.5 8C1.5 4.42 4.42 1.5 8 1.5C11.58 1.5 14.5 4.42 14.5 8C14.5 11.58 11.58 14.5 8 14.5Z"
                fill={fill}
            />
        </svg>
    );
};
