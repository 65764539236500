import { Switch } from '@mui/material';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import Modal from 'components/modal/Modal';
import Snackbar from 'components/snackbar/Snackbar';
import { SetState } from 'helpers';
import { FileType } from 'hook';
import useDocumentState from 'hook/useDocumentState';
import { FC, useEffect } from 'react';
import { DocumentRow } from 'store/rtk/document.service';
import classes from './AddDocumentModal.module.scss';
import DocumentDetails from './documentDetails/DocumentDetails';
import DocumentDragDrop from './documentDragDrop/DocumentDragDrop';
import DocumentForm from './documentForm/DocumentForm';
import DocumentActions from './documentActions/DocumentActions';
import LoadedFileCard from './loadedFileCard/LoadedFileCard';

type AddDocumentModalProps = {
    isModalOpen: boolean;
    setIsModalOpen: SetState<boolean>;
    allDocuments: DocumentRow[] | undefined;
    refetch: () => void;
};

export type Options = { id: FileType; label: string }[];

const options: Options = [
    { id: 'termsAndConditions', label: 'Termini & Condizioni' },
    { id: 'privacyPolicy', label: 'Privacy Policy' },
    { id: 'marketingAndProfiling', label: 'Marketing e Profilazione' }
];

const AddDocumentModal: FC<AddDocumentModalProps> = ({ isModalOpen, setIsModalOpen, refetch, allDocuments }) => {
    const {
        file,
        setFile,
        selectValue,
        setSelectValue,
        isConsent,
        setIsConsent,
        isLoading,
        isDocumentExists,
        date,
        setDate,
        handleCreateDocument,
        handleCheckExistingDocument,
        label
    } = useDocumentState(allDocuments, refetch, setIsModalOpen, isModalOpen);

    useEffect(() => {
        handleCheckExistingDocument();
    }, [handleCheckExistingDocument]);

    return (
        <>
            <Modal title="Specifica tipologia documento" handleClose={() => setIsModalOpen(false)} isOpen={isModalOpen}>
                <div className={classes.modalContent}>
                    <DocumentForm selectValue={selectValue} setSelectValue={setSelectValue} options={options} />
                    {isLoading ? (
                        <div className={classes.loading}>
                            <LoadingSpinner />
                        </div>
                    ) : selectValue && file ? (
                        <LoadedFileCard currentFile={file} setFile={setFile} />
                    ) : (
                        <DocumentDragDrop selectValue={selectValue} setFile={setFile} />
                    )}
                    <div className={classes.consent}>
                        <div className={classes.switch}>
                            <Switch checked={isConsent} onChange={() => setIsConsent(!isConsent)} disabled={!file} />
                            <b>Consenso obbligatorio</b>
                        </div>
                        <DocumentDetails date={date} setDate={setDate} isDocumentExists={isDocumentExists} label={label} />
                    </div>
                    <DocumentActions
                        setIsModalOpen={setIsModalOpen}
                        handleCreateDocument={handleCreateDocument}
                        isDisabled={!file}
                    />
                </div>
                <Snackbar />
            </Modal>
        </>
    );
};

export default AddDocumentModal;

