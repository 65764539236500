import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const ArrowDownSmall: SvgIconProps = ({ fill = colors.primary60 }) => {
    return (
        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="back_small">
                <path
                    id="Vector"
                    d="M15.6687 2.01248L8.66873 9.01248C8.37873 9.30248 7.89873 9.30248 7.60873 9.01248L0.608734 2.01248L1.66873 0.95248L8.13873 7.42248L14.6087 0.95248L15.6687 2.01248Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};

