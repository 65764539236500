import { Spinner } from 'components/icons/Spinner';
import { FC } from 'react';
import classes from './LoadingSpinner.module.scss';

type LoadingSpinnerProps = FC<{}>;

const LoadingSpinner: LoadingSpinnerProps = ({}) => {
    return (
        <div className={classes.spinner}>
            <Spinner />
        </div>
    );
};

export default LoadingSpinner;

