export const Stallo = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.47395 11.9907C9.34404 11.9907 9.21413 11.963 9.10421 11.8981L1.37973 7.82287C1.14989 7.70246 1 7.47091 1 7.22084C1 6.97077 1.1399 6.73922 1.37973 6.61881L6.15632 4.0903C6.38615 3.9699 6.66595 3.9699 6.89579 4.0903L14.6203 8.17482C14.8501 8.29522 15 8.52677 15 8.77684C15 9.02692 14.8601 9.25847 14.6203 9.37887L9.84368 11.9074C9.73376 11.9722 9.60385 12 9.47395 12V11.9907ZM3.25839 7.22084L9.46395 10.4996L12.7316 8.77684L6.53605 5.48886L3.26838 7.21158L3.25839 7.22084Z"
                fill="#8DBEF9"
            />
        </svg>
    );
};
