import { FormControlLabel, Switch } from '@mui/material';
import { FC } from 'react';
import colors from 'styles/modules/colors.module.scss';

type SwitchButtonProps = FC<{
    onChange?: () => void;
    checked?: boolean;
    label?: string;
    disabled?: boolean;
}>;

export const SwitchButton: SwitchButtonProps = ({ onChange, checked, label, disabled }) => {
    return (
        <FormControlLabel
            sx={{
                '.MuiFormControlLabel-label': {
                    color: 'white !important',
                    opacity: disabled ? 0.6 : 1
                }
            }}
            value="start"
            control={
                <Switch
                    disabled={disabled}
                    onChange={onChange}
                    checked={checked}
                    color="primary"
                    sx={{
                        padding: '0.5rem',
                        '.MuiSwitch-thumb': {
                            backgroundColor: colors.primary60
                        },
                        '.MuiSwitch-track': {
                            backgroundColor: colors.primary20,
                            opacity: disabled ? '0.6 !important' : 1,
                            borderRadius: '1.25rem'
                        },
                        '& .MuiSwitch-switchBase': {
                            '&.Mui-checked': {
                                '+ .MuiSwitch-track': {
                                    backgroundColor: colors.primary60,
                                    opacity: 1
                                },
                                '.MuiSwitch-thumb': {
                                    backgroundColor: colors.white
                                }
                            }
                        }
                    }}
                />
            }
            label={<b>{label}</b>}
            labelPlacement="start"
        />
    );
};

