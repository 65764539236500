import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from 'environments';
import { store } from 'store';

export const paymentsApi = createApi({
    reducerPath: 'payments-api',
    baseQuery: fetchBaseQuery({
        fetchFn(input, init) {
            const authState = store.getState().auth.token as any;
            return fetch(input, { ...init, headers: { authorization: authState!, 'content-type': 'application/json' } });
        },
        baseUrl: `${env.reactAppSocketUrl}${env.apiUrl}`
    }),
    endpoints: builder => ({
        getPaymentContracts: builder.query<any, { id: string }>({
            query: params => ({
                url: `/payments/contracts/${params.id}`,
                method: 'GET'
            })
        })
    })
});

export const { useGetPaymentContractsQuery } = paymentsApi;

