import TextButton from 'components/buttons/textButton/TextButton';
import Modal from 'components/modal/Modal';
import { SetState } from 'helpers';
import { UserWithVehicles, UsersStatus } from 'models';
import { FC, useCallback, useState } from 'react';
import { useLazyUpdateUserStatusQuery, usersApi } from 'store/rtk/user.service';
import classes from './BlacklistModal.module.scss';
import { customerServiceApi } from 'store/rtk/customerService.service';
import { useDispatch } from 'react-redux';
import { customerServiceActions } from 'store';

type BlacklistModalProps = FC<{
    user: UserWithVehicles;
    setUser: SetState<UserWithVehicles>;
    isModalOpen: boolean;
    setIsModalOpen: SetState<boolean>;
    refetch: () => void;
    refetchUsersList: () => void;
}>;

const BlacklistModal: BlacklistModalProps = ({ user, setUser, isModalOpen, setIsModalOpen, refetch, refetchUsersList }) => {
    const [updateStatus] = useLazyUpdateUserStatusQuery();
    const [isBlacklistedFor, setIsBlacklistedFor] = useState<string>('');
    const dispatch = useDispatch();

    const onClickHandler = useCallback(async () => {
        const res = await updateStatus({
            id: user.id,
            status: user?.status === UsersStatus.blacklisted ? UsersStatus.enabled : UsersStatus.blacklisted,
            isBlacklistedFor: isBlacklistedFor
        });
        if (!res.data) return;
        dispatch(customerServiceActions.updateItemsUserStatus({ id: user.id, status: res.data.status }));
        dispatch(customerServiceApi.util.resetApiState());
        dispatch(usersApi.util.resetApiState());
        setUser((user: any) => ({ ...user, status: res.data!.status }));
        setIsModalOpen(false);
        refetch();
        refetchUsersList();
    }, [updateStatus, user?.id, user?.status, isBlacklistedFor, dispatch, setUser, setIsModalOpen, refetch, refetchUsersList]);

    if (user?.status !== UsersStatus.blacklisted)
        return (
            <Modal
                className={classes.add}
                isOpen={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                title="Aggiungi utente in blacklist"
            >
                <label>
                    Stai per inserire <b> {`${user?.firstName} ${user?.lastName}`}</b> in Blacklist. L’utente non potrà più
                    effettuare soste con Muvin e verrà notificato della situazione in app. <br /> <br /> Inserisci una nota
                    riguardo la motivazione del blocco, l’utente la riceverà come notifica.
                </label>
                <div className={classes.motivation}>
                    <label className="small">Motivazione</label>
                    <textarea
                        onChange={(event: any) => setIsBlacklistedFor(event.target.value)}
                        maxLength={80}
                        placeholder="Es. Il numero di telefono associato risulta rubato."
                    />
                </div>
                <div className={classes.buttons}>
                    <TextButton onClick={() => setIsModalOpen(false)} className="secondary">
                        Annulla
                    </TextButton>
                    <TextButton onClick={onClickHandler} className="primary">
                        Conferma
                    </TextButton>
                </div>
            </Modal>
        );

    return (
        <Modal
            className={classes.remove}
            isOpen={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
            title="Rimuovi utente dalla blacklist"
        >
            <label>
                Vuoi rimuovere <b> {`${user?.firstName} ${user?.lastName}`}</b> dalla blacklist? L’utente potrà di nuovo
                effettuare soste con Muvin e verrà notificato della situazione in app.
            </label>
            <div className={classes.buttons}>
                <TextButton onClick={() => setIsModalOpen(false)} className="secondary">
                    Annulla
                </TextButton>
                <TextButton onClick={onClickHandler} className="primary">
                    Conferma
                </TextButton>
            </div>
        </Modal>
    );
};

export default BlacklistModal;

