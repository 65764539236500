import { SpreadsheetsFriend } from 'components/icons';
import classes from './NoResult.module.scss';

const NoResult = () => {
    return (
        <div className={classes.noresults}>
            <SpreadsheetsFriend />
            Nessun risultato.
        </div>
    );
};
export default NoResult;
