import { Box, Slider } from '@mui/material';
import React, { FC } from 'react';
import classes from './PaymentValueFilter.module.scss';

type PaymentValueFilterProps = FC<{
    activeFilter: number[];
    setActiveFilters: any;
}>;

const marks = [
    { value: 0, label: '0€' },
    { value: 20, label: '5€' },
    { value: 40, label: '10€' },
    { value: 60, label: '20€' },
    { value: 80, label: '50€' },
    { value: 100, label: '+100€' }
];

const PaymentValueFilter: PaymentValueFilterProps = ({ activeFilter, setActiveFilters }) => {
    return (
        <div className={classes.paymentValueContainer}>
            <Box>
                <div className={classes.labelContainer}>
                    <label className="small">
                        <b>Valore del pagamento</b>
                    </label>
                    <label className={`small ${classes.count}`}>
                        <b>0€-100€+</b>
                    </label>
                </div>
                <Slider
                    className={classes.slider}
                    value={activeFilter}
                    onChange={(event: any) => setActiveFilters('paymentValueRange', event.target.value)}
                    defaultValue={0}
                    marks={marks}
                    step={null}
                    aria-labelledby="discrete-slider-restrict"
                />
            </Box>
        </div>
    );
};

export default PaymentValueFilter;
