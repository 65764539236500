import { GridColDef } from '@mui/x-data-grid';
import GridTable from 'components/gridTable/GridTable';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, ParkingState, parkingActions, filtersActions } from 'store';
import ParkingDetail from './parkingDetail/ParkingDetail';
import { IconButton } from 'components/buttons';
import { ArrowForward, AttentionCircle, CheckmarkChecked, CircleRefound, Filters, RadioUnchecked } from 'components/icons';
import {
    DEFAULT_PARKING_FILTER_VALUES,
    DashboardProps,
    ParkingFilters as ParkingFiltersInterface,
    TableExtractionFormat,
    formatDate,
    openApiLink
} from 'helpers';
import ParkingFilters from './parkingFilters/ParkingFilters';
import { useGetAllParkingQuery, useGetParkingDashboardInfoQuery } from 'store/rtk/parking.service';
import classes from './ParkingTable.module.scss';
import Dashboard from 'components/dashboard/Dashboard';
import { BillingStatus, OrderStatus } from 'models';
import { IsOnGoing } from 'components/icons/IsOnGoing';
import TableExportDropdown from 'components/tableExportDropdown/TableExportDropdown';
import { useLazyGetExtractionTokenQuery } from 'store/rtk/auth.service';
import moment from 'moment';
import deepEqual from 'deep-equal';

const HEADERS: GridColDef[] = [
    { field: 'code', flex: 70, headerName: 'Codice', valueGetter: props => (props.row.code ? props.row.code : '***') },
    {
        field: 'name',
        flex: 130,
        headerName: 'Nome',
        valueGetter: props => (props.row.name ? props.row.name : '***')
    },
    { field: 'city', flex: 130, headerName: 'Città', valueGetter: props => (props.row.code ? props.row.city : '***') },
    { field: 'plate', flex: 80, headerName: 'Targa' },
    {
        field: 'orderAmount',
        flex: 70,
        headerName: 'Spesa',
        valueGetter: props => `${props.row.orderAmount.toFixed(2).replace('.', ',')}€`
    },
    {
        field: 'paymentStatus',
        flex: 60,
        headerName: 'Stato',
        sortable: false,
        renderCell: props => getPaymentStatusIcon(props.row.paymentStatus)
    },
    {
        field: 'billingStatus',
        headerName: 'Fattura',
        flex: 80,
        sortable: false,
        renderCell: props => getBillingStatusIcon(props.row.billingStatus)
    },
    {
        field: 'startTime',
        headerName: 'Inizio',
        flex: 150,
        valueGetter: props => formatDate(props.row.startTime)
    }
];

const getPaymentStatusIcon = (paymentStatus: OrderStatus) => {
    if (paymentStatus === OrderStatus.paid) return <CheckmarkChecked />;
    if (paymentStatus === OrderStatus.created) return <RadioUnchecked />;
    if (paymentStatus === OrderStatus.refunded) return <CircleRefound />;
    if (paymentStatus === OrderStatus.canceled) return <AttentionCircle />;
    return <></>;
};

const getBillingStatusIcon = (billingStatus: BillingStatus) => {
    if (billingStatus === BillingStatus.issued) return <CheckmarkChecked />;
    if (billingStatus === BillingStatus.pending) return <RadioUnchecked />;
    return <></>;
};

const getOnGoingStatusIcon = (date: number) => (date > Date.now() ? <IsOnGoing /> : <></>);

const paymentRangeRecord: Record<number, number> = {
    0: 0,
    20: 5,
    40: 10,
    60: 20,
    80: 50,
    100: 10000
};

type ParkingTableProps = FC<{
    toggleWidth: () => void;
    icon: JSX.Element;
}>;

const ParkingTable: ParkingTableProps = ({ toggleWidth, icon }) => {
    const [getToken] = useLazyGetExtractionTokenQuery();
    const [areFiltersOpen, setAreFiltersOpen] = useState<boolean>(false);
    const [activeFilters, setActiveFilters] = useState<ParkingFiltersInterface>(DEFAULT_PARKING_FILTER_VALUES);
    const [searchedWord, setSearchedWord] = useState<string>('');
    const [orderBy, setOrderBy] = useState<{ column: string; type: string }>({ column: 'startTime', type: 'desc' });
    const { selectedParking } = useSelector<RootState>(x => x.parking) as ParkingState;
    const { city, date } = useSelector((x: RootState) => x.filters);
    const [paginationModel, setPaginationModel] = useState({ pageSize: 20, page: 0 });
    const {
        data: page,
        isFetching,
        refetch: refetchAllParkings
    } = useGetAllParkingQuery({
        offset: paginationModel.page * paginationModel.pageSize,
        pageSize: paginationModel.pageSize,
        city: !city ? '' : city,
        date: JSON.stringify(date),
        searchedWord,
        billingState: JSON.stringify(activeFilters.billingState),
        paymentState: JSON.stringify(activeFilters.paymentState),
        paymentValueRange: JSON.stringify(activeFilters.paymentValueRange.map(value => paymentRangeRecord[value])),
        vehicle: JSON.stringify(activeFilters.vehicleTypes),
        inProgress: activeFilters.inProgress,
        orderBy: JSON.stringify(orderBy)
    });
    const { data: dashboardInfo } = useGetParkingDashboardInfoQuery({
        city: !city ? '' : city,
        date: JSON.stringify(date),
        searchedWord,
        billingState: JSON.stringify(activeFilters.billingState),
        paymentState: JSON.stringify(activeFilters.paymentState),
        paymentValueRange: JSON.stringify(activeFilters.paymentValueRange.map(value => paymentRangeRecord[value])),
        vehicle: JSON.stringify(activeFilters.vehicleTypes),
        inProgress: activeFilters.inProgress
    });
    const dispatch = useDispatch();

    const startExtraction = useCallback(
        async (format: TableExtractionFormat) => {
            const columnsDictionary = HEADERS.filter(header => !header.disableExport)
                .map(header => ({
                    header: header.headerName,
                    key: header.field
                }))
                .map(value => {
                    if (value.key === 'orderAmount') return { ...value, header: 'Importo totale' };
                    else if (value.key === 'city') return { ...value, header: 'Comune' };
                    return value;
                });

            columnsDictionary.push({ header: 'Fine', key: 'finishTime' });
            const authorization = (await getToken().unwrap()).token;
            const queryParams = {
                city: !city ? '' : city,
                date: JSON.stringify(date),
                searchedWord,
                inProgress: activeFilters.inProgress,
                billingState: JSON.stringify(activeFilters.billingState),
                paymentState: JSON.stringify(activeFilters.paymentState),
                paymentValueRange: JSON.stringify(activeFilters.paymentValueRange.map(value => paymentRangeRecord[value])),
                vehicle: JSON.stringify(activeFilters.vehicleTypes),
                orderBy: JSON.stringify(orderBy),
                format: format.toLowerCase(),
                columns: JSON.stringify(columnsDictionary),
                authorization,
                sheet: 'Soste'
            };
            openApiLink('extraction/ticket', queryParams);
        },
        [getToken, activeFilters, searchedWord, city, date, orderBy]
    );

    const areFiltersActive = useMemo(
        () =>
            !(
                deepEqual(activeFilters, DEFAULT_PARKING_FILTER_VALUES) &&
                city.length === 0 &&
                date.endDate === 0 &&
                date.startDate === 0
            ),
        [activeFilters, city, date]
    );

    const headers = useMemo(
        () =>
            HEADERS.concat({
                field: 'export-dropdown',
                minWidth: 90,
                maxWidth: 90,
                disableExport: true,
                sortable: false,
                renderHeader: () => {
                    if (page?.length === 0) return <></>;
                    return <TableExportDropdown onFormatSelect={format => startExtraction(format)} />;
                },
                renderCell: props => {
                    return (
                        <div className={classes.icon}>
                            {getOnGoingStatusIcon(props.row.finishTime)}
                            <div className={classes.arrow}>
                                <ArrowForward />
                            </div>
                        </div>
                    );
                }
            }),
        [startExtraction, page?.length]
    );
    const dashboardValues: DashboardProps[] = useMemo(() => {
        let averageDuration: string;
        if (!dashboardInfo?.totalTime || !+dashboardInfo?.finishedParking!) averageDuration = '0h 0m';
        else {
            const averageTime = dashboardInfo?.totalTime! / +dashboardInfo?.finishedParking!;
            const duration = moment.duration(averageTime);
            averageDuration = `${duration.hours()}h ${duration.minutes()}m`;
        }
        return [
            {
                label: 'Incasso totale',
                value: dashboardInfo?.totalAmount ? `${dashboardInfo?.totalAmount.toFixed(2).replace('.', ',')}€` : '0.00€'
            },
            {
                label: 'Incasso medio / sosta',
                value: dashboardInfo?.totalAmount
                    ? `${(dashboardInfo?.totalAmount / +dashboardInfo?.finishedParking!).toFixed(2).replace('.', ',')}€`
                    : '0.00€'
            },
            {
                label: 'Permanenza media',
                value: averageDuration
            }
        ];
    }, [dashboardInfo]);

    const onRowSelection = useCallback((value: any) => dispatch(parkingActions.setSelectedParking(value.row.id)), [dispatch]);

    const resetFilters = useCallback(() => {
        dispatch(filtersActions.setDate({ startDate: 0, endDate: 0 }));
        dispatch(filtersActions.setActiveCity(''));
        setActiveFilters(DEFAULT_PARKING_FILTER_VALUES);
    }, [dispatch]);

    const onFiltersChangeHandler = useCallback(
        (key: keyof ParkingFiltersInterface, value: any) => {
            if (key === 'city') {
                dispatch(filtersActions.setActiveCity(value));
                return;
            }
            if (key === 'date') {
                dispatch(filtersActions.setDate(value));
                return;
            }
            setActiveFilters(prev => {
                const filters = { ...prev, [key]: value };
                return filters;
            });
        },
        [dispatch]
    );

    const onInputChangeHandler = useCallback((value: any) => {
        setSearchedWord(value);
    }, []);

    if (selectedParking) return <ParkingDetail refetch={refetchAllParkings} />;

    if (areFiltersOpen)
        return (
            <ParkingFilters
                dashboardValues={dashboardValues}
                resetFilters={resetFilters}
                setAreFiltersOpen={setAreFiltersOpen}
                activeFilters={activeFilters}
                setActiveFilters={onFiltersChangeHandler}
            />
        );

    return (
        <>
            <div className={classes.dashboard}>
                <Dashboard props={dashboardValues} />
            </div>
            <div className={classes.filterButton}>
                <IconButton className="tertiary" icon={<Filters />} onClick={() => setAreFiltersOpen(true)} />
                {areFiltersActive && (
                    <span className={classes.active}>
                        <IsOnGoing />
                    </span>
                )}
            </div>
            <IconButton className={`tertiary ${classes.fullScreen}`} icon={icon} onClick={toggleWidth} />
            <div className={classes.container}>
                <GridTable
                    enableSearchBar
                    enableToolbar={true}
                    headers={headers}
                    rows={page ?? []}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    onRowSelection={onRowSelection}
                    isFetching={isFetching}
                    rowCount={+(dashboardInfo?.totalParking ?? 0)}
                    getRowClassName={(params: any) => (params.row.paymentStatus === 'canceled' ? classes.canceled : '')}
                    setSearchedWord={onInputChangeHandler}
                    setOrderBy={setOrderBy}
                    defaultSortColumn="startTime"
                    sortModel={orderBy}
                />
            </div>
        </>
    );
};

export default ParkingTable;

