import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import AccordionTable from './accordionTable/AccordionTable';

type BillingDetailsTableProps = FC<{
    showOnlyNonIssuedBills: boolean;
}>;

const BillingDetailsTable: BillingDetailsTableProps = ({ showOnlyNonIssuedBills }) => {
    const { billingDetails } = useSelector((x: RootState) => x.billings);

    const onlyToBeIssued = useMemo(() => {
        if (!billingDetails) return;
        const filteredUsersBillings = billingDetails.usersBillings.map(userBilling => ({
            ...userBilling,
            userBillings: userBilling.userBillings.filter(billing => billing.billing_status !== 'issued')
        }));

        return {
            ...billingDetails,
            usersBillings: filteredUsersBillings
        };
    }, [billingDetails]);

    const dataToMap = showOnlyNonIssuedBills ? onlyToBeIssued : billingDetails;

    if (!dataToMap?.usersBillings) return <></>;
    if (dataToMap.usersBillings.every(user => user.userBillings.length === 0)) return <></>;

    return <AccordionTable dataToMap={dataToMap} />;
};

export default BillingDetailsTable;

