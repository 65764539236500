import DeckGLMap from '@deck.gl/react/typed';
import { IconButton } from 'components/buttons';
import { Erase, Undo } from 'components/icons';
import MapPopup from 'components/mapPopup/MapPopup';
import MapSearchBar from 'components/mapSearchbar/MapSearchBar';
import UserInfoCard from 'components/userInfoCard/UserInfoCard';
import { MapContext } from 'context';
import { DEFAULT_INITIAL_VIEW_STATE, DEFAULT_LAYER_DATA, MAPBOX_TOKEN } from 'helpers';
import { useDrawLayer } from 'hook/useDrawLayer';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import ReactMap from 'react-map-gl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, TabTypes, drawActions } from 'store';
import classes from './Map.module.scss';

const Map: FC = () => {
    const { areasLayer } = useContext(MapContext);
    const [currentViewState, setCurrentViewState] = useState(DEFAULT_INITIAL_VIEW_STATE);
    const { initialViewState, data: drawData, mode } = useSelector((x: RootState) => x.draw);
    const { pickedArea } = useSelector((x: RootState) => x.areas);
    const { activeTab } = useSelector((x: RootState) => x.tab);
    const { isCreateAreaOpen, isEditingArea } = useSelector((x: RootState) => x.municipalities);
    const [isMoving, setIsMoving] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const debounceHandler = setTimeout(() => {
            setIsMoving(false);
        }, 100);

        return () => {
            clearTimeout(debounceHandler);
        };
    }, [currentViewState]);

    const getCursorType = useCallback((isDragging: boolean, isHovering: boolean) => {
        if (isDragging) return 'grabbing';
        return isHovering ? 'pointer' : 'grab';
    }, []);

    const drawLayer = useDrawLayer();

    useEffect(() => {
        dispatch(drawActions.setMode(isCreateAreaOpen ? 'drawPolygon' : 'modify'));
    }, [dispatch, isCreateAreaOpen]);

    const undoHandler = () => {
        if (drawLayer.state?.mode._clickSequence.length !== 0) return drawLayer.state?.mode._clickSequence.pop();
        const feature = { ...drawData.features[0] };
        if (!feature.geometry) return;
        const coords = [...feature.geometry.coordinates[0]];
        coords.splice(-1);
        drawLayer.state.mode._clickSequence = coords;
        const updatedData = { ...drawData, features: [] };
        dispatch(drawActions.setData(updatedData));
        dispatch(drawActions.setMode('drawPolygon'));
    };

    const clearHandler = useCallback(() => {
        dispatch(drawActions.setData(DEFAULT_LAYER_DATA));
        dispatch(drawActions.setMode('drawPolygon'));
    }, [dispatch]);

    return (
        <>
            <div className={classes.mapContainer}>
                {isEditingArea || isCreateAreaOpen ? (
                    <div className={classes.drawingTool}>
                        <IconButton icon={<Erase />} className="secondary" onClick={clearHandler} />
                        {mode === 'drawPolygon' ? (
                            <IconButton icon={<Undo />} className="secondary" onClick={undoHandler} />
                        ) : (
                            <></>
                        )}
                    </div>
                ) : (
                    <div
                        className={`${classes.searchBar} ${
                            activeTab === TabTypes.municipalitySettings ? classes.municipalityView : ''
                        }`}
                    >
                        <MapSearchBar />
                    </div>
                )}
                <div className={classes.__userCard}>
                    <UserInfoCard></UserInfoCard>
                </div>
            </div>
            <DeckGLMap
                style={activeTab === TabTypes.home ? { left: 'calc(50% + 60px)' } : {}}
                id="Muvin"
                width={activeTab === TabTypes.home ? 'calc(50% - 61px)' : '100%'}
                height="100vh"
                controller={{ doubleClickZoom: false, dragRotate: false }}
                layers={[isCreateAreaOpen || isEditingArea ? drawLayer : undefined, areasLayer]}
                initialViewState={initialViewState}
                onViewStateChange={params => {
                    setIsMoving(true);
                    setCurrentViewState(params.viewState as any);
                }}
                pickingRadius={1}
                getCursor={({ isDragging, isHovering }: any) => getCursorType(isDragging, isHovering)}
                glOptions={{ preserveDrawingBuffer: true }}
            >
                <ReactMap
                    viewState={currentViewState as any}
                    style={{ zIndex: 4 }}
                    mapboxAccessToken={MAPBOX_TOKEN}
                    mapStyle={'mapbox://styles/atemperini/clz70tzjl00rc01nu16ym4sya'}
                ></ReactMap>
                <ReactMap viewState={currentViewState as any} style={{ zIndex: 4 }} mapboxAccessToken={MAPBOX_TOKEN}>
                    {pickedArea && <MapPopup pickedArea={pickedArea} closeOnMove={!isMoving} />}
                </ReactMap>
            </DeckGLMap>
        </>
    );
};

export default Map;

